import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';
import { environment } from '../../../environments/environment';
import { FileUpload } from '../../shared/model/file-upload.model';
import { EmployeeClocking } from '../model/employee-clocking.model';
import { Employee } from '../../employee/model/employee.model';
import * as MOMENT from 'moment';
import { Page } from '../../shared/model/page.model';
import { EmployeeSummary } from '../../overrtime-dashboard/model/employee-summary.model';
import { EmployeeSummaryDetail } from '../../overrtime-dashboard/model/employee-summary-detail';
import { EmployeeTimeLeftToClockOut } from '../../employee/model/employee-time-left-to-clock-out.model';
import { EmployeeSummaryExport } from '../../overrtime-dashboard/model/employee-summary-export.model';
import { EmployeeDetailExport } from '../model/employee-detail-export.model';
import { map } from 'rxjs/operators';
import { EmployeeService } from 'app/employee/service/employee.service';
import { CompanySettingServiceV2 } from 'app/company/service/company-setting.v2.service';

@Injectable({ providedIn: 'root' })
export class EmployeeClockingService extends AbstractService<EmployeeClocking> {

    private uploadUrl = Constants.ROUTES.FILEUPLOAD;
    private apiURl = environment.server_ip;
    private apiURlv2 = environment.v2_server_ip;
    private notificationUrl = `${environment.v2_server_ip}/${Constants.ROUTES.NOTIFICATION}/email`;
    constructor(private http: HttpClient,
        private companySettingService: CompanySettingServiceV2,
        private employeeService: EmployeeService) {
        super(http, Constants.ROUTES.EMPLOYEECLOCKING, '');
    }

    uploadSignature(file: File, employeeId: any): Observable<any> {
        const reqUrl = `${this.apiURl}/${this.uploadUrl}`;
        const reqParams = { entity: 'signature', entityId: employeeId, fileClass: 'employee' };
        const reqParamStr = JSON.stringify(reqParams);
        const payload = new FormData();
        payload.append('file', file);
        return this._http.post<any>(reqUrl, payload, { params: reqParams }).pipe(map(resp => resp));
    }

    getByPin(pin: string): Observable<any> {
        const reqUrl = `${this.apiURl}/${Constants.ROUTES.EMPLOYEE}/${Constants.URI.EMPLOYEE.GETBYPIN}/${pin}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<EmployeeClocking>> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.FILTER}`;
        return this._http.post<Page<EmployeeClocking>>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
    }

    punchHistoryFilter(options: any): Observable<EmployeeClocking> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.apiURlv2}/${Constants.ROUTES.CLOCKING}/search`;
        const params = { page: options.page, size: options.size };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder;
        }
        return this._http.post<EmployeeClocking>(reqUrl, options, { params: params }).pipe(map(resp => resp));
    }

    summaryViewFilter(options: any): Observable<EmployeeClocking> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.VIEWSUMMARYWITHFILTER}`;
        const params = { page: options.page, size: options.size };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
        }
        return this._http.post<EmployeeClocking>(reqUrl, options, { params: params }).pipe(map(resp => resp));
    }

    getOverTimeWithFilter(options: any): Observable<EmployeeClocking> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.OVERTIMEWITHFILTER}`;
        const params = { page: options.page, size: options.size };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
        }
        return this._http.post<EmployeeClocking>(reqUrl, options, { params: params }).pipe(map(resp => resp));
    }

    punchHistoryFilterAll(options: any): Observable<EmployeeClocking> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.baseUrlV2}search`;
        return this._http.post<EmployeeClocking>(reqUrl, options).pipe(map(resp => resp));
    }

    checkClocking(options: any): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.CHECK_CLOCKING}`;
        return this._http.post<any>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
    }

    summary(options: any): Observable<EmployeeSummary> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const params = { page: options.page, size: options.size };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
        }
        const reqUrl = `${this.baseUrlV2}${Constants.URI.EMPLOYEECLOCKING.SUMMARY}`;
        return this._http.post<EmployeeSummary>(reqUrl, options).pipe(map(resp => resp));
    }

    chartSummary(options: any): Observable<any> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        options.withNoOvertime = false;
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.CHART_SUMMARY}`;
        return this._http.post<any>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
    }

    summaryDetail(options: any): Observable<EmployeeSummaryDetail> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const params = { page: options.page, size: options.size, isFromMobile: options.isFromMobile };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
        }
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.SUMMARY_DETAIL}`;
        return this._http.post<EmployeeSummaryDetail>(reqUrl, options, { params: params }).pipe(map(resp => resp));
    }

    getEmployeeList(): Observable<Employee[]> {
        const reqUrl = `${this.apiURl}/${Constants.ROUTES.EMPLOYEE}/${Constants.URI.EMPLOYEE.LISTALL}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    getByCompanyAndAgency(params: any): Observable<Page<Employee>> {
        const reqUrl = `${this.apiURlv2}/${Constants.ROUTES.EMPLOYEE}/${Constants.URI.EMPLOYEE.FILTERBYCOMPANY}`;
        return this._http.get<Page<Employee>>(reqUrl, { params: params }).pipe(map(resp => resp));
    }

    getReport(options): Observable<any> {
        if (options.toDate.getTime() >= options.fromDate.getTime()) {
            const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.REPORT}`;
            return this._http.post<any>(reqUrl, options, { params: { page: options.page, size: options.size } }).pipe(map(resp => resp));
        }
    }

    getReportByAgency(options): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.REPORT_BY_AGENCY}`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
    }

    getEmployeePaymentReport(options): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.EMPLOYEE_PAYMENT_REPORT}`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
    }

    getAbsencesCount(): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.ABSENCES_COUNT}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    clockedExpected(options): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.CLOCKED_EXPECTED}`;
        return this._http.get<any>(reqUrl, { params: options }).pipe(map(resp => resp));
    }

    punchStatistic(option: any): Observable<any> {
         // const reqUrl = `http://localhost:8005/api/v2/clocking/statistic/${option.companyId}`;
        const reqUrl = `${this.apiURlv2}/${Constants.ROUTES.CLOCKING}/${Constants.URI.EMPLOYEECLOCKING.PUNCH_STATISTIC_V2}/${option.companyId}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    punchStatisticNew(options: any): Observable<any> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const reqUrl = `${this.apiURlv2}/${Constants.ROUTES.CLOCKING}/statistic-new`;
        // const reqUrl = `http://localhost:8005/api/v2/clocking/statistic-new`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
    }
      
    getTimeLeftToClockOut(employeeId: number): Observable<EmployeeTimeLeftToClockOut> {
        const reqUrl = `${this.apiURlv2}/${Constants.ROUTES.CLOCKING}/${Constants.URI.EMPLOYEECLOCKING.TIME_LEFT_TO_CLOCK_OUT}/${employeeId}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    isEditedBeyondSettingsValue(id: number): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.IS_EDITED_BEYOND_SETTINGS}/${id}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));

    }
    getPunchMissingCount(): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.MISSINGPUNCHCOUNT}`;
        return this._http.get<any>(reqUrl).pipe(map(resp => resp));
    }

    getPunchMissingCountByAgency(agencyId): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.MISSING_PUNCH_COUNT_BY_AGENCY}`;
        return this._http.get<any>(reqUrl, { params: { agencyId: agencyId } }).pipe(map(resp => resp));
    }

    getPunchMissingCountByFilter(options): Observable<any> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.MISSING_PUNCH_COUNT_BY_FILTER}`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
    }

    getPunchMissingEmployess(options: any): Observable<EmployeeClocking> {
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.PUNCHWITHFILTER}`;
        return this._http.post<EmployeeClocking>(reqUrl, options).pipe(map(resp => resp));
    }

    getFirstClockingOfDateByEmployee(options: any): Observable<Page<EmployeeClocking>> {
        return this._http.post<Page<EmployeeClocking>>(
            this.baseUrlV2 + Constants.URI.EMPLOYEECLOCKING.GETFIRSTCLOCKINGOFDATEBYEMPLOYEE, options
        ).pipe(map(resp => resp));
    }

    getSecondClockingOfDateByEmployee(params: any): Observable<Page<EmployeeClocking>> {
        return this._http.get<Page<EmployeeClocking>>(
            this.baseUrl + Constants.URI.EMPLOYEECLOCKING.GETSECONDCLOCKINGOFDATEBYEMPLOYEE, { params: params }
        ).pipe(map(resp => resp));
    }

    getMultipleFirstClockingOfDateByEmployee(options: any): Observable<Array<EmployeeClocking>> {
        return this._http.post<EmployeeClocking[]>(
            this.baseUrl + Constants.URI.EMPLOYEECLOCKING.GETMULTIPLEFIRSTCLOCKINGOFDATEBYEMPLOYEE, options).pipe(map(resp => resp));
    }

    getMultipleSecondClockingOfDateByEmployee(options: any): Observable<Array<EmployeeClocking>> {
        return this._http.post<EmployeeClocking[]>(
            this.baseUrl + Constants.URI.EMPLOYEECLOCKING.GETMULTIPLESECONDCLOCKINGOFDATEBYEMPLOYEE, options).pipe(map(resp => resp));
    }

    getLunchHoursForAllEmployees(options: any) {
        return this._http.post(this.baseUrl + `getLunchHoursForAllEmployees`, options).pipe(map(resp => resp));
    }

    generateOvertimeDetailsPdfFiles(employeeDetailExport: EmployeeDetailExport) {
        return this.http.post<any>(this.baseUrl + `generate-pdf`, employeeDetailExport);
    }

    uploadWrongPinNotification(agencyId: number, pin: string, imageAsBase64?: string): Observable<any> {
        return this.http.post<any>(this.baseUrl + `wrongPin`, { agencyId: agencyId, pin: pin, image: imageAsBase64 }).pipe(map(resp => resp));
    }

    getComplianceReport(options: any) {
        // options.toDate = 0 // MOMENT(options.toDate).utc(true).toISOString(true);
        // options.fromDate = 0 // MOMENT(options.fromDate).utc(true).toISOString(true);
        // const params = { page: page, size: size };
        console.log(this.apiURlv2);
        // return this._http.post<any>('http://localhost:8005/api/v2/clocking/compliance', options);
        return this._http.post<any>(this.apiURlv2 + '/clocking/api/v2/clocking/compliance', options);
    }

    exportCompliancePdf(options: any) {
        return this._http.post<any>(this.apiURlv2 + '/clocking/api/v2/clocking/compliance/pdf', options);
    }

    summaryToPdf(employeeSummaryExport: EmployeeSummaryExport) {
        // const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.SUMMARYTOPDF}`;
        // return this.http.post<any>(reqUrl, employeeSummaryExport);
        return this.http.post<any>(this.baseUrl + `summaryToPdf`, employeeSummaryExport);
    }

    createPunchInOutOffline(employeeOfflinePunchRequest: any) {
        return this.http.post<any>(this.baseUrl + `offline`, employeeOfflinePunchRequest);
    }

    getDetailsReportXls(options: any, fmt: string): Observable<Blob> {
        options.toDate = MOMENT(options.toDate).toISOString(true);
        options.fromDate = MOMENT(options.fromDate).toISOString(true);
        const params = {
            page: options.page,
            size: options.size,
            fmt: fmt
        };
        if (options.sortField != null) {
            params['sortField'] = options.sortField;
        }
        if (options.sortOrder != null) {
            params['sortOrder'] = options.sortOrder === 1 ? 'ASC' : 'DESC';
        }
        const reqUrl = `${this.baseUrl}${Constants.URI.EMPLOYEECLOCKING.REPORT_DETAILS}`;
        return this._http.post(reqUrl, options, { params: params, responseType: 'blob' }).pipe(map(resp => resp));
    }

    searchChanges(params: any): Observable<Page<any>> {
        const reqUrl = `${this.baseUrlV2}changing/history/search`;
        return this._http.post<Page<any>>(reqUrl, params).pipe(map(resp => resp));
    }

    getLunchTime(id: Number): Observable<any> {
        return this._http.get<any>(`${this.baseUrl}lunch-hour/${id}`).pipe(map(resp => resp));
    }

    updateEditAllowed(ids: any) {
        return this.http.put<any>(this.baseUrl + `edit-allowed`, ids);
    }

    sendMissedPunchEmail(companyId, message) {
        forkJoin([
            this.companySettingService.getCompanySettingByCompanyId(companyId),
            this.employeeService.searchEmployee({
              companyId: companyId,
              departmentNameSearch: ['HR', 'Accountant'],
              status: 1
            })
          ]).subscribe((details: any[]) => {
            const emails = [];
            if (details[0] && details[0].status === 'SUCCESS' && details[0].data) {
                if (details[0].data.employeeMissingPunchNotificationEmail) {
                    emails.push(details[0].data.employeeMissingPunchNotificationEmail);
                }
            }
            if (details[1] && details[1].status === 'SUCCESS' && details[1].data && details[1].data.content && details[1].data.content.length > 0) {
                details[1].data.content.forEach(e => {
                  if (!emails.includes(e.email)) {
                    emails.push(e.email);
                  }
                });
            }
            if (emails.length > 0) {
                const payload: any = {
                    subject: 'Missing Clock out',
                    toEmails: emails,
                    details: message,
                    emailCompanyId: companyId
                }
                this.http.post(`${this.notificationUrl}/missed-punch`, payload).subscribe();
            }
          });
    }

    getComplianceReportNew(options: any) {
        // return this._http.post<any>('http://localhost:8005/api/v2/clocking/get-compliance', options);
        return this._http.post<any>(this.apiURlv2 + '/clocking/api/v2/clocking/get-compliance', options);
    }
}
