import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { Table } from 'primeng/table';
import { LazyLoadEvent, MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import { Company } from '../../../company/model/company.model';
import { Agency } from '../../../payroll-setting/model/agency.model';
import { RoleLevel } from '../../../layout/component/app-menu/role-level';
import { User } from '../../../shared/model/user/user.model';
import { AgencyService } from '../../../agency/service';
import { CrmCommService } from '../../../crm/service/crm-comm.service';
import { PaymentInvoiceService } from '../../service/payment-invoice.service';
import { PaymentInvoice } from '../../model/payment-invoice.model';
import { PhonePackageService } from '../../../phonesystem/service/phone-package.service';
import { PhonePackage } from '../../../phonesystem/model/phone-package.model';
import { Subject } from 'rxjs/Subject';
import { interval } from 'rxjs/observable/interval';
import { takeUntil } from 'rxjs/operators';
import { FileUtility } from '../../../shared/utility/file.utility';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'app/shared/data/config-common';
import { ClientService } from '../../../crm/service/client.service';
import { ProductService } from '../../../crm/service/product.service';
import { DatePipe } from '@angular/common';
import { FollowUpBillingInvoiceService } from 'app/payment/service/follow-up-billing.service';
import { DocumentsService } from 'app/shared/service/documents-upload/documents.service';
import { FollowUpBillingInvoice } from 'app/payment/model/follow-up-billing-invoice.model';
import * as moment from 'moment';
import { BrowserNotificationService } from 'app/shared/service/browser-notification/browser-notification.service';
import { BrowserNotification } from 'app/shared/model/browser-notification.model';
import { OperatorService } from 'app/employee/service/v2/operator.v2.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import * as _moment from 'moment';

@Component({
  selector: 'app-payment-follow-up-note',
  templateUrl: './payment-follow-up-note.component.html',
  styleUrls: ['./payment-follow-up-note.component.scss'],
  providers: [PaymentInvoiceService, DropDownsService, FollowUpBillingInvoiceService,
    OperatorService,
     DocumentsService, BrowserNotificationService, PhonePackageService]
})
export class PaymentFollowUpNoteListComponent implements OnInit, OnDestroy {
  detailEnable = false;
  contentList: PaymentInvoice[] = [];
  selectedContent: PaymentInvoice = new PaymentInvoice();
  invoiceDetail: PaymentInvoice = new PaymentInvoice();
  details = [];
  totalRecords: Number = 0;
  items: MenuItem[];
  msgs: Message[] = [];
  fileUrl: any;
  historyType = 'upload';
  private destroyed$ = new Subject<void>();
  loading = false;
  tableRows = 10;
  sumFee = 0;
  balance = 0;
  balanceTxt = '';
  sumMinFee = 0;
  sumTax = 0;
  sumTotalAmount = 0;
  sumFeeTxt: string;
  sumMinFeeTxt: string;
  sumTaxTxt: string;
  sumTotalAmountTxt: string;
  sumOutStandingBalanceTxt: string;
  sumOutStandingBalance = 0;
  sumPaidFee = 0;
  sumPaidFeeTxt: string;
  @ViewChild('dt', { static: true }) table: Table;
  userPermission: Boolean;
  selectedFollowUpNote: any;
  attachment = 'fa-solid fa-paperclip text-primary';
  attachment1 = 'fa-solid fa-paperclip text-success';
  showHistoryDialog = false;
  uploadOption = null;
  rowData: any;
  size: number;
  page: number;
  allCompanyList: any = [];
  allAgencyList: any = [];
  phonePackages: PhonePackage[];
  invoiceNumber: string;
  searchText: string;
  selectedCompany: Company;
  selectedAgency: Agency;
  selectedPhonePackage: PhonePackage;
  selectedStatus: string;
  user: User;
  loggedInRole: number;
  disableCompany = true;
  disableAgency = false;
  fromDate: Date;
  selectedMethodContact = '';
  toDate: Date;
  rangeDates: any;
  statusTypes: any[] = [];
  invoiceFilter: any;
  enabledGenerate = false;
  followUpDate: any;
  rowsPerPageOptions: any[];
  rowsPerPageItems: SelectItem[];
  loadingSpinner = false;
  sortF: any = '';
  isLoadingExport = false;
  showConfirmDialog = false;
  deleteFollowUpId: any;
  isOpenCollectionNote = false;
  message = '';
  followUpParamId: any;
  hasFileOptions = [
    { label: 'View', value: 'View' },
    { label: 'Change', value: 'Change' },
    { label: 'Remove', value: 'Remove' },
    { label: 'History', value: 'History' }
  ];

  noFileOptions = [
    { label: 'Upload', value: 'Upload' },
    { label: 'History', value: 'History' }
  ];
  contacts = [
    { label: 'Phone Call', value: 'Phone Call' },
    { label: 'Text Message', value: 'Text Message' },
    { label: 'Email', value: 'Email' },
    { label: 'In Person Contact', value: 'In Person Contact' }
  ];
  cols: any[] = [
    { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'number', width: '7%' },
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'methodContact', label: 'Contact ', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'collectionRepresentative', label: 'Agent', sortOptions: '', sort: 'text', width: '12%' },
    { field: 'document', label: 'Attached', sortOptions: '', sort: 'text', width: '8%' },
    { field: 'note', label: 'Notes', sortOptions: '', sort: 'number' },
    { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'createdAt', label: 'Created', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'followUpDate', label: 'Follow Up', sortOptions: '', sort: 'number', width: '9%' },
  ];
  sortField: string;
  sortOrder: number;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedSortOption = '';
  selectedField = '';
  allClientList: any = [];
  clientList: any = [];
  selectedClient;
  productList = [];
  showViewFileDialog = false;
  isPlatformAdmin = false;
  disableFields = false;
  selectedCollectionPresentative = '';
  headerNote = 'Collection Note';
  followUpNote: any;
  selectedContact = '';
  selectedNoteUpdate: any = {};
  selectedFollowUpNoteId: any;
  paymentInvoiceData: any = {};
  assignedToEmployeeList: any = [];
  employeeList: any = [];
  @ViewChild('notesEditor', { static: true })
  concernEditor: any;
  charactersLength = 0;
  MAX_LENGTH = 500;
  timeSpent = new Date();
  deleteTime: Date;
  agents: any = [];
  isOverTime: boolean;
  agentList: any;
  followUpInvoiceDueDate: any;
  collectionTypeSelected: any;
  followUpCount: any;
  disabledExtendDueDate: any;
  collectionTypeOptions = [
    { label: 'Follow-up', value: 'Follow-up' },
    { label: 'Payment Refusal', value: 'Payment Refusal' },
    { label: 'Delay Payment Requested', value: 'Delay Payment Requested' }
  ];

  constructor(
    private phonePackageService: PhonePackageService,
    private browserNotificationService: BrowserNotificationService,
    private contentService: PaymentInvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dropDownsService: DropDownsService,
    private operatorService: OperatorService,
    private documentsService: DocumentsService,
    private messageService: MessageService,
    private followUpBillingInvoiceService: FollowUpBillingInvoiceService,
    private clientService: ClientService,
    private datePipe: DatePipe,
    private productService: ProductService,
    private monitoringDetailsService: MonitoringDetailsService) {
    this.user = this.authService.getUserInfo();
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.followUpParamId = parseInt(params.get('id'), 10);
        this.openViewDialogByFollowUpId();
      } else {
        this.followUpParamId = null;
      }
  });
  }

  ngOnInit() {
    this.loadEmployeeDropDown();
    this.loggedInRole = this.authService.getRoleLevel();
    if (this.loggedInRole === RoleLevel.ROLE_CLIENT) {
      this.disableFields = true;
    }
    this.rowsPerPageOptions = [5, 10, 20];
    this.rowsPerPageItems = [];
    this.rowsPerPageOptions.forEach(rowPerPage => {
      this.rowsPerPageItems.push({ label: String(rowPerPage), value: rowPerPage });
    });
    this.statusTypes = [{ label: 'All', value: '' },
    { label: 'Unpaid', value: 'Unpaid' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Paid', value: 'Paid' }
    ];
    this.items = [
      { label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewContent(this.selectedContent) },
      { label: 'View', icon: 'fa-solid fa-xmark text-danger', command: (event) => this.deleteContent(this.selectedContent) }
    ];
    this.contentList = [];
    this.userPermission = this.authService.adminRole();
    this.searchText = '';
    this.loadAllCompanyList();
    this.loadAllClient();
    this.enabledGenerate = false;
    this.disableAgency = true;
    this.disableCompany = true;

    if (this.loggedInRole === RoleLevel.ROLE_COMPANY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_COMPANY_ADMIN) {
      this.disableCompany = true;
      this.disableAgency = false;
    }

    if (this.loggedInRole === RoleLevel.ROLE_AGENCY_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_AGENCY_ADMIN) {
      this.disableAgency = true;
      this.disableCompany = true;
    }
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    if (this.isPlatformAdmin) {
      this.cols = [
		{ field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text', width: '7%' },
		{ field: 'clientName', label: 'Client/Base', sortOptions: '', sort: 'text', width: '10%' },
		{ field: 'methodContact', label: 'Contact', sortOptions: '', sort: 'text', width: '12%' },
		{ field: 'collectionRepresentative', label: 'Agent', sortOptions: '', sort: 'text', width: '12%' },
		{ field: 'document', label: 'Attached', sortOptions: '', sort: 'text', width: '8%' },
    { field: 'note', label: 'Notes', sortOptions: '', sort: 'number' },
    { field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'createdAt', label: 'Created', sortOptions: '', sort: 'text', width: '10%' },
    { field: 'followUpDate', label: 'Follow Up', sortOptions: '', sort: 'number', width: '9%' },

      ];
    }
    if (this.loggedInRole === RoleLevel.ROLE_ADMIN || this.loggedInRole === RoleLevel.ROLE_SUB_ADMIN) {
      this.disableAgency = false;
      this.disableCompany = false;
    }
    this.invoiceFilter = {
      fromDate: null,
      toDate: null,
      page: 0,
      size: 10,
      search: '',
      agencyId: 0,
      companyId: 0,
      packageId: 0,
      status: '',
      invoiceNumber: '',
      sort: null
    };
    this.detailEnable = false;
    if (this.authService.isEmployeeRole()) {
      this.collectionTypeOptions = [
        { label: 'Follow-up', value: 'Follow-up' },
        { label: 'Payment Refusal', value: 'Payment Refusal' }
      ];
    }

    this.search();
  }
  getAllAddedProductsByClientId(clientId: any) {
    const options: any = {
      page: 0, size: 99999, sort: 'id,desc',
    };
    options.clientId = this.selectedClient;
    options.crmClientIds = [this.selectedClient];
    this.productService.getAllProductsByClient(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.productList = resObj.data.content;
      }
    });
  }
  loadAllClient() {
    const options: any = { size: 99999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data.map(c => ({ value: c.key, label: c.value }));
        this.clientList = resObj.data;
      }
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  loadPackageBasedOnCompanyId() {
    this.phonePackageService.getAllList({ companyId: this.selectedCompany }).subscribe((res) => {
      const resObj: any = res;
      console.log(resObj);
      if (resObj.status === 'SUCCESS') {
        this.phonePackages = resObj.data;
      }
    });
    this.detailEnable = false;
  }
  changeStatus(event) {
    this.selectedStatus = event.status;
  }
  viewContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'view', c.id]);
  }

  deleteContent(c: PaymentInvoice) {
    this.contentService.delete(c.id).subscribe((_response) => {
      this.search();
    }, (error) => {
      console.log(error);
    });
  }

  getDetail(detail: PaymentInvoice) {
    this.invoiceDetail = new PaymentInvoice();
    this.invoiceDetail = detail;
    this.details = [];
    this.details = [{ name: 'Package Fee', value: detail.fee },
    { name: 'Minutes Fee', value: detail.minuteFee },
    { name: 'Taxes', value: detail.taxes }];
    this.detailEnable = true;
  }

  editContent(c: PaymentInvoice) {
    this.router.navigate(['app/phonesystem', 'edit', c.id]);
  }

  // no need using
  paginate(event) {
    const first = event.first;
    const rows = event.rows;
    this.sumFee = 0;
    this.sumMinFee = 0;
    this.sumTax = 0;
    this.sumTotalAmount = 0;
    this.sumOutStandingBalance = 0;
    this.sumPaidFee = 0;

    const newList = this.contentList.slice(first, first + rows);
    newList.forEach(invoice => {
      const paidFee = invoice.paidFee ? invoice.paidFee : 0;
      invoice.paidFeeTxt = paidFee.toFixed(2);
      invoice.feeTxt = invoice.fee.toFixed(2);
      invoice.totalAmountTxt = invoice.totalAmount.toFixed(2);
      this.sumFee = this.sumFee + invoice.fee;
      this.sumTotalAmount = this.sumTotalAmount + invoice.totalAmount;
      this.balance = invoice.fee - paidFee;
      invoice.balanceTxt = this.balance.toFixed(2);
      this.sumOutStandingBalance += this.balance;
      this.sumPaidFee += paidFee;
    });
    this.sumFeeTxt = this.sumFee.toFixed(2);
    this.sumPaidFeeTxt = this.sumPaidFee.toFixed(2);
    this.sumOutStandingBalanceTxt = this.sumOutStandingBalance.toFixed(2);
    this.sumTotalAmountTxt = this.sumTotalAmount.toFixed(2);
  }


  cancelDialog() {
    this.detailEnable = false;
  }
  resetFilters() {
    this.invoiceNumber = null;
    this.selectedStatus = null;
    this.selectedClient = null;
    this.selectedMethodContact = null;
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.agentList = null;
    this.fromDate = null;
    this.toDate = null;
    this.searchText = null;
    this.table.reset();
    this.search();
  }

  search(event?: any) {
    this.table.first = 0;
    this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
    this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    const options: any = {};
    options.size = 99999;
    options.page = 0;
    this.invoiceFilter.page = 0;
    this.invoiceFilter.size = this.table.rows;
    if (event === this.searchText) {
      options.search = this.searchText;
    }

    if (this.agentList) {
      options.agents = this.agentList;
    }
    if (this.selectedMethodContact) {
      options.methodContact = this.selectedMethodContact;
    }

    if (this.selectedClient != null) {
      options.clientId = this.selectedClient;
    }

    if (this.fromDate) {
      options.fromDate = _moment(this.fromDate);
    }
    options.sortOrder = 'DESC';
    options.sortField = 'id';
    if (this.toDate) { options.toDate = _moment(this.toDate); }
    this.detailEnable = false;

    this.loadPage(options);

  }

  searchBySelectedDate(date) {
    if (date.startDate) {
      this.fromDate = date.startDate;
    }
    if (date.endDate) {
      this.toDate = date.endDate;
    }
  }

  loadPage(options) {
    this.loading = true;
    this.contentList = [];

    const invoiceNumberStorage =  localStorage.getItem('follow-up-note-number');
    if (invoiceNumberStorage) {
        options.invoiceNumber = invoiceNumberStorage;
        this.invoiceNumber = invoiceNumberStorage;
        localStorage.removeItem('follow-up-note-number');
    }
    options.companyId = this.authService.getCurrentCompanyId();
    this.followUpBillingInvoiceService.getFollowUpBillingInvoiceListByPage(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.loading = false;
        if (!this.agents || this.agents.length <= 0) {
          resObj.data.content.forEach(d => {
            const f = this.agents.find(i => (i.value === d.collectionRepresentative && d.collectionRepresentative));
            if (!f) {
              this.agents.push({value: d.collectionRepresentative, label: d.collectionRepresentative});
            }
          });
        }
        this.contentList = resObj.data.content.map(value => {
          return {
            ...value,
            note: value.note.replaceAll('@', '')
          };
        });
        this.totalRecords = resObj.data.totalElements;
      }
      for (let i = 0; i < this.contentList.length; i++) {
        this.contentList[i].index = i + 1;
      }
      this.filteredValues = this.contentList;
    });
  }


  loadAllCompanyList() {
    const pafc = this;
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        // this.allCompanyList = resObj.data;
        resObj.data.forEach(rs => {
          this.allCompanyList.push({ value: rs.key, label: rs.value });
        });
        if (this.user.companyId) {
          const company = this.allCompanyList.find(c => c.value === this.user.companyId);
          this.selectedCompany = company.value;
        }
      }
    });
  }

  loadAgencyBasedOnCompanyId() {
    const pafc = this;
    this.dropDownsService.getAllAgencyByCompanyId(this.selectedCompany).subscribe((res) => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.allAgencyList = resObj.data;
      }
    });
  }
  sortDataSelected(selectedSortOption, event: LazyLoadEvent) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.filteredValues
    };
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }
  setSortOption(field, selectedSortOption?) {
    this.cols.forEach(item => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  openConfirmDeleteDialog(rowData: any) {
    this.showConfirmDialog = true;
    this.deleteFollowUpId = rowData.id;
    this.message = 'Are you sure you want to Delete this record?';
    this.deleteTime = new Date();
  }

  closeConfirmDeleteDialog() {
    this.showConfirmDialog = false;
  }

  deleteFollowUpNote() {
    this.showConfirmDialog = false;
    this.followUpBillingInvoiceService.delete(this.deleteFollowUpId).subscribe((data: any) => {
        const resObj = data;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({
            severity: 'info', summary: 'Delete Follow Up Note',
            detail: 'Follow Up Note has been deleted successfully!'
          });
          this.monitoringDetailsService.monitorAction(
            'Follow Up Note Deleted',
            this.deleteTime,
            {
               follow_up_note_deleted_by: this.authService.getCurrentLoggedInName(),
               follow_up_note_id: resObj.data.id
            },
            'complete',
            'Follow Up Note Deleted',
            0
          );
            this.search();
        }
    });
  }

  onChangeUploadOption(event, data) {
    this.selectedFollowUpNote = data;
    if (event.value.label === 'View') {
      this.onViewDocument();
    }
    if (event.value.label === 'Upload' || event.value.label === 'Change') {
      this.selectFile();
    }
    if (event.value.label === 'Remove') {
      this.onRemoveDocument();
    }
    if (event.value.label === 'History') {
      this.showHistoryDialog = true;
    }
    this.uploadOption = '';
  }

  onRemoveDocument() {
    if (this.selectedFollowUpNote.documentId) {
      this.selectedFollowUpNote.documentId = null;
      this.followUpBillingInvoiceService.update(this.selectedFollowUpNote, this.selectedFollowUpNote.id).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: 'Removed', detail: 'Document Removed Successfully!' });
        }
      });
    }
  }

  onViewDocument() {
    this.documentsService.getUploadedFile(this.selectedFollowUpNote.documentId).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        if (resObj.data.fileType === 'pdf') {
          window.open(resObj.data.fileUrl);
        } else {
          this.fileUrl = resObj.data.fileUrl;
          this.showViewFileDialog = true;
        }
      }
    }, error => {
    });
  }

  selectFile() {
    document.getElementById('documentId').click();
  }

  onSelectDocument(event) {
    if (event.target.files.length === 0) {
      return;
    }

    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      if (mimeType.match(/pdf\/*/) == null) {
        if (event.target.files[0].name.match(/\.(xlsx)$/) !== null) {
          this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'User is not allowed to upload excel format!' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Invalid', detail: 'User is not allowed to upload this file format!' });
        }
        return;
      }
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.documentsService.uploadFile(event.target.files[0], 'follow_up_noted_file', this.selectedFollowUpNote.id, 'Operation')
      .subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.selectedFollowUpNote.documentId = resObj.data.id;
          // tslint:disable-next-line:no-shadowed-variable
          this.followUpBillingInvoiceService.update(this.selectedFollowUpNote, this.selectedFollowUpNote.id).subscribe((res: any) => {
            if (res.status === 'SUCCESS') {
              this.messageService.add({ severity: 'success', summary: 'Created', detail: 'Document Uploaded Successfully!' });
            }
          });
        }
      });
  }

  closeViewFileDialog() {
    this.showViewFileDialog = false;
  }

  disableDeleteButton(rowData: any) {
      const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
      const createdAt = rowData.createdAt != null ? new Date(rowData.createdAt).getTime() : new Date().getTime();
      const now = new Date();
      now.setMinutes(now.getMinutes() + now.getTimezoneOffset());
      const today = now.getTime();
      if (today - createdAt >= ONE_DAY) {
        return true;
      } else if (today - createdAt < ONE_DAY) {
        return false;
      }
  }

    showDeleteButton(rowData: any) {
        const ONE_DAY = 1 * 24 * 60 * 60 * 1000;
        const createdAt = rowData.createdAt != null ? new Date(rowData.createdAt).getTime() : new Date().getTime();
        const now = new Date();
        now.setMinutes(now.getMinutes() + now.getTimezoneOffset());
        const today = now.getTime();
        if (today - createdAt >= ONE_DAY) {
            return false;
        } else {
            return true;
        }
    }

  closeDialog() {
    this.isOpenCollectionNote = false;
    this.paymentInvoiceData = {};
    this.selectedContact = '';
    this. followUpDate = '';
    this.followUpNote = '';
    this.selectedCollectionPresentative = '';
    this.monitoringDetailsService.monitorAction(
      'Follow Up Note Viewed',
      this.timeSpent,
      {
         follow_up_note_viewed_by: this.authService.getCurrentLoggedInName(),
         follow_up_note_id: this.selectedFollowUpNoteId
      },
      'complete',
      'Follow Up Note Viewed',
      0
  );
  }

  openViewDialog(rowData: any) {
    this.timeSpent = new Date();
    const options = <any>{
      page: 0,
      size: 1
    };
    this.selectedNoteUpdate = rowData;
    this.selectedFollowUpNoteId = rowData.id;
    this.paymentInvoiceData = {};
    options.invoiceNumbers = [rowData.invoiceNumber];
    this.selectedContact = rowData.methodContact;
    this.followUpDate = rowData.createdAt;
    this.followUpNote = rowData.note;
    this.selectedCollectionPresentative = rowData.collectionRepresentative;
    this.isOverTime = this.isOver24hrs(rowData.createdAt);
    this.followUpInvoiceDueDate = moment(rowData.invoiceDueDate).format('MM/DD/YYYY');
    this.collectionTypeSelected = rowData.collectionType;
    this.followUpCount = rowData.extendDueDateCount ? rowData.extendDueDateCount : 0;
    this.disabledExtendDueDate = rowData.disabledExtendDueDate;

    this.contentService.filter(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS' && resObj.data.content.length > 0) {
        this.isOpenCollectionNote = true;
        this.paymentInvoiceData = resObj.data.content[0];

        const invoiceDate = new Date(this.paymentInvoiceData.invoiceDate);
        invoiceDate.setMinutes(invoiceDate.getMinutes() + invoiceDate.getTimezoneOffset());
        this.paymentInvoiceData.invoiceDate = invoiceDate;

        const paidFee = this.paymentInvoiceData.paidFee ? this.paymentInvoiceData.paidFee : 0;
        this.balance = this.paymentInvoiceData.fee - paidFee;
        this.paymentInvoiceData.balanceTxt = this.paymentInvoiceData.balance.toFixed(2);
        this.paymentInvoiceData.clientName = rowData.clientName;
      }
    });
  }

  async openViewDialogByFollowUpId() {
    if (this.followUpParamId) {
      const followUpNote: any = await  this.followUpBillingInvoiceService.get(this.followUpParamId).toPromise();
      if (followUpNote && followUpNote.status === 'SUCCESS' && followUpNote.data) {
        this.openViewDialog(followUpNote.data);
      }
    }
  }


  async loadEmployeeDropDown() {
    const options: any = {};
    options.companyId = this.authService.getCurrentCompanyId();
    options.status = 1;
    const employee = await this.dropDownsService.getAllEmployeeList(options).toPromise();
    this.assignedToEmployeeList = [];
    this.employeeList = [];
    if (employee.data.length > 0) {
      this.employeeList = employee.data;
      employee.data.forEach((item) => {
        const nm = item.value;
        this.assignedToEmployeeList.push({
          label: nm,
          value: nm
        });
      });
    }
  }

  saveCollectionNote(data?: any, event?: any) {
    const followUpBilling = new FollowUpBillingInvoice();
    followUpBilling.collectionRepresentative = this.selectedCollectionPresentative;
    followUpBilling.methodContact = this.selectedContact;
    followUpBilling.note = this.followUpNote;
    followUpBilling.invoiceId = this.paymentInvoiceData.id;
    followUpBilling.invoiceNumber = this.paymentInvoiceData.invoiceNumber;
    followUpBilling.clientName = this.paymentInvoiceData.clientName;
    followUpBilling.companyId = this.paymentInvoiceData.companyId;
    followUpBilling.id = this.selectedFollowUpNoteId;
    followUpBilling.createdAt = this.selectedNoteUpdate.createdAt;
    followUpBilling.createdByUsr = this.selectedNoteUpdate.createdByUsr;
    followUpBilling.lastModifiedBy = this.authService.getCurrentLoggedInName();
    followUpBilling.invoiceDueDate = this.followUpInvoiceDueDate;
    followUpBilling.collectionType = this.collectionTypeSelected;
    this.followUpBillingInvoiceService.update(followUpBilling, this.selectedFollowUpNoteId).subscribe(res => {
      const resObj: any = res;
      this.isOpenCollectionNote = false;
      if (resObj.status === 'SUCCESS') {
        this.notifyMentionEmployee(this.followUpNote, this.selectedFollowUpNoteId, followUpBilling);
        this.messageService.add({
          severity: 'info', summary: 'Update Follow Up Note',
          detail: 'Follow Up Note has been updated successfully!'
        });
      }
      this.monitoringDetailsService.monitorAction(
        'Follow Up Note Updated',
        this.timeSpent,
        {
           follow_up_note_updated_by: this.authService.getCurrentLoggedInName(),
           follow_up_note_id: this.selectedFollowUpNoteId
        },
        'complete',
        'Follow Up Note Updated',
        0
    );
        this.search();
    });

  }
  escapeHtml(unsafe)
  {
      unsafe = this.removeTags(unsafe);
      return unsafe
          //.replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;");
          //.replace(/"/g, "&quot;")
          //.replace(/'/g, "&#039;");

  }
   removeTags(str) {
      if ((str === null) || (str === ''))
          return '';
      else
          str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '');
  }
  exportPdf() {
    this.isLoadingExport = true;
    if (this.filteredValues && this.filteredValues.length) {
      const data: any = {
        followUpBillings: this.filteredValues.map((value) => {
          return {
            invoiceNumber: value.invoiceNumber,
            clientName: this.escapeHtml(value.clientName || ''),
            createdAt: value.createdAt ? moment.tz(value.createdAt, 'America/New_York').format('MM/DD/yyyy') : '',
            methodContact: value.methodContact,
            collectionRepresentative: value.collectionRepresentative,
            note: this.escapeHtml(value.note || ''),
            createdByUsr: value.createdByUsr,
          };
        })
      };
      data.companyId = this.authService.getCurrentCompanyId();
      data.companyName = this.allCompanyList.find(company => company.value === data.companyId).label;
      data.printedBy = this.authService.getCurrentUsername();
      data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
      this.followUpBillingInvoiceService.exportPdf(data).subscribe(res => {
        if (res.data) {
          this.isLoadingExport = false;
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        } else {
          this.isLoadingExport = false;
          this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting email activity pdf list'});
        }
      });
    }
  }

  exportPdfRow(value) {
    const time = new Date();
    this.isLoadingExport = true;
    const data: any = {
      followUpBillings: [{
        invoiceNumber: value.invoiceNumber,
        clientName: value.clientName,
        createdAt: value.createdAt ? moment.tz(value.createdAt, 'America/New_York').format('MM/DD/yyyy') : '',
        methodContact: value.methodContact,
        collectionRepresentative: value.collectionRepresentative,
        note: value.note,
        createdByUsr: value.createdByUsr,
      }]
    };
    data.companyId = this.authService.getCurrentCompanyId();
    data.companyName = this.allCompanyList.find(company => company.value === data.companyId).label;
    data.printedBy = this.authService.getCurrentUsername();
    data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
    this.followUpBillingInvoiceService.exportPdf(data).subscribe(res => {
      if (res.data) {
        this.monitoringDetailsService.monitorAction(
          'Follow Up Note Printed',
          time,
          {
             follow_up_note_printed_by: this.authService.getCurrentLoggedInName(),
             invoice_number: data.invoiceNumber
          },
          'complete',
          'Follow Up Note Printed',
          0
        );
        this.isLoadingExport = false;
        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } else {
        this.isLoadingExport = false;
        this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting email activity pdf list'});
      }
    });
  }

  async notifyMentionEmployee(details: string, followNoteId: number, followUpBilling: any) {
    const employeeList = details.match(/(@)([@.a-zA-Z0-9_-]*)/g);
    for (const employee of employeeList) {
      const key = this.employeeList.find(e => '@' + e.value === employee)?.key;
      if (key != null) {
        const notification: BrowserNotification = new BrowserNotification();
        notification.entity = 'employee';
        const regex = new RegExp(employee + '(.{0,30})');
        notification.content = this.authService.getCurrentUsername() + ' mentioned you: ' + this.stripHtml(details).match(regex)[0] + '...';
        notification.logo = 'https://livestore.operrwork.com/operrwork/2021_2_11__2_10_35__1__2021_1_23__13_42_4__1__m6qxrYbF_400x400.jpg';
        notification.url = document.location.origin + '/#/app/payments/follow-up-note/' + followNoteId;
        notification.status = 'New';
        notification.entityId = key;
        await this.browserNotificationService.save(notification).toPromise();
        // add the bell notification
        const bellNotification: any = {};
        bellNotification.sourceId = followNoteId;
        bellNotification.targetId = key;
        bellNotification.targetTableName = 'employee';
        bellNotification.targetRoleId = 7;
        bellNotification.message = details;
        bellNotification.type = 'follow_up_note';
        bellNotification.tableName = 'follow_up_billing';
        bellNotification.projectId = this.authService.getCurrentCompanyId();
        bellNotification.createdByUsr = this.authService.getCurrentLoggedInName();
        bellNotification.urlLink = '/app/payments/follow-up-note/' + followNoteId;
        await this.operatorService.addBellNotification(bellNotification).toPromise();
        // send email
        const emailData: any = {};
        emailData.employeeId = key;
        emailData.methodContact = followUpBilling.methodContact;
        emailData.collectionRepresentation = followUpBilling.collectionRepresentative;
        emailData.clientName = followUpBilling.clientName;
        emailData.invoiceNumber = followUpBilling.invoiceNumber;
        emailData.note = details.replace(/<[^>]*>/g, '');
        await this.followUpBillingInvoiceService.sendFollowUpTagEmail(emailData).toPromise();
      }
    }
  }

  stripHtml(html) {
    const tmp = document.createElement('tmp');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  boldMention() {
    setTimeout(() => {
      const regexTag = /(@)([@.a-zA-Z0-9_-]*)(<\/p>)/g;
      const regexTag2 = /(@)([@.a-zA-Z0-9_-]*)( +)/g;
      this.followUpNote = this.followUpNote.replace(regexTag, '<strong>$1$2</strong>&nbsp;$3');
      this.followUpNote = this.followUpNote.replace(regexTag2, '<strong>$1$2</strong>$3');
      setTimeout(() => {
        this.concernEditor.getQuill().setSelection(this.concernEditor.value.length);
      }, 100);

    }, 500);
  }

  setEditor($event: any, type?) {
    this.concernEditor = $event.editor;
  }

  textChanged1(event) {
    this.msgs = [];
    if (this.concernEditor.getLength() < 25) {
      this.charactersLength = this.concernEditor.getLength() - 1;
      return;
    } else {
      this.charactersLength = this.concernEditor.getLength() - 1;
    }
    if (this.concernEditor.getLength() > this.MAX_LENGTH) {
      this.followUpNote.deleteText(this.MAX_LENGTH - 1, this.concernEditor.getLength());
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `You have exceeded the maximum text limit!` });
    }
    this.charactersLength = this.concernEditor.getLength() - 1;
  }

  isOver24hrs(createdDate) {
    const currentTime = moment().tz('America/New_York');
    return currentTime.diff(createdDate, 'days') > 0;
  }
  filteredValues = [];
  onFilter(event, dt) {
      this.filteredValues = event.filteredValue;
      this.totalRecords = this.filteredValues.length;
  }
}
