import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {Message, MessageService} from 'primeng/api';
import {TranslatePipe} from '@ngx-translate/core';
import {DocumentSettingsService} from '../../service/document-settings.service';
import {DocumentSettings} from '../../model/document-settings';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OverlayPanel, Table} from 'primeng';
import { DropDownsService } from 'app/shared/service/drop-downs/drop-downs.service';
import {DocumentsService} from "../../../shared/service/documents-upload/documents.service";
import * as moment from "moment";
import {FileUtility} from "../../../shared/utility/file.utility";
import {DatePipe} from "@angular/common";
import {DocumentsServicev2} from "../../../shared/service/documents-upload/document.v2.service";
import {OperationalPolicyService} from "../../service/operational-policy.service";


@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  styleUrls: ['./document-settings.component.scss']
})
export class DocumentSettingsComponent implements OnInit {

  @ViewChild('fdt', {static: true})
  table: Table;

  showAddDocumentPopup: boolean = false;
  // addDocumentForm: UntypedFormGroup;
  // updateDocumentForm: UntypedFormGroup;
  documentRoles: any = [];
  selectedVisibleAll: boolean;
  documentSettings: DocumentSettings = new DocumentSettings();
  documentDetails: DocumentSettings[] = [];
  documentDetailsTmp: DocumentSettings[] = [];
  defaultDocument: DocumentSettings[] = [];
  documentSetting: DocumentSettings = new DocumentSettings();
  filterText: string = '';
  companyId: number;
  documentUpdate: DocumentSettings[] = [];
  documentSettingsId: number;
  selectedCompany;
  isPlatformAdmin;
  companies;
  showConfirmDialog = false;
  currentRowData: any;
  msgs: Message[] = [];
  information;
  cols: any[] = [
    { field: 'documentName', label: 'Document Title', sortOptions: '', sort: 'text' }
  ];
  roles = [
    {label: 'Employee', value: 'employee'},
    {label: 'Intern (Paid)', value: 'inter_paid'},
    {label: 'Intern (Not Paid)', value: 'inter_not_paid'},
    {label: 'Volunteer', value: 'volunteer'},
    {label: 'Contractor', value: 'contractor'},
  ];

  businessTypes = [
      { label: 'All', value: null },
      { label: 'Office', value: 'Office' },
      { label: 'Restaurant/Bar', value: 'Restaurant/Bar' },
  ];
  creators = [];
  init = true;
  selectedRole;
  selectedBusinessType;
  selectedCreatedBy;
  fromDate;
  toDate;
  loading = false;
  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
    ];
  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  selectedSortOption;
  selectedField;
  constructor(private router: Router,
              private authService: AuthService,
              private messageService: MessageService,
              private translatePipe: TranslatePipe,
              private formBuilder: UntypedFormBuilder,
              private documentsService: DocumentsService,
              private dropDownsService: DropDownsService,
              private datePipe: DatePipe,
              private documentServicev2: DocumentsServicev2,
              private operationalService: OperationalPolicyService,
              private documentSettingsService: DocumentSettingsService) {
    this.companyId = this.authService.getCurrentCompanyId();
  }

  ngOnInit(): void {
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    this.documentRoles = [
      {label: 'Employee', value: 'employee'},
      {label: 'Intern (Paid)', value: 'inter_paid'},
      {label: 'Intern (Not Paid)', value: 'inter_not_paid'},
      {label: 'Volunteer', value: 'volunteer'},
      {label: 'Contractor', value: 'contractor'},
      {label: 'Public', value: 'public'}
    ];
    this.loadOperationalPolicies();
    this.getAllDocument();
    this.loadAllCompanyList();
  }

  loadAllCompanyList() {
      this.dropDownsService.getAllCompanyList().subscribe((res) => {
          const resObj: any = res;
          this.companies = [];
          if (resObj.status === 'SUCCESS') {
              resObj.data.forEach(data => {
                  this.companies.push({ value: data.key, label: data.value });
              });
              console.log(this.companies);
          }
      });
  }

  onCompanyChange() {
    this.getAllDocument();
  }

  openAddDocumentPopup() {
    this.documentSettingsId = null;
    this.showAddDocumentPopup = true;
  }

  closeAddDocumentPopup(reload?) {
    this.showAddDocumentPopup = false;
    if (reload) {
        this.getAllDocument();
    }
  }

  onSelectedVisibleAll(event: any) {
    if (event) {
      this.documentDetails.map(item => {
        item.isVisible = event.checked;
        if (!event.checked) {
          item.isRequired = event.checked;
        }
      });
      if (!event.checked)
        this.changeAllRequired();
    }
  }

  onSelectedVisible(event: any, row: DocumentSettings) {
    if (event) {
      if (!event.checked) {
        this.selectedVisibleAll = event.checked;
        row.isRequired = event.checked;
      } else {
        this.selectedVisibleAll = this.documentDetails.filter(d => !d.isVisible).length === 0;
      }
    }
  }

  getAllDocument() {
      this.loading = true;
      let options = {type: 'default',
          role: this.selectedRole,
          businessType: this.selectedBusinessType,
          createdBy: this.selectedCreatedBy,
          fromDate: this.fromDate? moment(this.fromDate).startOf('day').utc(true).toDate().getTime() : null,
          toDate: this.toDate? moment(this.fromDate).startOf('day').utc(true).toDate().getTime() : null,
      };
    this.documentSettingsService.search(options).subscribe(res => {
      this.defaultDocument = res.data || [];
      this.loading = false;
      this.documentDetailsTmp = this.defaultDocument.map(x => {
        x.selectedRole = this.documentRoles.filter(r => x.role.includes(r.value));
        x.roleText = this.roles.filter(r => x.role.includes(r.value)).map((item) => {
            return item.label;
        });
        if (x.documentIds && x.documentIds.length) {
            x.documentIds = x.documentIds.filter(item => item != "");
        }
        return Object.assign({}, x);
      });
      this.onFilter();
    });
  }

  saveDocument() {
    this.documentSettingsService.showSpin();
    let update = false;
    if (this.documentSettings.id) update = true;
    this.documentSettings.companyId = this.companyId;
    this.documentSettings.isVisible = this.documentSettings.isRequired;
    this.documentSettings.createdBy = this.authService.getCurrentUsername();
    this.documentSettings.role = this.documentSettings.selectedRole.map(r => r.value).join(',');
    this.documentSettingsService.create(this.documentSettings).subscribe(res => {
      const resObj: any = res;
      this.documentSettingsService.hideSpin();
      if (resObj.status === 'SUCCESS') {
        this.closeAddDocumentPopup(true);
        this.getAllDocument();
        if (!update) {
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Created'),
                detail: this.translatePipe.transform('createNewDocumentSuccess')
            });
        } else {
            this.messageService.add({
                severity: 'info',
                summary: this.translatePipe.transform('Updated'),
                detail: this.translatePipe.transform('updateNewDocumentSuccess')
            });
        }

      } else {
        if (!update) {
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('createNewDocumentFailed')
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: this.translatePipe.transform('error'),
                detail: this.translatePipe.transform('updateNewDocumentFailed')
            });
        }
      }
    });
  }

  // updateDocument() {
  //   this.documentSettingsService.showSpin();
  //   this.getDocumentSettingsUpdate().then(() => {
  //     this.documentSettingsService.updateAll(this.documentUpdate).subscribe(res => {
  //       const resObj: any = res;
  //       this.documentSettingsService.hideSpin();
  //       if (resObj.status === 'SUCCESS') {
  //         this.getAllDocument();
  //         this.messageService.add({
  //           severity: 'info',
  //           summary: this.translatePipe.transform('Updated'),
  //           detail: this.translatePipe.transform('updateNewDocumentSuccess')
  //         });
  //       } else {
  //         this.messageService.add({
  //           severity: 'error',
  //           summary: this.translatePipe.transform('error'),
  //           detail: this.translatePipe.transform('updateNewDocumentFailed')
  //         });
  //       }
  //     });
  //   });
  // }

  onFilter() {
      if (this.filterText && this.documentDetailsTmp) {
          this.documentDetails = this.documentDetailsTmp.filter((item) => {
              return (item.documentName||'').toLowerCase().search(this.filterText.toLowerCase()) > -1 //|| (item.role||'').toLowerCase().search(this.filterText.toLowerCase()) > -1
                  || (item.createdBy||'').toLowerCase().search(this.filterText.toLowerCase()) > -1 || (item.roleText||[]).toString().toLowerCase().search(this.filterText.toLowerCase()) > -1
                  || (item.operationalPolicy||[]).toString().toLowerCase().search(this.filterText.toLowerCase()) > -1;
          });
      } else {
          this.documentDetails = this.documentDetailsTmp;
      }
    this.selectedVisibleAll = this.documentDetails.filter(d => !d.isVisible).length === 0;
    if (this.init) {
        this.creators = this.documentDetails.map((item) => {
            return {
                label: item.createdBy,
                value: item.createdBy
            }
        });
        this.creators = [...new Map(this.creators.map(item =>
            [item['value'], item])).values()];
        this.init = false;
    }
  }

  reset() {
      this.fromDate = null;
      this.filterText = null;
      this.toDate = null;
      this.selectedBusinessType = null;
      this.selectedRole = null;
      this.selectedCreatedBy = null;
      this.getAllDocument();
  }

  // async getDocumentSettingsUpdate() {
  //   this.documentUpdate = [];
  //   await this.defaultDocument.forEach(obj1 => {
  //     const obj2 = this.documentDetailsTmp.find(x => x.id === obj1.id);
  //     if (obj1 && obj2 && Object.keys(obj1).some(key => String(obj1[key]).toLowerCase() !== String(obj2[key]).toLowerCase())) {
  //       if (obj2.isDefault) {
  //         const newDoc = new DocumentSettings();
  //         newDoc.companyId = this.companyId;
  //         newDoc.role = obj2.role;
  //         newDoc.documentName = obj2.documentName;
  //         newDoc.createdBy = this.authService.getCurrentUsername();
  //         newDoc.position = obj2.position;
  //         newDoc.isDefault = false;
  //         newDoc.isRequired = obj2.isRequired;
  //         newDoc.isVisible = obj2.isVisible;
  //         newDoc.parentId = obj2.id;
  //         this.documentUpdate.push(newDoc);
  //       } else {
  //         obj2.role = obj2.selectedRole.map(r => r.value).join(',');
  //         this.documentUpdate.push(obj2);
  //       }
  //     }
  //   });
  // }

    changeRequired(rowData) {
        this.documentUpdate = [];
        rowData.role = rowData.selectedRole.map(r => r.value).join(',');
        this.documentUpdate.push(rowData);
        this.documentSettingsService.updateAll(this.documentUpdate).subscribe(res => {
            const resObj: any = res;
            this.documentSettingsService.hideSpin();
            if (resObj.status === 'SUCCESS') {
                //this.getAllDocument();
                // this.messageService.add({
                //     severity: 'info',
                //     summary: this.translatePipe.transform('Updated'),
                //     detail: this.translatePipe.transform('updateNewDocumentSuccess')
                // });
            } else {
                // this.messageService.add({
                //     severity: 'error',
                //     summary: this.translatePipe.transform('error'),
                //     detail: this.translatePipe.transform('updateNewDocumentFailed')
                // });
            }
        });
    }

    changeAllRequired() {
        this.documentSettingsService.updateAll(this.documentUpdate).subscribe(res => {
            const resObj: any = res;
            this.documentSettingsService.hideSpin();
            if (resObj.status === 'SUCCESS') {
                //this.getAllDocument();
                // this.messageService.add({
                //     severity: 'info',
                //     summary: this.translatePipe.transform('Updated'),
                //     detail: this.translatePipe.transform('updateNewDocumentSuccess')
                // });
            } else {
                // this.messageService.add({
                //     severity: 'error',
                //     summary: this.translatePipe.transform('error'),
                //     detail: this.translatePipe.transform('updateNewDocumentFailed')
                // });
            }
        });
    }


    editContent(rowData) {
    this.documentSettingsId = rowData.id;
    this.showAddDocumentPopup = true;
  }


  onDeleteClick(rowData): void {
    this.currentRowData = rowData;
    this.showConfirmDialog = true;
}


deleteContent(): void {
    this.documentSettingsService.delete(this.currentRowData.id).subscribe(res => {
          const resObj: any = res;
          //this.documentSettingsService.hideSpin();
          if (resObj.status === 'SUCCESS') {
              this.getAllDocument();
              this.messageService.add({
                  severity: 'info',
                  summary: this.translatePipe.transform('Deleted'),
                  detail: this.translatePipe.transform('Document deleted successfully')
              });
          } else {
              this.messageService.add({
                  severity: 'info',
                  summary: this.translatePipe.transform('Deleted'),
                  detail: this.translatePipe.transform('Document deleted failed')
              });
          }
      });
  }

    fileUrl;
    showViewFileDialog;
    onViewDocument(documentId) {
        this.documentsService.getUploadedFile(documentId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data.fileType === 'pdf'
                    || resObj.data.fileType === 'txt'
                    || resObj.data.fileType === 'docx'
                    || resObj.data.fileType === 'doc'
                    || resObj.data.fileType === 'xls'
                    || resObj.data.fileType === 'xlsx'
                ) {
                    window.open(resObj.data.fileUrl);
                } else {
                    this.fileUrl = resObj.data.fileUrl;
                    this.showViewFileDialog = true;
                }
            }
        }, error => {
            // error
        });
    }


    exportPdf() {
        if (this.documentDetails && this.documentDetails.length) {
            const data: any = {
                contentList: this.documentDetails.map(value => {
                    return {
                        documentName: value.documentName,
                        role: value.roleText?value.roleText.join(','): null,
                        required: value.isRequired? 'Y' : 'N',
                        businessType: value.operationalPolicy? value.operationalPolicy.join(','): null,
                        createdAt: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm a'),
                        createdBy: value.createdBy,
                    };
                }),
                exportedDate:this.datePipe.transform(new Date().getTime(), 'MM/dd/yyyy EEE hh:mm a')
            };
            data.companyId = this.authService.getCurrentCompany();

            data.username = this.authService.getCurrentUsername();
            this.documentSettingsService.exportPdf(data).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting client pdf list' });
                }
            });
        }
    }

    materials = [];
    documentOverview;
    getAllMaterials(documentIds) {
        const options = {
           ids: documentIds.map(Number)
        };
        this.documentServicev2.searchFile(options)
          .subscribe(res => {
            const resObj: any = res;
            this.materials = resObj.data.content;
        });
        this.documentOverview = true;
    }

    getFileName(url) {
        return url.split('/').pop().split('__').pop();
    }

    sortingClick(selectedSortOption) {
        selectedSortOption = selectedSortOption === 'ASC' ? 1 : -1;
        this.sort(this.selectedField, selectedSortOption);
    }
    sort(fieldName: string, order: number) {

        this.documentDetails.sort((row1, row2) => {
            let val1 = row1[fieldName];
            let val2 = row2[fieldName];
            if (!val1 && !val2) {
                return 0;
            }
            if (fieldName === 'operationalPolicy') {
                val1 = row1.operationalPolicy.join(",");
                val2 = row2.operationalPolicy.join(",");
            }
            if (fieldName === 'createdAt') {
                return order === 1 ?  row1.createdAt - row2.createdAt : row2.createdAt - row1.createdAt;
            }
            if(val1) {
                val1 = val1.toLowerCase();
            }
            if(val2) {
                val2 = val2.toLowerCase();
            }
            if (val1 === val2) {
                return 0;
            }
            let result = -1;
            if (val1 > val2) {
                result = 1;
            }
            if (order < 0) {
                result = -result;
            }
            return result;
        });
    }

    loadOperationalPolicies() {
        let options = <any> {};
        options.size = 9999;
        options.page = 0;
        options.sortField = 'createdAt';
        options.sortOrder = 'DESC';
        this.operationalService.search(options).subscribe((res) => {
            const resObj: any = res;
            this.businessTypes = [{ label: 'All', value: null }];
            if (resObj.status === 'SUCCESS') {
                resObj.data.content.forEach(data => {
                    this.businessTypes.push({ value: data.name, label: data.name });
                });
        }});
    }
}
