import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../../shared/model/constants';
import { environment } from '../../../../environments/environment';
import * as MOMENT from 'moment';
import { HighlightModel } from '../../model/highlight.model';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class DashboardService {

  private uploadUrl = Constants.ROUTES.FILEUPLOAD;
  private apiURl = environment.v2_server_ip;
  protected baseUrl = `${this.apiURl}/${Constants.URI.DASHBOARD.ROOT}`;

  constructor(private http: HttpClient) {
  }

  getEmployeeOvertimeList(): Observable<any> {
    const reqUrl = `${this.apiURl}/${Constants.ROUTES.EMPLOYEE}/${Constants.URI.EMPLOYEE.OVERTIME}`;
    return this.http.get<any>(reqUrl).pipe(map(res => res));
  }

  getDashboardData(options): Observable<any> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    options.withNoOvertime = false;
    const reqUrl = `${this.apiURl}/${Constants.ROUTES.DASHBOARD.GET_DASHBOARD_DATA}`;
    return this.http.post<any>(reqUrl, options).pipe(map(res => res));
  }

  getHighlight(): Observable<HighlightModel> {
    const reqUrl = `${this.baseUrl}/${Constants.URI.DASHBOARD.HIGHLIGHT}`;
    return this.http.get<any>(reqUrl).pipe(map(res => res.data));
  }

  getDashboardDataNew(options): Observable<any> {
    options.toDate = MOMENT(options.toDate).toISOString(true);
    options.fromDate = MOMENT(options.fromDate).toISOString(true);
    options.withNoOvertime = false;
    const reqUrl = `${this.apiURl}/${Constants.ROUTES.DASHBOARD.GET_DASHBOARD_DATA_NEW}`;
    // const reqUrl = `http://localhost:8005/api/v2/clocking/get-dashboard-data`;
    return this.http.post<any>(reqUrl, options).pipe(map(res => res));
  }
}
