import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authenticate/auth.guard';
import { CompanyLayoutComponent } from './component/company-layout/company-layout.component';
import { CompanyListComponent } from './component/company-list/company-list.component';
import { CompanyAddComponent } from './component/company-add/company-add.component';
import { CompanyViewComponent } from './component/company-view/company-view.component';
import { CompanyUpdateComponent } from './component/company-update/company-update.component';
import { CompanyContractComponent } from './component/company-contract/company-contract.component';
import { CompanySettingViewComponent } from './component/company-setting-view/company-setting-view.component';
import { CompanySettingEditComponent } from './component/company-setting-edit/company-setting-edit.component';
import { PayrollSettingComponent } from '../payroll-setting/component/payroll-setting/payroll-setting.component';
import { CompanyHolidayManageComponent } from './component/company-holiday-manage/company-holiday-manage.component';
import { PayrollSettingViewComponent } from '../payroll-setting/component/payroll-setting-view/payroll-setting-view.component';
import { CompanyOtherViewComponent } from './component/company-other-view/company-other-view.component';
import { DocumentSettingsComponent } from './component/document-settings/document-settings.component';
import { CompanyPaymentDetailsComponent } from './component/comany-payment-details/company-payment-details.component';
import { CompanyPaymentMethodComponent } from './component/company-payment-method/company-payment-method.component';
import { NgModule } from '@angular/core';
import { PayrollHolidayAddComponent } from 'app/payroll-setting/component/payroll-holiday-add/payroll-holiday-add.component';
import { PayrollHolidayEditComponent } from 'app/payroll-setting/component/payroll-holiday-edit/payroll-holiday-edit.component';
import {OperationalPolicyComponent} from "./component/operational-policy/operational-policy.component";

export const routes: Routes = [
    {
        path: '',
        component: CompanyLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full' },
            { path: 'list', component: CompanyListComponent },
            { path: 'add', component: CompanyAddComponent },
            { path: 'edit/:id', component: CompanyUpdateComponent },
            { path: 'view/:id', component: CompanyViewComponent },
            { path: 'setting/view/:id', component: CompanySettingViewComponent },
            { path: 'setting/edit/:id', component: CompanySettingEditComponent },
            { path: 'contract/:id', component: CompanyContractComponent },
            { path: 'payroll/:id', component: PayrollSettingComponent },
            { path: 'payroll/view/:id', component: PayrollSettingViewComponent },
            { path: 'payroll/:id/holiday/add', component: PayrollHolidayAddComponent },
            { path: 'payroll/:id/holiday/edit/:holidayId', component: PayrollHolidayEditComponent },
            { path: 'other/view/:id', component: CompanyOtherViewComponent },
            { path: 'other/edit/:id', component: CompanySettingEditComponent },
            { path: 'holiday', component: CompanyHolidayManageComponent },
            { path: 'company-payment', component: CompanyPaymentDetailsComponent },
            { path: 'company-payment-method', component: CompanyPaymentMethodComponent },
            { path: 'document-settings/list', component: DocumentSettingsComponent },
            { path: 'operational-policy', component: OperationalPolicyComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CompanyRoutingModule { }
