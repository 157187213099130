import { InvoiceVerificationService } from './../../service/invoice-verification.service';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DropDownsService } from '../../../shared/service/drop-downs/drop-downs.service';
import { Company } from '../../../company/model/company.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { RoleLevel } from '../../../layout/component/app-menu/role-level';
import { LazyLoadEvent, MenuItem, Message, MessageService, SelectItem } from 'primeng/api';
import moment from 'moment';
import { TranslatePipe } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng';
import { QuoteService } from 'app/quote/service/quote.service';
import { ClientService } from '../../../crm/service/client.service';
import { forkJoin } from 'rxjs-compat/observable/forkJoin';
import { buildSurveyLandPdfFile } from 'app/quote/model/quote.model';
import { CompanyService } from 'app/company/service/company.service';
import { PaymentInvoiceService } from '../../service/payment-invoice.service';
import { FileUtility } from '../../../shared/utility/file.utility';
import { NotificationService, NotificationType } from 'app/crm/service/notification.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {Freelancer, FreelancerExcel} from '../../../crm/model/freelancer.model';
import * as _moment from 'moment';
import {GeneralInvoiceTypeService} from '../../../general-invoice/service/general-invoice-type.service';
import {Table} from "primeng/table";

@Component({
  selector: 'app-invoice-verification',
  templateUrl: './invoice-verification.component.html',
  styleUrls: ['./invoice-verification.component.scss'],
  providers: [ InvoiceVerificationService, QuoteService ]
})
export class InvoiceVerificationComponent implements OnInit {
  @ViewChild('dt', {static: true}) table: Table;
  msgs: Message[] = [];
  disableFields = false;
  loggedInRole: number;
  selectedCompany: Company;

  allClientList: any = [];
  clientList: any = [];
  selectedClient;

  fromDate: Date;
  toDate: Date;

  verifyByOptions: any = [];
  selectedVerifyBy;

  statusTypes: any[] = [
    { label: 'All', value: null},
    { label: 'Pending', value: 'Pending' },
    { label: 'Verified', value: 'Verified' },
    { label: 'Submitted', value: 'Submitted' },
    { label: 'Paid', value: 'Paid' }
  ];
  selectedStatus;
  
  items: MenuItem[];
  totalRecords: Number = 0;
  totalServiceFees: Number = 0;
  totalAPIUsageFees: Number = 0;
  contentList: any = [];
  loading = true;
  fileTypes = [
    { name: 'Excel', value: 'excel' },
    { name: 'PDF', value: 'pdf' }
  ];
  cols: any[] = [
    { field: 'createdAt', dbField: 'createdAt' , label: 'Created', sortOptions: '', sort: 'number' },
    { field: 'clientName', dbField: 'clientId' , label: 'Client name', sortOptions: '', sort: 'text' },
    { field: 'invoiceType', dbField: 'invoiceType' ,  label: 'Invoice Type', sortOptions: '', sort: 'text' },
    { field: 'invoiceNumber', dbField: 'invoiceNumber' ,  label: 'Invoice #', sortOptions: '', sort: 'number' },
    { field: 'fee', dbField: 'fee' ,  label: 'Service Fee', sortOptions: '', sort: 'number' },
    { field: 'apiUsageCost', dbField: 'apiUsageCost' ,  label: 'API Usage Fee', sortOptions: '', sort: 'number' },
    { field: 'totalServiceAndUsage', dbField: 'totalServiceAndUsage' ,  label: 'Total', sortOptions: '', sort: 'number' },
    { field: 'verification1st', dbField: 'verification1st' ,  label: '1st Verification', sortOptions: '', sort: 'text' },
    { field: 'verification2st', dbField: 'verification2st' ,  label: '2st Verification', sortOptions: '', sort: 'text' },
    { field: 'emailSent', dbField: 'emailSent' ,  label: 'Email Sent', sortOptions: '', sort: 'number' },
    { field: 'status', dbField: 'status' ,  label: 'Status', sortOptions: '', sort: 'text' },
  ];

  sortF: any = '';

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];
  numberSortOptions = [
    { name: 'Sort 0 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 0', value: 'DESC', img: 'arrow-up-9-1' }
  ];
  dateSortOptions = [
    { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  selectedSortOption = '';
  selectedField = '';

  size: number = 9999;
  page: number;
  sortField: string;
  sortOrder: number = 0;
  
  showConfirmationPopup = false;
  confirmationId = '';
  confirmationType = '';

  disableEmailSmsInvoice = true;
  listSelectedDataRow: any = [];

  showConfirmationPopupEmailInvoice = false;

  @ViewChild('op3', { static: true }) op3: OverlayPanel;

  @ViewChild('op4', { static: true }) op4: OverlayPanel;
  invoiceRef: any = {};
  invoiceVerificationRef: any = {};

  exportOptions = [
    { name: 'View Detail', value: 'view' },
    { name: 'Download invoice', value: 'download' },
    { name: 'Inactive', value: 'inactive' },
  ];
  exportAs: any;

  showInactiveInvoice = false;
  showCheckbox = false;
  assignControls = false;
  typeList: any = [];
  selectedType: any;
  bulkIds: any = [];
  bulkIdsTmp: any = [];
  bulkAll: boolean = false;

  showConfirmationPopupSmsInvoice = false;
  sendingSms = false;
  emailSmsAll: boolean = false;
  sumServiceFeesAndAPIUsageFees: Number = 0;
  textSearch;

  exportPdfLoading = false;
  constructor(
    private dropDownsService: DropDownsService,
    public authService: AuthService,
    private invoiceVerificationService: InvoiceVerificationService,
    private translatePipe: TranslatePipe,
    private messageService: MessageService,
    private quoteService: QuoteService,
    private clientService: ClientService,
    private companyService: CompanyService,
    private contentService: PaymentInvoiceService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private generalInvoiceTypeService: GeneralInvoiceTypeService,
  ) { 
  }

  ngOnInit(): void {
    this.loggedInRole = this.authService.getRoleLevel();
    if (this.loggedInRole === RoleLevel.ROLE_CLIENT) {
      this.disableFields = true;
    }
    this.loadAllClient();
    this.getInvoiceTypes();
    this.getAllVerification();
    this.search();
  }

  loadAllClient() {
    const options: any = { size: 99999, page: 0, moduleName: 'billing' };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    if (this.disableFields) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }
    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      const resObj: any = res;
      this.allClientList = [];
      this.clientList = [];
      if (resObj.status === 'SUCCESS') {
        this.allClientList = resObj.data.map(c => ({ value: c.key, label: c.value }));
        this.clientList = resObj.data;
      }
    });
  }

  reset() {
    this.selectedClient = null;
    this.fromDate = null;
    this.toDate = null;
    this.selectedStatus = null;
    this.selectedVerifyBy = null;
    this.selectedType = null;
    this.textSearch = null;
    this.table.reset();
    this.search();
  }

  search(event?) {
    const options = this.buildOptions(event);
    options.clientId = this.selectedClient;
    options.status = this.selectedStatus;
    options.verifyBy = this.selectedVerifyBy ? this.selectedVerifyBy.value : null;
    options.billingType = this.selectedType;
    this.page = 0; // Reset to the first page
    this.bulkAll = false;
    this.bulkIds = [];
    this.bulkIdsTmp = [];
    this.loadPage(options);
  }

  searchVerifyBy(event?) {
    const options = this.buildOptions(event);
    options.clientId = this.selectedClient;
    options.status = this.selectedStatus;
    options.verifyBy = event ? event.value : null;
    options.billingType = this.selectedType;
    this.page = 0; // Reset to the first page
    this.bulkAll = false;
    this.bulkIds = [];
    this.bulkIdsTmp = [];
    this.loadPage(options);
  }

  exportExcel(event?) {
    const options = this.buildOptions(null, 0, 99999);
    this.loadPageExcel(options);
  }

  loadPageExcel(options) {

    this.invoiceVerificationService.search(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.convertdataToExcel(resObj.data.content);
      }
    }, () => {
    });
  }
  buildOptionsExcel(event?: any, page?, size?) {
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };

    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;
    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }
    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    if (this.selectedClient) {
      options.clientId = this.selectedClient.key;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }
    options.status = this.selectedStatus;
    return options;
  }

  buildOptions(event?: any, page?, size?) {
    this.size = event ? event.rows : this.size ? this.size : 10;
    this.page = event && event.first >= 0 && event.rows > 0 ? event.first / event.rows : 0;
    const options = <any>{
      page: page ? page : this.page,
      size: size ? size : this.size
    };

    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'id';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;
    if (this.sortField === 'id') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    if (this.selectedClient) {
      options.clientId = this.selectedClient;
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }
    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }

    options.status = this.selectedStatus;

    return options;
  }

  loadPage(options) {
    this.loading = true;
    this.invoiceVerificationService.search(options).subscribe(res => {
      this.loading = false;
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.contentList = resObj.data.content;
        // this.populateVerifyByOptions();
        this.totalRecords = resObj.data.totalElements;
        this.totalServiceFees = this.contentList ? this.contentList[0]?.totalServiceFees : 0;
        this.totalAPIUsageFees = this.contentList ? this.contentList[0]?.totalAPIUsageFees : 0;
        this.sumServiceFeesAndAPIUsageFees = +this.totalServiceFees + +this.totalAPIUsageFees;
        this.bulkIds = this.contentList ? this.contentList[0]?.ids : [];
        this.contentList.forEach(data => {
            data.clientName = this.getClientName(data);
            data.invoiceType = data.invoice? data.invoice.invoiceType: '';
            data.invoiceNumber = data.invoice? data.invoice.invoiceNumber: '';
            data.fee = data.invoice? data.invoice.fee: '';
            data.apiUsageCost = data.invoice? data.invoice.apiUsageCost: '';
            data.totalServiceAndUsage = data.invoice ? (data.invoice?.fee || 0) + (data.invoice?.apiUsageCost || 0) : '';
        });
        if (this.bulkAll && this.contentList && this.bulkIds) {
          this.contentList.forEach(data => data.verificationChecked = this.bulkIds.find(i => i === data.id));
        }
        this.filteredValues = this.contentList;
      }
    }, () => {
      this.loading = false;
      this.contentList = [];
      this.totalRecords = 0;
    });
    this.cdr.detectChanges();
  }

  // populateVerifyByOptions() {
  //   const verifyByNames = new Set();
  //   this.contentList.forEach(item => {
  //     if (item.verification1st) verifyByNames.add(item.verification1st);
  //     if (item.verification2st) verifyByNames.add(item.verification2st);
  //   });
  //   this.verifyByOptions = Array.from(verifyByNames).map(name => ({ label: name, value: name }));
  //   console.log("Verify By Options:", this.verifyByOptions);
  // }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        event.data.sort((data1, data2) => {
          let value1;
          let value2;
          if (event.field === 'clientName') {
            value1 = this.allClientList.find(el => el.value === data1.invoice.clientId)?.label;
            value2 = this.allClientList.find(el => el.value === data2.invoice.clientId)?.label;
          } else if (event.field === 'invoiceType' || event.field === 'invoiceNumber' || event.field === 'fee' || event.field === 'apiUsageCost') {
            value1 = data1.invoice ? data1.invoice[event.field] : '';
            value2 = data2.invoice ? data2.invoice[event.field] : '';
          } else {
            value1 = data1[event.field];
            value2 = data2[event.field];
          }

          let result;

          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
        });
      }
      this.sortF = '';
    }
  }

  emailInvoice() {
    this.showConfirmationPopupEmailInvoice = true;
  }
  
  sortDataSelected(selectedSortOption, event: LazyLoadEvent) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.filteredValues
    };

    this.sortField = this.selectedField;
    this.sortOrder = selectedSortOption === 'ASC' ? 1 : -1;
    // this.search();
    this.customSort(options);
  }

  getClientName(data) {
    if (data.clientId) {
      const client = this.allClientList.find(el => el.value === data.clientId);
      if (client) { return client.label; }
    }
    return data.clientName;
  }

  openConfirmationPopup(type?, id?) {
    this.confirmationId = id;
    this.confirmationType = type;
    this.showConfirmationPopup = (type === '1' || type === '2');
  }

  confirmInvoiceVerification() {
    if (this.confirmationId && this.confirmationType) {
      const verification1st = this.confirmationType === '1' ? this.authService.getCurrentUsername() : '';
      const verification2st = this.confirmationType === '2' ? this.authService.getCurrentUsername() : '';
      const verificationCheckedRows = this.contentList.filter(data => data.verificationChecked);
      let postRequest;
      if (verificationCheckedRows.length > 0 || this.bulkAll) {
        postRequest = this.invoiceVerificationService.bulkConfirmInvoiceVerification({ids: this.bulkAll ? this.bulkIds : verificationCheckedRows.map(v => v.id), verification1st, verification2st});
      } else {
        postRequest = this.invoiceVerificationService.confirmInvoiceVerification({id: this.confirmationId, verification1st, verification2st});
      }
      postRequest.subscribe(res => {
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success',
              summary: this.translatePipe.transform('Success'),
              detail: this.translatePipe.transform('Invoice Verification confirm successfully') });
          this.search();
        } else {
          this.messageService.add({ severity: 'error',
              summary: this.translatePipe.transform('Error'),
              detail: res.message? res.message :this.translatePipe.transform('Invoice Verification confirm failure') });
        }
        // this.search();
        if (verificationCheckedRows.length > 0) {
          verificationCheckedRows.forEach(row => {
            if (verification1st) {
              row.verification1st = verification1st;
            }
            if (verification2st) {
              row.verification2st = verification2st;
            }
          });

        } else {
          const row = this.contentList.find(data => data.id === this.confirmationId);
          if (row) {
            if (verification1st) {
              row.verification1st = verification1st;
            }
            if (verification2st) {
              row.verification2st = verification2st;
            }
          }

        }
        this.openConfirmationPopup();
      }, (error) => {
        // this.search();
        this.openConfirmationPopup();
        this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: error.error.message? error.error.message :this.translatePipe.transform('Invoice Verification confirm failure') });
      })
    }
  }

  checkRowData() {
    let checkedCount = 0;
    if (this.contentList) {
      checkedCount = this.contentList.filter(data => data.checked = ((data.verification1st || data.verification2st) && data.status !== 'Paid')).length;
    }
    if (checkedCount > 0) {
      this.disableEmailSmsInvoice = false;
    } else {
      this.disableEmailSmsInvoice = true;
    }
  }

  closePopupConfirmationEmailInvoice() {
    this.showConfirmationPopupEmailInvoice = false;
    this.disableButton = false;
  }

  disableButton = false;
  accept() {
    this.disableButton = true;
    this.listSelectedDataRow = [];
    this.contentList.forEach((item) => {
      if (item.checked) {
        this.listSelectedDataRow.push(item);
      }
    });

    const options = <any> {
      list: this.listSelectedDataRow
    };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }

    this.invoiceVerificationService.sendEmailInvoice(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        if (res.data.code === '00') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Send Email Invoice'),
            detail: this.translatePipe.transform('Email sent successfully!')
          });
          this.contentList.forEach((item) => {
            if (item.checked) {
              if (item.emailSent) {
                item.emailSent++;
              } else {
                item.emailSent = 1;
              }
            }
          });
        } else {
          this.messageService.add({
            severity: 'error', summary: this.translatePipe.transform('Send Email Invoice'),
            // detail: res.data.message
            detail: this.translatePipe.transform('Sorry, the verification email was unable to be sent! Contact OperrWork Admin.')
          });
        }
      }
      this.search()
      this.showConfirmationPopupEmailInvoice = false;
      setTimeout(() => {
        this.disableButton = false;
      }, 1000);
    }, err => {
      this.showConfirmationPopupEmailInvoice = false;
      this.messageService.add({
        severity: 'error', summary: this.translatePipe.transform('Send Email Invoice'),
        detail: this.translatePipe.transform('Sorry, the verification email was unable to be sent! Contact OperrWork Admin.')
      });
      this.disableButton = false;
    });
  }

  bulkEmailSms(value, isSelectedAll) {
    let canCheckCount = 0;
    let checkedCount = 0;
    if (this.contentList) {
      canCheckCount = this.contentList.filter(data => (data.verification1st || data.verification2st) && data.status !== 'Paid').length;
    }
    if (isSelectedAll) {
      this.emailSmsAll = value;
      if (this.contentList) {
        this.contentList.forEach(data => data.checked = (((data.verification1st || data.verification2st) && data.status !== 'Paid') && this.emailSmsAll));
      }
      if (this.emailSmsAll) {
        checkedCount = canCheckCount;
      }
    } else {
      if (this.contentList) {
        checkedCount = this.contentList.filter(data => data.checked).length;
        canCheckCount = this.contentList.filter(data => (data.verification1st || data.verification2st) && data.status !== 'Paid').length;
      }
      this.emailSmsAll = checkedCount > 0 && canCheckCount === checkedCount;
    }
    if (checkedCount > 0) {
      this.disableEmailSmsInvoice = false;
    } else {
      this.disableEmailSmsInvoice = true;
    }
  }

  acceptSms() {
    const rows = [];
    this.contentList.forEach((item) => {
      if (item.checked) {
        rows.push(item);
      }
    });
    const options = <any> {
      list: rows
    };
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    this.invoiceVerificationService.sendSmsInvoice(options).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        if (res.data.code === '00') {
          this.messageService.add({
            severity: 'info', summary: this.translatePipe.transform('Send SMS Invoice'),
            detail: this.translatePipe.transform('SMS Invoice is sent successfully')
          });
        } else {
          this.messageService.add({
            severity: 'error', summary: this.translatePipe.transform('Failed'),
            detail: res.data.message
          });
        }
      }
      this.closePopupConfirmationSmsInvoice();
    }, () => {
      this.messageService.add({
        severity: 'error', summary: this.translatePipe.transform('Failed'),
        detail: this.translatePipe.transform('SMS Invoice Failed')
      });
      this.closePopupConfirmationSmsInvoice();
    });
  }

  closePopupConfirmationSmsInvoice() {
    this.showConfirmationPopupSmsInvoice = false;
    this.sendingSms = false;
  }

  clickInvoiceRef(event: any, rowData) {
    this.op4.toggle(event);
    this.invoiceRef = rowData.invoice;
    this.invoiceVerificationRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
      case 'inactive': this.inactiveInvoiceRefPdf();
        break;
    }
  }

  async exportClientList(type) {
    if (type === 'pdf') {
      this.exportTable();
    } else {
      this.exportExcel();
    }
  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      if (invoice.invoiceType === 'Survey Land') {
        this.quoteService.findByInvoiceNumber(invoice.invoiceNumber).subscribe(res => {
          const resObj: any = res;
          forkJoin([
            this.clientService.getClientById(resObj.data.clientId),
            this.companyService.getCompanyByCompanyId(resObj.data.companyId)
          ]).subscribe((response: any) => {
            const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
            const blobUrl = URL.createObjectURL(doc.output("blob"));
            window.open(blobUrl, '_blank');
          });
        });
      } else {
        this.contentService.exportPdf(invoice.id).subscribe(res => {
          if (res.data) {
            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          }
        });
      }
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    if (this.invoiceRef.invoiceType === 'Survey Land') {
      this.quoteService.findByInvoiceNumber(this.invoiceRef.invoiceNumber).subscribe(res => {
        const resObj: any = res;
        forkJoin([
          this.clientService.getClientById(resObj.data.clientId),
          this.companyService.getCompanyByCompanyId(resObj.data.companyId)
        ]).subscribe((response: any) => {
          const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
          const blobUrl = URL.createObjectURL(doc.output("blob"));
          const a = document.createElement('a');
          a.href = blobUrl;
          a.target = '_blank';
          let fileName = 'Dummy.pdf';
          if (res.data.fileName && res.data.fileName.length > 0) {
            fileName = res.data.fileName;
          }
          a.download = fileName;
          a.click();
        });
      });
    } else {
      this.contentService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobUrl;
          a.target = '_blank';
          let fileName = 'Dummy.pdf';
          if (res.data.fileName && res.data.fileName.length > 0) {
            fileName = res.data.fileName;
          }
          a.download = fileName;
          a.click();
          const args = {
            ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
            Ticket_id: this.invoiceRef.invoiceId,
          };
        }
      });
    }
  }
  
  inactiveInvoiceRefPdf() {
    this.op3.hide();
    this.showInactiveInvoice = true;
  }

  inactiveInvoiceAction() {
    const options = <any> {};
    options.invoiceId = this.invoiceRef.id;
    options.invoiceVerificationId = this.invoiceVerificationRef.id;
    if (this.selectedCompany) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompanyId();
    }
    options.lastModifiedBy = this.authService.getCurrentUsername();
    options.invoiceStatus = 'Inactive';

    this.invoiceVerificationService.updateStatusInvoice(options).subscribe(res => {
      this.showInactiveInvoice = false;
      if (res.status === 'SUCCESS') {
        if (res.data.code === '00') {
          this.notificationService.open({
            type: NotificationType.SUCCESS,
            title: 'Success',
            body: 'The invoice is updated to inactive',
          });
          this.search();
        } else {
          this.messageService.add({ severity: 'error',
            summary: this.translatePipe.transform('Error'),
            detail: res.data.message });
        }
      }
    }, () => {
      this.showInactiveInvoice = false;
      this.messageService.add({ severity: 'error',
          summary: this.translatePipe.transform('Error'),
          detail: this.translatePipe.transform('The invoice is updated to inactive failure') });
    });
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sortOptions = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  async exportTable() {

    this.exportPdfLoading = true;
    await this.exportData(this.buildOptions(null, 0, 9999));

    const doc = this.buildPdf();
    let output = doc.output('bloburl');
    window.open(output.toString(), '_blank');
    this.exportPdfLoading = false;
  }

  buildPdf(): jsPDF {
    const pdfBody: any = this.getPdfBody();

    pdfBody.push([
      {
        content: 'Total',
        colSpan: 4,
        styles: {
          fontStyle: 'bold',
          halign: 'left'
        }
      },
      {
        content: this.totalRecords,
        styles: {
          fontStyle: 'bold',
          halign: 'right'
        }
      },
      {
        content: '$' + (this.totalServiceFees || 0).toFixed(2),
        styles: {
          fontStyle: 'bold',
          halign: 'right'
        }
      },
      {
        content: '$' + (this.totalAPIUsageFees || 0).toFixed(2),
        styles: {
          fontStyle: 'bold',
          halign: 'right'
        }
      },
      {
        content: '',
        colSpan: 3,
        styles: {
          fontStyle: 'bold',
          halign: 'left'
        }
      }
    ]);

    const doc = new jsPDF();
    autoTable(doc, {
      body: [
        [
          {
            content: "Invoice Verifications",
            styles: {
              halign: 'center',
              fontSize: 20,
              fontStyle: 'bold'
            }
          }
        ],
        [
          {
            content: "Name: " + this.authService.getCurrentUsername(),
            styles: {
              halign: 'left',
              fontSize: 11
            }
          }
        ],
        [
          {
            content: "Date: " + moment().format('MM/DD/YYYY hh:mm a'),
            styles: {
              halign: 'left',
              fontSize: 11
            }
          }
        ]
      ],
      theme: 'plain'
    });

    autoTable(doc, {
      margin: 0,
      head: [
        [
          {
            content: '#',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 10
            }
          },
          {
            content: 'Date & Time',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 25
            }
          },
          {
            content: 'Client Name',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: 'Invoice Type',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 18
            }
          },
          {
            content: 'Invoice #',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 22
            }
          },
          {
            content: 'Service Fee',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 15
            }
          },
          {
            content: 'API Usage Fee',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 15
            }
          },
          {
            content: '1st Verification',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: '2st Verification',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          },
          {
            content: 'Status',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 20
            }
          }
        ],
      ],
      theme: 'striped',
      columnStyles: {
        0: {cellWidth: 10},
        1: {cellWidth: 25},
        2: {cellWidth: 20},
        3: {cellWidth: 18},
        4: {cellWidth: 22},
        5: {cellWidth: 15},
        6: {cellWidth: 15},
        7: {cellWidth: 20},
        8: {cellWidth: 20},
        9: {cellWidth: 20},
      },
      styles: {
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: 255
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      body: pdfBody,
    });
    return doc;
  }

  convertdataToExcel(data: any[]) {
    const excelData: Array<any> = [];
    for (let i = 0; i < data.length; i++) {
      const freelancer = data[i];
      const freelancerExcel = <any>{};
      freelancerExcel.id = i+1;

          freelancerExcel.createdDate = this.formatDate(freelancer.createdAt);
      freelancerExcel.clientName = this.getClientName(freelancer);
      freelancerExcel.invoiceType = freelancer.invoice ? freelancer.invoice.invoiceType : '';
      freelancerExcel.invoiceNumber = freelancer.invoice?.invoiceNumber;
      freelancerExcel.free = '$' + (freelancer.invoice?.fee || 0).toFixed(2);
      freelancerExcel.apiUsageCost = '$' + (freelancer.invoice?.apiUsageCost || 0).toFixed(2);
      freelancerExcel.verification1st = (freelancer.verification1st || '');
      freelancerExcel.verification2st = (freelancer.verification2st || '');
      if (freelancer.status === 1) {
        freelancerExcel.status = 'Active';
      } else {
        freelancerExcel.status = 'Inactive';
      }
      excelData.push(freelancerExcel);
    }
    const headers = [['#', 'Date & Time', 'Client Name', 'Invoice Type', 'Invoice #', 'Service Fee', 'API Usage Fee', '1st Verification', '2st Veification', 'Status']];
    this.invoiceVerificationService.exportAsExcelFile(excelData, 'InvoiceVerificationList', headers);
    // this.selectedDownload = null;
  }

  getPdfBody() {
    const cloneList = this.filteredValues.map(x => Object.assign({}, x));
    return cloneList.map((row, i) => {
      return [
        {
          content: String(i + 1),
          styles: {
            halign: 'left'
          }
        },
        {
          content: this.formatDate(row.createdAt),
          styles: {
            halign: 'left'
          }
        },
        {
          content: this.allClientList.find(el => el.value === row.invoice?.clientId)?.label ? this.allClientList.find(el => el.value === row.invoice?.clientId)?.label : '',
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.invoice?.invoiceType ? row.invoice?.invoiceType : '',
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.invoice?.invoiceNumber ? '#' + row.invoice?.invoiceNumber : '',
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.invoice?.fee || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.invoice?.apiUsageCost || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: (row.verification1st || ''),
          styles: {
            halign: 'left'
          }
        },
        {
          content: (row.verification2st || ''),
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.status,
          styles: {
            halign: 'left'
          }
        }
      ]
    });
  }

  formatDate(date: any) {
    if (date) {
      return moment(date).format('MM/DD/YYYY hh:mm a');
    }
    return null;
  }

  contentListExport: any = [];
  async exportData(options) {
    await this.invoiceVerificationService.search(options).toPromise().then(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        this.contentListExport = resObj.data.content;
      }
    }, () => {
      this.exportPdfLoading = false;
      this.contentListExport = [];
    });
  }

  assignInBulkClicked() {
    this.showCheckbox = !this.showCheckbox;
  }

  cancelAssignInBulk() {
    if (this.contentList) {
      this.contentList.forEach(data => data.verificationChecked = false);
    }
    this.showCheckbox = !this.showCheckbox;
  }

  getInvoiceTypes() {
    this.typeList = [];
    this.generalInvoiceTypeService.getDropdown({status: 'Active'}).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        resObj.data.forEach(type => {
          this.typeList.push({ label: type.value, value: type.value });
        });
      }
    });
  }

  bulkVerifications(value, isSelectedAll) {
    if (isSelectedAll) {
      this.bulkAll = value;
      if (this.contentList) {
        this.contentList.forEach(data => data.verificationChecked = (this.bulkAll && this.bulkIds.find(i => i === data.id)));
      }
    } else {
      if (value.verificationChecked) {
        this.bulkIdsTmp.push(value.id);
      } else {
        this.bulkIdsTmp.splice(this.bulkIdsTmp.findIndex(i => i === value.id), 1);
      }
      this.bulkAll = this.bulkIds.length === this.bulkIdsTmp.length;
    }
  }

  getAllVerification() {
    this.invoiceVerificationService.findAllVerification(this.authService.getCurrentCompanyId()).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.verifyByOptions = res.data.map((verifyBy: any) => this.mapToDropdown(verifyBy, verifyBy));
      }
    });
  }

  mapToDropdown(label, val) {
    return {
      label: label,
      value: val
    };
  }

  filteredValues = [];
  onFilter(event, dt) {
      this.filteredValues = event.filteredValue;
      this.totalRecords = this.filteredValues.length;
  }
}
