<div>
  	<span class="call-button" (click)="toggleCallModel()" *ngIf="!isCallNoteModal">
		<i class="fa-solid fa-phone" *ngIf="!(['connecting', 'connected', 'on-hold', 'resume'].indexOf(connectionStatus) !== -1)" pTooltip="Calls"></i>
		<i class="fa-solid fa-phone-volume" *ngIf="['connecting', 'connected', 'on-hold', 'resume'].indexOf(connectionStatus) !== -1" pTooltip="Call Active"></i>
		<span class="topbar-item-name">Call Handler</span>
	</span>
  	<div [ngClass]="{ active: isModelOpen(), 'client-modal fadeInDown': true }" *ngIf="isCallNoteModal">	
    	<p-panel>	
		<p-header class="main-buttons-row">	
			<span>Ongoing Call</span>	
			<span class="pull-right d-flex">	
			{{ currentCallDuration }}	
			<button	
				*ngIf="	
				[	
					'connecting',
					'connected',
					'accepted',
					'on-hold',
					'resume'	
				].indexOf(connectionStatus) !== -1 && !inTransfer	
				"	
				class="btn main-btn btn-red field-tip"	
				style="margin-left: 10px;"
				(click)="endCall()"	
			>	
				<span class="tip-content">End Call</span>	
				<i class="material-icons">call_end</i>	
			</button>	
			<button 
			*ngIf="	
				[	
					'connecting',
					'connected',
					'accepted',
					'on-hold',
					'resume'	
				].indexOf(connectionStatus) !== -1
				"	
				style="margin-left: 10px;"
				class="btn main-btn btn-green field-tip" (click)="isCallNoteModal = false">
				<span class="tip-content">Switch to Phone</span>	
				<i class="material-icons">autorenew</i>
			</button>
			<span	
				*ngIf="	
				[	
					'connecting',
					'connected',
					'accepted',
					'on-hold',
					'resume'		
				].indexOf(connectionStatus) == -1 && !inTransfer	
				"	
				style="color: tomato"	
			>	
				{{ msgText }}	
			</span>	
			</span>	
		</p-header>	
      <div class="ui-g ui-fluid">	
        <!-- <div class="ui-g-12 ui-lg-12"> -->	
        <!-- <div class="ui-g ui-fluid"> -->	
        <div class="ui-g-12 ui-lg-6">	
          <h6>Name: {{ client.name }}</h6>	
        </div>	
        <div class="ui-g-12 ui-lg-6">	
          <h6>Email: {{ client.email }}</h6>	
        </div>	
        <div class="ui-g-12 ui-lg-6">	
          <h6>Address: {{ client.address }}</h6>	
        </div>	
        <div class="ui-g-12 ui-lg-6">	
          <h6>Phone: {{ client.phone }}</h6>	
        </div>	
        <!-- </div> -->	
        <!-- </div> -->	
      </div>	
      <div class="ui-g ui-fluid">	
        <div class="ui-g-12 ui-lg-6">	
          <div class="ui-g ui-fluid">	
            <div class="card card-w-title p-0 border-0 m-0">	
              <p class="tabCardHead">	
                <span>Add New Note</span>	
              </p>	
              <div class="ui-g ui-fluid pr-2 pb-2 pt-2 pl-1">	
                <div class="ui-g-12 ui-md-12">	
                  <label>Date : </label>	
                  <span>{{ dateForNoteDialog }}</span>	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex align-center">	
                  <label>From : </label>	
                  <span>{{ noteHistory.user }}</span>	
                </div>	
                <div class="ui-g-12 ui-md-12">	
                  <label	
                    ><sup class="font-weight-bold">*</sup>Note Type :	
                  </label>	
                  <p-dropdown	
                    class="d-inline-block pl-1"	
                    [options]="noteTypeList"	
                    [(ngModel)]="noteHistory.noteType"	
                   
                    placeholder="Select Note Type"	
                  ></p-dropdown>	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex flex-wrap align-center">	
                  <label><sup class="font-weight-bold">*</sup>Note : </label>	
                  <textarea	
                    id="id_note_text"	
                    cols="30"	
                    rows="4"	
                    minlength="25"	
                    class="ml-2"	
                    [(ngModel)]="noteHistory.note"	
                  ></textarea>	
                  <div style="color: darkgrey; margin-left: 56px;">	
                    <span class="font-weight-bold">Note : </span>Please enter	
                    minimum 25 characters.	
                  </div>	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex align-center flex-wrap">	
                  <label>Email : </label>	
                  <input	
                    pInputText	
                    type="email"	
                    class="d-inline-block w-auto"	
                    [(ngModel)]="noteHistory.email"	
                  />	
                  <span class="text-red" *ngIf="isValidEmail"	
                    >Please enter valid email address</span	
                  >	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex align-center flex-wrap">	
                  <span>Other email: </span>	
                  <p-checkbox (onChange)="emailDropdown()"></p-checkbox>	
                  <p-multiSelect	
                    class="d-inline-block pl-1"	
                    [options]="positionsList"	
                    [(ngModel)]="noteHistory.positions"	
                    *ngIf="dropdownEmail"	
                    (onChange)="noteHistoryToDropDownChange($event)"	
                    id="id_noteHistory_to_selected_label"	
                  ></p-multiSelect>	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex align-center">	
                  <span class="text-red" *ngIf="isValidForm"	
                    >Please enter required fields</span	
                  >	
                </div>	
                <div class="ui-g-12 ui-md-12 d-flex flex-wrap">	
                  <span> Document : </span>	
                  <div *ngIf="noteHistory.documentPath">	
                    <div class="ui-g ui-fluid">	
                      <div	
                        class="ui-g-10 ui-md-10 pt-0"	
                        style="height: 37px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"	
                        data-toggle="tooltip"	
                        data-placement="right"	
                        title="Hooray!"	
                      >	
                        <a	
                          href="{{ noteHistory.documentPath }}"	
                          style="vertical-align: sub;"	
                          class="text-red"	
                          ><i class="fa-solid fa-download"></i	
                        ></a>	
                        <span class="text-right"	
                          ><a	
                            href="javascript:"	
                            (click)="noteHistory.documentPath = ''"	
                            ><i class="fa-solid fa-xmark"></i></a	
                          ></span	
                        >	
                      </div>	
                    </div>	
                  </div>	
                  <a	
                    *ngIf="!noteHistory.documentPath"	
                    (click)="selectFile()"	
                    href="javascript:"	
                    style="vertical-align: sub;"	
                    ><i class="fa-solid fa-upload"></i	
                  ></a>	
                  <input type="file" id="documentPath" style="display: none;" />	
                  <div style="color: darkgrey; margin-left: 85px;">	
                    <span class="font-weight-bold">Note : </span>File must be	
                    1000 KB in size or smaller.	
                  </div>	
                </div>	
                <div class="ui-g-12 ui-md-12 text-center pb-3">	
                  <button	
                    pButton	
                    class="w-auto"	
                    label="Save"	
                    (click)="saveNoteHistoryDate()"	
                    [disabled]="saveButtonDisabled"	
                  ></button>	
                  <!-- <button pButton class="w-auto" label="Close" (click)="hideNoteHistoryDialog()"></button> -->	
                </div>	
              </div>	
            </div>	
          </div>	
        </div>	
        <div class="ui-g-12 ui-lg-6">	
          <div class="ui-g ui-fluid">	
            <p-panel>	
              <p-header>	
                <span>Note History</span>	
                <span class="pull-right d-flex">	
                  <input	
                    type="text"	
                    placeholder="Search"	
                    pInputText	
                    size="20"	
                    [(ngModel)]="searchText"	
                    (ngModelChange)="onFilterChange()"	
                    (keyup.enter)="onFilterChange()"	
                    style="width:200px; border: 1px; background-color: white;"	
                  />	
                </span>	
              </p-header>	
              <p-table	
                #table	
                [value]="noteHistoryTableData"	
                [responsive]="true"	
                [lazy]="true"	
                editMode="cell"	
                (onLazyLoad)="loadDataSourceGroup($event)"	
                [rows]="tableData?.size"	
                [paginator]="true"	
                [totalRecords]="tableData?.totalElements"	
                [rowsPerPageOptions]="[5, 10, 25]"	
                [loading]="loading"	
              >	
                <ng-template pTemplate="header" let-columns>	
                  <tr>	
                    <th style="width: 12%;"><p>Date</p></th>	
                    <th><p>Note Type</p></th>	
                    <th style="width: 45%;"><p>Note Text</p></th>
                    <th><p>User</p></th>	
                    <!-- <th style="width: 6%;"><p>Document</p></th>	
                              <th style="width: 5%;"><p>Status</p></th>	
                              <th style="width: 7%;"><p>Actions</p></th> -->	
                  </tr>	
                </ng-template>	
                <ng-template pTemplate="body" let-rowData>	
                  <tr>	
                    <td>{{ showMMDDYYYYFormat(rowData.date) }}</td>	
                    <td>{{ rowData.noteType }}</td>	
                    <td>{{ rowData.note }}</td>	
                    <td>{{ rowData.user }}</td>	
                  </tr>	
                </ng-template>	
              </p-table>	
              <!--<p-paginator (onPageChange)="paginate($event)" #pgn [rows]="5" [rowsPerPageOptions]="rowsPerPageOptions">	
                      <p-dropdown (onChange)="onRppChange($event)" [options]="rowsPerPageItems" [(ngModel)]="pgn.rows"></p-dropdown>	
                  </p-paginator>-->	
            </p-panel>	
          </div>	
        </div>	
      </div>	
    </p-panel>	
  </div>
  <div
    [ngClass]="{ active: isModelOpen(), 'phone-modal fadeInDown': true }"
  >
    <div
      *ngIf="!isConnected"
      class="col-md-12 m-t-15 m-b-15 sip-login-div"
      data-scale=".25"
    >
      <div class="font-weight-bold text-center">Operrtel Credentials</div>
      <form [formGroup]="formGroup" #myFormGroup="ngForm" autocomplete="nope">
        <div formGroupName="user">
          <div class="row form-group">
            <div class="col-md-12">
              <input
                autocomplete="off"
                type="text"
                pInputText
                formControlName="username"
                placeholder="Username"
                [(ngModel)]="username"
              />
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-12">
              <input
                autocomplete="off"
                type="password"
                pInputText
                formControlName="password"
                placeholder="Password"
                [(ngModel)]="password"
              />
            </div>
          </div>
        </div>
        <div *ngIf="authService?.getUserInfo()?.phoneOperrtell">
          <div class="font-weight-bold">OperrTell Information</div>
          <div><label>First Name: {{authService?.getUserInfo()?.firstNameOperrtell}}</label></div>
          <div><label>Last Name: {{authService?.getUserInfo()?.lastNameOperrtell}}</label></div>
          <div><label>Phone: {{authService?.getUserInfo()?.phoneOperrtell}}</label></div>
        </div>
        <div class="d-flex justify-content-between">
          <!--<button class="btn btn-primary" [disabled]="" (click)="connect()">
            Connect
          </button>-->
          <div></div>
          <div>
            <button class="btn btn-danger mr-1" [disabled]="isLoadOperrtell" (click)="clearOperrtel()">
              Clear
            </button>
            <button class="btn btn-info" [disabled]="disableOperrtel() || isLoadOperrtell" (click)="loginAccountOperrtel()">
              Login
            </button>
          </div>

        </div>
      </form>
    </div>
    <div class="py-5" *ngIf="isConnected"
      ngDraggable>
      <div
        class="main-modal-box"
        [ngStyle]="{ transform: 'scale(' + scale + ')' }"
        *ngIf="!isCallNoteModal"
      >
        <div class="outer-body">
          <button class="btn btn-close" (click)="toggleCallModel()">
            <i class="material-icons">close</i>
          </button>
          <button class="btn btn-zout" (click)="zoomOut()">
            <i class="material-icons">zoom_out</i>
          </button>
          <button class="btn btn-zin" (click)="zoomIn()">
            <i class="material-icons">zoom_in</i>
          </button>
          <button  *ngIf="	
              [	
                'connecting',
                'connected',
                'accepted',
                'on-hold',
                'resume'	
              ].indexOf(connectionStatus) !== -1 && client && client.id
            "	
            class="btn btn-rotate" (click)="isCallNoteModal = true">
            <i class="material-icons">autorenew</i>
          </button>
          <div class="main-body">
            <div class="display-part d-flex flex-column mb-2">
              <div
                class="caller-id"
                *ngIf="
                  ['connecting', 
                  'connected', 
                  'incoming', 
                  'on-hold',
                  'resume',
                  'accepted', 
                  'conference'].indexOf(connectionStatus) !==
                  -1
                "
              >
              <i class="material-icons initial">phone_in_talk</i>...
                <span class="align-text-bottom"
                  >{{ remoteFriendlyName }}
                  <small>({{ connectionStatus }})</small>
                </span>
              </div>
              <div class="caller-id" *ngIf="contactNumber.length > 15">
                You have entered more then 15 digits!
              </div>
              <div class="h4 mb-0 align-self-center my-auto">
                  <div *ngIf="connectionStatus === 'conference'">CONFERENCE</div>
                <!-- enter dialed no. here -->
                <input
                *ngIf="connectionStatus !== 'conference'"
                  #contactNumberInput
                  type="text"
                  [(ngModel)]="contactNumber"
                  name="contactNumber"
                  (keypress)="keyDownCNMethod($event.target.value)"
                  (keyup)="keyUpCNMethod($event.target.value)"
                  class="contact-number-input"
                  id="dialInput"
                />
                <i
                  class="material-icons arrow-pointer input-delete"
                  *ngIf="contactNumber && connectionStatus !== 'conference'"
                  (click)="updateContactNumber('DELETE')"
                  >backspace</i
                >
              </div>
              <div
                class="other-bottom-details mt-auto d-flex justify-content-between"
                *ngIf="
                  ['accepted',  
                  'on-hold',
                  'resume',
                  'conference'].indexOf(connectionStatus) !== -1
                "
              >
                <div class="call-time">
                  <!-- <i class="material-icons initial">access_time</i> -->
                  <span class="align-text-bottom font-weight-bold">{{ currentCallDuration }}</span>
                </div>
                <div class="call-duration">
                  <!-- <small>Duration:</small> -->
                  <span class="font-weight-bold"
                    >Call established</span
                  >
                </div>
              </div>
              <div
                class="other-bottom-details mt-auto d-flex justify-content-between"
                *ngIf="
                  ['ended'].indexOf(connectionStatus) !==
                  -1
                "
              >
                <div class="call-time">
                  <!-- <i class="material-icons initial">access_time</i> -->
                  <span class="align-text-bottom font-weight-bold">Ready</span>
                </div>
                <div class="call-duration">
                  <!-- <small>Duration:</small> -->
                  <span class="font-weight-bold"
                    >Your extension is: {{user.username}}</span
                  >
                </div>
              </div>
              <div
                class="other-bottom-details mt-auto d-flex justify-content-between"
                style="align-self: center;
                max-width: 150px;"
              >
                <div class="call-time">
                  <!-- <i class="material-icons initial">access_time</i> -->
                  <span *ngIf="phoneSettings.dnd" class="align-text-bottom font-weight-bold">DND</span>
                </div>
                <div class="call-duration">
                  <!-- <small>Duration:</small> -->
                  <span *ngIf="phoneSettings.cfwd" class="font-weight-bold"
                    >CFWD</span
                  >
                </div>
              </div>
            </div>
            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
              *ngIf="showCallButton"
            >
              <button
                type="button"
                [disabled]="
                  ['connecting'].indexOf(connectionStatus) !== -1 || inTransfer
                "
                (click)="callVoicemail()"
                class="voicemail-button mt-3 btn"
              >
                <i class="material-icons align-middle">voicemail</i>
                <span class="topbar-badge animated rubberBand voice-mail-notification">{{voiceMailNumber}}</span>
              </button>
              <button
                type="button"
                (click)="call(false)"
                [disabled]="!contactNumber"
                class="btn dialpad-button btn-success clear-btn mt-3"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold"></span>
              </button>
              <button
              type="button"
              (click)="call(true)"
              [disabled]="!contactNumber"
              class="btn dialpad-button btn-success clear-btn mt-3 dial-button"
            >
              <i class="material-icons align-middle">video_call</i>
              <span class="font-weight-bold"></span>
              </button>
              <button
                class="voicemail-button mt-3 btn"
                (click)="showDialPad = !showDialPad" pTooltip="Keypad"
              >
                <i class="material-icons">dialpad</i>
              </button>
            </div>

            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
             *ngIf="showIncomingConsole"
            >
              <button
                type="button"
                (click)="answer(true)"
                class="btn dialpad-button btn-success clear-btn w-50 mt-3 mr-2"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold">Answer</span>
              </button>

              <button
                type="button"
                (click)="endCall()"
                class="btn dialpad-button btn-danger clear-btn w-50 mt-3 ml-2"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold">Reject</span>
              </button>
            </div>
            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
             *ngIf="showConferenceConsole"
            >
              <button
                type="button"
                (click)="onConferenceCall()"
                class="btn dialpad-button btn-success clear-btn w-50 mt-3 mr-2"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold">Add</span>
              </button>

              <button
                type="button"
                (click)="CancelConferenceOption()"
                class="btn dialpad-button btn-danger clear-btn w-50 mt-3 ml-2"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold">Cancel</span>
              </button>
            </div>
            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
             *ngIf="mergeCallOption"
            >
              <button
                type="button"
                (click)="onMergeCall()"
                class="btn dialpad-button btn-success clear-btn w-50 mt-3 mr-2"
              >
                <i class="material-icons align-middle">call</i>
                <span class="font-weight-bold">Merge</span>
              </button>

              <button
                type="button"
                (click)="endCall()"
                class="btn dialpad-button btn-danger clear-btn w-50 mt-3 ml-2"
              >
                <span class="font-weight-bold">Hang Up</span>
              </button>
            </div>

            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
              *ngIf="inTransfer"
            >
              <button
                type="button"
                (click)="transferCall()"
                [disabled]="!contactNumber"
                class="btn dialpad-button btn-success clear-btn w-50 mt-3 mr-2"
              >
                <span class="font-weight-bold">Transfer Call</span>
              </button>

              <button
                type="button"
                (click)="cancelTranferCall()"
                class="btn dialpad-button btn-warning clear-btn w-50 mt-3 ml-2"
              >
                <span class="font-weight-bold">Cancel</span>
              </button>
            </div>

            <div
              class="main-buttons-row d-flex justify-content-around pt-4"
              *ngIf="isConsoleVisible"
            >
              <button
                class="btn btn-blue main-btn mt-n3 field-tip"
                (click)="muteCall()"
                *ngIf="!mute"
              >
                <span class="tip-content">Mute</span>
                <i class="material-icons">mic</i>
              </button>
              <button
                class="btn btn-blue main-btn mt-n3 field-tip"
                (click)="muteCall()"
                *ngIf="mute"
              >
                <span class="tip-content">Unmute</span>
                <i class="material-icons">mic_off</i>
              </button>
              <button
                class="btn btn-blue main-btn mt-n1 field-tip"
                (click)="onHoldCall()"
                *ngIf="!hold"
              >
                <span class="tip-content">Hold</span>
                <i class="material-icons">pause_outline</i>
              </button>
              <button
                class="btn btn-blue main-btn mt-n1 field-tip"
                (click)="onHoldCall()"
                *ngIf="hold"
              >
                <span class="tip-content">Resume</span>
                <i class="material-icons">play_arrow</i>
              </button>
              <!-- <button
                class="btn btn-blue main-btn dialpad-toggle"
                (click)="showDialPad = !showDialPad" pTooltip="Keypad"
              >
                <i class="material-icons">dialpad</i>
              </button> -->
              <button
                class="btn btn-blue main-btn mt-n1 field-tip"
                (click)="initTransferCall()"
              >
                <span class="tip-content">Transfer</span>
                <i class="material-icons">shuffle</i>
              </button>
              <button
                class="btn btn-blue main-btn mt-n1 field-tip"
                (click)="addConference()"
              >
                <span class="tip-content">Conference</span>
                <i class="material-icons">add</i>
              </button>
              <button
                class="btn main-btn btn-red mt-n3 field-tip"
                (click)="endCall()"
              >
                <span class="tip-content">End Call</span>
                <i class="material-icons">call_end</i>
              </button>
            </div>
            <div class="dialpad-buttons-part mx-auto" *ngIf="showDialPad">
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('1')"
              >
                1
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('2')"
              >
                2
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('3')"
              >
                3
              </button>

              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('4')"
              >
                4
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('5')"
              >
                5
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('6')"
              >
                6
              </button>

              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('7')"
              >
                7
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('8')"
              >
                8
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('9')"
              >
                9
              </button>

              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('*')"
              >
                *
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('0')"
              >
                0
              </button>
              <button
                type="button"
                class="btn dialpad-button"
                (click)="updateContactNumber('#')"
              >
                #
              </button>
            </div>
            <div>
              <a class="arrow-pointer" (click)="disconnect()">
                <span class="text-danger">Exit</span>
              </a>
            </div>
          </div>
        </div>
        <div class="outer-left call-details" [ngClass]="{ open: openLeft }">
          <button
            type="button"
            class="close-outer"
            (click)="openLeft = !openLeft"
          >
            <i class="material-icons">{{
              openLeft ? "chevron_right" : "chevron_left"
            }}</i>
          </button>
          <h3 class="h5 text-center mb-3">Ongoing Call</h3>
          <div class="inner-panel" [ngClass]="{ 'video-panel': openLeft }">
            <div class="user-component media"
                *ngIf="
                  [
                    'connecting',
                    'connected',
                    'accepted',
                    'incoming'
                  ].indexOf(connectionStatus) !== -1
                ">
              <img
                src="https://randomuser.me/api/portraits/women/65.jpg"
                alt="user-avatar"
                class="user-thumbnail mr-3"
              />
              <div
                class="media-body"
                *ngIf="!inTransfer"
              >
                <span class="contact-name d-block">{{
                  remoteFriendlyName
                }}</span>
                <span class="text-danger mb-1 d-block">{{connectionStatus}}</span>
                <div class="contact-actions">
                  <video id="selfView" class="local-video" [ngClass]="{ hidden: connectionStatus !== 'accepted' || !openLeft}" autoplay loop="loop" controls="controls"></video>
                  <video id="remoteView" class="remote-video" [ngClass]="{ hidden: connectionStatus !== 'accepted' || !openLeft}" autoplay></video>
                </div>
              </div>
            </div>
        </div>
      </div>
        <!--  Call Setting  -->

        <div
          class="outer-right call-settings"
          [ngClass]="{ open: openSettingLeft }"
        >
          <button
            type="button"
            class="close-outer close-call-settings"
            (click)="openSettingLeft = !openSettingLeft"
          >
            <i class="material-icons">{{
              openSettingLeft ? "chevron_left" : "chevron_right"
            }}</i>
          </button>
          <div *ngIf="openSettingLeft">
            <h3 class="h5 text-center mb-3">Settings</h3>
            <div class="inner-panel">
              <div class="ui-g">
                <div class="ui-g-12">
                  <form
                    [formGroup]="formGroupPhoneSettings"
                    #myFormGroupPhoneSettings="ngForm"
                    autocomplete="nope"
                  >
                    <div
                      class="contact-component media row"
                      formGroupName="phoneSettings"
                    >
                      <div class="row" *ngIf="openSettingLeft">
                          <div class="col-md-3">
                            <div class="checkboxFour">
                                <input type="checkbox" formControlName="dnd" value="1" id="checkboxFourInput" name="" />
                                <label for="checkboxFourInput"></label>
                            </div>
                          </div>
                          <div class="col-md-9">
                              <label class="settings-label">Do Not Disturb</label>
                          </div>
                      </div>
                      <div class="row" *ngIf="openSettingLeft">
                          <div class="col-md-3">
                            <div class="checkboxFour">
                                <input type="checkbox" formControlName="cfwd" value="1" id="checkboxFourInput1" name="" />
                                <label for="checkboxFourInput1"></label>
                            </div>
                          </div>
                          <div class="col-md-9">
                              <label class="settings-label">Call Forwarding</label>
                          </div>
                      </div>
                      <div class="col-md-12"
                        *ngIf="formGroupPhoneSettings.getRawValue().phoneSettings.cfwd">
                        <input
                          type="text"
                          pInputText
                          formControlName="cfwdTo"
                          placeholder="To"
                        />
                      </div>
                      <div class="col-md-12">
                        <button
                          class="btn btn-primary save-contact"
                          (click)="savePhoneSettings()"
                          [disabled]="
                            formGroupPhoneSettings.getRawValue().phoneSettings.cfwd
                            && !formGroupPhoneSettings.getRawValue().phoneSettings.cfwdTo
                          "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Call Setting-->

        <div
          class="outer-right contacts-history"
          [ngClass]="{ open: openRight }"
        >
          <button
            type="button"
            class="close-outer"
            (click)="toggleRightTab()"
          >
            <i class="material-icons">{{
              openRight ? "chevron_left" : "chevron_right"
            }}</i>
          </button>
          <div *ngIf="openRight">
            <h3 class="h5 text-center mb-3">Calls & Contacts</h3>
            <div class="inner-panel">
              <!-- main tabs -->

              <p-tabView
                (onChange)="onChange($event)"
                [activeIndex]="selectedIndex"
              >
                <p-tabPanel header="Call Log" [selected]="true">
                  <div
                    class="tab-pane h349 fade show active"
                    id="call_history"
                    role="tabpanel"
                  >
                    <p-tabView>
                      <p-tabPanel header="All">
                        <div
                          class="tab-pane h302 fade show active"
                          id="call_history_all"
                        >
                        <div class="parent_div" *ngFor="let callLog of getCallLogs('ALL')">
                          <div
                            class="contact-component media"
                            (click)="callFromLog(callLog.otherEndDid)"
                          >
                            <i
                              ngClass="{{
                                callLog.callType === 'MISSED'
                                  ? 'material-icons text-danger'
                                  : 'material-icons text-success'
                              }}"
                              >{{ getIcon(callLog.callType) }}</i
                            >
                            <div class="media-body">
                              <span class="contact-name d-block">{{
                                callLog.otherEndName
                              }}</span>
                              <div class="">
                                <a
                                  href="javascript:void(0)"
                                  class="stretched-link text-muted mb-1"
                                  >{{ callLog.otherEndDid }}</a
                                >
                                <span
                                  class="float-right"
                                  *ngIf="callLog.callType !== 'MISSED'"
                                  >( {{ callLog.duration }}s)</span
                                >
                              </div>
                              <div>
                                <span class="float-right">{{
                                  callLog.createdAt
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="set_left" ngClass="{{callLog.audioObject.stage==='stopped' ? '' : 'set_left_add_border' }}" *ngIf='callLog.audioObject.validLink'>
                            <!-- <i class="material-icons" *ngIf='callLog.audioObject.stage === "stopped" || callLog.audioObject.stage === "paused"'
                              (click)="play(callLog.audioObject)" title="Play">
                              play_circle_outline
                            </i> -->
                            <i class="material-icons" title="Pause" *ngIf='callLog.audioObject.stage === "playing"' (click)="pause(callLog.audioObject)">
                              pause
                            </i>
                            <i class="material-icons" title="Stop" *ngIf='callLog.audioObject.stage === "playing" || callLog.audioObject.stage === "paused"'
                              (click)="stop(callLog.audioObject)">
                              stop
                            </i>
                          </div>
                          </div>
                        </div>
                      </p-tabPanel>

                      <p-tabPanel header="R">
                        <div
                          class="tab-pane h302"
                          id="call_history_received"
                          role="tabpanel"
                        >
                        <div class="parent_div" *ngFor="let callLog of getCallLogs('INCOMING')">
                          <div
                            class="contact-component media"
                            (click)="callFromLog(callLog.otherEndDid)"
                          >
                            <i class="material-icons text-success">{{
                              getIcon(callLog.callType)
                            }}</i>
                            <div class="media-body">
                              <span class="contact-name d-block">{{
                                callLog.otherEndName
                              }}</span>
                              <div class="">
                                <a
                                  href="javascript:void(0)"
                                  class="stretched-link text-muted mb-1"
                                  >{{ callLog.otherEndDid }}</a
                                >
                                <span
                                  class="float-right"
                                  *ngIf="callLog.callType !== 'MISSED'"
                                  >( {{ callLog.duration }}s)</span
                                >
                              </div>
                              <div>
                                <span class="float-right">{{
                                  callLog.displayTime
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="set_left" ngClass="{{callLog.audioObject.stage==='stopped' ? '' : 'set_left_add_border' }}"
                            *ngIf='callLog.audioObject.validLink'>
                            <!-- <i class="material-icons" title="Play"
                              *ngIf='callLog.audioObject.stage === "stopped" || callLog.audioObject.stage === "paused"'
                              (click)="play(callLog.audioObject)">
                              play_circle_outline
                            </i> -->
                            <i class="material-icons" title="Pause" *ngIf='callLog.audioObject.stage === "playing"'
                              (click)="pause(callLog.audioObject)">
                              pause
                            </i>
                            <i class="material-icons" title="Stop"
                              *ngIf='callLog.audioObject.stage === "playing" || callLog.audioObject.stage === "paused"'
                              (click)="stop(callLog.audioObject)">
                              stop
                            </i>
                          </div>
                          </div>
                        </div>
                      </p-tabPanel>

                      <p-tabPanel header="D">
                        <div
                          class="tab-pane h302"
                          id="call_history_dialed"
                          role="tabpanel"
                        >
                        <div class="parent_div" *ngFor="let callLog of getCallLogs('DIALED')">
                          <div
                            class="contact-component media"
                            (click)="callFromLog(callLog.otherEndDid)"
                          >
                            <i class="material-icons text-success">{{
                              getIcon(callLog.callType)
                            }}</i>
                            <div class="media-body">
                              <span class="contact-name d-block">{{
                                callLog.otherEndName
                              }}</span>
                              <div class="">
                                <a
                                  href="javascript:void(0)"
                                  class="stretched-link text-muted mb-1"
                                  >{{ callLog.otherEndDid }}</a
                                >
                                <span
                                  class="float-right"
                                  *ngIf="callLog.callType !== 'MISSED'"
                                  >( {{ callLog.duration }}s)</span
                                >
                              </div>
                              <div>
                                <span class="float-right">{{
                                  callLog.displayTime
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="set_left" ngClass="{{callLog.audioObject.stage==='stopped' ? '' : 'set_left_add_border' }}"
                            *ngIf='callLog.audioObject.validLink'>
                            <!-- <i class="material-icons" title="Play"
                              *ngIf='callLog.audioObject.stage === "stopped" || callLog.audioObject.stage === "paused"'
                              (click)="play(callLog.audioObject)">
                              play_circle_outline
                            </i> -->
                            <i class="material-icons" title="Pause" *ngIf='callLog.audioObject.stage === "playing"'
                              (click)="pause(callLog.audioObject)">
                              pause
                            </i>
                            <i class="material-icons" title="Stop"
                              *ngIf='callLog.audioObject.stage === "playing" || callLog.audioObject.stage === "paused"'
                              (click)="stop(callLog.audioObject)">
                              stop
                            </i>
                          </div>
                          </div>
                        </div>
                      </p-tabPanel>

                      <p-tabPanel header="M">
                        <div
                          class="tab-pane h302"
                          id="call_history_missed"
                          role="tabpanel"
                        >
                          <div
                            class="contact-component media"
                            (click)="callFromLog(callLog.otherEndDid)"
                            *ngFor="let callLog of getCallLogs('MISSED')"
                          >
                            <i class="material-icons text-danger">{{
                              getIcon(callLog.callType)
                            }}</i>
                            <div class="media-body">
                              <span class="contact-name d-block">{{
                                callLog.otherEndName
                              }}</span>
                              <div class="">
                                <a
                                  href="javascript:void(0)"
                                  class="stretched-link text-muted mb-1"
                                  >{{ callLog.otherEndDid }}</a
                                >
                                <span class="float-right">{{
                                  callLog.displayTime
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </p-tabPanel>
                    </p-tabView>
                    <!-- history tabs content end -->
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Contacts">
                  <div
                    class="tab-pane h349"
                    id="contacts"
                    *ngIf="!isNewContactClicked"
                  >
                    <button
                      class="kc_fab_main_btn"
                      *ngIf="openRight"
                      (click)="editContact({})"
                    >
                      +
                    </button>
                    <div class="contact-component-search media">
                      <div style="width: 100%">
                        <input	
                          type="text"	
                          [(ngModel)]="search"	
                          (ngModelChange)="onSearch()"	
                          pInputText	
                          placeholder="Search Contact" />
                      </div>
                    </div>
                    <div
                      class="contact-component media"
                      *ngFor="let cont of contacts"
                    >
                      <img
                        src="{{ getUserAvtar(cont.profilePhotoImageSrc) }}"
                        alt="user-avatar"
                        class="user-thumbnail"
                      />
                      <div class="media-body">
                        <button
                          class="btn contact-name d-block"
                          (click)="editContact(cont)"
                        >
                          {{ cont.firstName }} {{ cont.lastName }}
                        </button>
                        <span style="font-size: 15px"
                          [hidden]="!cont.mobile"
                        >
                          <i class="material-icons call-icon"
                          (click)="callFromLog(cont.mobile)"
                            >call</i
                          >
                          <i class="material-icons sms-icon"
                          (click)="sendSmsFromContact(cont.mobile)"
                            >sms</i
                          >
                          <span class="arrow-pointer"
                          (click)="callFromLog(cont.mobile)">
                            {{ cont.mobile }}
                          </span>
                      </span>
                        <span
                          [hidden]="!cont.landLine"
                        >
                          
                        <i class="material-icons call-icon"
                        (click)="callFromLog(cont.landLine)"
                          >call</i
                        >
                        <span class="arrow-pointer"
                        (click)="callFromLog(cont.landLine)">
                          {{ cont.landLine }}
                        </span>
                    </span>
                        <!-- <a
                        href="javascript:void(0)"
                        (click)="callFromLog(cont.landLine)"
                        class="stretched-link text-muted mb-1"
                        >{{cont.landLine}}</a
                      > -->
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane h349"
                    id="new_contact"
                    *ngIf="isNewContactClicked"
                  >
                    <button
                      class="kc_fab_main_btn"
                      *ngIf="openRight"
                      (click)="isNewContactClicked = false"
                    >
                      x
                    </button>
                    <form
                      [formGroup]="formGroupNewContact"
                      #myFormGroupNewContact="ngForm"
                      autocomplete="nope"
                    >
                      <div
                        class="contact-component media row"
                        formGroupName="newContact"
                      >
                        <div class="col-md-12">
                          <input
                            type="text"
                            formControlName="firstName"
                            pInputText
                            placeholder="First Name"
                          />
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            formControlName="lastName"
                            pInputText
                            placeholder="Last Name"
                          />
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            formControlName="mobile"
                            pInputText
                            placeholder="Mobile"
                          />
                        </div>
                        <div class="col-md-12">
                          <input
                            type="text"
                            formControlName="landLine"
                            pInputText
                            placeholder="Land Line"
                          />
                        </div>
                        <div class="col-md-12">
                          <button
                            class="btn btn-primary save-contact"
                            [disabled]="
                              !formGroupNewContact.getRawValue().newContact
                                .firstName
                            "
                            (click)="saveNewContact()"
                          >
                            Save Contact
                          </button>
                          <button
                            class="btn btn-danger save-contact"
                            *ngIf="
                              formGroupNewContact.getRawValue().newContact.id
                            "
                            (click)="deleteContact()"
                          >
                            Delete Contact
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </p-tabPanel>
              </p-tabView>
              <!-- main tabs content end -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
