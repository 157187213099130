import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../shared/model/constants';
import { AbstractService } from '../../shared/service/abstract.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClockingAutorizationCodeService extends AbstractService<any> {
    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.CLOCKING, '');
    }

    generateEmployeeAuthorizationCode(companyId: number, employeeId: number): Observable<any> {
        // return this._http.get<any>(`http://localhost:8005/api/v2/clocking/authorization-code/employee/generate?companyId=${companyId}&employeeId=${employeeId}`).pipe(map(resp => resp));
        return this._http.get<any>(`${this.baseUrlV2}authorization-code/employee/generate?companyId=${companyId}&employeeId=${employeeId}`).pipe(map(resp => resp));
    }

    validateAuthorizationCode(companyId: number, employeeId: number, code: string): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}authorization-code/employee/validate?companyId=${companyId}&employeeId=${employeeId}&code=${code}`).pipe(map(resp => resp));
    }
}
