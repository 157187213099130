<p-table #dt [columns]="cols" [value]="transactionList" dataKey="id" [lazy]="true" (onLazyLoad)="loadContentsLazy($event)" [rows]="10" [paginator]="true" [totalRecords]="totalRecords" [resizableColumns]="true">
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th class="adjust-padding" pResizableColumn *ngFor="let col of columns" [ngStyle]="{'display': col.display}" [hidden]="col.field==='actions' && !(isEditAllowed || isDeleteAble)">
				<ng-container [ngSwitch]="col.field">
					<ng-container [ngSwitch]="col.field">
					<span *ngSwitchDefault>
						<div class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
							<span *ngIf="col.sort">
								<span (click)="col.sort == 'number'? op12.toggle($event) : op11.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
									<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
								</span>
							</span>
						</div>
					</span>
					</ng-container>
				</ng-container>	
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-rowData let-i="rowIndex">

		<tr *ngIf="!loading" [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
			<td>{{i+1}}</td>
			<td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.clientName" pTooltip="{{ rowData.clientName }}" tooltipPosition="left">{{ rowData.clientName }}</span>
					<span *ngIf="!rowData.clientName" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
			<td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.productName" pTooltip="{{ rowData.productName }}" tooltipPosition="left">{{ rowData.productName }}</span>
					<span *ngIf="!rowData.productName" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
			<td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.date" pTooltip="{{ rowData.date | date: 'MM/dd/yyyy h:mm:ss'  }}" tooltipPosition="left">{{ rowData.date | date: 'MM/dd/yyyy h:mm:ss'  }}</span>
					<span *ngIf="!rowData.date" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
			<td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.client.assignedTo" pTooltip="{{ rowData.client.assignedTo }}" tooltipPosition="left">{{ rowData.client.assignedTo }}</span>
					<span *ngIf="!rowData.client.assignedTo" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
			<td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.user" pTooltip="{{ rowData.user }}" tooltipPosition="left">{{ rowData.user }}</span>
					<span *ngIf="!rowData.user" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="emptymessage" let-columns>
		<tr *ngIf="!loading && (!totalRecords || !totalRecords.length)">
			<td [attr.colspan]="6" class="text-center py-5">
				<div class="text-muted my-5">{{'no data' | translate}}</div>
			</td>
		</tr>
		<tr *ngIf="loading">
			<td [attr.colspan]="6" class="text-center py-5">
				<div class="text-muted my-5">{{'Loading...' | translate}}</div>
			</td>
		</tr>
	</ng-template>

</p-table>


<p-dialog [baseZIndex]="99999999" *ngIf="displayContractDetail" showEffect="fade" styleClass="w-50" [autoZIndex]="true" [(visible)]="displayContractDetail" [style]="{'width':'90%', 'max-width':'900px', 'max-height':'80vh'}">
	<p-header>
		<span><i class="fa-solid fa-circle-plus mr-2"></i>{{'Contacts' | translate}}: {{contractSelected.clientName}} ({{contractList.length || 0}})</span>
	</p-header>
	<p-table [value]="contractList" [totalRecords]="contractList.length" [rows]="10" [paginator]="true">
        <ng-template pTemplate="header" let-columns>
			<tr>
				<th>
				#
				</th>
				<th>
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{'Signed' | translate}}" tooltipPosition="left">{{'Signed' | translate}}</span>
					</div>
				</th>
				<th>
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{'Rate' | translate}}" tooltipPosition="left">{{'Rate' | translate}}</span>
					</div>
				</th>
				<th>
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{'% Rate' | translate}}" tooltipPosition="left">{{'% Rate' | translate}}</span>
					</div>
				</th>
				<th>
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner" pTooltip="{{'Monthly' | translate}}" tooltipPosition="left">{{'Monthly' | translate}}</span>
					</div>
				</th>
			</tr>
		</ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr>
            <td>{{index +1}}</td>
            <td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.productName" pTooltip="{{ rowData.productName }}" tooltipPosition="left">{{ rowData.productName }}</span>
					<span *ngIf="!rowData.productName" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
			</td>
            <td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.tripRate" pTooltip="{{ rowData.tripRate }}" tooltipPosition="left">{{ rowData.tripRate }}</span>
					<span *ngIf="!rowData.tripRate" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
            </td>
            <td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.rate" pTooltip="{{ rowData.rate }}" tooltipPosition="left">{{ rowData.rate }}</span>
					<span *ngIf="!rowData.rate" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
            </td>
            <td>
				<span class="one-liner">
					<span class="mobile-table-label">{{col.label | translate}}: </span>
					<span *ngIf="rowData.monthlyRate" pTooltip="{{ rowData.monthlyRate }}" tooltipPosition="left">{{ rowData.monthlyRate }}</span>
					<span *ngIf="!rowData.monthlyRate" pTooltip="{{ 'no data' | translate }}" tooltipPosition="left">{{ 'no data' | translate }}</span>
				</span>
            </td>
          </tr>
        </ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loadingConracts && (!totalRecords || !contractList.length)">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loadingConracts">
				<td [attr.colspan]="5" class="text-center py-5">
					<div class="text-muted my-5">{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="cancelDialog()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>


<p-overlayPanel [appendTo]="'body'" #op1>

  <p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortingClick(selectedSortOption)">
    <ng-template let-col pTemplate="item">
      <div>
        <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
        <span>{{col.name}}</span>
      </div>
    </ng-template>
  </p-listbox>

</p-overlayPanel>
<p-overlayPanel [appendTo]="'body'" #op2>

  <p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption"
    (onChange)="sortingClick(selectedSortOption)">
    <ng-template let-col pTemplate="item">
      <div>
        <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
        <span>{{col.name}}</span>
      </div>
    </ng-template>
  </p-listbox>

</p-overlayPanel>


<p-confirmDialog header="Confirmation" icon="fa-solid fa-circle-question" width="525"></p-confirmDialog>
