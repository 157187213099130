import {WindowRefService} from '../../../shared/service/window-ref/window-ref.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {AuthService} from '../../../shared/service/auth/auth.service';
import moment from 'moment';
import {PaymentTransactionService} from '../../service/payment-transaction.service';
import {FileUtility} from 'app/shared/utility/file.utility';
import {DatePipe, formatNumber} from '@angular/common';
import {MonitoringDetailsService} from 'app/admin/process-monitor/service/monitoring-details.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {PaymentInvoiceService} from '../../service/payment-invoice.service';
import {OverlayPanel} from 'primeng';
import {debounceTime} from 'rxjs/operators';
import {DropDownsService} from 'app/shared/service/drop-downs/drop-downs.service';
import {PayrollSettingService} from '../../../payroll-setting/service/payroll-setting.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {DocumentsServicev2} from '../../../shared/service/documents-upload/document.v2.service';
import {FormValidatorService} from '../../../shared/service/form-validator/form-validator.service';
import {forkJoin} from 'rxjs-compat/observable/forkJoin';
import {buildSurveyLandPdfFile} from '../../../quote/model/quote.model';
import {QuoteService} from '../../../quote/service/quote.service';
import {ClientService} from '../../../crm/service/client.service';
import {CompanyService} from '../../../company/service/company.service';
import {GeneralInvoiceService} from '../../../general-invoice/service/general-invoice.service';
import {PunchBillingService} from '../../../billing/service/punch-billing.service';

@Component({
    selector: 'app-details-list',
    templateUrl: './payment-details-list.component.html',
    styleUrls: ['./payment-details-list.component.scss'],
    providers: [WindowRefService]

})
export class PaymentDetailsListComponent implements OnInit {
    @ViewChild('op3', { static: true }) op3: OverlayPanel;

    transactionList: any[] = [];
    invoiceRef: any = {};
    exportAs: any;
    loading = false;
    totalRecords = 0;
    totalAmountPaid = 0;
    isPlatformAdmin: any;
    showPaymentDialog: boolean = false;
    paymentMethod: { label: string; value: string; }[];
    paymentType: { label: string; value: string; }[];
    paymentPayer: any[];
    defaultDate: any;
    disablePayer: boolean = true;
    msgs = [];
    rowData: any;

    cols: any[];
    size: number;
    page: number;
    sortField: string = "";
    sortOrder: number;
    selectedSortOption = "";
    selectedField = "";
    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
      ];
      numberSortOptions = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
      ];
      dateSortOptions = [
          { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
          { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
        ];
    exportOptions = [
        { name: 'View Details', value: 'view' },
        { name: 'Download Invoice', value: 'download' },
    ];
    fromDate: Date;
    toDate: Date;
    timeSpent = new Date();
    private _routerSub = Subscription.EMPTY;
    searchText;
    searchTextSubject = new Subject<string>();

    statusTypes: any[] = [
        { label: 'Success', value: 'Succeeded' },
        { label: 'Failed', value: 'Failed' },
        { label: 'Refunded', value: 'Refunded' },
        { label: 'Pending', value: 'Pending' }
    ];
    selectedStatus = ['Succeeded'];
    allClientList: any = [];
    selectedClient;

    paymentMethods = [
        { label: 'VISA', value: 'VISA' },
        { label: 'MASTER CARD', value: 'MASTER_CARD' },
        { label: 'DISCOVER', value: 'DISCOVER' },
        { label: 'AMERICAN EXPRESS', value: 'AMERICAN_EXPRESS' },
        { label: 'BANK TRANSFER', value: 'BANK_TRANSFER' }
    ];
    paymentMethodSelected;

    isClientRole;
    exportingPDF = false;

    showPaymentDetail: boolean = false
    detailData: any = {}
    functionImport = ''
    transferFeeCashOut = 0
    transferFeeFixedAmount = 0
    showSendEmailPopup: boolean = false
    emailMessage: any = ''
    emailAddress: any = ''
    popupData: any

    sendEmailLoading = false;
    constructor(
        private authService: AuthService,
        private dropDownsService: DropDownsService,
        private messageService: MessageService,
        private datePipe: DatePipe,
        private router: Router,
        private paymentTransactionService: PaymentTransactionService,
        private contentService: PaymentInvoiceService,
        private monitoringDetailsService: MonitoringDetailsService,
        private payrollSettingService: PayrollSettingService,
        private documentsServicev2: DocumentsServicev2,
        private formValidator: FormValidatorService,
        private quoteService: QuoteService,
        private clientService: ClientService,
        private companyService: CompanyService,
        private generalInvoiceService: GeneralInvoiceService,
        private punchBillingService: PunchBillingService) {
        this._routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url !== '/app/payments/detail/list') {
                    this.monitoringDetailsService.monitorAction(
                        `Navigated to Payment List`,
                        this.timeSpent,
                        {
      navigated_to_payment_list_page_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                        },
                        'complete',
                        `Navigated to Payment List`,
                        0
                    );
                }
            }
        });

        this.isClientRole = this.authService.isClientRole();

    }

    ngOnInit() {
        this.searchTextSubject.pipe(debounceTime(1000)).subscribe(text => {
            this.searchText = text;
            this.search();
        })
        this.fromDate = moment().startOf('month').toDate();
        this.toDate = moment().endOf('month').toDate();
        this.cols = [
            { field: 'invoiceNumber', label: 'Invoice', sortOptions: '', sort: 'text' },
            { field: 'date', label: 'Date', sortOptions: '', sort: 'number' },
            { field: 'transactionId', label: 'ID', sortOptions: '', sort: 'text' },
            { field: 'description', label: 'Desc', sortOptions: '', sort: 'text' },
            { field: 'amount', label: 'Paid', sortOptions: '', sort: 'number' },
            { field: 'paymentMethod', label: 'Method', sortOptions: '', sort: 'text' },
            { field: 'transactionType', label: 'Type', sortOptions: '', sort: 'text' },
            { field: 'autoPay', label: 'Processed', sortOptions: '', sort: 'text' },
            { field: 'status', label: 'Status', sortOptions: '', sort: 'text' },
            { field: 'createdByUsr', label: 'Created', sortOptions: '', sort: 'text' }
        ];
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.loadAllClient();
    }


    ngOnDestroy(): void {
        this.searchTextSubject.unsubscribe();
        setTimeout(() => {
            this._routerSub.unsubscribe();
        }, 1000)
    }

    loadAllClient() {
        const options: any = {size: 99999, page: 0, moduleName: 'billing'};
        if (!this.isPlatformAdmin) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        // options.status = 1;
        if (this.isClientRole) {
            // Prevent load all client when login user is client
            const userInfor = this.authService.getUserInfo();
            this.selectedClient = {value: userInfor.username, label: userInfor.name, email: userInfor.email};
            this.allClientList = [this.selectedClient];
            return;
        }
        this.dropDownsService.getAllClientList(options).subscribe((res) => {
            const resObj: any = res;
            this.allClientList = [];
            if (resObj.status === 'SUCCESS') {
                const data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
                this.allClientList = data.map(c => ({value: c.fullName, label: c.value, email: c.email}));
            }
        });
    }

    loadContentsLazy(event?: LazyLoadEvent) {
        if (this.authService.isContractorRole()) {
            return;
        }
        //console.log(event);
        this.loading = true;
        this.sortField = event && event.sortField ? event.sortField : "";
        this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
        let sortOrder = this.selectedSortOption === 'DESC' ? 'desc' : 'asc'
        if (this.sortField === 'autoPay') {
            if (sortOrder === 'desc') {
                sortOrder = 'asc';
            } else {
                sortOrder = 'desc';
            }
        }
        const fromDate = this.fromDate ? (new Date(this.fromDate).getTime() + new Date(this.fromDate).getTimezoneOffset() * 60000) : null;
        const toDate = this.toDate ? (new Date(this.toDate).getTime() + new Date(this.toDate).getTimezoneOffset() * 60000) : null;
        this.size = event ? event.rows : (this.size ? this.size : 10);
        this.page = event ? (event.first) / event.rows : (this.page ? this.page : 0);
        const options: any =
        {
            size: this.size,
            page: this.page,
            fromDate: fromDate,
            toDate: toDate,
            statuses: this.selectedStatus,
            paymentMethod: this.paymentMethodSelected
        };
        if (this.selectedClient) {
            options.createdByUsr = this.selectedClient.value;
        }
        if (this.authService.isCompanyAdminRole()) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.authService.isClientRole()) {
            options.createdByUsr = this.authService.getCurrentUsername();
        }
        if (this.searchText) {
            options.searchText = this.searchText;
        }

        if (this.selectedField != "") {
            options.sort = this.selectedField + ',' + sortOrder;
            options.sortField = this.selectedField;
            options.sortOrder = this.selectedSortOption;
        } else {
            options.sortField = 'createdAt';
            options.sortOrder = 'DESC';
        }
        this.loadPage(options);
    }


    onChangeType() {
    }

    loadPage(options: any) {
        const time = new Date();
        // this.paymentTransactionService.getTotalAmountPaid(options).subscribe((res: any) => {
        //     if (res.status === 'SUCCESS') {
        //         this.totalAmountPaid = res.data || 0;
        //     }
        // });
        this.totalAmountPaid = 0;
        const totalPaidRequest = {
            ...options,
            size: 999999,
            page: 0
        }
        this.paymentTransactionService.search(totalPaidRequest).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                resObj.data.content.forEach(t => {
                    if (t.amount) {
                        this.totalAmountPaid += t.amount;
                    }
                });
            }
        });

        this.paymentTransactionService.search(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.transactionList = resObj.data.content;
                this.transactionList.forEach(t => {
                    if (t.status === 'Failed' && t.description) {
                        t.description.split(';');
                        t.description = t.description.split(';')[0]
                    }
                    
                })
                this.totalRecords = resObj.data.totalElements;
                const args = {
                    payment_searched_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                this.saveMonitorDetails(args, `Payment Searched`, time)
            }
            this.loading = false;
            //console.log(this.paymentInfoList);
        }, () => this.loading = false);

    }

    sortingClick(selectedSortOption) {
        this.loadContentsLazy();
    }

    sort(fieldName: string, order: number) {
        this.transactionList.sort((row1, row2) => {
            let val1 = row1[fieldName];
            let val2 = row2[fieldName];
            if (val1?.toLowerCase) {
                val1 = val1.toLowerCase();
            }
            if (val2?.toLowerCase) {
                val2 = val2.toLowerCase();
            }
            if (val1 === val2) {
                return 0;
            }
            let result = -1;
            if (val1 > val2) {
                result = 1;
            }
            if (order < 0) {
                result = -result;
            }
            return result;
        });
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sort = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    exportPdf() {
        this.exportingPDF = true;
        const options: any =
        {
            size: 99999,
            page: 0,
            fromDate: this.fromDate ? (new Date(this.fromDate).getTime() + new Date(this.fromDate).getTimezoneOffset() * 60000) : null,
            toDate: this.toDate ? (new Date(this.toDate).getTime() + new Date(this.toDate).getTimezoneOffset() * 60000) : null,
            sortField: 'createdAt',
            sortOrder: 'DESC',
            searchText: this.searchText,

        };
        if (this.authService.isCompanyAdminRole()) {
            options.companyId = this.authService.getCurrentCompanyId();
        }
        if (this.authService.isClientRole()) {
            options.createdByUsr = this.authService.getCurrentUsername();
        }
        const time = new Date();
        this.paymentTransactionService.search(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const billings = resObj.data.content;
                if (billings && billings.length) {
                    const data: any = {
                        contentList: billings.map(value => {
                            return {
                                date: this.datePipe.transform(value.date, 'MM/dd/yyyy'),
                                transactionId: value.transactionId,
                                invoiceNumber: this.convertArrayToString(value.invoiceNumber),
                                description: 'OPERRWORK Invoices Payment',
                                amount: formatNumber(value.amount, 'en-US', '1.2-2'),
                                paymentMethod: value.paymentMethod,
                                transactionType: 'Authorization and Capture',
                                createdByUsr: value.createdByUsr
                            };
                        })
                    };
                    if (!this.isPlatformAdmin) {
                        data.companyId = this.authService.getCurrentCompany();
                    }
                    data.username = this.authService.getCurrentUsername();
                    data.totalAmountPaid = this.totalAmountPaid >= 0 ? this.totalAmountPaid.toFixed(2) : '0.00';
                    data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
                    this.paymentTransactionService.exportPdf(data).subscribe(res => {
                    
                        if (res.data) {
                            this.exportingPDF = false;
                            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                            const blobUrl = URL.createObjectURL(blob);
                            window.open(blobUrl, '_blank');
                        } else {
                            this.exportingPDF = false;
                            this.messageService.add({ severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting client pdf list' });
                        }
                    });
                }
                const args = {
                    payment_list_downloaded_by: this.authService.isClientRole() ? this.authService.getUserInfo()?.name : this.authService.getCurrentLoggedInName()
                }
                this.saveMonitorDetails(args, `Payment List Downloaded`, time)
            }
        });
    }

    convertArrayToString(array: any) {
        return array.join(", ");
    }

    saveMonitorDetails(args, type, time) {
        this.monitoringDetailsService.monitorAction(
            `${type}`,
            time,
            args,
            'complete',
            `${type}`,
            0
        );
    }

    clickInvoiceRef(event: any, rowData) {
        this.op3.toggle(event);
        this.invoiceRef = rowData;
    }

    invoiceRefAction(type: string) {
        switch (type) {
            case 'view': this.exportInvoicePdf(this.invoiceRef);
                break;
            case 'download': this.downloadInvoiceRefPdf();
                break;
        }
    }

    downloadInvoiceRefPdf() {
        this.op3.hide();
        if (this.invoiceRef.invoiceType === 'Survey Land') {
            this.quoteService.findByInvoiceNumber(this.invoiceRef.invoiceNumber).subscribe(res => {
                const resObj: any = res;
                forkJoin([
                    this.clientService.getClientById(resObj.data.clientId),
                    this.companyService.getCompanyByCompanyId(resObj.data.companyId)
                ]).subscribe((response: any) => {
                    const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
                    const blobUrl = URL.createObjectURL(doc.output("blob"));
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.target = '_blank';
                    let fileName = 'Dummy.pdf';
                    if (res.data.fileName && res.data.fileName.length > 0) {
                        fileName = res.data.fileName;
                    }
                    a.download = fileName;
                    a.click();
                });
            });
        } else if (this.invoiceRef?.lastModifiedBy && this.invoiceRef.lastModifiedBy.startsWith('GENERAL-INVOICE')) {
            this.downloadGeneralInvoiceRefPdf(this.invoiceRef.lastModifiedBy.replace('GENERAL-INVOICE-', ''));
        } else if (this.invoiceRef?.invoiceType === 'Punch') {
            this.downloadPunchInvoiceRefPdf(this.invoiceRef.id);
        } else {
            this.contentService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.target = '_blank';
                    let fileName = 'Dummy.pdf';
                    if (res.data.fileName && res.data.fileName.length > 0) {
                        fileName = res.data.fileName;
                    }
                    a.download = fileName;
                    a.click();
                    const args = {
                        ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
                        Ticket_id: this.invoiceRef.invoiceId,
                    };
                }
            });
        }
    }

    exportInvoicePdf(invoice) {
        this.op3.hide();
        if (invoice.pdfLink) {
            window.open(invoice.pdfLink, '_blank');
        } else if (invoice?.lastModifiedBy && invoice.lastModifiedBy.startsWith('GENERAL-INVOICE')) {
            this.viewGeneralInvoiceRefPdf(invoice.lastModifiedBy.replace('GENERAL-INVOICE-', ''));
        } else if (invoice?.invoiceType === 'Punch') {
            this.viewPunchInvoiceRefPdf(invoice.id);
        } else {
            if (invoice.invoiceType === 'Survey Land') {
                this.quoteService.findByInvoiceNumber(invoice.invoiceNumber).subscribe(res => {
                    const resObj: any = res;
                    forkJoin([
                        this.clientService.getClientById(resObj.data.clientId),
                        this.companyService.getCompanyByCompanyId(resObj.data.companyId)
                    ]).subscribe((response: any) => {
                        const doc = buildSurveyLandPdfFile(resObj.data, response[1].data.name, response[0].data.email, response[0].data.phone, true);
                        const blobUrl = URL.createObjectURL(doc.output("blob"));
                        window.open(blobUrl, '_blank');
                    });
                });
            } else {
                this.contentService.exportPdf(invoice.id).subscribe(res => {
                    if (res.data) {
                        const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank');
                    }
                });
            }
        }
    }

    searchEvent(event?: any) {
        const wordSearch = event.target.value;
        this.searchTextSubject.next(wordSearch);
    }

    search() {
        this.page = 0;
        this.loadContentsLazy();
    }

    reset() {
        this.page = 0;
        this.selectedClient = null;
        this.searchText = null;
        this.selectedField = '';
        this.selectedStatus = null;
        this.paymentMethodSelected = null;
        this.fromDate = moment().startOf('month').toDate();
        this.toDate = moment().endOf('month').toDate();
        this.loadContentsLazy();
    }

    closePaymentDetailPopup(e) {
        this.showPaymentDetail = false
    }

    openPaymentDetailPopup(rowData) {
        if (rowData) {
            this.payrollSettingService.getPayrollSettings(rowData.companyId, { companyId: rowData.companyId }).subscribe((res: any) => {
                if (res.status === 'SUCCESS') {
                    if (res.data.transferFeeCashOut) {
                        this.transferFeeCashOut = Number(res.data.transferFeeCashOut)
                    }
                    if (res.data.transferFeeFixedAmount) {
                        this.transferFeeFixedAmount = Number(res.data.transferFeeFixedAmount)
                    }
                    this.getInvoices(rowData)
                }
            })
        }
    }

    getInvoices(rowData) {
        const options = {
            page: 0,
            size: 9999,
            companyId: rowData.companyId,
            oldInvoiceOnly: false,
            ignoreInvoiceDetails: true,
            invoiceNumbers: rowData.invoiceNumber
        }

        this.contentService.exportInvoices(options).subscribe((res: any) => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                const list = resObj.data.content

                const totalInvoiceAmount = list.reduce((total, item) => total += (item.totalFee || 0), 0)
                this.detailData.invoice = rowData.invoiceNumber.join(', ')
                this.detailData.invoiceNumbers = rowData.invoiceNumber.length
                this.detailData.paymentCardType = rowData.paymentMethod
                this.detailData.transactionPaymentId = rowData.transactionId
                this.detailData.amount = totalInvoiceAmount
                this.detailData.fee = rowData.amount - totalInvoiceAmount

                this.functionImport = 'PRINT'
                this.showPaymentDetail = true
            }
        })
    }

    openSendEmailPopup(data) {
        this.popupData = data
        this.showSendEmailPopup = true
        this.showPaymentDetail = false
    }

    sendEmail() {
        if (!this.emailAddress || this.emailAddress.length <= 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Email Address is required!' });
            return;
        }

        if (!this.formValidator.checkValidEmails(this.emailAddress)) {
            const errorValidEmail = "Email Address: " + this.emailAddress +  " is not valid!";
            this.messageService.add({ severity: 'error', summary: 'Error', detail: errorValidEmail });
            return;
        }

        if (!this.emailMessage || this.emailMessage.length <= 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Message is required!' });
            return;
        }

        const toEmails = []
        toEmails.push(this.emailAddress)

        const data = document.createElement('div');

        if (this.popupData && this.popupData.el && this.popupData.el.nativeElement) {
            data.innerHTML = this.popupData.el.nativeElement.innerHTML;
        } else {
            data.innerHTML = this.popupData.innerHTML;
        }
        data.style.width = '800px';
        data.style.padding = '25px';
        document.getElementById('pdfContent').appendChild(data);

        this.sendEmailLoading = true;
        html2canvas(data, { useCORS: true }).then(canvas => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = 210;
            const pageHeight = 295;
            const imgWidth = pageWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            const blob = pdf.output('blob');
            const fileName = `Receipt_Confirmation_${this.authService.getCurrentCompanyId()}_${new Date().getTime()}.pdf`
            const file = new File([blob], fileName, { type: 'application/pdf' });

            this.documentsServicev2.uploadFile(file, 'send_email_receipt_confirmation', 1, 'Company').subscribe(uploadFileRes => {

                const pdfUrl = uploadFileRes?.data?.fileUrl;
                const bodyEmail = '<html><body><br/>Dear guy,'
                    + '<br/>' + this.emailMessage
                    + '<br/>Pdf Receipt Confirmation: <a href="' + pdfUrl + '" target="_blank">click here</a>'
                    + '<br/>Thank you for your attention to this matter. <br/>Best regards.'
                    + '</body></html>';

                const sendEmailData: any = {
                    toEmails: toEmails,
                    subject: 'Receipt',
                    body: bodyEmail,
                    createdByUsr: this.authService.getCurrentUsername(),
                    emailCompanyId: this.authService.getCurrentCompanyId()
                };

                this.documentsServicev2.sendEmailFromOperr(sendEmailData).subscribe(() => {
                    this.showSendEmailPopup = false;
                    this.sendEmailLoading = false;
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Send Receipt Confirmation Success!' });
                }, () => {
                    this.sendEmailLoading = false;
                    this.showSendEmailPopup = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Send Receipt Confirmation Fail!' });
                });

            }, () => {
                this.sendEmailLoading = false;
                this.showSendEmailPopup = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Send Receipt Confirmation Fail!' });
            });
        });
    }

    viewGeneralInvoiceRefPdf(id) {
        this.generalInvoiceService.exportPdfWithName(id).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            }
        }, () => { });
    }

    viewPunchInvoiceRefPdf(invoiceId) {
        this.punchBillingService.exportPdfDetailsByInvoiceId(invoiceId).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            }
        }, () => { });
    }

    downloadGeneralInvoiceRefPdf(id) {
        this.generalInvoiceService.exportPdfWithName(id).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                let fileName = 'Dummy.pdf';
                if (res.data.fileName && res.data.fileName.length > 0) {
                    fileName = res.data.fileName;
                }
                a.download = fileName;
                a.click();
            }
        }, () => { });
    }

    downloadPunchInvoiceRefPdf(invoiceId) {
        this.punchBillingService.exportPdfDetailsByInvoiceId(invoiceId).subscribe(res => {
            if (res.data) {
                const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                let fileName = 'Dummy.pdf';
                if (res.data.fileName && res.data.fileName.length > 0) {
                    fileName = res.data.fileName;
                }
                a.download = fileName;
                a.click();
            }
        }, () => { });
    }
}
