<div class="spinner-overlay" *ngIf="loadingSpinner">
    <p-progressSpinner class="center-spin"></p-progressSpinner>
</div>
<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Parking Invoices' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i> {{'Find Parking Invoices' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="!disableCompany || disableFields">
			<label>{{'companyLabel' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [options]="allCompanyList" [disabled]="disableCompany || disableFields" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="loadAllClient()" style="flex-grow:1"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Client/Base' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-briefcase"></i>
					</button>
				</div>
				<p-dropdown [options]="allClientList" [filter]="true" [(ngModel)]="selectedClient" [showClear]="true" (onChange)="getAllAddedProductsByClientId($event)" [disabled]="disableFields" placeholder="{{'pleaseSelect' | translate}}" style="flex-grow:1"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field class="w-100">
					<mat-date-range-input [rangePicker]="picker">
						<input matStartDate matInput placeholder="{{'Start date' | translate}}" [(ngModel)]="fromDate" (focus)="picker.open()">
						<input matEndDate matInput placeholder="{{'End date' | translate}}" [(ngModel)]="toDate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			  <label>{{'Invoice' | translate}} #:</label>
			  <div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-file-invoice-dollar"></i>
					</button>
				</div>
				<input type="text" pInputText placeholder="{{'Search' | translate}}" class="w-100" [(ngModel)]="invoiceNumber">
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			  <label>{{'PaymentMethod' | translate}}:</label>
			  <div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-wallet"></i>
					</button>
				</div>
				<p-dropdown class="w-100" [options]="paymentMethods" [showClear]="true" [(ngModel)]="paymentMethod" [style]="{'width': '100%'}" placeholder="{{'pleaseSelect' | translate}}"></p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Status' | translate}}:</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown [options]="statusTypes" [disabled]="disableAgency" [(ngModel)]="selectedStatus" style="flex-grow:1">
					<ng-template pTemplate="item" let-item>
						{{item.label | translate}}
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						{{item.label | translate}}
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button type="button" class="btn btn-danger mx-1" (click)="resetFilters()"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button type="button" class="btn btn-primary mx-1" (click)="search()"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<div class="d-flex align-items-center justify-content-start my-4">   
	<h1 class="m-0 ml-2">{{'Browse Parking Invoices' | translate}}</h1>
</div>
<p-panel id="table-ppanel" [toggleable]="true">
	<p-contextMenu #cm [model]="items"></p-contextMenu>
	<p-header>
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'Parking Invoice List' | translate}} ({{totalRecords}})</span>
	</p-header>
	<p-table #dt [value]="contentList" dataKey="id" selectionMode="single" [loading]="loading"
				  [rows]="10" [paginator]="true" [rowsPerPageOptions]="[5,10,20]" (onLazyLoad)="search()" [totalRecords]="totalRecords"
				  [contextMenu]="cm" [responsive]="true" (onPage)="paginate($event)"
				  (sortFunction)="customSort($event)" [customSort]="true"
			 [globalFilterFields]="['invoiceNumber', 'clientName', 'totalAmountTxt', 'balanceTxt', 'paymentMethod', 'companyName']">

		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center flex-wrap justify-content-between" style="row-gap: 10px">
				<div class="d-flex">
					<button class="btn btn-info mx-1" (click)="exportReportPdf()" type="button" [disabled]="!selectedClient">
						<span class="text-nowrap"><i class="fa-solid fa-glasses mr-2"></i>{{'Detailed Report' | translate}}</span>
					</button>
					<button type="button" (click)="exportTablePdf()" class="btn btn-primary mx-1">
						<span class="text-nowrap"><i class="fa-solid fa-print mr-2"></i> {{'Export PDF' | translate}}</span>
					</button>
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 450px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input [(ngModel)]="searchText" (input)="customFilter($event)" placeholder="{{'Filter' | translate}}" class="form-control" style="height: 40px !important">
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of cols">
					<div class="d-flex align-items-center justify-content-between w-100">
						<span class="one-liner">{{col.label | translate}}</span>
						<span *ngIf="col.sort">
							<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
								<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
							</span>
						</span>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData">
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.invoiceNumber" class="text-primary" (click)="exportPdf(rowData)" pTooltip="{{rowData.invoiceNumber}}"> {{rowData.invoiceNumber}}</span>
						<span *ngIf="!rowData.invoiceNumber" class="text-muted" (click)="exportPdf(rowData)"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span> 
						<span *ngIf="rowData.invoiceDate" class="text-primary" pTooltip="{{rowData.invoiceDate | date: 'MM/dd/yyyy'}}"> {{rowData.invoiceDate | date: 'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.invoiceDate" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Client' | translate}}: </span>
						<span *ngIf="rowData.clientName" class="text-primary" pTooltip="{{rowData.clientName}}"> {{rowData.clientName}}</span>
						<span *ngIf="!rowData.clientName" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Due Date' | translate}}: </span> 
						<span *ngIf="rowData.dueDate" class="text-primary" pTooltip="{{rowData.dueDate | date: 'MM/dd/yyyy'}}"> {{rowData.dueDate | date: 'MM/dd/yyyy'}}</span>
						<span *ngIf="!rowData.dueDate" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Hours' | translate}}: </span>
						<span *ngIf="rowData.totalHrs" class="text-primary" pTooltip="{{rowData.totalHrs}}"> {{rowData.totalHrs}}</span>
						<span *ngIf="!rowData.totalHrs" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Due' | translate}}: </span>
						<span *ngIf="rowData.amountDue" class="text-primary" pTooltip="${{rowData.amountDue}}">${{rowData.amountDue}}</span>
						<span *ngIf="!rowData.amountDue" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Paid' | translate}}: </span>
						<span *ngIf="rowData.totalAmountTxt" class="text-primary" pTooltip="${{rowData.totalAmountTxt}}"> ${{rowData.totalAmountTxt}}</span>
						<span *ngIf="!rowData.totalAmountTxt" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Balance' | translate}}: </span>
						<span *ngIf="rowData.balanceTxt" class="text-primary" pTooltip="${{rowData.balanceTxt}}">${{rowData.balanceTxt}}</span>
						<span *ngIf="!rowData.balanceTxt" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Method' | translate}}: </span>
						<span *ngIf="rowData.paymentMethod" class="text-primary" pTooltip="{{rowData.paymentMethod}}"> {{rowData.paymentMethod}}</span>
						<span *ngIf="!rowData.paymentMethod" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td>
				<td *ngIf="isPlatformAdmin">
					<span class="one-liner">
						<span class="mobile-table-label">{{'Invoice' | translate}}: </span>
						<span *ngIf="rowData.companyName" class="text-primary" pTooltip="{{rowData.companyName}}"> {{rowData.companyName}}</span>
						<span *ngIf="!rowData.companyName" class="text-muted"> {{'no data' | translate}}</span>
					</span>
				</td> 
			</tr>
		</ng-template>

		<ng-template pTemplate="footer" let-columns>
			<tr *ngIf="contentList.length > 0">
				<td><span class="desktop-table-label">{{'Totals' | translate}}</span></td>
				<td></td>
				<td></td>
				<td></td>
				<td>
					<!--
					<span class="one-liner">
						<span class="mobile-table-label">Fees: </span>${{sumFeeTxt}}
					</span>
					-->
				</td>
				<td>
				<!-- 		
					<span class="one-liner">
						<span class="mobile-table-label">Fees Paid: </span>${{sumPaidFeeTxt}}
					</span>
				-->
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">Total: </span>${{sumTotalAmountTxt}}
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">Balance: </span>${{sumOutStandingBalanceTxt}}
					</span>
				</td>
				<td></td>
				<td *ngIf="isPlatformAdmin"></td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="loading" class="text-center">
				<td [attr.colspan]="isPlatformAdmin ? 9 : 8">
					{{'Loading' | translate}} ...
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading" class="text-center">
				<td [attr.colspan]="isPlatformAdmin ? 9 : 8">
					{{'no data' | translate}}
				</td>
			</tr>
		</ng-template>
	</p-table>

	<p-dialog header="Invoice Details" styleClass="w-50" [modal]="true" [responsive]="true" [autoZIndex]="true"
			  [(visible)]="detailEnable" [contentStyle]="{'max-height':'400px'}">
		<div class="ui-g invoice-details">
			<div id="contentToConvert" style="padding:50px;">
				<div style="text-align: center;">
					<img src="../../../../assets/punch-images/operr_logo.jpg">
				</div>
				<div class="ui-g-12">
					<p style="text-align: center;">
						<b>{{'INVOICE' | translate}}</b>
					</p>
				</div>
				<div class="ui-g-12">
					<div class="ui-g-6">
						{{'Invoice Number' | translate}}: {{invoiceDetail.invoiceNumber}}
					</div>
					<div style="text-align: right">
						{{'Date' | translate}}: {{invoiceDetail.invoiceDate | date: 'dd/MM/yyyy'}}
					</div>
				</div>
				<div class="ui-g-12">
					<div class="ui-g-8 pb-0 ui-g-offset-2">
						<p-table [value]="details">
							<ng-template pTemplate="header">
								<tr>
									<th><b>{{'Charge Item' | translate}}</b></th>
									<th><b>{{'Amount' | translate}}</b></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-detail>
								<tr>
									<td>{{detail?.name}}</td>
									<td>
										<div style="text-align: right">
											{{detail?.value?.toFixed(2)}}
										</div>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="footer" let-columns>
								<tr>
									<td>
										<b>{{'Total' | translate}}:</b>
									</td>
									<td>
										<div style="text-align: right">
											${{invoiceDetail?.totalAmount?.toFixed(2)}}
										</div>
									</td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</div>
				<div class="ui-g-12">
					<b>{{'Please make to payment' | translate}}</b>
				</div>
			</div>
		</div>
		<div class="ui-g-12" style="text-align: center;">
			<button pButton type="button" label="{{'Ok' | translate}}" (click)="cancelDialog()"></button>
		</div>
	</p-dialog>
</p-panel>
  
  
  <p-overlayPanel #op1>
	<p-listbox [options]="textSortOptionsClient" [(ngModel)]="selectedSortOption"
	  (onChange)="sortDataSelected(selectedSortOption, $event)">
	  <ng-template let-col pTemplate="item">
		<div>
		  <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
		  <span>{{col.name | translate}}</span>
		</div>
	  </ng-template>
	</p-listbox>
  </p-overlayPanel>
  <p-overlayPanel #op2>
	<p-listbox [options]="numberSortOptionsClient" [(ngModel)]="selectedSortOption"
	  (onChange)="sortDataSelected(selectedSortOption, $event)">
	  <ng-template let-col pTemplate="item">
		<div>
		  <img src="assets/images/{{col.img}}.png" style="display:inline-block;" width="20">
		  <span>{{col.name | translate}}</span>
		</div>
	  </ng-template>
	</p-listbox>
  </p-overlayPanel>
