import { PunchDirection, PunchType } from './punch.enum';
import { ClockLocation } from '../../../shared/model/enums';

export class EmployeePunchResponse {
    id: number;
    employeeId: number;
    EmployeeFullName: string;
    isMissedPunchIn: Boolean;
    isAnniversaryToday: Boolean;
    isMissedPreviousDayPunchOut: Boolean;
    isSavedInOrOut: string;
    weeklyWorkedHours: string;
    remainWorkingHours: string;
    lastPunchDate: Date;
    isClockIn: Boolean;
    direction: PunchDirection;
    clockType: PunchType;
    leftWorkingHours: string;
    isAdminUpdateCheckOut: Boolean;
    isAdminUpdateCheckIn: Boolean;
    totalHours?: string;
    device?: string;
    punchInLate?: boolean;
    clockInPhoto?: string;
    lunchInPhoto?: string;
    lunchOutPhoto?: string;
    clockOutPhoto?: string;
    lunchHistoryId?: number;
    timeConfirmation?: any;
    confirmPunch?: string;
    constructor() { }
}

export class EmployeePunchRequest {

    pin: string;
    isPunchIn: Boolean;
    punchType: PunchType;
    ipAddress: string;
    location: ClockLocation;
    locationName: string;
    employeeId: number;
    clockInReason: string;
    clockOutReason: string;
    lunchInReason: string;
    lunchOutReason: string;
    device?: string;
    isSkipCheckPlanAssignment: Boolean;
    lat?: number;
    lng?: number;
    address?: string;
    clockAuthorization?: string;
    fullName?: string;
    isContractor: Boolean;
    clockInPhoto?: string;
    lunchInPhoto?: string;
    lunchOutPhoto?: string;
    clockOutPhoto?: string;
    faceDetection?: boolean;
    constructor() { }
}

export class EmployeeClockingV2 {

    id: number;
    employee: SimpleEmployeeV2;
    isMissedPunchIn: Boolean;
    isAnniversaryToday: Boolean;
    isMissedPreviousDayPunchOut: Boolean;
    isSavedInOrOut: string;
    weeklyWorkedHours: string;
    remainWorkingHours: string;
    punchDate: any;
    locationName: any;
    lastPunchDate: Date;
    isClockIn: Boolean;
    direction: PunchDirection;
    clockType: PunchType;
    leftWorkingHours: string;
    totalHours?: string;
    clockInTime: number;
    clockOutTime: number;
    lunchInTime: number;
    lunchOutTime: number;
    clockInTimeOriginal: number;
    clockOutTimeOriginal: number;
    lunchInTimeOriginal: number;
    lunchOutTimeOriginal: number;
    lastModifiedBy: string;
    lastPunchType: string;
    createdAt: Date;
    createdByUsr: string;
    isAdminUpdateCheckOut: Boolean;
    isAdminUpdateCheckIn: Boolean;
    isAdminUpdateLunchIn: Boolean;
    isAdminUpdateLunchOut: Boolean;
    updateType: number; // 0 check-in, 1 lunch-out, 2 Lunch-in, 3 Check-Out
    signature: any;
    signatureUrl: any;
    employeeId: any;
}

export class SimpleEmployeeV2 {
    employeeId: number;
    fullName: string;
    companyId: number;
}

export class EmployeeClockingSearchCriteria {
    employeeIds: any;
    fromDate: Date;
    toDate: Date;
    withNoOvertime: Boolean;
    weekend: string;
}

export class ValidPunchResponse {
}
