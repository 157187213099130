<p-toast position="bottom-right">{{msgs}}</p-toast>
<div class="d-flex">
	<h1 clas="m-0 ml-2">{{'Payroll Settings' | translate}}</h1>
	<button class="btn btn-primary text-nowrap mt-2 ml-3" style="height: 40px;" (click)="openPunchSetting()" [disabled]="!isSuperAdmin()"><i class="fa-solid fa-gear mr-2"></i>Setting</button>
</div>
<form [formGroup]="payrollSettingsForm" #payrollForm [name]="payrollForm">
	<p-panel [toggleable]="true" [collapsed]="collapsedList[0]" (collapsedChange)="collapsedChange(0)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Payroll Details' | translate}}</span>
			<span class="ci-history text-dark mr-3" *ngIf="payrollSettingFormData.id" (click)="getHistory()"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
    	</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Payroll Frequency' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-user"></i>
						</button>
					</div>
					<p-dropdown [options]="payReferences" name="payReference" formControlName="payReference"  [(ngModel)]="payrollSettingFormData.payReference" optionLabel="label">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['payReference'].errors?.required && payrollSettingsForm.controls['payReference'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Mileage Charge' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar"></i>
						</button>
					</div>
					<input currencyMask type="text" pInputText 
					formControlName="mileagePrice" 
					[(ngModel)]="mileage.mileagePrice" placeholder="0">
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Distance (miles)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-route"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="mile" [(ngModel)]="mileage.perMile" placeholder="0" placeholder="{{'Miles covered by charge' | translate}}">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Amount Rounding' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calculator"></i>
						</button>
					</div>
					<p-dropdown [options]="roundOn" formControlName="roundOn" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="payrollSettingFormData.roundOn">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Mins' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="mins" formControlName="mins" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="payrollSettingFormData.mins">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Payroll Week Ending' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar-day"></i>
						</button>
					</div>
					<p-dropdown [options]="days" formControlName="payrollWeekEnding" placeholder="{{'pleaseSelect' | translate}}" [(ngModel)]="payrollSettingFormData.payrollWeekEnding">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['payrollWeekEnding'].errors?.required && payrollSettingsForm.controls['payrollWeekEnding'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'MinimumHoursForDaily' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-inputMask formControlName="minimumHourDaily" [(ngModel)]="payrollSettingFormData.minimumHourDaily" mask="99:99" class="w-100"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['minimumHourDaily'].errors?.required && payrollSettingsForm.controls['minimumHourDaily'].dirty ">{{'minimumHourDaily' | translate}}</span>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['minimumHourDaily'].errors?.pattern && payrollSettingsForm.controls['minimumHourDaily'].dirty">{{'minimumHourDailyFormat' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Allow Overtime' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-circle-exclamation"></i>
						</button>
					</div>
					<p-dropdown [options]="useOvertimeOptions" name="useOvertime" formControlName="useOvertime" [(ngModel)]="payrollSettingFormData.useOvertime" optionLabel="label" (onChange)="onOvertimeChange($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['useOvertime'].errors?.required && payrollSettingsForm.controls['useOvertime'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'DisplayEmployeeNameOnStub' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox name="displayEmployeeNameOnSub" formControlName="displayEmployeeNameOnSub" (onChange)="EmployeeNameOnSub($event)" [(ngModel)]="displayEmployeeNameOnSub" binary="true"></p-checkbox>
						</button>
					</div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'OvertimeAfter' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
				    <p-inputMask formControlName="overtimeAfter" [(ngModel)]="payrollSettingFormData.overtimeAfter" mask="99:99" class="w-100"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['overtimeAfter'].errors?.required && payrollSettingsForm.controls['overtimeAfter'].dirty ">{{'This field is required' | translate}}</span>
			  	<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['overtimeAfter'].errors?.pattern && payrollSettingsForm.controls['overtimeAfter'].dirty">{{'OvertimeAfterRequiredFormat' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'FrequencyOther' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-timeline"></i>
						</button>
					</div>
					<p-dropdown [options]="frequencyDropdown" id="frequencyForOtRate" formControlName="frequencyForOtRate" [(ngModel)]="payrollSettingFormData.frequencyForOtRate">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Overtime Rate (Multiplier)' | translate}}<span class="required-indicator">*</span></label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-x"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="overtimeMultipler" [(ngModel)]="payrollSettingFormData.overtimeMultipler" placeholder="1.5">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['overtimeMultipler'].errors?.required && payrollSettingsForm.controls['overtimeMultipler'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'LunchTimeDuration' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-inputMask formControlName="lunchTimeDuration" [(ngModel)]="payrollSettingFormData.lunchTimeDuration" class="w-100" placeholder="H:MM" mask="99:99"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['lunchTimeDuration'].errors?.required && payrollSettingsForm.controls['lunchTimeDuration'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'PTO Calculation' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="vacationMethodOptions" formControlName="vacationMethod" [(ngModel)]="payrollSettingFormData.vacationMethod" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
				  </p-dropdown>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationMethod'].errors?.required && payrollSettingsForm.controls['vacationMethod'].dirty ">{{'validate.thisField' | translate}}</span>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'HolidayPay' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox  formControlName="holidayPay" [(ngModel)]="holidayPay" binary="true" pTooltip="{{holidayPay ? ('yes' | translate) : ('no' | translate)}}"></p-checkbox>						
						</button>
					</div>
					<p-multiSelect [disabled]="readOnly" class=" w-100" formControlName="holidayPayType" [options]="holidayPayType" [(ngModel)]="this.holidayTypes">
						<ng-template pTemplate="item" let-item>
							<span class="one-liner" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
					</p-multiSelect>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Sick-Time (hrs)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask [(ngModel)]="payrollSettingFormData.maxSickLeave" formControlName="maxSickLeave" class="w-100" placeholder="HH:MM" mask="99:99"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Vacation Time (hrs)' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask [(ngModel)]="payrollSettingFormData.maxVacation" formControlName="maxVacation" class="w-100" placeholder="HH:MM" mask="99:99"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Sick-Time Calculation' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="sickTimeCalculationHour" placeholder="Hrs" [(ngModel)]="payrollSettingFormData.sickTimeCalculationHour" placeholder="00">
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['sickTimeCalculationHour'].errors?.pattern && payrollSettingsForm.controls['sickTimeCalculationHour'].dirty">{{'onlyNumbers' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'SickTimeCalculationEvery' | translate}}<i class="fa fa-question-circle ml-1" aria-hidden="true"
																	[ngbPopover]="popContent"
																	severity="secondary"
																	triggers="mouseenter:mouseleave"
																	popoverClass="custom-popver"
																	placement="auto"></i></label>

				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="sickTimeCalculationForEvery" [(ngModel)]="payrollSettingFormData.sickTimeCalculationForEvery" class="w-100" placeholder="HH:MM"  mask="99:99"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['sickTimeCalculationForEvery'].errors?.required && payrollSettingsForm.controls['sickTimeCalculationForEvery'].dirty ">{{'minimumHourDaily' | translate}}</span>
			  	<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['sickTimeCalculationForEvery'].errors?.pattern && payrollSettingsForm.controls['sickTimeCalculationForEvery'].dirty">{{'sickTimeCalculationForEveryValidate' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'VacationCalculationHours' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<input type="text" twoDigitDecimaNumber name="vacationTimeCalculationHour" formControlName="vacationTimeCalculationHour" [(ngModel)]="payrollSettingFormData.vacationTimeCalculationHour" pInputText />
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationTimeCalculationHour'].errors?.pattern && payrollSettingsForm.controls['vacationTimeCalculationHour'].dirty">{{'onlyNumbers' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'VacationTimeCalculationEvery' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="vacationTimeCalculationForEvery" [(ngModel)]="payrollSettingFormData.vacationTimeCalculationForEvery" class="w-100" placeholder="HH:MM"  mask="99:99"></p-inputMask>
				</div>
				<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationTimeCalculationForEvery'].errors?.required && payrollSettingsForm.controls['vacationTimeCalculationForEvery'].dirty ">{{'minimumHourDaily' | translate}}</span>
			  	<span class="ui-message ui-messages-error" *ngIf="payrollSettingsForm.controls['vacationTimeCalculationForEvery'].errors?.pattern && payrollSettingsForm.controls['vacationTimeCalculationForEvery'].dirty">{{'vacationTimeCalculationForEveryValidate' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'ClockOnDayOff' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox name="clockOnDayOff" formControlName="clockOnDayOff" (onChange)="changeClockOnDayOff($event)" 
								[(ngModel)]="clockOnDayOff" binary="true"></p-checkbox>
						</button>
					</div>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'LunchTimeLimit' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox name="checkLunchTimeLimit" formControlName="checkLunchTimeLimit" (onChange)="changeCheckLunchTimeLimit($event)" 
								[(ngModel)]="checkLunchTimeLimit" binary="true"></p-checkbox>
						</button>
					</div>
					<p-inputMask formControlName="lunchTimeLimit" 
						[(ngModel)]="payrollSettingFormData.lunchTimeLimit" 
						class="w-100" placeholder="HH:MM"  mask="99:99"
						[disabled]="!checkLunchTimeLimit">
					</p-inputMask>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-6">
				<label>{{'Employee Time Confirmation' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="employeeTimeConfirmationOptions" formControlName="employeeTimeConfirmation" [(ngModel)]="payrollSettingFormData.employeeTimeConfirmation" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div *ngIf="payrollSettingFormData.employeeTimeConfirmation === '1'" class="ui-g-3 ui-sm-6">
				<label>{{'Display On' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="dayOfWeekOptions" formControlName="displayOn" [(ngModel)]="payrollSettingFormData.displayOn" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[1]" (collapsedChange)="collapsedChange(1)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Job Report' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3" *ngFor="let sh of listSpreadHour; let in=index">
			<div class="ui-g-4 ui-sm-12">
				<label>{{'Job Report Missing # limitation' | translate}}:</label>
				<div class="input-group w-100">
					<input type="number" name="jobReportMissingLimit" formControlName="jobReportMissingLimit" 
					[(ngModel)]="payrollSettingFormData.jobReportMissingLimit" 
					pInputText  
					min="0" 
					oninput="validity.valid||(value='');" [disabled]="readOnly" />
				</div>
			</div>
			<div class="ui-g-8 ui-sm-12">
				<label>{{'Beyond limitation Decision' | translate}}:</label>
				<div class="input-group w-100">
					<p-dropdown [options]="limitationDecisionLst" 
						placeholder="{{'Select One' | translate}}" 
						formControlName="beyondLimitationDecision" 
						name="beyondLimitationDecision"
						autoWidth="false" 
						[(ngModel)]="payrollSettingFormData.beyondLimitationDecision"
						[disabled]="readOnly">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[2]" (collapsedChange)="collapsedChange(2)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'SpreadOfHour' | translate}}</span>
    	</p-header>
		<form #formRef="ngForm">
			<div class="ui-g pb-3" *ngFor="let sh of listSpreadHour; let in=index">
				<div class="ui-g-4 ui-sm-12">
					<label>{{'State' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map"></i>
							</button>
						</div>
						<p-multiSelect [options]="sh.statesObj" [(ngModel)]="sh.states" [ngModelOptions]="{standalone: true}" (onChange)="changeStates($event)" id="id_filteredEmployeeList_to_selected_label{{in}}" name="name1{{in}}" #name="ngModel" class="w-100" [disabled]="readOnly">
							<ng-template pTemplate="item" let-item>
								<span class="one-liner" style="font-size: 14px !important">{{item.label | translate}}</span>
							</ng-template>
						</p-multiSelect>
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>{{'CalculationMethod' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-folder-tree"></i>
							</button>
						</div>
						<p-dropdown [options]="calculateMethod" placeholder="{{'Select One' | translate}}" [(ngModel)]="sh.calculateMethod" name="name2{{in}}" #name="ngModel" [disabled]="readOnly">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>	
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>{{'MinimumWage' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-dollar-sign"></i>
							</button>
						</div>
						<input currencyMask type="number" [disabled]="readOnly" (change)="minimumWageOnchange(sh)" [(ngModel)]="sh.minimumWage" min="0" pInputText name="name{{in}}" #name="ngModel" placeholder="0.00">
						<div class="input-group-append pl-3">
							<button (click)="deleteSpreadHour(sh.companyId, sh.id)" pTooltip="{{'button.delete' | translate}}" [disabled]="readOnly" class="btn btn-white" tooltipPosition="left"><i class="fa-solid fa-xmark text-danger"></i></button>
							<button (click)="updateSpreadHour(sh.companyId, sh.id, sh)" pTooltip="{{'button.update' | translate}}" [disabled]="readOnly" class="btn btn-white" tooltipPosition="left"><i class="fa-solid fa-check text-success"></i></button>
						</div>
					</div>
				</div>
			</div>
	
			<!--new-->
			<div class="ui-g pb-3">
				<div class="ui-g-4 ui-sm-12">
					<label>{{'State' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-map"></i>
							</button>
						</div>
						<p-multiSelect class="flex-auto" [options]="listOfState" class="w-100" [panelStyle]="{'width': '100%'}" name="nameState" #name="ngModel" [(ngModel)]="listOfStateValue" id="id_filteredEmployeeList_to_selected_label" [disabled]="readOnly">
							<ng-template pTemplate="item" let-item>
								<span class="one-liner" style="font-size: 14px !important">{{item.label | translate}}</span>
							</ng-template>
						</p-multiSelect>
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>{{'CalculationMethod' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-folder-tree"></i>
							</button>
						</div>
						<p-dropdown [options]="calculateMethod" name="nameCalMethod" #name="ngModel" placeholder="{{'Select One' | translate}}" [(ngModel)]="calMethodValue" [disabled]="readOnly">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>	
						</p-dropdown>
					</div>
				</div>
				<div class="ui-g-4 ui-sm-12">
					<label>{{'MinimumWage' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-dollar-sign"></i>
							</button>
						</div>
						<input currencyMask type="number" [disabled]="readOnly" (change)="minimumWageOnchange(false)" [(ngModel)]="minimumWage" min="0" pInputText name="nameNew" #name="ngModel" placeholder="0.00">
					</div>
				</div>
			</div>
		</form>
		<p-footer class="d-flex justify-content-center">
			<button (click)="createSpreadHour()" tooltipPosition="left" [disabled]="readOnly" class="btn btn-white"><i class="fa-solid fa-plus text-primary mr-2"></i>{{'Add Another' | translate}}</button>
		</p-footer>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[3]" (collapsedChange)="collapsedChange(3)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Come late or leave early monitoring' | translate}}</span>
			<div class="form-check text-center form-switch" style="right: 50px;">
				<span class="form-check-label ml-2" for="flexSwitchCheckDefault" style="margin-right: .5rem!important;">OFF</span>
				<input class="form-check-input" [(ngModel)]="payrollSettingFormData.allowMonitorComeLateOrLeaveEarly" 
					type="checkbox" role="switch" id="allowMonitorComeLateOrLeaveEarly" 
					formControlName="allowMonitorComeLateOrLeaveEarly" [disabled]="readOnly">
				<span class="form-check-label ml-2" for="flexSwitchCheckDefault">ON</span>
			</div>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Lateness Grace Period' | translate}} (hh:mm)</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-inputMask class="timer" formControlName="employeeLate" [(ngModel)]="payrollSettingFormData.employeeLateAfterHours" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Lateness limitation #' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-cog"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="employeeLateLimit" [(ngModel)]="payrollSettingFormData.employeeLateLimit" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Lateness Decision' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="employeeLatenessDecisionOption" placeholder="{{'Select One' | translate}}" formControlName="employeeLatenessDecision" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeLatenessDecision" (onChange)="changeLatenessDecision($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showLate">
				<label>{{'Suspend X' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-cog"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="employeeLateSuspend" [(ngModel)]="payrollSettingFormData.employeeLateSuspend" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showLate">
				<label>{{'Hours/Days' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="hoursDaysOption" placeholder="{{'Select One' | translate}}" formControlName="employeeLateHoursDays" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeLateHoursDays">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showLate">
				<label>{{'When reach x times lateness' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-cog"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="employeeLateTimes" [(ngModel)]="payrollSettingFormData.employeeLateTimes" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showLate">
				<label>{{'Per week/month/year' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="perWeekMonthYearOption" placeholder="{{'Select One' | translate}}" formControlName="employeeLatePer" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeLatePer">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
					<div class="input-group-append pl-3">
						<button pTooltip="{{'button.add' | translate}}" (click)="showLateRules = true" [disabled]="readOnly" class="btn btn-white" tooltipPosition="left"><i class="fa-solid fa-plus text-success"></i></button>
					</div>
				</div>
			</div>
			<div class="ui-g-12 ui-sm-12" *ngIf="showLateRules">
				<label>{{'Rules' | translate}}</label>
				<div class="input-group w-100">
					<textarea pInputTextarea formControlName="employeeLateRules" [(ngModel)]="payrollSettingFormData.employeeLateRules" [rows]="3" maxlength="500" autoResize="true" style="font-size: 16px"></textarea>
				</div>
			</div>
		</div>

		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Lateness Early Grace Period' | translate}} (hh:mm)</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-stopwatch"></i>
						</button>
					</div>
					<p-inputMask class="timer" formControlName="employeeEarlyAfterHours" [(ngModel)]="payrollSettingFormData.employeeEarlyAfterHours" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Lateness Early Decision' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="employeeLatenessDecisionOption" placeholder="{{'Select One' | translate}}" formControlName="employeeEarlyDecision" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeEarlyDecision"  (onChange)="changeEarlyDecision($event)">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showEarly">
				<label>{{'Suspend X' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-cog"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="employeeEarlySuspend" [(ngModel)]="payrollSettingFormData.employeeEarlySuspend" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showEarly">
				<label>{{'Hours/Days' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="hoursDaysOption" placeholder="{{'Select One' | translate}}" formControlName="employeeEarlyHoursDays" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeEarlyHoursDays">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showEarly">
				<label>{{'When reach x times lateness' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="employeeEarlyTimes" [(ngModel)]="payrollSettingFormData.employeeEarlyTimes" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12" *ngIf="showEarly">
				<label>{{'Per week/month/year' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-dropdown [options]="perWeekMonthYearOption" placeholder="{{'Select One' | translate}}" formControlName="employeeEarlyPer" autoWidth="false" [(ngModel)]="payrollSettingFormData.employeeEarlyPer">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
					<div class="input-group-append pl-3">
						<button pTooltip="{{'button.add' | translate}}" (click)="showEarlyRules = true" [disabled]="readOnly" class="btn btn-white" tooltipPosition="left"><i class="fa-solid fa-plus text-success"></i></button>
					</div>
				</div>
			</div>
			<div class="ui-g-12 ui-sm-12" *ngIf="showEarlyRules">
				<label>{{'Rules' | translate}}</label>
				<div class="input-group w-100">
					<textarea pInputTextarea formControlName="employeeEarlyRules" [(ngModel)]="payrollSettingFormData.employeeEarlyRules" [rows]="3" maxlength="500" autoResize="true" style="font-size: 16px"></textarea>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[4]" (collapsedChange)="collapsedChange(4)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Other' | translate}}</span>
			<span class="ci-history text-dark mr-3" *ngIf="payrollSettingFormData.id" (click)="openOtherHistory()"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
    	</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'AlertEmployeeEndTime' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="alertEmployeeEndTime" [(ngModel)]="payrollSettingFormData.alertEmployeeEndTime" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'frequency' | translate}}</label>
            	<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-timeline"></i>
						</button>
					</div>
					<p-dropdown [options]="frequencyDropdown" formControlName="frequency" [(ngModel)]="payrollSettingFormData.frequency" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
              	</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Alert Recipient' | translate}}</label>
            	<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-folder-tree"></i>
						</button>
					</div>
					<p-multiSelect [options]="dropDownPositions" class="w-100" defaultLabel="{{'Select Items' | translate}}" formControlName="sendAlertTo" [(ngModel)]="payrollSettingFormData.sendAlertTo" id="id_payrollSetting_to_selected_label ">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate" style="font-size: 14px !important">{{item.label | translate}}</span>
						</ng-template>
				 	 </p-multiSelect>
              	</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Employee PTO Display' | translate}}</label>
            	<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-eye-slash"></i>
						</button>
					</div>
					<p-dropdown [options]="allowEmployeePTOs" formControlName="allowEmployeePTO" [(ngModel)]="allowEmployeePTOStr" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
              	</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'RedeemHours' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<input type="number" pInputText formControlName="redeemHours" [(ngModel)]="payrollSettingFormData.redeemHours" min="0" oninput="validity.valid||(value='');">
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'OnTimeRate' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="onTimeRate" [(ngModel)]="payrollSettingFormData.onTimeRate" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'End of Day Job Report Options' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown [options]="jobReportVsEndWorkDropdown" formControlName="jobReportVsEndWork" [(ngModel)]="payrollSettingFormData.jobReportVsEndWork" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'End of Day Assignment Options' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown  [options]="planAssignmentListOption" formControlName="planAssignmentOption" autoWidth="false" [(ngModel)]="payrollSettingFormData.planAssignmentOption" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Transfer Fee (Cash Out)%' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calculator"></i>
						</button>
					</div>
					<p-inputNumber id="transferFeeCashOut" formControlName="transferFeeCashOut" [(ngModel)]="payrollSettingFormData.transferFeeCashOut" mode="decimal" locale="en-US" [minFractionDigits]="2" style="height: 40px !important"></p-inputNumber>
					<p-inputNumber id="transferFeeFixedAmount" formControlName="transferFeeFixedAmount" [(ngModel)]="payrollSettingFormData.transferFeeFixedAmount" mode="decimal" locale="en-US" [minFractionDigits]="2" style="height: 40px !important"></p-inputNumber>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Late Fee' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-percent"></i>
						</button>
					</div>
					<input type="text" twoDigitDecimaNumber id="lateFee" formControlName="lateFee" [(ngModel)]="payrollSettingFormData.lateFee" pInputText (keyup)="formatLateFee()"/>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
			    <label>{{'Delay Over'}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-percent"></i>
						</button>
					</div>
					<input type="text" opr-only-numbers id="delayOver" formControlName="delayOver" [(ngModel)]="payrollSettingFormData.delayOver" pInputText/>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
			    <label>{{'Invoice Tax'}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-percent"></i>
						</button>
					</div>
					<input type="text" opr-only-numbers id="invoiceTax" formControlName="invoiceTax" [(ngModel)]="payrollSettingFormData.invoiceTax" pInputText/>
				</div>
			</div>
			<div class="ui-g-3 ui-sm-12">
				<label>{{'Invoice type' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-dropdown  [options]="invoiceTypeOptions" formControlName="invoiceType" autoWidth="false" 
						[(ngModel)]="payrollSettingFormData.invoiceType" placeholder="{{'Select One' | translate}}">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
					</p-dropdown>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[5]" (collapsedChange)="collapsedChange(5)">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Overtime Alert Preferences' | translate}}</span>
    	</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-12">
				<h5 class="mb-1 mt-3">{{'Alert Timing Preferences' | translate}}</h5>
				<span>{{'alert_preferences_subtext' | translate}}</span>
			</div>
			<div class="ui-g-3 ui-sm-6">
                <label>{{'Hours' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="customizedHour" [(ngModel)]="payrollSettingFormData.customizedHour" class="w-100" mask="99" placeholder="HH"></p-inputMask>
				</div>
			</div> 
			<div class="ui-g-3 ui-sm-6">
                <label>{{'Minutes' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask formControlName="customizedMinute" [(ngModel)]="payrollSettingFormData.customizedMinute" class="w-100" mask="99" placeholder="MM"></p-inputMask>
				</div>
			</div>          
			<div class="ui-g-6 ui-s-12">
                <label>{{'OvertimeAlertCount' | translate}}</label>
            	<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-bell"></i>
						</button>
					</div>
                	<input type="number" formControlName="sendTime" [(ngModel)]="payrollSettingFormData.sendTime" min="0" pInputText placeholder="0">
              	</div>
            </div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'OvertimeAlertInterval' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-hourglass-start"></i>
						</button>
					</div>
                	<input type="number" formControlName="intervalTime" [(ngModel)]="payrollSettingFormData.intervalTime" min="0" pInputText placeholder="0:00">
              	</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
                <label>{{'ShowTakeOTHour' | translate}}</label>
				<div class="d-flex pt-3" style="height: 40px; column-gap: 50px">
					<p-radioButton name="showTakeOT" formControlName="showTakeOvertime" value='1'label="{{'yes' | translate}}" [(ngModel)]="showTakeOvertime"></p-radioButton>
					<p-radioButton name="showTakeOT" formControlName="showTakeOvertime" value='0' label="{{'no' | translate}}" [(ngModel)]="showTakeOvertime"></p-radioButton>
				</div>
            </div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[6]" (collapsedChange)="collapsedChange(6)">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Overtime Balance' | translate}}</span>
		</p-header>
		<div class="ui-g">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Take-Back Hours' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox  formControlName="isTakeHourBack" [(ngModel)]="payrollSettingFormData.isTakeHourBack" binary="true" pTooltip="{{payrollSettingFormData.isTakeHourBack ? ('Disable' | translate) : ('Enable' | translate)}}"></p-checkbox>
						</button>
					</div>
					<p-dropdown [options]="takeHourBackOption" formControlName="takeHourBack" placeholder="{{'Select One' | translate}}" [(ngModel)]="payrollSettingFormData.takeHourBack" [disabled]="!payrollSettingFormData.isTakeHourBack">
						<ng-template pTemplate="item" let-item>
							<span class="text-truncate">{{item.label | translate}}</span>
						</ng-template>
						<ng-template let-item pTemplate="selectedItem">
							<span class="one-liner">{{item.label | translate}}</span>
						</ng-template>
                  </p-dropdown>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'From Week Ending On' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-calendar"></i>
						</button>
					</div>
					<mat-form-field class="w-100">
						<input class="w-100" [(ngModel)]="payrollSettingFormData.fromWeekEndDate" formControlName="fromWeekEndDate"  matInput (focus)="picker1.open()" [matDatepicker]="picker1" name="end-date" (click)="picker1.open()" style="cursor:pointer">
						<mat-datepicker #picker1></mat-datepicker>
					</mat-form-field>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Weekly Hours Take-Back' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask [(ngModel)]="payrollSettingFormData.hoursTakeBackWeekly" formControlName="hoursTakeBackWeekly" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Daily Hours Take-Back' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-clock"></i>
						</button>
					</div>
					<p-inputMask [(ngModel)]="payrollSettingFormData.hoursTakeDaily" formControlName="hoursTakeDaily" class="w-100" mask="99:99" placeholder="HH:MM"></p-inputMask>
				</div>
			</div>
			<div class="ui-g-6 ui-sm-12">
				<label>{{'One Time' | translate}}</label>
				<div class="pt-3 d-flex align-items-center" style="column-gap: 50px">
					<p-radioButton name="isOneTime" formControlName="isOneTime" [value]="true" label="{{'yes' | translate}}" [(ngModel)]="payrollSettingFormData.isOneTime"></p-radioButton>
                  	<p-radioButton name="isOneTime" formControlName="isOneTime" [value]="false" label="{{'no' | translate}}" [(ngModel)]="payrollSettingFormData.isOneTime"></p-radioButton>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[7]" (collapsedChange)="collapsedChange(7)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Card Reader' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Reader ID' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-id-card"></i>
						</button>
					</div>
					<input type="text" pInputText formControlName="readerId" [(ngModel)]="payrollSettingFormData.readerId" placeholder="0" placeholder="{{'Reader ID' | translate}}">
				</div>
			</div>
		</div>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Reader ID' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<p-checkbox formControlName="testMode" [(ngModel)]="payrollSettingFormData.testMode" binary="true"></p-checkbox>
						</button>
					</div>
					<div class="form-control"><span class="one-liner">Enable Test Mode</span></div>
				</div>
			</div>
		</div>
		<div class="ui-g pb-3">
			<div class="ui-g-6 ui-sm-12">
				<label>{{'Test Card Number' | translate}}</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-credit-card-alt"></i>
						</button>
					</div>
					<p-inputMask formControlName="testCardNumber" [mask]="'9999 9999 9999 9999'" [(ngModel)]="payrollSettingFormData.testCardNumber" [placeholder]="payrollSettingFormData.testCardNumber" slotChar=" " class="w-100"></p-inputMask>
				</div>
			</div>
		</div>
	</p-panel>
	<br>
	<p-panel [toggleable]="true" [collapsed]="collapsedList[8]" (collapsedChange)="collapsedChange(8)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Auto Generate Invoice' | translate}}</span>
		</p-header>
		<form #formAGI="ngForm">
			<div class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center">
				<div class="ui-g-6 ui-sm-12 col-sm-5">
					<label>{{'Auto Generate Invoice' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<p-dropdown [disabled]="readOnly" [autoDisplayFirst]="false" [options]="autoGenerateInvoiceOptions" name="autoGenerateInvoice" [(ngModel)]="autoGenerateInvoice" (ngModelChange)="generateInvoiceChange($event)">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
			</div>
			<div *ngFor="let setting of autoGenerateInvoiceSettings; let i = index">
				<div *ngIf="autoGenerateInvoice === '1'" class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center">
					<div class="flex-grow-1 px-2 ui-sm-12 col-sm-3">
						<label>{{'Frequency' | translate}}:</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<p-dropdown [disabled]="readOnly" [autoDisplayFirst]="false" [options]="frequencyOptions" name="frequency{{i}}" [(ngModel)]="setting.frequency">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="flex-grow-1 px-2 ui-sm-12 col-sm-2" *ngIf="setting.frequency == '1' || setting.frequency == '3'">
						<label>{{setting.frequency == '1' ? 'Day of week' : 'Day Of month' | translate}}:</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<ng-container *ngIf="setting.frequency == '1'">
								<p-dropdown [disabled]="readOnly" [autoDisplayFirst]="false" [options]="dayOfWeekOptions" name="weekDay{{i}}" [(ngModel)]="setting.weekDay">
									<ng-template pTemplate="item" let-item>
										<span class="text-truncate">{{item.label | translate}}</span>
									</ng-template>
									<ng-template let-item pTemplate="selectedItem">
										<span class="one-liner">{{item.label | translate}}</span>
									</ng-template>
								</p-dropdown>
							</ng-container>
							<ng-container *ngIf="setting.frequency == '3'">
								<p-dropdown [disabled]="readOnly" [autoDisplayFirst]="false" [options]="dayOfMonthOptions" name="monthDay{{i}}" [(ngModel)]="setting.monthDay">
									<ng-template pTemplate="item" let-item>
										<span class="text-truncate">{{item.label | translate}}</span>
									</ng-template>
									<ng-template let-item pTemplate="selectedItem">
										<span class="one-liner">{{item.label | translate}}</span>
									</ng-template>
								</p-dropdown>
							</ng-container>
						</div>
					</div>
					<div class="flex-grow-1 px-2 ui-sm-12 col-sm-5">
						<label>{{'Product' | translate}}:</label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<p-dropdown [disabled]="readOnly" [autoDisplayFirst]="false" [options]="productOptions" name="productId{{i}}" [(ngModel)]="setting.productId">
								<ng-template pTemplate="item" let-item>
									<span class="text-truncate">{{item.label | translate}}</span>
								</ng-template>
								<ng-template let-item pTemplate="selectedItem">
									<span class="one-liner">{{item.label | translate}}</span>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="ui-sm-12 text-center">
						<button *ngIf="i === this.autoGenerateInvoiceSettings.length - 1 && i < 4" class="btn btn-white mt-3" (click)="createAutoGenerateInvoice(setting)" pTooltip="{{'Add' | translate}}"
								tooltipPosition="left"><i class="fa-solid fa-plus text-primary"></i>
						</button>
						<button *ngIf="i !== this.autoGenerateInvoiceSettings.length - 1" [disabled]="readOnly" (click)="deleteAutoGenerateInvoiceSettings(setting.companyId, setting.id)" class="btn btn-white mt-3"
								pTooltip="{{'Delete' | translate}}" tooltipPosition="left"><i class="fa-solid fa-xmark text-danger"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center">
				<div class="ui-g-12 text-center">
					<span style="font-size: medium; font-weight: 600;">{{'Reminder: The system will generate Monthly invoices covering the entire month, starting on the 1st and ending on the last day of the invoice month.' | translate}}</span>	
				</div>
			</div>
			<!-- <div *ngIf="autoGenerateInvoice === '1' && this.autoGenerateInvoiceSettings.length < 4" class="px-3 pb-3 pt-2 d-flex flex-wrap align-items-center">
				<div class="flex-grow-1 px-2 ui-sm-12 col-sm-5">
					<label>{{'Frequency' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<p-dropdown [options]="frequencyOptions" name="frequencyAdd" [(ngModel)]="frequencySetting">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="flex-grow-1 px-2 ui-sm-12 col-sm-5">
					<label>{{'Product' | translate}}:</label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" type="button" style="cursor:default !important">
								<i class="fa-solid fa-user"></i>
							</button>
						</div>
						<p-dropdown [options]="productOptions" name="productIdAdd" [(ngModel)]="productSetting">
							<ng-template pTemplate="item" let-item>
								<span class="text-truncate">{{item.label | translate}}</span>
							</ng-template>
							<ng-template let-item pTemplate="selectedItem">
								<span class="one-liner">{{item.label | translate}}</span>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
				<div class="ui-sm-12 text-center">
					<button class="btn btn-white mt-3" (click)="createAutoGenerateInvoice()" pTooltip="{{'Add' | translate}}"
							tooltipPosition="left"><i class="fa-solid fa-plus text-primary"></i>
					</button>
				</div>
			</div> -->
		</form>
	</p-panel>
	<br>

	<p-panel [toggleable]="true" [collapsed]="collapsedList[9]" (collapsedChange)="collapsedChange(9)">
		<p-header class="flex-grow-1 d-flex align-items-center justify-content-between">
			<span><i class="fa-solid fa-circle-plus text-primary mr-2"></i>{{'Fundraisers' | translate}}</span>
			<span class="ci-history text-dark mr-3" *ngIf="payrollSettingFormData.id" (click)="showFundraiserHistory()"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i>{{'history.title' | translate}}</span>
		</p-header>
		<div class="ui-g pb-3">
			<div class="ui-g-4 ui-sm-12">
				<label>{{'Donation Limit' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<input formControlName="donationLimit"
                           [(ngModel)]="payrollSettingFormData.donationLimit"
                           class="w-100" pInputText
                           placeholder="Donation Limit"
							(input)="onlyNumber($event)"
                           (paste)="false"
                           ondrop="return false;"
                           (keypress)="onlyDecimal($event)">
				</div>
			</div>
			<div class="ui-g-4 ui-sm-12">
				<label>{{'Fundraisers % Fee' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-percent"></i>
						</button>
					</div>
					<input formControlName="fundraisersFee"
                           [(ngModel)]="payrollSettingFormData.fundraisersFee"
                           pInputText class="w-100" mask="100"
                           placeholder="Fundraisers % Fee"
                           (input)="onlyNumber($event)"
                           (keypress)="onlyDecimal($event)"
                           (paste)="false"
                           ondrop="return false;">
				</div>
                <span class="ui-message ui-messages-error" *ngIf="maxFundraisersFee()">{{'Maximum value is 100' | translate}}</span>
			</div>
			<div class="ui-g-4 ui-sm-12">
				<label>{{'Fundraising Max Limitation' | translate}}:</label>
				<div class="input-group w-100">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
							<i class="fa-solid fa-dollar-sign"></i>
						</button>
					</div>
					<input formControlName="fundraisingMaxLimitation"
                           [(ngModel)]="payrollSettingFormData.fundraisingMaxLimitation"
                           min="0"
                           pInputText placeholder="Fundraising Max Limitation"
                           (input)="onlyNumber($event)"
                           (paste)="false"
                           ondrop="return false;"
                           (keypress)="onlyDecimal($event)">
				</div>
			</div>
		</div>
	</p-panel>
	<br>

	<div class="d-flex align-items-center justify-content-between py-4">
		<h1 class="m-0 ml-2">{{'Company Holidays' | translate}}</h1>
		<button [disabled]="readOnly" class="btn btn-primary" style="cursor: pointer;" (click)="addPayrollHoliday()" pTooltip="{{'Add' | translate}}" tooltipPosition="left"><i class="fa-solid fa-plus"></i></button>
	</div>
   	<p-panel [toggleable]="true" id="table-ppanel" [collapsed]="collapsedList[10]" (collapsedChange)="collapsedChange(10)">
		<p-header class="flex-grow-1">
			<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'PayrollHoliday' | translate}} ({{payrollSettingFormData?.payrollHolidays?.length}})</span>
		</p-header>
		<p-table [value]="payrollSettingFormData.payrollHolidays" [lazy]="true" (onLazyLoad)="loadPayrollHolidayLazy($event)" [rows]="5" [paginator]="true" [rowsPerPageOptions]="[5,10,25]" [totalRecords]="totalRecords" class="holiday-grid">
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th style="width: 80px !important">
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'ID' | translate}}" tooltipPosition="left">{{'ID' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Holiday' | translate}}" tooltipPosition="left">{{'Holiday' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Date' | translate}}" tooltipPosition="left">{{'HolidayDate' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'position.name' | translate}}" tooltipPosition="left">{{'position.name' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'department.name' | translate}}" tooltipPosition="left">{{'department.name' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Pay Rate' | translate}}" tooltipPosition="left">{{'Pay Rate' | translate}}</span>
						</span>
					</th>
					<th>
						<span class="d-flex align-items-center justify-content-between w-100">
							<span class="one-liner" pTooltip="{{'Actions' | translate}}" tooltipPosition="left">{{'Actions' | translate}}</span>
						</span>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
			<tr>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'ID' | translate}}: </span>
						<span *ngIf="rowData.id" pTooltip="{{rowData.id}}">{{rowData.id}}</span>
						<span *ngIf="!rowData.id" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Holiday' | translate}}: </span>
						<span *ngIf="rowData.holidayName" pTooltip="{{rowData.holidayName}}">{{rowData.holidayName}}</span>
						<span *ngIf="!rowData.holidayName" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Date' | translate}}: </span>
						<span *ngIf="rowData.holidayDate" pTooltip="{{rowData.holidayDate | date: 'M/d/yyyy'}}">{{rowData.holidayDate | date: 'M/d/yyyy'}}</span>
						<span *ngIf="!rowData.holidayDate" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'position.name' | translate}}: </span>
						<span *ngIf="rowData.positionNames" pTooltip="{{rowData?.positionNames}}">{{rowData?.positionNames}}</span>
						<span *ngIf="!rowData.positionNames" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'department.name' | translate}}: </span>
						<span *ngIf="rowData.departmentNames" pTooltip="{{rowData?.departmentNames}}">{{rowData?.departmentNames}}</span>
						<span *ngIf="!rowData.departmentNames" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'HolidayRate' | translate}}: </span>
						<span *ngIf="rowData.holidayMultipler" pTooltip="{{rowData.holidayMultipler}}">{{rowData.holidayMultipler}}</span>
						<span *ngIf="!rowData.holidayMultipler" class="text-muted">{{'no data' | translate}}</span>
					</span>
				</td>
				<td>
					<span class="one-liner">
						<span class="mobile-table-label">{{'Actions' | translate}}: </span>
						<i class="fa-solid fa-pen-to-square text-primary mx-1" (click)="editPayrollHistory(rowData)" pTooltip="{{'View/Edit' | translate}}" tooltipPosition="left"></i>
						<i class="fa-solid fa-xmark text-danger mx-1" (click)="deletePayrollHistory(rowData)" pTooltip="{{'Delete' | translate}}" tooltipPosition="left"></i>
					</span>
				</td>
			</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-columns>
				<tr *ngIf="!loading">
					<td [attr.colspan]="7" class="text-center py-5">
						<div class="text-muted my-5">{{'no data' | translate}}</div>
					</td>
				</tr>
				<tr *ngIf="loading">
					<td [attr.colspan]="7" class="text-center py-5">
						<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</p-panel>
	<div class="ui-g">
		<div class="ui-g-12 text-center pt-4">
			<button (click)="onCancel()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
			<button *ngIf="mode === 'view'" (click)="settingPayroll()" class="btn btn-info mx-1"><i class="fa-solid fa-pen-to-square mr-2"></i>{{'button.edit' | translate}}</button>
			<button *ngIf="mode !== 'view'" (click)="modifyPayrollSetting(payrollForm)" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>
	</div>
</form>


<p-dialog appendTo="body" *ngIf="historyShow" [(visible)]="historyShow" [closable]="true" showEffect="fade" [modal]="true" [showHeader]="true" [style]="{'width':'90%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History' | translate}}</span>
	</p-header>
  	<app-payroll-setting-history [payrollSettingId]="payrollSettingId" (onHistoryHide)="hideHistory()" [historyType]="historyType"></app-payroll-setting-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="historyShow = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog modal="modal" [(visible)]="historyOtherShow" [dismissableMask]="true"  (onHide)="hideOtherHistory()" [modal]="true" [style]="{'width':'90%', 'max-width':'900px'}">
	<p-header>
		<span><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'Edit History: Other' | translate}}</span>
	</p-header>
    <app-payroll-setting-other-history  appendTo="body" (onHideHistory)="hideOtherHistory()" [payrollSettingId]="payrollSettingId" [historyType]="historyType"></app-payroll-setting-other-history>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="historyOtherShow = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<p-dialog modal="modal" [(visible)]="punchSettingDialog" [dismissableMask]="true" [modal]="true" [style]="{'width':'80%', 'max-width':'400px'}">
	<p-header>
		<span><i class="fa-solid fa-gear mr-2"></i>{{'Punch in/out Rate' | translate}}</span>
	</p-header>
	<div class="ui-g p-3">
		<div class="ui-g-12">
			<label>{{'Punch In/Out Daily Rate' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar"></i>
					</button>
				</div>
				<input twoDigitDecimaNumber type="text" pInputText [(ngModel)]="punchDailyRate" placeholder="0">
			</div>
		</div>
		<div class="ui-g-12">
			<label>{{'Tracking Daily Rate' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-dollar"></i>
					</button>
				</div>
				<input twoDigitDecimaNumber type="text" pInputText [(ngModel)]="punchTrackingRate" placeholder="0">
			</div>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button (click)="punchSettingDialog = false" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'button.cancel' | translate}}</button>
		<div>
			<button (click)="viewHistoryPunchSetting()" class="btn btn-while text-muted mx-1"><i class="fa-solid fa-clock-rotate-left mr-2"></i>{{'History' | translate}}</button>
			<button (click)="submitPunchSetting()" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'button.submit' | translate}}</button>
		</div>

	</p-footer>
</p-dialog>

<ng-template #popContent>
	<div class="flex align-items-center" id="noted-tooltip">
		<table class="styled-table table-body-container table-bordered">
			<thead>
			<tr class="header1">
				<th>Number of Employees</th>
				<th>Employer Sick Leave Requirements</th>
			</tr>
			</thead>
			<tbody>
			<tr class="tr1">
				<td class="text-center">0-4</td>
				<td>If net income is $1 million or less in the previous tax year, the employer is required to provide up to 40 hours of unpaid sick leave per calendar year.</td>
			</tr>
			<tr class="tr2">
				<td class="text-center">0-4</td>
				<td>If net income is greater than $1 million in the previous tax year, the employer is required to provide up to 40 hours of paid sick leave per calendar year.</td>
			</tr>
			<tr class="tr3">
				<td class="text-center">5-99</td>
				<td>Up to 40 hours of paid sick leave per calendar year.</td>
			</tr>
			<tr class="tr4">
				<td class="text-center">100+</td>
				<td>Up to 56 hours of paid sick leave per calendar year.</td>
			</tr>
			</tbody>
		</table>
	</div>
</ng-template>
