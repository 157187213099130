import {WindowRefService} from '../../../shared/service/window-ref/window-ref.service';
import {Component, Input, OnInit} from '@angular/core';
import {ClientService} from '../../../crm/service/client.service';
import {LazyLoadEvent, ConfirmationService, MessageService} from 'primeng/api';
import {FileUtility} from 'app/shared/utility/file.utility';
import {DatePipe, formatNumber} from '@angular/common';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {ProductService} from '../../service/product.service';
import moment from 'moment';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';


@Component({
    selector: 'app-client-contract-list-dashboard',
    templateUrl: './client-contract-list-dashboard.component.html',
    styleUrls: ['./client-contract-list-dashboard.component.scss'],
    providers: [WindowRefService]

})
export class ClientContractListDashboardComponent implements OnInit {

    transactionList: any[] = [];
    contractList: any[] = [];
    displayContractDetail = false;
    contractSelected: any = {};
    loading = false;
    totalRecords = 0;

    @Input()
    isModal = false;

    @Input()
    startDate = null;
    @Input()
    endDate = null;
    showPaymentDialog = false;
    paymentMethod: { label: string; value: string; }[];
    paymentType: { label: string; value: string; }[];
    paymentPayer: any[];
    defaultDate: any;
    disablePayer = true;
    isPlatformAdmin: any;
    msgs = [];
    rowData: any;
    allClientList: any = [];
    createdByList: any[] = [];
    createdBy: any;
    clientList: any = [];
    cols: any[];
    size: number;
    page: number;
    sortField = '';
    sortOrder: number;
    selectedSortOption = '';
    selectedField = '';
    textSortOptions = [
        {name: 'Sort A to Z', value: 'ASC', img: 'a-z'},
        {name: 'Sort Z to A', value: 'DESC', img: 'z-a'}
    ];
    numberSortOptions = [
        {name: 'Sort 0 to 9', value: 'ASC', img: 'a-z'},
        {name: 'Sort 9 to 0', value: 'DESC', img: 'z-a'}
    ];
    fromDate: Date;
    toDate: Date;
    freeQuencyFrom: Date;
    freeQuencyTo: Date;
    selectedCompany: any;
    disableCompany: any;
    allCompanyList: any = [];
    disableFields = false;
    selectedClient;
    products = [];
    selectedProduct: any;
	loadingConracts: boolean;

    constructor(
        private clientService: ClientService,
        private exportMonitorService: ExportMonitorService,
        private productService: ProductService,
        private authService: AuthService,
        private dropDownsService: DropDownsService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {

    }

    ngOnInit() {
        this.selectedCompany = this.authService.getCurrentCompanyId();
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.loadAllCompanyList();
        this.loadAllClient();
        this.loadProducts();
        this.disableCompany = true;
        this.displayContractDetail = false;
        this.cols = [
            {field: '#', label: '#', width: '75'},
            {field: 'clientName', label: 'Client', sortOptions: '', sort: 'text'},
            {field: 'productName', label: 'Product', sortOptions: '', sort: 'text'},
            {field: 'date', label: 'Date', sortOptions: '', sort: 'number'},
            {field: 'employeeName', label: 'Assignee', sortOptions: '', sort: 'text'},
            {field: 'createdByUsr', label: 'Creator', sortOptions: '', sort: 'text'}
        ];
        this.fromDate = this.startDate;
        this.toDate = this.endDate;

    }

    loadProducts() {
        const options: any = {
            page: 0,
            size: 99999,
            sort: 'id,desc',
        };
        const criteria = {
            adminId: 0,
            agencyId: 0,
            companyId: 0,
            id: 0,
            productName: '',
            status: 0,
        };
        if (this.selectedCompany) {
            criteria.companyId = this.selectedCompany;
        }
        this.productService.searchProduct(criteria, options).subscribe((res) => {
            const resObj: any = res;
            this.products = [];
            if (resObj.status === 'SUCCESS') {
                resObj.data.content.forEach(data => {
                    this.products.push({value: data.id, label: data.productName});
                });
            }
        });
    }

    loadAllCompanyList() {
        const pafc = this;
        this.dropDownsService.getAllCompanyList().subscribe((res) => {
            const resObj: any = res;
            this.allCompanyList = [];
            if (resObj.status === 'SUCCESS') {
                // this.allCompanyList = resObj.data;
                resObj.data.forEach(rs => {
                    this.allCompanyList.push({value: rs.key, label: rs.value});
                });
            }
        });
    }

    loadAllClient() {
        const options: any = {size: 99999, page: 0, moduleName: 'billing'};
        if (this.selectedCompany) {
            options.company = this.selectedCompany;
        }
        this.dropDownsService.getAllClientList(options).subscribe((res) => {
            const resObj: any = res;
            this.allClientList = [];
            this.clientList = [];
            if (resObj.status === 'SUCCESS') {
                this.allClientList = resObj.data.map(c => ({value: c.key, label: c.value, email: c.email}));
                this.clientList = resObj.data;
            }
        });
    }

    loadContentsLazy(event?: LazyLoadEvent) {
        // console.log(event);
        this.loading = true;
        this.sortField = event && event.sortField ? event.sortField : '';
        this.sortOrder = event && event.sortOrder ? event.sortOrder : 1;
        const sortOrder = this.selectedSortOption === 'DESC' ? 'desc' : 'asc';
        const toDate = this.toDate ? moment(this.toDate).format('MM/DD/YYYY') : null;
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 10);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
        if (event && (event.first / event.rows) === 0) {
            this.page = 0;
        }
        const options: any = {
            size: this.size,
            page: this.page,
            createdByUsr: this.authService.getCurrentUsername(),
            clientId: this.selectedClient,
            fromDate: this.fromDate,
            toDate: this.toDate
        };
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany;
        }
        if (this.selectedProduct != null) {
            options.productIds = [this.selectedProduct];
        }
        if (this.selectedField !== '' && this.selectedField !== 'Frequency' && this.selectedField !== 'employeeName') {
            options.sort = this.selectedField + ',' + sortOrder;
            options.sortField = this.selectedField;
            options.sortOrder = this.selectedSortOption;
            if ('productName' === options.sortField) {
                options.sortField = 'product.productName';
            }
            if ('clientName' === options.sortField) {
                options.sortField = 'client.name';
            }
        }

        this.loadPage(options);
    }

    resetFilter() {
        this.freeQuencyFrom = null;
        this.freeQuencyTo = null;
        this.fromDate = null;
        this.toDate = null;
        this.selectedClient = null;
        this.selectedCompany = null;
        this.selectedProduct = null;
        this.loadContentsLazy();
    }

    viewDefault(rowData) {
        this.displayContractDetail = true;
        this.contractSelected = rowData;
        this.loadContract(this.contractSelected.client.id);
    }

    cancelDialog() {
        this.displayContractDetail = false;
    }

    loadContract(clientId) {
        const toDate = this.toDate ? moment(this.toDate).format('MM/DD/YYYY') : null;
        const options: any = {
                size: 9999,
                page: 0,
                clientId: clientId,
            };

        this.loadPageRelated(options);
    }

    loadPageRelated(options: any) {
        this.loadingConracts = true;
        this.productService.searchContract(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.contractList = resObj.data.content;
                this.loadingConracts = false;
            }
        });
        this.loadingConracts = false;
    }


    onChangeType() {
    }

    loadPage(options: any) {
        this.productService.searchContractRelated(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.transactionList = resObj.data.content;
                this.totalRecords = resObj.data.totalElements;
            }

            // console.log(this.paymentInfoList);
        });
        this.loading = false;
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            // tslint:disable-next-line:triple-equals
            if (item.field === this.selectedField) {
                item.sortOptions = selectedSortOption;
            } else {
                item.sortOptions = null;
            }
        });
    }

    sortingClick(selectedSortOption) {
        this.loadContentsLazy();
        // let sortOrder = selectedSortOption === 'DESC' ? 'desc' : 'asc';
        // let sortField = this.selectedField;
        // let options: any = {};
        // this.setSortOption(this.selectedField, this.selectedSortOption);
        // options.page = 0;
        // options.size = 10;
        // options.sort = sortField + ',' + sortOrder;
        // options.sortField = sortField;
        // options.sortOrder = sortOrder;
        // this.loadPage(options);
    }

    exportPdf() {
        const options: any = {
            size: 9999,
            page: 0,
            createdByUsr: this.authService.getCurrentUsername(),
            clientId: this.selectedClient,
            fromDate: this.fromDate,
            toDate: this.toDate
        };
        if (this.selectedCompany) {
            options.companyId = this.selectedCompany;
        }
        if (this.selectedProduct != null) {
            options.productIds = [this.selectedProduct];
        }

        let clientContractLists: any[] = [];
        this.productService.searchContractRelated(options).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                clientContractLists = resObj.data.content;
                if (clientContractLists && clientContractLists.length) {
                    const data: any = {
                        contentList: clientContractLists.map(value => {
                            return {
                                date: this.datePipe.transform(value.date, 'MM/dd/yyyy'),
                                freequency: '',
                                employeeName: value.client.assignedTo,
                                productName: value.productName,
                                dayAgo: value.dayAgo,
                                clientName: value.clientName,
                                serviceFee: formatNumber(value.serviceFee, 'en-US', '1.2-2'),
                                serviceFeePaid: formatNumber(value.serviceFeePaid, 'en-US', '1.2-2'),
                                banance: formatNumber(value.banance, 'en-US', '1.2-2'),
                                createdByUsr: value.user
                            };
                        })
                    };
                    if (!this.isPlatformAdmin) {
                        data.companyId = this.authService.getCurrentCompany();
                    } else {
                        if (this.selectedCompany) {
                            data.companyId = this.selectedCompany.key;
                        }
                    }
                    data.username = this.authService.getCurrentUsername();
                    // tslint:disable-next-line:no-shadowed-variable
                    this.productService.exportClientContractPdf(data).subscribe(res => {
                        if (res.data) {
                            const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                            const blobUrl = URL.createObjectURL(blob);
                            window.open(blobUrl, '_blank');
                            let data = <any>{
                                featureName: 'Client Contract List',
                                fileName: blobUrl,
                                fileSize: blob.size,
                                action: 'Export',
                                createdByUsr: this.authService.getCurrentUsername(),
                                companyId: this.authService.getCurrentCompanyId()
                            };
                            this.exportMonitorService.create(data).subscribe(()=>{
                            });
                        } else {
                            this.messageService.add({severity: 'error', summary: 'Problem exporting', detail: 'Problem exporting client pdf list'});
                        }
                    });
                }
            }
            // console.log(this.paymentInfoList);
        });
    }
}
