import {Injectable} from '@angular/core';
import {AbstractServiceV2} from '../../shared/service/abstract.v2.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../shared/model/constants';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperationalPolicyService extends AbstractServiceV2<any, any> {

    constructor(private http: HttpClient) {
        super(http, Constants.ROUTES.OPERATIONAL_POLICY, '');
    }


    createRecord(policy: any) {
        return this._http.post(`${this.baseUrlV2}`, policy).pipe(map(resp => resp));
    }

    updateRecord(policy: any) {
        return this._http.put(`${this.baseUrlV2}`, policy).pipe(map(resp => resp));
    }

    deleteRecord(id: Number) {
        return this._http.delete(`${this.baseUrlV2}/${id}`).pipe(map(data => data));
    }

    search(options: any): Observable<any> {
        const reqUrl = `${this.baseUrlV2}/search`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
      }

    exportPdf(data?: any): Observable<any> {
        return this._http.post<any>(`${this.baseUrlV2}/exportPdf`, data).pipe(map(resp => resp));
    }

    searchHistory(options: any): Observable<any> {
        const reqUrl = `${this.baseUrlV2}/history`;
        return this._http.post<any>(reqUrl, options).pipe(map(resp => resp));
    }
}