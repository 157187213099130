import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RoleLevel } from '../../component/app-menu/role-level';
import { Constants } from '../../../shared/model/constants';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PlatformAdmin } from '../../../admin/platform-admin/model/platform-admin.model';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { Item } from '@syncfusion/ej2-splitbuttons';

@Injectable({ providedIn: 'root' })
export class AllMenusService {
  platformAdmin: PlatformAdmin;
  themeConfig = {
    layout: '',
    theme: ''
  };
  hasBranch = true;
  private apiUrl = Constants;

  constructor(private http: HttpClient, private cookieService: CookieService,
    private authService: AuthService) {
  }

  getAllMenus() {

    let employeeProfileURL = '';
    let labelEmployeeProfile = '';
    let documentUrl = '';
    if (this.authService.getRoleLevel() === 7) {
      employeeProfileURL = '/app/employee/view/' + this.authService.getCurrentLoggedInId();
      labelEmployeeProfile = 'employee.employeeProfile';
      documentUrl = '/app/employee/document/' + this.authService.getCurrentLoggedInId();//+ '?companyId=' + this.authService.getCurrentCompanyId() + '&role=employee';
    } else {
      employeeProfileURL = '/app/employee/list';
      labelEmployeeProfile = 'All Employees';
    }
    this.hasBranch = this.authService.isCurrentCompanyHasBranch();
    let allMenus: any;
    let allMenus2: any = [
      {
        label: 'Dashboards', icon: 'dashboard', id: 'dashboard',
        items: [
          {
            label: 'Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/dashboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1,
            id: 'admin_dashboard',
            icon: 'space_dashboard'
          },
          {
            label: 'Overtime', icon: 'running_with_errors', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/overtime/list'],
            menuId: 62,
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            uid: 90024,
            items: [
              {
                label: 'Prevention', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/overtime/prevention'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 77,
                id: 'prevention',
                icon: 'lock_clock',
              },
              {
                label: 'Requests', id: 'menu_overrtime_request',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/', 'app', 'employee', 'overtime-request'],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 55,
                icon: 'more_time',
              },
            ],
          },
          {
            label: 'Client Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client/dasboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            id: 'client_dasboard',
            icon: 'pie_chart',
            menuId: 1000,
          },
 
		  {
            label: 'Survey Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/survey/dasboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            id: 'survey_dasboard',
            icon: 'ballot',
            menuId: 1000,
          },
 
		  {
            label: 'CRM Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/invoice/dasboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1000,
            id: 'crm_dashboard',
            icon: 'equalizer',
          },
          {
            label: 'CEO Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ceo/dashboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1000,
            id: 'ceo_dashboard',
            icon: 'diversity_3',
          },
          {
            label: 'VRM Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/voter/dashboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            id: 'vrm_dasboard',
            icon: 'how_to_vote',
            menuId: 1000,
          },
          {
            label: 'Operrtel Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/operrtel/dashboard'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            id: 'oppertel_dasboard',
            icon: 'ring_volume',
            menuId: 1000,
          },
          {
              label: 'Global Dashboard', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/dashboard'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                  RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
              id: 'global_dasboard',
              icon: 'diversity_3',
              menuId: 1277,
          }
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 9000
      },
      {
        label: 'Employee Punch', id: 'menu_employee_punch',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch'],
        menuId: 22,// 1-> 22
        icon: 'pattern',
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        uid: 8999
      },
      {
        label: 'Dashboard', icon: 'dashboard', id: 'employee-dashboard',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-dashboard'],
        disabled: localStorage.getItem('_disableAll') === '1',
        menuId: 111,
        show: [RoleLevel.ROLE_EMPLOYEE],
        uid: 9111
      },
      {
        label: 'Dashboard', icon: 'dashboard',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client/dasboard'],
        show: [RoleLevel.ROLE_CLIENT],
        id: 'client_dasboard'
      },
      {
        label: 'Platform Color', icon: 'palette', id: 'color',
        items: [
          {
            label: 'Default Blue', icon: 'brush', command: (event) => {
              this.changeLayout('moody');
              this.changeTheme('bluegrey');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701
          },
          {
            label: 'Red', icon: 'brush', command: (event) => {
              this.changeLayout('red');
              this.changeTheme('redd');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Purple', icon: 'brush', command: (event) => {
              this.changeLayout('cool');
              this.changeTheme('cool');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Green', icon: 'brush', command: (event) => {
              this.changeLayout('coolgreen');
              this.changeTheme('coolgreen');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Orange', icon: 'brush', command: (event) => {
              this.changeLayout('orange');
              this.changeTheme('orange');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Teal', icon: 'brush', command: (event) => {
              this.changeLayout('teal');
              this.changeTheme('teal');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Black', icon: 'brush', command: (event) => {
              this.changeLayout('black');
              this.changeTheme('black');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
          {
            label: 'Night Mode', icon: 'dark_mode', command: (event) => {
              this.changeLayout('night');
              this.changeTheme('night');
            }, show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 701

          },
        ],
        menuId: 70,
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CLIENT],
        uid: 9001
      },

      {
        label: 'operation', icon: 'build', id: 'menu_operation',
        items: [
          {
            label: 'Companies', id: 'company_profile',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/company/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'business',
            menuId: 5,
          },
          {
            label: 'Departments', id: 'menu_department_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/department/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
            RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 7,
            icon: 'sort'
          },
          {
            label: 'Positions', id: 'menu_position_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/position/add'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 8,
            icon: 'work'
          },
          {
            label: 'Content', id: 'menu_content_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/content/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 9,
            icon: 'description',

          },
          {
            label: 'Conference Rooms', id: 'menu_conference_room',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference-room/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1011,
            icon: 'record_voice_over',

          },
          {
            label: 'Shareholders', id: 'menu_share_holder_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/share-holder/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
            RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'group',
            menuId: 1011,
          },
          {
            label: 'Notifications', id: 'menu_notification_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/notification/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
            RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'notifications_active',
            menuId: 1056,
          },

          {
            label: 'Manage PWDs', id: 'menu_pwdmng_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/pwdmng/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
            RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'lock',
            menuId: 1011,
          },
          {
            label: 'Document Settings', id: 'menu_document_settings',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/company/document-settings/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: '-1',
            menuId: 1176
          },
          {
            label: 'API Settings', id: 'menu_google_api_setting',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/google-api-setting'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 86,
            icon: 'code'
          },
          {
            label: 'OperrTel Pools', id: 'menu_operrtel_pool_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/operrtel-pool/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
              RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'workspaces',
            menuId: 1011,
          },
          {
            label: 'Service Plans', id: 'menu_package_service_plan_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/package-service-plan/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN,
              RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'shopping_cart',
            menuId: 1181,
          },
          {
            label: 'OperrSign', id: 'menu_doc_sign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/doc-sign'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1177,
            icon: 'history_edu'
          },
          {
            label: 'Location Management', id: 'menu_location_management',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1187,
            icon: 'location_on',
            items: [
              {
                label: 'Apt/Room Inspection', id: 'menu_apt_inspection',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/inspection'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 1187,
                icon: 'code'
              },
              {
                label: 'Unit Views', id: 'menu_unit_view',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/location-management/apt-room-view'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 1187,
                icon: 'workspaces'
              }
            ]
          }
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        uid: 9002
      },
      {
        label: 'Mail', icon: 'email', id: 'menu_email',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lettermail/list'],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        menuId: 999,
        items: [
          {
            label: 'Deadline Reminder', id: 'menu_deadline_reminder',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lettermail/reminder/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 998
          },
          {
            label: 'Document', id: 'menu_document',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lettermail/document/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 998
          }
        ],
        uid: 9003
      },
      {
        label: 'Survey Land', icon: 'architecture', id: 'menu_survey_land',
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_CLIENT],
        uid: 9016,
        items: [
          {
            label: 'Quote', id: 'menu_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/quote/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CLIENT],
            menuId: 1670,
          }, {
            label: 'Land Project', id: 'menu_land_project',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/lander/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1671,
          },
          {
            label: 'Service Rate', id: 'menu_service_rate',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-rate/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1669,
          },
          {
            label: 'Service Provider', id: 'menu_service_provider',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-provider/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1677,
          }
        ]
      },
      {
          label: 'Compliance', icon: 'assured_workload', id: 'compliance_setting-menu',
          show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_CLIENT],
          uid: 9018,
          items: [
              {
                  label: 'Dashboard', id: 'menu_compliance_dashboard', icon: 'grid_view',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : [''],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN, 
                        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CLIENT],
                  menuId: 1680,
              }, {
                  label: 'Document Settings', id: 'menu_compliance_doc', icon: 'edit_document',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/list'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                      RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                  menuId: 1681,
              },
              {
                  label: 'Staff Compliance', id: 'menu_stuff_compliance', icon: 'rule',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/stuff-compliance'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  menuId: 1682,
              },
              {
                  label: 'Time Recollection', id: 'menu_time_recollection', icon: 'history_toggle_off',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/time-recollection'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  //menuId: 1158,
                  menuId: 1685,
              },
              {
                  label: 'Re-Collection Uploads', id: 'menu_time_recollection_upload', icon: 'upload_file',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/time-recollection-upload-history'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  //menuId: 1158,
                  menuId: 1693,
              },
              {
                  label: 'Voucher', id: 'stuff-voucher', icon: 'request_quote',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/stuff-voucher'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  menuId: 1684,
              },
              {
                  label: 'Wage Schedule', id: 'wage-schedule', icon: 'price_change',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/wage-schedule'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  menuId: 1686,
              },
              {
                label: 'Employee Voucher', id: 'employee-voucher', icon: 'receipt_long',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/employee-voucher'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                menuId: 1691,
              },
              {
                  label: 'Tip Credit', id: 'tip-credit', icon: 'savings',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/compliance-setting/tip-credit'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
                  menuId: 1694,
              },
          ]
      },
      {
        label: 'Training', icon: 'school', id: 'menu_trainning',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/in-service/list'],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        menuId: 1001,
        items: [
          {
            label: 'Topics', icon: 'bookmark', id: 'topic',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/training-topic/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1002
          }
        ],
        uid: 9003
      },

      {
        label: 'employeeName', icon: 'account_circle', id: 'menu_employee_list',
        items: [
          {
            label: 'Manage Employees', icon: 'settings', id: 'menu_employee',
            items: [
              {
                label: labelEmployeeProfile, id: 'menu_employee_profile', icon: 'group',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : [employeeProfileURL],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 21
              },
              // {
              //     label: 'Employee Document', id: 'menu_employee_document', icon: 'business_center',
              //     routerLink: localStorage.getItem('_disableAll') === '1' ? null : [documentUrl],
              //     queryParams: { companyId: this.authService.getCurrentCompanyId(), role: 'employee' },
              //     show: [RoleLevel.ROLE_EMPLOYEE],
              //     menuId: 21
              //   },
              {
                label: 'Add Employee', id: 'menu_employee_add', icon: 'person_add',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/add'],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 99
              },
              {
                label: 'Interns', id: 'menu_intern',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/intern/list'],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 1030
              },
              {
                label: 'Volunteers', id: 'menu_volunteer',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/volunteer/list'],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 1031
              }
            ],
            show: [RoleLevel.ROLE_ADMIN],
            uid: 90025
          },
          {
            label: 'Employee On Duty', id: 'menu_employee_on_duty',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/evv/onduty'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1138,
            icon: 'record_voice_over',

          },
          // {
          //   label: 'Contacts', icon: 'contact_emergency', id: 'menu_my_contact',
          //   routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/my-contact/list'],
          //   show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
          //   uid: 90025
          // },
          {
            label: 'Punch Clock',  id: 'menu_employee_punch', icon: 'apps',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            items: [
              {
                label: 'History', id: 'punch_history',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/punch-history'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 23,
                icon: 'history'
              },
              {
                label: 'Punctuality', id: 'menu_on_time_late',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-clocking/ontime-late'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 24,
                icon: 'timer'
              },
            ],
            uid: 90026
          },
          {
            label: 'Time-Off Requests', icon: 'free_cancellation',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            items: [
              {
                label: 'Add Request', id: 'menu_add_time_off_request', icon: 'event_busy',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/add'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 105
              },
              {
                label: 'Calendar', id: 'menu_employee_calendar', icon: 'calendar_month',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/absence/calendar'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 106
              }
            ],
            uid: 90029
          },
          {
            label: 'Payments', icon: 'monetization_on',
            items: [
              {
                label: 'Employee Payment', id: 'menu_employee_payment',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/payment'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 24,
                icon: 'monetization_on'
              },
              {
                label: 'Timesheets', id: 'menu_timesheet', icon: 'task',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/timesheet'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 47
              },
              {
                label: 'Time Corrections', id: 'menu_time_correction', icon: 'edit_calendar',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/protest-hour'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 45
              },
              {
                label: 'Paystubs', id: 'menu_employee_paystub', icon: 'receipt_long',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/paystub'],
                show: [RoleLevel.ROLE_EMPLOYEE],
                menuId: 500
              },
              {
                label: 'Expenses', id: 'menu_expense', icon: 'point_of_sale',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/expense/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 71
              }
            ],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            uid: 90027
          },
          {
            label: 'Event Schedule', icon: 'date_range',
            items: [
              {
                label: 'All Events', id: 'menu_event_schedule',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/event-schedule'],
                show: [RoleLevel.ROLE_ADMIN],
                menuId: 46,
              },
            ],
            show: [RoleLevel.ROLE_ADMIN],
            uid: 90078
          },
          {
            label: 'Performance', icon: 'query_stats',
            items: [
              {
                label: 'Employee Evaluation', id: 'menu_employee_performance', icon: 'star_half',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/employee-performance'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 107,
              },
              {
                label: 'Evaluation Results', id: 'menu_performance', icon: 'align_horizontal_left',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/employees-list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 44,
              },
              {
                label: 'Evaluation Criteria', id: 'menu_performance_configuration', icon: 'tune',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 44,
              },
              {
                label: 'Suspension Records', id: 'menu_employee_suspension',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/performance/suspension-record/list'],
                show: [RoleLevel.ROLE_EMPLOYEE],
                menuId: 1028
              },
            ],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            uid: 90029
          },
          {
            label: 'Employee Authorization', id: 'menu_employee_auth',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/employmentAuthorization/' + this.authService.getCurrentLoggedInId()],
            show: [RoleLevel.ROLE_EMPLOYEE],
            menuId: 677
          },
          {
            label: 'View Details Report', id: 'employee_detail_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/detail-report'],
            show: [RoleLevel.ROLE_EMPLOYEE],
            menuId: 1100
          },
          {
            label: 'Employee Setting', id: 'employee_setting',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/setting'],
            show: [RoleLevel.ROLE_EMPLOYEE],
            menuId: 678
          },
          {
            label: 'Phone Directory', id: 'phone_directory', icon: 'contact_phone',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/phone-directory-list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1158
          },
          {
            label: 'Job Report', id: 'employee_job_report', icon: 'assignment_add',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
              ['/app/employee/employee-job-report/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1028
          },
          {
            label: 'Time History', id: 'employee_time_history', icon: 'work_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null :
                ['/app/employee/employee-time-history-list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
              RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1029
          },
          {
            label: 'Scheduler', icon: 'date_range', id: 'menu_scheduler',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/scheduler'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1180
          },
          {
              label: 'Compliances', icon: 'business_center', id: 'menu_compliance',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/compliance/list'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                  RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
              menuId: 1682,
          },
          // {
          //   label: 'Time Recollection', id: 'employee_time_recollection', icon: 'work_history',
          //   routerLink: localStorage.getItem('_disableAll') === '1' ? null :
          //       ['/app/employee/time-recollection/list'],
          //   show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
          //     RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
          //   menuId: 1158
          // },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        uid: 9004
      },
      {
        label: 'Work Order', icon: 'assignment', id: 'menu_plan_assignment',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/workorder/list'],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
        RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        menuId: 48,
        uid: 9005
      },
      {
        label: 'Administrative', icon: 'security', id: 'menu_administrative',
        items: [
          {
            label: 'Platform Admins', id: 'menu_platform_admin',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/platform-admin/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN],
            menuId: 2,
            icon: 'verified_user'
          },
          {
            label: 'Administrators', id: 'menu_admin',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/agency-admin/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 4,
            icon: 'vpn_key'
          },
          {
            label: 'Groups', id: 'menu_groups',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/groups/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 41,
            icon: 'group'
          },
          {
            label: 'Process Monitor', id: 'menu_process_monitor', 
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 49, icon: 'pageview',
            items: [
              {
                label: 'Monitor Details', id: 'menu_monitor_details',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/monitor-details/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 41,
                icon: 'memory'
              },
              {
                label: 'Login Activity', id: 'menu_login_activity',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/monitor-details/login-activity'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 42,
                icon: 'admin_panel_settings'
              },
              {
                label: 'Email Activity', id: 'menu_email_activity',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/email-activity'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 43,
                icon: 'send_and_archive'
              },
              {
                  label: 'Export Monitor', id: 'export_monitor',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/process-monitor/export-monitor'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                      RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                  menuId: 43,
                  icon: 'admin_panel_settings'
              },
            ],
          },
          {
            label: 'Desk Activity', id: 'menu_employee_av_board',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/employee-av-board'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 50,
            icon: 'mouse'
          },
          {
            label: 'Monitoring', id: 'menu_monitoring',
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            items: [
              {
                label: 'Suspicious Activity', id: 'menu_suspicious_activities',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/monitoring/suspicious-activities'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
                menuId: 1033,
                icon: 'error'
              },
            ],
            menuId: 1032,
            icon: 'fingerprint'
          },
          {
            label: 'Email Subscriptions', id: 'menu_email_subscription',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/admin/email-subscription/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1039,
            icon: 'mark_email_read'
          },
          {
            label: 'Guests', id: 'menu_guest',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/guest/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 37,
            icon: 'supervised_user_circle'
          },
          {
              label: 'Plan Preview', id: 'menu_plan',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/plan/preview'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                  RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
              menuId: 1189,
              icon: 'workspaces'
          },
          {
              label: 'Maintenance', id: 'menu_maintenance',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/maintenance/list'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN],
              menuId: 1191,
              icon: 'construction'
          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 9006
      },
/*
      {
        label: 'Member', icon: 'badge', id: 'menu_member',
        items: [
          {
            label: 'Membership', id: 'menu_membership',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/membership/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 38,
            icon: 'people_outline'
          },
          {
            label: 'Contribution', id: 'menu_contribution',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/contribution/type'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 39,
            icon: 'portrait'
          },
          {
            label: 'Guest', id: 'menu_guest',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/guest/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 37,
            icon: 'portrait'
          },
          {
            label: 'Excursion', id: 'menu_excursion',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/excursion/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 60,
            icon: 'portrait'
          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 9007
      },
*/
      {
        label: 'Calendar', id: 'menu_clendar', icon: 'event',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/mananger-calendar/list'],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
        RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        menuId: 1005,
        uid: 9007
      },
      {
        label: 'CRMfull', id: 'menu_crm',
        icon: 'contacts',
        items: [
          {
            label: 'Clients', id: 'menu_crm_list', icon: 'business_center',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 30
          },
          {
            label: 'Client Contracts', id: 'menu_client_contract_list', icon: 'find_in_page',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/contract/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1157
          },
          {
            label: 'Client Documents', id: 'menu_clientdocument', icon: 'folder_zip',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/document/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 54
          },
          {
            label: 'Client Transfers', id: 'menu_client_transferred', icon: 'transfer_within_a_station',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/client/transfer-acceptance/'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 51
          },
          {
            label: 'Products', id: 'menu_product', icon: 'sell',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/products/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 66
          },
          {
            label: 'Client User Accounts', id: 'menu_client_account', icon: 'switch_account',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client-account/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1137
          },
          {
            label: 'Billing Client Log', id: 'menu_job_follow_up', icon: 'pending_actions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: (this.authService.getLoggedInUserMenuList().includes('1012')) ? 1012 : -1
          },
          {
            label: 'Campaigns', id: 'menu_crm_campaign', icon: 'campaign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/campaign/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1101
          },
          {
            label: 'Activity Log', id: 'activity_log', icon: 'troubleshoot',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/activity-log/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1136
          },
          {
              label: 'Lead', id: 'lead_list', icon: 'campaign',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/potential/list'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                  RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
              menuId: 1153
          },
          {
            label: 'Email Templates', id: 'email_template', icon: 'post_add',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/email-template/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1139
          },
          {
            label: 'Linked Accounts', id: 'menu_client_info', icon: 'link',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/client-info-account/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1153
          },
          {
              label: 'EVV', id: 'menu_evv_registered', icon: 'add_location_alt',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/evv/search'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                  RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
              menuId: 52
          },
          {
            label: 'Call Note', id: 'call_note', icon: 'assignment',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/call-note'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
              RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1153
          },
          {
            label: 'Call Record', id: 'call_record', icon: 'record_voice_over',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/call-record'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
              RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1153
          },
          {
            label: 'Operrtel Contact List', id: 'operrtel_contact_list', icon: 'contacts',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/operrtel/contacts-list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
              RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1153
          }
        ],
        uid: 9008
      },
      {
        label: 'VRMfull', id: 'menu_vrm', routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/voter/dashboard'],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN], icon: 'poll',
        items: [
          {
            label: 'Electoral Campaign', id: 'menu_crm_campaign_vrm',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/campaign/electoral/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'record_voice_over',
            menuId: 1169
          },
          {
            label: 'Voter List', id: 'menu_voter_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/voter/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'receipt_long',
            menuId: 1163
          },
          {
            label: 'Walker\'s List', id: 'menu_walker_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/walker/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'directions_walk',
            menuId: 1164
          },
          {
            label: 'Zone Management', id: 'menu_zone_management',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/zone-management/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'location_on',
            menuId: 1165
          },
          {
            label: 'Candidates', id: 'menu_candidate_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/candidate/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'groups',
            menuId: 1166
          },
          {
            label: 'Schedule Batch', id: 'menu_schedule_batch',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/schedule-batch'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'event_note',
            menuId: 1168
          },
          {
            label: 'Flyer List', id: 'menu_flyer_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/flyer/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'markunread_mailbox',
            menuId: 1170
          },
          {
            label: 'Customize Questions', id: 'menu_customize_questions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vrm/customize-questions/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'help_center',
            menuId: 1172
          },
          {
            label: 'Visit History', id: 'menu_visit_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/visit-history/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'history',
            menuId: 2341,
          },
          {
            label: 'Voter Upload History', id: 'menu_voter_upload_history',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/voter-upload-history/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'upload',
            menuId: 3158,
          },
          {
            label: 'Fundraisers', id: 'menu_fundraisers',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vrm/fundraisers/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'help_center',
            menuId: 1173
          },
        ],
        uid: 9008
      },
      {
        label: 'Ind. Contractors', icon: 'business_center', id: 'menu_contractor',
        items: [
          {
            label: 'Contractors', id: 'menu_contractor_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/crm/freelancer/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'face',
            menuId: 56,

          },
          {
            label: 'Assigned Tickets', id: 'menu_assigned_ticket',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/crm/freelancer/assignedtickets'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'assignment',
            menuId: 710,

          },
          {
            label: 'Completed Tickets', id: 'menu_completed_ticket',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/crm/freelancer/completedtickets'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'assignment_turned_in',
            menuId: 711,

          },
          {
            label: 'Ticket Summary', id: 'ticket_summary',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/contractors/ticket-summary'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'list_alt',
            menuId: 1029,
          },
          {
            label: 'Payment Methods', id: 'menu_payment_method',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-method/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 704,
            icon: 'credit_card',
          },
          {
            label: 'Invoices', id: 'menu_contractor_invoie',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/contractors/invoice'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'description',
            menuId: 703,

          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 90023
      },
      {
        label: 'OperrTel', icon: 'ring_volume', id: 'menu_operrtel',
        items: [
          {
            label: 'Contract', id: 'menu_phone_contract', icon: 'policy',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/contract'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 68,
          },
          {
            label: 'Phone Packages', id: 'menu_phone_package',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 58,
            icon: 'phonelink_setup'
          },
          {
            label: 'Invoices', id: 'menu_phone_invoice',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/invoice'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'request_quote',
            menuId: 63,
          },
          {
            label: 'Payments', id: 'menu_phone_payment', icon: 'mobile_friendly',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/detail/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 61
          },

          {
            label: 'Send SMS', id: 'menu_send_sms', icon: 'sms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/send-sms'],
            queryParams: { section: 'sendSms' },
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1027,
          },
          {
            label: 'SMS Usage', id: 'menu_sms_monitoring', icon: 'troubleshoot',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/sms-monitoring'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1036,
          },
          {
            label: 'Auto-Dialer', id: 'menu_auto_dial', icon: 'dialpad',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/auto-dial'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1184,
          },
          {
            label: 'Bulk SMS', id: 'menu_bulk_sms', icon: 'sms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/bulk-sms'],
            queryParams: { section: 'sendBulkSms' },
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1182,
          },
          {
            label: 'Scheduler', id: 'menu_schedule_list', icon: 'calendar_month',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/schedule-list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1183,
          },
          {
            label: 'Top Up', id: 'menu_top_up', icon: 'mobile_friendly',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/top-up'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1037,
          },
          {
            label: 'My Messages', id: 'menu_my_message', icon: 'textsms',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/my-message'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1185
          },
          {
            label: 'OperrTel Groups', id: 'menu_vrm_group',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/ele-campaign/vrm-group/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'groups',
            menuId: 2342,
          },
          {
            label: 'Contacts', icon: 'contact_emergency', id: 'menu_my_contact',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee/my-contact/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            uid: 90025,
            menuId: 2344,
          },
          {
            label: 'DRIP Campaign', id: 'menu_drip_campaign',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/drip-campaign/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'groups',
            menuId: 2343,
          },
          {
            label: 'Shorten URLs', id: 'shorten_url',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/phonesystem/shorten-url'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
              RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'content_cut',
            menuId: 2343,
          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 9009
      },
      {
        label: 'Payments', icon: 'payment', id: 'menu_payment',
        items: [
          {
            label: 'Billing', id: 'menu_billing',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billings/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 67,
            icon: 'paid'
          },
          {
            label: 'Process Payments', id: 'menu_process_payment',
            // routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/method'],
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/detail'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 59,
            icon: 'credit_score'
          },
          {
            label: 'Invoices', id: 'menu_invoice',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'request_quote',
            menuId: 65,
          },
          {
            label: 'Invoice Templates', id: 'menu_invoice_template',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.isPlatFormAdmin() ? ['/app/billings/invoice-template/list'] : ['/app/billings/invoice-template/add'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'post_add',
            menuId: 110,
          },
          {
            label: 'Fees', id: 'fees_invoice_template', icon: 'price_check',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : this.isPlatFormAdmin() ? ['/app/monthly-fee/list'] : ['/app/monthly-fee/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 114,
          },
          {
            label: 'Wallet', id: 'wallet', icon: 'account_balance_wallet',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/wallet'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1666,
          },
          {
            label: 'Wallet Transactions', id: 'wallet_transaction', icon: 'receipt_long',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/wallet-transaction'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1667,
          },
          {
            label: 'Invoice Verification', id: 'invoice_verification', icon: 'verified',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice-verification'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1678,
          },
          {
            label: 'Invoice Summary', id: 'invoice_summary', icon: 'verified',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payments/invoice-summary'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN],
            menuId: 1679,
          }
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
        uid: 9010
      },
      {
        label: 'Billing Reports', icon: 'assessment', id: 'billing_report',
        items: [
          {
            label: 'MAS', id: 'mas_report',
            items: [
              {
                label: 'Total Job', id: 'total_job',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-total-job'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CHILD_CLIENT],
                menuId: 1019
              },
              {
                label: 'General Report', id: 'general_report',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-report'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1020
              },
              {
                label: 'Details Report', id: 'details_report',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-details-report'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1021
              },
              {
                label: 'Correction', id: 'correction',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-correction-report'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1022
              },
              {
                label: 'Upload History', id: 'upload_history',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-upload-history'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1024
              },
              {
                label: 'Billing Client Status', id: 'billing_client_status',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-client-status'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1034
              }
            ],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            uid: 9021
          },

          // changes
          {
            label: 'Fidelis Report', id: 'fidelis_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/fidelis-billing-report/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1023
          },
          {
            label: 'LOG', id: 'log_report',
            items: [{
              label: 'Log Total Job', id: 'log_total_job',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-log-total-job'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CHILD_CLIENT],
              menuId: 1025
            },
            {
              label: 'Log Detail Report', id: 'log_details_report',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/billing-log-details-report'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE, RoleLevel.ROLE_CHILD_CLIENT],
              menuId: 1026
            }
            ],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            uid: 9022
          },
          {
              label: 'Dispatch Total Job', id: 'dispatch_total_job',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/job-follow-up/dispatch-total-job'],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                  RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
              menuId: 1122
          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        uid: 9020
      },
      {
        label: 'Accounting', icon: 'savings', id: 'menu_accounting',
        items: [
          {
            label: 'Transactions', id: 'menu_transaction',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/transaction/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'sync_alt',
            menuId: (this.authService.getLoggedInUserMenuList().includes('82')) ? 82 : (this.authService.getLoggedInUserMenuList().includes('83'))
              ? 83 : (this.authService.getLoggedInUserMenuList().includes('84')) ? 84 : -1
          },
          {
            label: 'Taxes', id: 'menu_tax_info',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/tax-info/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'calculate',
            menuId: 115,
          },
          {
            label: 'Received Payments', id: 'menu_transaction_details',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-info'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'price_check',
            menuId: 96
          },
          {
            label: 'Projects', id: 'menu_project', icon: 'account_tree',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/project/add'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 100
          },
          {
            label: 'Credit & Debit', id: 'menu_account',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/account/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            icon: 'credit_card',
            menuId: 101,
          },
          {
            label: 'Contracts & Licenses', id: 'menu_generic_compliance', icon: 'policy',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/generic-compliance/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 102
          },
          {
            label: 'Loans', id: 'menu_loan', icon: 'real_estate_agent',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/loan/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 104
          },
          {
            label: 'Follow-up Bills', id: 'menu_bill', icon: 'forward_to_inbox',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/employee-bill'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 26
          },
          {
            label: 'Payment Tracking', id: 'menu_payment_tracking', icon: 'find_in_page',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payment-tracking/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 103
          },
          {
            label: 'Payroll Journal', id: 'menu_payroll_journal_report', icon: 'history_edu',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/payroll-journal-report/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 1673
          },
          {
            label: 'Insurance', id: 'menu_insurance', icon: 'health_and_safety',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/insurance/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 1151
          },
          {
            label: 'File Examination', id: 'menu_file_examination', icon: 'rule_folder',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/file-examination/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 2003
          },
          {
            label: 'General Invoices', id: 'menu_general_invoice', icon: 'request_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/general-invoice/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 2004
          },
          {
            label: 'Invoice Tracking', id: 'menu_invoice_tracking', icon: 'account_tree',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/invoice-tracking/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 2005
          },
          {
            label: 'Punch Billing', id: 'menu_punch_billing', icon: 'request_quote',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billings/punch-billing/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            menuId: 2006
          },
          {
            label: 'Accounts Receivable', id: 'ar_report', icon: 'insights',
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            items: [
              {
                label: 'Revenue Payment', id: 'revenue_payment', icon: 'monetization_on',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/revenue-payment/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1160
              },
              {
                label: 'Service Revenue', id: 'service_revenue', icon: 'query_stats',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/service-revenue/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1159
              },
              {
                label: 'Gross Income', id: 'gross_income', icon: 'payments',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/gross-income/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1161
              },
              {
                label: 'Aging Details', id: 'accounts_receivable_aging_details', icon: 'manage_search',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/receivable-aging-details/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                  RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1167
              },
              {
                label: 'Aging Summary', id: 'aging_summary', icon: 'summarize',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/aging-summary/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1179
              },
            ],
          },
          {
            label: 'Payroll', id: 'menu_contractor_payroll',
            // routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/contractors/new-payroll'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
            icon: 'attach_money',
            items: [
              {
                label: 'Payroll', id: 'menu_list_payroll',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/contractors/payroll-list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                icon: 'price_check',
                menuId: 1155,
              },
              {
                label: 'New Payroll', id: 'menu_new_payroll',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['app/contractors/new-payroll'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                icon: 'add_circle',
                menuId: 1154,
              }
            ]
          },
          {
            label: 'Billing Location', id: 'billing_location', icon: 'summarize',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/billing-location/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN],
            menuId: 1186
          },
          {
            label: 'SMS/MMS Billing', id: 'sms_mms_billing',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/sms-mms-billing/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN],
            menuId: 4422
          }
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
        uid: 9014
      },
      // {
      //   label: 'Spreadsheets', icon: 'grid_on', id: 'spread_sheet',
      //   routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/spread-sheet/list'],
      //   items: [],
      //   show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
      //   RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
      //   uid: 9030,
      //   menuId: 1162
      // },
      {
        label: 'Reports', icon: 'assessment', id: 'menu_report',
        items: [
          {
            label: 'Compliance Report', id: 'menu_compliance_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
            menuId: 76,
            icon: 'how_to_reg'
          },
          {
            label: 'Performance report', id: 'menu_job_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/job-report'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 69,
            icon: 'assignment_add'
          },
          {
            label: 'Report Templates', id: 'menu_report_template', 
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/report-template/list'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 97,
            icon: ''
          },
          {
            label: 'OperrTel Report', id: 'menu_operrtel_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/operrtel-report'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 97,
            icon: ''
          },
          {
            label: 'API Usage Report', id: 'menu_api_usage_report', icon: 'integration_instructions',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reports/api-usage-report'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN],
            menuId: 85,
          }
        ],
        menuId: 75,
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
        uid: 9011
      },
/*
      {
        label: 'Chat Box', icon: 'chat', id: 'menu_chatbox',
        items: [
          {
            label: 'Chat Box', id: 'menu_sub_chatbox',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/chat-box'],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
            menuId: 64,
            icon: 'chat'
          }
        ],
        menuId: 64,
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
        uid: 9012
      },
 */
      // {
      //   label: 'Multimedia', icon: 'videocam', id: 'conference_menu',
      //   items: [
      //     {
      //       label: 'Join Conference',
      //       id: 'menu_join_a_meeting',
      //       routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/join'],
      //       show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
      //       RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
      //       menuId: 36,
      //       icon: 'camera_indoor'
      //     },
      //     {
      //       label: 'Video Conferences',
      //       id: 'menu_meeting_schedule',
      //       routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/schedule'],
      //       show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
      //       RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
      //       menuId: 36,
      //       icon: 'video_chat'
      //     },
      //     {
      //       label: 'Screen Recordings', id: 'menu_screen_recorder', icon: 'video_settings',
      //       routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/screen-recorder/list'],
      //       show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
      //       RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
      //       menuId: 125
      //     }
      //   ],
      //   show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
      //   RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
      //   // menuId: 36,
      //   uid: 9013
      // },
      // {
      //   label: 'Notebook', icon: 'auto_stories', id: 'menu_note_page_list',
      //   routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/note'],
      //   items: [],
      //   show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
      //   RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
      //   menuId: 78,
      //   uid: 9015
      // },
      {
          label: 'Resident', id: 'menu_resident', icon: 'real_estate_agent',
          routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/list'],
          items: [
              {
                  label: 'Sign-In/Out List', id: 'resident_sign_in_sign_out', icon: 'qr_code_scanner',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/sign-in-out-list'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                      RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                  menuId: 1273
              },
              {
                  label: 'Flag', id: 'resident_flag', icon: 'flag',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/flag'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                      RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                  menuId: 1274
              },
              {
                  label: 'Alert History', id: 'alert_history', icon: 'history',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/alert-history'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                      RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                  menuId: 1275
              },
              {
                  label: 'Incident Report', id: 'incident-report', icon: 'emergency_home',
                  routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/incident-report/list'],
                  show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                      RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                  menuId: 1206
              },
              {
                label: 'Curfew Schedule', id: 'curfew-schedule', icon: 'lock_clock',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/curfew-schedule'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                    RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1207
            },
            {
                label: 'Service Management', id: 'service-management', icon: 'roofing',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/resident/resident-services/list'],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                    RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1208
            },
          ],
          show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
          uid: 9017
      },
      {
          label: 'Reminder Alert', id: 'alarm_on',
          routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/reminder/list'],
          items: [],
          show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
          menuId: 1209,
          uid: 9017
      },
      {
        label: 'Task Management', icon: 'fact_check', id: 'menu_task_management',
        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/tm'],
        items: [],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
        RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
        menuId: 78,
        uid: 9015
      },

      {
        label: 'Tools', icon: 'construction', id: 'support_tool',
        items: [
            {
                label: 'Spreadsheets', icon: 'grid_on', id: 'spread_sheet',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/spread-sheet/list'],
                items: [],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
                uid: 9030,
                menuId: 1162
            },
            {
                label: 'Multimedia', icon: 'videocam', id: 'conference_menu',
                items: [
                    {
                        label: 'Join Conference',
                        id: 'menu_join_a_meeting',
                        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/join'],
                        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
                        menuId: 127, //36 -> 127
                        icon: 'camera_indoor'
                    },
                    {
                        label: 'Video Conferences',
                        id: 'menu_meeting_schedule',
                        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/conference/schedule'],
                        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK],
                        menuId: 126,// 36 -> 126
                        icon: 'video_chat'
                    },
                    {
                        label: 'Screen Recordings', id: 'menu_screen_recorder', icon: 'video_settings',
                        routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/screen-recorder/list'],
                        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN,
                            RoleLevel.ROLE_SUB_COMPANY_ADMIN, RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_EMPLOYEE],
                        menuId: 125
                    }
                ],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
                // menuId: 36,
                uid: 9013
            },
            {
                label: 'Notebook', icon: 'auto_stories', id: 'menu_note_page_list',
                routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/note'],
                items: [],
                show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                    RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
                menuId: 1038,// 78 -> 1038
                uid: 9015
            },
            {
              label: 'Law Firms', icon: 'balance', id: 'menu_law_firm_list',
              routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/law-firm/list'],
              items: [],
              show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
              menuId: 4461,
              uid: 9015
            },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
            RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
        // menuId: 36,
        uid: 9016
      },
      {
        label: 'Inventory', icon: 'inventory', id: 'menu_inventory',
        items: [
          {
            label: 'Inventory List', icon: 'local_offer', id: 'menu_inventory_list',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/list'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
              RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1202,
          },
          {
            label: 'Items', icon: 'grid_on', id: 'menu_items',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/product/add'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1200
          },
          {
            label: 'Equipment Check-Out', icon: 'cloud_sync', id: 'menu_equipment_check_out',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/equipment/list'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1201
          },
          {
            label: 'Vehicles', icon: 'car_rental', id: 'menu_vehicles',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/vehicle/list'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1152
          },
          {
            label: 'Purchase Requests', icon: 'production_quantity_limits', id: 'menu_purchase_requests',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/purchase-list'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1203
          },
          {
            label: 'Inventory Report', icon: 'description', id: 'menu_inventory_report',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/stock-used/list'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1204
          },
          {
            label: 'Manage Attributes', icon: 'edit_attributes', id: 'menu_manage_attributes',
            routerLink: localStorage.getItem('_disableAll') === '1' ? null : ['/app/stock/manage-attribute'],
            items: [],
            show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
                RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
            uid: 9030,
            menuId: 1205
          },
        ],
        show: [RoleLevel.ROLE_ADMIN, RoleLevel.ROLE_SUB_ADMIN, RoleLevel.ROLE_COMPANY_ADMIN, RoleLevel.ROLE_SUB_COMPANY_ADMIN,
          RoleLevel.ROLE_AGENCY_ADMIN, RoleLevel.ROLE_SUB_AGENCY_ADMIN, RoleLevel.ROLE_FRONT_DESK, RoleLevel.ROLE_EMPLOYEE],
        uid: 9030
      }
    ];
    // for top search bar
    const newMenus = [
      { label: 'Billing Report', routerLink: ['/app/job-follow-up/billing-report'], menuId: 1012, hideOnMenuBar: true },
      // { label: 'Total Job', routerLink: ['/app/job-follow-up/billing-total-job'], menuId: 1012 , hideOnMenuBar: true},
      // { label: 'Details Report', routerLink: ['/app/job-follow-up/billing-details-report'], menuId: 1012 , hideOnMenuBar: true},
      { label: 'Employee on Duty', routerLink: ['/app/crm/evv/onduty'], menuId: 1138, hideOnMenuBar: true },
      { label: 'View Payment', routerLink: ['/app/payments/view-payment-method/list'], menuId: 704, hideOnMenuBar: true },
      { label: 'Follow-up List', routerLink: ['/app/payments/follow-up-note'], menuId: 704, hideOnMenuBar: true },
      { label: 'AR Report', routerLink: ['/app/revenue-payment/list'], menuId: 1160, hideOnMenuBar: true },
    ];

    allMenus2 = allMenus2.concat(newMenus);
    allMenus = allMenus2;
    if (this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
      allMenus.forEach((ele: any) => {
        if (ele.label === 'Operation') {
          ele.items = ele.items.filter((item) => {
            return item.menuId !== 6;
          });
        }
      });
    }
    if (this.isPlatFormAdmin()) {
      allMenus = allMenus.filter((ele: any) => {
        return ele.uid !== 9111 || ele.id !== 'employee_detail_report';
      });

      allMenus.forEach((ele: any) => {
        if (ele.label === 'employeeName') {
          ele.items = ele.items.filter((item) => {
            return item.menuId !== 1100;
          });
        }
      });
    }
    if (!this.authService.isSuper() && !this.authService.isSubSuper()
      && !this.authService.isCompanyAd() && !this.authService.isSubCompanyAd()) {
      allMenus.forEach((ele: any) => {
        if (ele.label === 'Operation') {
          ele.items = ele.items.filter((item) => {
            return item.uid !== 90021;
          });
        } else {
          if (ele.label === 'employeeName') {
            ele.items = ele.items.filter((item) => {
              return item.menuId !== 1100;
            });
          }
        }
      });
    }

    if (!this.isPlatFormAdmin()) {
        allMenus.forEach((ele: any) => {
          if (ele.label === 'Administrative') {
                ele.items = ele.items.filter((item) => {
                    return item.id !== 'menu_maintenance';
                });
            }
        });
    }


    if (this.authService.isClientRole()) {

      allMenus.forEach((ele: any) => {
        if (ele.label === 'Payment') {
          ele.items = ele.items.filter((item) => {
            return item.id !== 'menu_invoice_template' && item.id !== 'fees_invoice_template';
          });
        }
      });

      allMenus = allMenus.filter((ele: any) => {
        return ele.uid !== 9002 && ele.uid !== 9004 && ele.uid !== 9000;
      });
    }

    if (this.authService.isEmployeeRole()) {
      allMenus = allMenus.filter((ele: any) => {
        return ele.uid !== 9000 && ele.uid !== 9006;
      });
      allMenus.forEach(e => {
        if (e['items']) {
          e['items'] = e['items'].filter((ele: any) => {
            return ele.menuId !== 24;
          });
        }
      });
      allMenus = allMenus.filter((ele: any) => {
        if (ele.uid === 9004) {
          ele.items = ele.items.filter((item) => {
            if (item.uid === 90027) {
              item.items = item.items.filter((item2) => {
                return item2.id !== 'menu_employee_payment';
              });
            }
            return item;
          });
        }
        return ele;
      });

      allMenus = allMenus.filter((ele: any) => {
        if (ele.uid === 9002) {
          ele.items = ele.items.filter((item2) => {
            return item2.id !== 'menu_conference_room';
          });
        }

        if (ele.uid === 9020) {
          ele.items = ele.items.filter((item) => {
            if (item.uid === 9021) {
              item.items = item.items.filter((itm) => {
                return itm.id !== 1023;
              });
            }
            return item;
          });
        }
        return ele;
      });
    }

    if (this.authService.isCompanyAdminRole()) {
      allMenus.forEach(e => {
        if (e['items']) {
          e['items'] = e['items'].filter((ele: any) => {
            return ele.menuId !== 2 && ele.menuId !== 1100;
          });
        }
      });
    }

    if (localStorage.getItem('_user') === 'freelancer') {
      // allMenus = allMenus.filter((ele: any) => {
      //   if (ele.uid === 9010) {
      //     if (ele['items']) {
      //       ele['items'] = ele['items'].filter((e: any) => {
      //         return e.menuId === 59 || e.menuId === 1666 || e.menuId === 1667
      //       });
      //       return true;
      //     }
      //     return false;
      //   }
      //   return ele.uid === 90023 || ele.uid === 9015 || ele.uid === 9001 || ele.uid === 8999;
      // });

      if (this.authService.getContractorType() === 1 || this.authService.getContractorType() === 0) {
        allMenus = allMenus.filter((ele: any) => {
          if (ele.id === 'menu_contractor') {
            ele.items = ele.items.filter((item) => {
              return item.id === 'menu_completed_ticket' || item.id === 'menu_assigned_ticket'
                  || item.id === 'menu_payment_method' || item.id === 'menu_contractor_list' || item.id === 'ticket_summary' || item.id === 'menu_employee_punch';
            });
          }
          if (ele.id === 'color') {
            return ele.items;
          }
          return ele;
        });
      }

    }

    if (localStorage.getItem('_user') === 'guess') {
      allMenus = allMenus.filter((ele: any) => {
        return ele.uid === 90023 || ele.uid === 9015 || ele.uid === 9005 || ele.uid === 9013;
      });
    }

    if (this.authService.isChildClientRole()) {
      allMenus = allMenus.filter((ele: any) => {
        if (ele.uid === 9000) {
          ele.items = ele.items.filter((item2) => {
            return item2.id !== 'client_dasboard';
          });
        }
        return ele.id !== 'client_dasboard';
      });
    }
    const userLoginInfo = this.authService.getUserInfo();
    if (this.authService.isContractorRole() && !userLoginInfo.groupId) {
      return [];
    }
    return allMenus;
  }

  getThemeConfig() {
    // console.log("GET ADMIN THEME");
    if (localStorage.getItem('_userId')) {
      const adminId = localStorage.getItem('_userId');
      const loginType = this.authService.getLoginUserType();
      const reqUrl = `${environment.v2_server_ip}/${this.apiUrl.URI.THEME.GETADMINTHEMECONFIG}/${adminId}/login-type/${loginType}`;
      this.http.get(reqUrl).subscribe(res => {
        const resObj: any = res;
        if (resObj.data) {
          const parsedData = JSON.parse(resObj.data.config);
          // console.log(parsedData);
          this.changeTheme(parsedData.theme, 'exits');
          this.changeLayout(parsedData.layout, 'exits');
        }
      });
    }
  }

  changeTheme(theme, type?) {
    if (theme !== '') {
      this.themeConfig.theme = theme;
      const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
      if (themeLink) {
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
      }
      if (!type) {
        this.saveThemeConfig();
      } else {
        // console.log(type);
      }
    }

  }

  changeLayout(theme, type?) {
    if (theme !== '') {
      this.themeConfig.layout = theme;
      const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
      if (layoutLink) {
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
      }
      if (!type) {
        this.saveThemeConfig();
      } else {
        // console.log(type);
      }
    }
  }

  // save theme config to database
  saveThemeConfig() {
    if (localStorage.getItem('_globals')) {
      if (localStorage.getItem('_config')) {
        const loggedIn = JSON.parse(localStorage.getItem('_globals'));
        const loginType = this.authService.getLoginUserType();
        const reqUrl = `${environment.v2_server_ip}/${this.apiUrl.URI.THEME.PUTADMINTHEMECONFIG}`;
        const config = {
          'adminId': loggedIn.adminId,
          'loginType': loginType,
          'config': JSON.stringify(this.themeConfig)
        };
        return this.http.post(reqUrl, config).subscribe(res => {
        });
      }
    }
  }

  isPlatFormAdmin() {
    if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCEOAdmin()) {
      return true;
    }
  }

}
