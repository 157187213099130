import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbService} from '../../../layout/service/breadcrumb.service';

import * as _ from 'lodash';
import {DatePipe} from "@angular/common";
import {HolidayHistory} from "../../model/holiday-history.model";
import {HolidayHistoryService} from "../../service/holiday-history.service";
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-holiday-history',
    templateUrl: './holiday-history.component.html',
    styleUrls: ['./holiday-history.component.scss'],
    providers: [HolidayHistoryService]
})
export class HolidayHistoryComponent implements OnInit {
    @Input() holidayId: number;
    dataSourceSubject = new BehaviorSubject(null);
    dataSource$ = this.dataSourceSubject.asObservable();
    size: number;
    page: number;
    holidayHistories: HolidayHistory[];
    cols: any[] = [];
    first = 0;
    rangeDates;
    date = { startDate: new Date(), endDate: new Date() };
    startDate;
    endDate;
    maxDate: any;
    fieldNames: any[];
    selectedField = 'fieldName';

    constructor(
        private datePipe: DatePipe,
        private breadcrumbService: BreadcrumbService,
        private holidayHistoryService: HolidayHistoryService,
        private translate: TranslateService
    ) {
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');

        this.translate.get(['']).subscribe((result: string) => {

            const fieldNames = [
                { label: 'cols.holidayName', value: 'holidayName' },
                { label: 'cols.date', value: 'holidayDate' },
                { label: 'Logic', value: 'holidayDate' },
                { label: 'Month', value: 'month' },
                { label: 'Every', value: 'day' },
                { label: 'Day Of Week', value: 'dayOfWeek' },
                { label: 'cols.status', value: 'status' }
            ];

            this.fieldNames =  _.sortBy(fieldNames, 'label');

            this.cols = [
                { field: 'updatedDate', label: 'history.updatedDate' },
                { field: 'userName', label: 'history.userName' },
                { field: 'oldValue', label: 'history.oldValue' },
                { field: 'newValue', label: 'history.newValue' },
                { field: 'action', label: 'history.action' }
            ];
        });

    }

    loadDataSource(event?: any) {
        this.size = event && event.rows ? event.rows : (this.size ? this.size : 25);
        this.page = event && event.first && event.rows ? (event.first / event.rows) : (this.page ? this.page : 0);
    }

    filterClientHistory() {
        // let fromDate = this.rangeDates ? new Date(this.rangeDates[0]).getTime() : null;
        // let toDate = this.rangeDates ? new Date(this.rangeDates[1]).getTime() : null;
        let fromDate = this.startDate ? new Date(this.startDate).getTime() : null;
        let toDate = this.endDate ? new Date(this.endDate).getTime() : null;

        let options:any= {};
        if (this.holidayId) {
            options.holidayId = this.holidayId;
        }
        if(this.selectedField){
            options.fieldName = this.selectedField;
        }
        if(fromDate){ 
            options.fromDate = fromDate;
        }
        if(toDate){ 
            options.toDate = toDate;
        }
        this.holidayHistoryService.getClientHistoryPage(options).subscribe(
            (data: any) => {
                this.holidayHistories = data.data.content;
                this.holidayHistories.forEach(history => {
                    if (history.fieldName === 'date' || history.fieldName === 'dateOfConversion') {
                        history.newValue = this.datePipe.transform(history.newValue, 'M/d/y h:m:s a');
                        history.oldValue = this.datePipe.transform(history.oldValue, 'M/d/y h:m:s a');
                    }
                    if (history.fieldName === 'holidayDate') {
                        if (history.newValue)
                            history.newValue = this.datePipe.transform(history.newValue, 'MM/dd/yyyy');
                        if (history.oldValue)
                            history.oldValue = this.datePipe.transform(history.oldValue, 'MM/dd/yyyy');
                    }
                });
                this.dataSourceSubject.next(data.data);
            }
        );

    }

    reset() {
        this.selectedField = null;
        this.startDate = null;
        this.endDate  = null;
        this.holidayHistories = [];
        this.dataSourceSubject = new BehaviorSubject(null);
        // this.loadDataSource();
    }

}
