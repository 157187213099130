import { Component, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { IdleService } from './idle/idle.service';
import { AuthService } from './shared/service/auth/auth.service';
import { AllMenusService } from './layout/service/all-menus/all-menus.service';
import { EmployeeClockingService } from '../app/employee-clocking/service/employee-clocking.service';
import { EmployeeService } from '../app/employee/service/employee.service';
import { environment } from '../environments/environment';
import { MenuItem, MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceType } from './layout/component/app-menu/service-type';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MenuList } from '../app/shared/data/menu-list';
import { TaskManagementService } from './task-management/service/task-management.service';
import moment, * as MOMENT from "moment";
import { ClientService } from './crm/service/client.service';
import { Guess } from './sign-up/model/guess.model';
import { forkJoin, Subscription } from 'rxjs';
import { PaymentCardDefaultService } from './payment/service/payment-card-default.service';
import { PaymentProfileService } from './payment/service/payment-profile.service';
import { PaymentInvoiceService } from './payment/service/payment-invoice.service';
import { CountdownEvent, CountdownStatus } from 'ngx-countdown';
import { CompanySettingServiceV2 } from './company/service/company-setting.v2.service';
import { PaymentNotificationService } from './payment/service/payment-notification.service';
import { DocumentsService } from './shared/service/documents-upload/documents.service';
import { FirebaseNotificationService } from './shared/service/firebase-notification.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { debounceTime } from 'rxjs/operators';

const CronJob = require('cron').CronJob;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  menuClick: boolean;

  menuButtonClick: boolean;

  topbarMenuButtonClick: boolean;

  topbarMenuClick: boolean;

  topbarMenuActive: boolean;

  activeTopbarItem: Element;

  layoutMode = 'overlay';

  sidebarActive: boolean;

  mobileMenuActive: boolean;

  darkMenu: boolean;

  isRTL: boolean;

  rippleInitListener: any;

  rippleMouseDownListener: any;

  menuHoverActive: boolean;

  resetMenu: boolean;

  isAuthorized: Observable<boolean>;
  isAuthorized8: Observable<boolean>;
  punchInOut: boolean;
  loggedInUserMenus = [];
  loggedInUserMenusEdit = [];
  isCheckURL: Boolean = true;
  menuList = [];
  dashBoardForRole = {};
  menuListAfter = [];
  menuListData = new MenuList();
  menuListBefore = [];
  menuEditList = [];
  isEmployee: boolean = false;
  punchHistoryList = [];
  vioLateTheLawDisplay: boolean = false;
  defaultChildUserName: string = '';
  defaultEmail: string = '';

  authObservable: Subscription;
  paymentChargingObservable: Subscription;
  invoiceWarningMessage;
  invoiceWarningDialog = false;
  showRegisterChildAccountDialog = false;
  restrictedAccess = false;
  invoiceWarningCountdown;
  childClientForm: UntypedFormGroup;
  showPwd = false;
  readOnly: Boolean = false;
  listOfPhoneCode: any = [];
  childClient: Guess = new Guess();

  showConfirmAutoPayReminderDialog;
  defaultCard;
  showErr: boolean;

  unpaidInvoices;
  totalUnPaidAmount;
  payUnPaidInvoiceDialog;
  autoPayPending;

  showOutstandingBalancePayDialog = false;
  outstandingBalancePayForm: UntypedFormGroup;
  outstandingBalancePay: any = {
    paymentMethod: null,
    invoice: null,
    amount: null,
    paymentDate: null,
    note: null,
    invoices: []
  };

  paymentMethod = [
    { label: 'Zelle', value: 'zelle' },
    { label: 'Check', value: 'check' },
    { label: 'Other', value: 'other' }
  ];
  uploadingImage = false;
  balanceWaringDialog = false;

  visibleImage;
  previewImage;

  isQuotePreviewPage = false;
  isPayrollPreviewPage = false;
  isDispatchPotentialClientPage = false;
  isFidelisPotentialClientPage = false;
  isFidelisLookbackPotentialClientPage = false;
  isMasPotentialClientPage = false;

  isDispatchClientPage = false;
  isFidelisClientPage = false;
  isFidelisLookbackClientPage = false;
  isMasClientPage = false;

  quotePreviewId;
  payrollPreviewId;
  potentialClientId;
  clientId;
  showConfirmDialogImage = false;
  freezePageWhenAway = false;
  outstandingBalancePayProcessing = false;

  showConfirmPaymentDialog;
  uploadProofOfPaymentDialog;
  isLockedTrustPayment = false;

  subscriptions: Subscription = new Subscription();
  
  isResetPasswordUrl: Boolean = false;
  maxPaymentDate = new Date();

  isSignInOut: Boolean = false;

  routerEventsSubscribe: Subscription;

  constructor(
    public renderer: Renderer2,
    public zone: NgZone,
    public authService: AuthService,
    private router: Router,
    private messageService: MessageService,
    public allMenus: AllMenusService,
    public employeeClockingService: EmployeeClockingService,
    public employeeService: EmployeeService,
    private idleService: IdleService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private location: Location,
    private taskManagementService: TaskManagementService,
    private paymentCardDefaultService: PaymentCardDefaultService,
    private paymentProfileService: PaymentProfileService,
    private paymentInvoiceService: PaymentInvoiceService,
    private clientService: ClientService,
    private companySettingService: CompanySettingServiceV2,
    private paymentNotificationService: PaymentNotificationService,
    private documentService: DocumentsService,
    private firebaseNotificationService: FirebaseNotificationService,
    private db: AngularFireDatabase
  ) {
    let currentLang = 'en';
    if (this.cookieService.get('_currentLang')) {
      currentLang = this.cookieService.get('_currentLang');
    }
    translate.setDefaultLang(currentLang);
    translate.use(currentLang);

    this.cookieService.set('_currentLang', currentLang);
    idleService.init(); // important

    this.allMenus.getThemeConfig();
    this.isAuthorized = this.authService.authObservable;
    this.isAuthorized8 = this.authService.authObservable8;
    // Below code was and commented placed in login component after login click because when user refresh any page this code get them back on dashboard.
    // if (this.authService.adminRole()) {
    //     this.router.navigate(['app/dashboard']);
    // } else if (this.authService.getRoleLevel() === 7) {
    //     this.router.navigate(['app/employee/view', this.authService.getCurrentLoggedInId()]);
    // } else if (this.authService.getRoleLevel() === 8) {
    //     this.router.navigate(['app/employee-clocking/punch']);
    // }
    if (this.authService.getRoleLevel() === 8) {
      this.router.navigate(['app/employee-clocking/punch']);
      this.punchInOut = true;
    }

    // scheduler

    // update latest pin number to PinList every 2 hours at 23 mins past the hour
    const jobFindAllPinNumber = new CronJob({
      cronTime: '0 23 */1 * * *',
      onTick: function () {
        employeeService.findAllPinNumberWithoutPageable().subscribe((data: any) => {
          localStorage.removeItem('_pinInfo');
          localStorage.setItem('_pinInfo', JSON.stringify(data.data));
        });
      },
      start: false,
      timeZone: 'America/Los_Angeles'
    });
    jobFindAllPinNumber.start();
    setInterval(() => {
      this.checkVolateEmployee();
    }, 60 * 1000);




    // add employee clocking every 2 hours
    // const jobAddPunchInOutOffline = new CronJob({
    //   cronTime: '0 0 */2 * * *',
    //   onTick: function () {
    //     this.onlineCheck().then(() => {
    //       // Has internet connection, carry on
    //       const temp = localStorage.getItem('_employeeClockingInfo');
    //       if (temp) {
    //         const employeeOfflinePunchRequest = JSON.parse(temp);
    //         employeeClockingService.createPunchInOutOffline(employeeOfflinePunchRequest).subscribe((data: any) => {
    //           localStorage.removeItem('_employeeClockingInfo');
    //         });
    //       }
    //     }).catch(() => {
    //       // Has no internet connection, let the user know
    //       // do nothing
    //     });
    //   },
    //   start: false,
    //   timeZone: 'America/Los_Angeles'
    // });
    // jobAddPunchInOutOffline.start();
    this.routerEventsSubscribe = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.isQuotePreviewPage = false;
        this.isPayrollPreviewPage = false;
        this.isDispatchPotentialClientPage = false;
        this.isFidelisPotentialClientPage = false;
        this.isFidelisLookbackPotentialClientPage = false;
        this.isMasPotentialClientPage = false;

        this.isDispatchClientPage = false;
        this.isFidelisClientPage = false;
        this.isFidelisLookbackClientPage = false;
        this.isMasClientPage = false;
        if (this.employeeService.showRegisterChildAccountDialog) {
          this.employeeService.showRegisterChildAccountDialog = false;
        }
        if (this.authService.getRoleLevel() === 8) {
          this.router.navigate(['app/employee-clocking/punch']);
          this.punchInOut = true;
        }
        if (ev.url.indexOf('/login') >= 0) {
          this.vioLateTheLawDisplay = false;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/reports/dailypdf') >= 0) {
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/quote-preview') >= 0) {
          this.isQuotePreviewPage = true;
          this.isCheckURL = true;
          const urls = ev.url.split("/");
          this.quotePreviewId = urls[urls.length - 1];
        } else if (ev.url.indexOf('/payroll-journal-report-preview') >= 0) {
          this.isPayrollPreviewPage = true;
          this.isCheckURL = true;
          const urls = ev.url.split("/");
          this.payrollPreviewId = urls[urls.length - 1];
        } else if (ev.url.indexOf('/dispatch-potential-client') >= 0) {
          this.isDispatchPotentialClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/fidelis-potential-client') >= 0) {
          this.isFidelisPotentialClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/fidelis-lookback-potential-client') >= 0) {
          this.isFidelisLookbackPotentialClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/mas-potential-client') >= 0) {
          this.isMasPotentialClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/dispatch-client') >= 0) {
          this.isDispatchClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/fidelis-client') >= 0) {
          this.isFidelisClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/fidelis-lookback-client') >= 0) {
          this.isFidelisLookbackClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/mas-client') >= 0) {
          this.isMasClientPage = true;
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/ticket-summary') >= 0 && this.authService.adminRole()) {
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/contacts-interest') >= 0) {
          this.isCheckURL = true;
        } else if (ev.url.indexOf('/signing/emails') >= 0) {
          this.isCheckURL = true;
        } else if ((ev.url.indexOf('/plan/list') >= 0 || ev.url.indexOf('company/document-settings/list') >= 0 || ev.url.indexOf('/app/maintenance/list') >= 0) && (this.authService.getRoleLevel() !== 1 && this.authService.getRoleLevel() !== 2)) {
          this.isCheckURL = false;
        //} else if (ev.url.indexOf('/maintenance') >= 0 && (this.authService.getRoleLevel() !== 1 && this.authService.getRoleLevel() !== 2)) {
        } else if (ev.url.indexOf('/maintenance') >= 0 && !this.authService.adminRole()) {
            this.isCheckURL = false;
        }
        // else if (ev.url.indexOf('/app/payroll-journal-report') >= 0) {
        //   this.isCheckURL = true;
        // }
        else {
          if (this.authService.getRoleLevel() === 8 && ev.url.indexOf('/app/employee-clocking/punch') < 0) {
            this.isCheckURL = false;
          } else if (this.authService.getRoleLevel() === 8 && ev.url.indexOf('/app/employee-clocking/punch') >= 0) {
            this.isCheckURL = true;
          } else if (this.authService.isEmployeeRole()) {
            this.isCheckMenu4Role(ev.url);
          } else if (this.authService.adminRole()) {
            this.isCheckMenu4Admin(ev.url);
          }
          if (ev.url.endsWith('/app/resident/sign-in-out')) {
            this.isSignInOut = true;
          } else {
            this.isSignInOut = false;
          }
          const parseUrl = ev.url.split('/');
          if (parseUrl.length > 3 && parseUrl[2] === 'tm') {
            const parseProjectId = parseUrl[3].split('?');
            const projectId = this.taskManagementService.decodeId(decodeURIComponent(parseProjectId[0]));
            this.taskManagementService.getProjectById(projectId).subscribe(async (res: any) => {
              const data = res.data;
              const accessMember = data.acceptedUsers && data.acceptedUsers.length > 0
                ? data.acceptedUsers : [];
              // if (data.invitedUsers && data.invitedUsers.length > 0) {
              //   accessMember.push(...data.invitedUsers);
              // }
              const findAccessMember = accessMember.find(member => member.id === this.authService.getCurrentLoggedInId());
              if (findAccessMember || this.authService.isSuper() ||
                this.authService.getCurrentLoggedInId() === 0) {
                this.isCheckURL = true;
              } else {
                this.isCheckURL = false;
              }
            });
          }
          if (!this.loggedInUserMenus.includes('1012') && ev.url.startsWith('/app/job-follow-up/')) {
            this.isCheckURL = true;
          }
          if (!this.loggedInUserMenus.includes('1163') && ev.url.startsWith('/app/crm/campaign/voter')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }

          }
          if (!this.loggedInUserMenus.includes('1164') && ev.url.startsWith('/app/crm/campaign/walker')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1165') && ev.url.startsWith('/app/crm/campaign/zone-management')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole() || this.authService.isSuper() || this.authService.isSubSuper()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1166') && ev.url.startsWith('/app/crm/campaign/candidate')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }

          }
          if (ev.url.startsWith('/app/reminder')) {
              if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
                  this.isCheckURL = true;
              }

          }
          if (!this.loggedInUserMenus.includes('1165') && ev.url.startsWith('/app/ele-campaign/schedule-batch')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1169') && ev.url.startsWith('/app/crm/campaign/electoral/list')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1168') && ev.url.startsWith('/app/ele-campaign/schedule-batch')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1170') && ev.url.startsWith('/app/ele-campaign/flyer')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1172') && ev.url.startsWith('/app/vrm/customize-questions')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('2341') && ev.url.startsWith('/app/ele-campaign/visit-history')) {
            if (this.authService.isEmployeeRole() || this.authService.isCompanyAdminRole()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1176') && ev.url.startsWith('/app/company/document-settings')) {
            if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole()
                || this.authService.isSubCompanyAd() || this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('1191') && ev.url.startsWith('/app/maintenance/list')) {
                if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole()
                    || this.authService.isSubCompanyAd() || this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
                    this.isCheckURL = true;
                }
          }
          if (!this.loggedInUserMenus.includes('1056') && ev.url.startsWith('/app/notification')) {
            if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAdminRole()
                || this.authService.isSubCompanyAd() || this.authService.isAgencyAd() || this.authService.isSubAgencyAd()) {
              this.isCheckURL = true;
            }
          }
          if (!this.loggedInUserMenus.includes('85') && ev.url.startsWith('/app/reports/api-usage-report')) {
            if (this.authService.isSuper() || this.authService.isSubSuper()) {
              this.isCheckURL = true;
            }
          }
        }
        if (ev.url.indexOf('/app/dashboard') >= 0) {
          if (this.authService.isClientRole() || this.authService.isContractorRole() || this.authService.isGuestRole() || this.authService.isEmployeeRole()) {
            this.isCheckURL = false;
          } else {
            this.isCheckURL = true;
          }
        }
        if (ev.url.indexOf('/app/service-revenue/list') >= 0) {
          if (this.authService.isClientRole() || this.authService.isContractorRole() || this.authService.isGuestRole() || this.authService.isEmployeeRole()) {
            this.isCheckURL = false;
          }
        }

        if ((this.authService.isClientRole() || this.authService.isChildClientRole()) && (ev.url.indexOf('/app/job-follow-up/billing-total-job') >= 0
          || ev.url.indexOf('/app/job-follow-up/billing-report') >= 0)) {
          this.isCheckURL = true;
        }

        if ((this.authService.isClientRole() || this.authService.isChildClientRole()) && (ev.url.indexOf('/app/job-follow-up/billing-log-total-job') >= 0
          || ev.url.indexOf('/app/job-follow-up/billing-log-report') >= 0)) {
          this.isCheckURL = true;
        }

        if (this.authService.isClientRole() && (ev.url.indexOf('/app/job-follow-up/billing-details-report') >= 0
          || ev.url.indexOf('/app/job-follow-up/billing-report') >= 0)) {
          this.isCheckURL = true;
        }
        if (this.authService.isClientRole() && (ev.url.indexOf('/app/job-follow-up/billing-log-details-report') >= 0
          || ev.url.indexOf('/app/job-follow-up/billing-log-report') >= 0)) {
          this.isCheckURL = true;
        }
        if (ev.url.indexOf('/app/crm/all-card') >= 0) {
          this.isCheckURL = true;
        }
        if (this.authService.isClientRole() && (ev.url.indexOf('/app/job-follow-up/billing-upload-history') >= 0
          || ev.url.indexOf('/app/job-follow-up/billing-report') >= 0 
          || ev.url.indexOf('/app/job-follow-up/billing-client-status') >= 0 
          || ev.url.indexOf('/app/job-follow-up/billing-correction-report') >= 0)) {
          this.isCheckURL = true;
        }

        if ((this.authService.adminRole()
          || this.authService.isEmployeeRole())
          && ev.url.indexOf('/app/phonesystem/send-sms') >= 0
        ) {
          this.isCheckURL = true;
        }

        if (this.authService.isEmployeeRole()
          && ev.url.indexOf('/app/employee/list?employeeId=') >= 0
        ) {
          this.isCheckURL = true;
        }

        if (this.authService.isSuper() || this.authService.isSubSuper()) {
          if (ev.url.indexOf('/app/google-api-setting') >= 0) {
            this.isCheckURL = true;
          }
        }
        if ((this.authService.adminRole()
            || this.authService.isEmployeeRole())) {
            if (ev.url.indexOf('/app/user/view') >= 0) {
                this.isCheckURL = true;
            }
        }
        if (ev.url === '/' || ev.url === '/app') {
          // check default page with role
          if (localStorage.getItem('_user') === 'freelancer') {
            this.router.navigate(['/app/crm/freelancer/edit', localStorage.getItem('_userId')]);
          } else if (localStorage.getItem('_user') === 'client') {
            this.router.navigate(['/app/payments/invoice']);
          } else if (localStorage.getItem('_user') === 'child_client') {
            this.router.navigate(['//app/job-follow-up/billing-total-job']);
          }
          else if (localStorage.getItem('_user') === 'guess') {
            this.router.navigate(['/app/tm']);
          } else {
            this.router.navigate(['/app/dashboard']);
            if (this.authService.adminRole()) {
              this.router.navigate(['app/dashboard']);
            } else if (this.authService.getRoleLevel() === 7) {
              this.router.navigate(['app/employee-dashboard']);
            }
          }
        }

        if (ev.url.indexOf('/reset-password') >= 0) {
          this.isResetPasswordUrl = true;
          this.isCheckURL = true;
        } else {
          this.isResetPasswordUrl = false;
        }
      }
    });
  }


  checkVolateEmployee() {
    if (this.authService.isEmployeeRole()) {
      const options = <any>{};
      options.employeeIds = [];
      options.fromDate = MOMENT().startOf('day');
      options.toDate = MOMENT().endOf('day');
      options.requestFromPunchInOutPage = true;
      options.employeeIds.push(this.authService.getCurrentLoggedInId());
      options.page = 0;
      options.size = 100;
      this.employeeClockingService.punchHistoryFilter(options).subscribe(res => {
        const resObj: any = res;
        // console.log(resObj);
        if (resObj.status === 'SUCCESS') {
          this.punchHistoryList = resObj.data.content;
          for (const obj of this.punchHistoryList) {
            if (obj.deskStatus == 'Start work') {
              this.vioLateTheLawDisplay = false;
              if (obj.clockInTime) {
                const durationHours = MOMENT.duration(MOMENT().diff(MOMENT(obj.clockInTime))).asHours();
                if (durationHours >= 6) {
                  this.vioLateTheLawDisplay = true;
                  console.log(this.vioLateTheLawDisplay);
                  break;
                }
              }
            } else {
              this.vioLateTheLawDisplay = false;
            }
          }
        }
      });
    }
  }

  ngOnInit() {
    // this.outstandingBalancePay.paymentDate = new Date(this.outstandingBalancePay.paymentDate);
    // const googleMapUrl = "https://maps.googleapis.com/maps/api/js?key=" + environment.map_google_key + "&libraries=places";

    // this.loadScript(googleMapUrl).then(() => {
    //   console.log('Success');
    // })

    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
    this.zone.runOutsideAngular(() => {
      this.bindRipple();
    });

    if (this.authObservable) {
      this.authObservable.unsubscribe();
    }
    this.authObservable = this.authService.authObservable.debounceTime(1000).subscribe(e => {
      this.invoiceWarningMessage = null;
      this.employeeService.invoiceWarningDialog = false;
      this.restrictedAccess = false;
      localStorage.removeItem('_pastDueInvoice');
      this.invoiceWarningCountdown = null;
      this.employeeService.showConfirmAutoPayReminderDialog = false;
      if (!e || !this.authService.isClientRole()) {
        return;
      }
      this.checkInvoicePayment(true);
    });

    if (this.paymentChargingObservable) {
      this.paymentChargingObservable.unsubscribe();
    }
    this.paymentChargingObservable = this.paymentProfileService.paymentChargingObservable.debounceTime(1000).subscribe(e => {
      this.invoiceWarningMessage = null;
      this.employeeService.invoiceWarningDialog = false;
      this.restrictedAccess = false;
      localStorage.removeItem('_pastDueInvoice');
      this.invoiceWarningCountdown = null;
      this.employeeService.showConfirmAutoPayReminderDialog = false;
      if (!this.authService.isClientRole()) {
        return;
      }
      this.checkInvoicePayment(false);
    });

    // this.isCheckMenu4Role(this.location.path());
    // init form group
    this.childClientForm = new UntypedFormGroup({
      email: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      clientChildUsername: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required]),
      childClientPassword: new UntypedFormControl({ value: '', disabled: this.readOnly }, [Validators.required])
    });

    this.outstandingBalancePayForm = new UntypedFormGroup({
      paymentMethod: new UntypedFormControl({ value: null }, [Validators.required]),
      invoiceNumber: new UntypedFormControl({ value: null }, [Validators.required]),
      amount: new UntypedFormControl({ value: null }, [Validators.required]),
      paymentDate: new UntypedFormControl({ value: new Date() }, [Validators.required]),
      note: new UntypedFormControl({ value: null })
    });

    if (this.authService.isClientRole()) {
      this.subscriptions.add(this.db.object(`/invoice-payment/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`).valueChanges().pipe(debounceTime(200)).subscribe((event: any) => {
        if (event) {
            const currentTimestamp = new Date().getTime();
            if (event.updateTime > currentTimestamp - 15000) {
              this.checkInvoicePayment(false);
            }
        }
      }));
    }
  }

  checkDefaultPayment(unpaidInvoices: any[], forkCheck?) {
    if (!forkCheck && (!unpaidInvoices || unpaidInvoices.length == 0)) {
      return;
    }
    forkJoin([
      this.paymentProfileService.getUserCardList(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType()),
      this.paymentProfileService.listBankAccount(this.authService.getUserInfo().adminId, this.authService.getPaymentUserType())
    ]).subscribe((details: any[]) => {
      if ((details[0].data && details[0].data && details[0].data.length > 0)
        || (details[1].data && details[1].data && details[1].data.length > 0)) {
        this.paymentCardDefaultService.getDefaultCard({
          roleId: this.authService.getRoleLevel(),
          userId: this.authService.getCurrentLoggedInId(),
          companyId: this.authService.getCurrentCompanyId()
        }).subscribe(r => {
          const resObj: any = r;
          if (resObj.status === 'SUCCESS' && resObj.data && (resObj.data.cardId || resObj.data.bankId)) {
            if (resObj.data.autoPay) {
              if (this.authService.isClientRole() && unpaidInvoices && unpaidInvoices.length > 0) {
                // let totalUnPaidAmount = 0;
                // unpaidInvoices.forEach(u => {
                //   const amount = u.totalFee - (u.paidFee ? u.paidFee : 0);
                //   totalUnPaidAmount += (amount ? amount : 0);
                // })
                // this.totalUnPaidAmount = totalUnPaidAmount;
                this.employeeService.payUnPaidInvoiceDialog = true;
              }
            } else {
              this.defaultCard = resObj.data;
              this.employeeService.showConfirmAutoPayReminderDialog = true;
            }

          }
        });
      }
    });
  }

  getChildClient() {
    const options = {
      clientId: this.authService.getUserInfo().adminId,
      companyId: this.authService.getUserInfo().companyId,
      sortField: 'id',
      sortOrder: 'DESC',
      page: 0,
      size: 999
    }

    this.clientService.getChildClientList(options).subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        // if (resObj.data.content.length > 0) {
        //   this.childClientForm.controls['email'].setValue(resObj.data.content[0].email);
        //   this.childClientForm.controls['clientChildUsername'].setValue(resObj.data.content[0].username);
        // }
        this.employeeService.showRegisterChildAccountDialog = true;
      }
    });
  }

  checkInvoicePayment(defaultPaymentCheck?) {
    const options = {
      clientId: this.authService.getUserInfo().adminId,
      companyId: this.authService.getUserInfo().companyId,
      oldInvoiceOnly: false,
      ignoreInvoiceDetails: true,
      sortField: 'id',
      sortOrder: 'DESC',
      page: 0,
      size: 999
    };
    this.paymentInvoiceService.filter(options).subscribe((invoiceReponse: any) => {
      if (invoiceReponse.status === 'SUCCESS') {
        localStorage.removeItem('_pastDueInvoice');
        this.unpaidInvoices = this.getUnpaidInvoices(invoiceReponse.data.content);
        if (this.unpaidInvoices && this.unpaidInvoices.length > 0) {
          const unpaidInvoice = this.unpaidInvoices[0];
          const dueDate = moment(unpaidInvoice.dueDate).startOf('d');
          const now = moment().startOf('d');
          const diff = dueDate.diff(now, 'd', true);
          if (diff < -1) {
            // this.invoiceWarningMessage = 'Attention: Your account is currently restricted due to overdue Outstanding balance.';
            this.invoiceWarningMessage = 'INVOICE_DUE_MESSAGE';
            this.companySettingService.getCompanySettingByCompanyId(this.authService.getUserInfo().companyId).subscribe(com => {
              if (com.status === 'SUCCESS' && com.data && com.data.clientPaymentGracePeriod) {
                localStorage.setItem('_pastDueInvoice', 'yes');
                this.restrictedAccess = true;
                this.employeeService.invoiceWarningDialog = true;
              } else {
                this.checkDefaultPayment(this.unpaidInvoices);
              }
            });

          } else if (diff === -1) {
            this.invoiceWarningMessage = 'You will have restricted access to your account in ';
            const startInvoiceCountDown = this.cookieService.get('_startInvoiceCountDown');
            if (startInvoiceCountDown) {
              const jsonObj = JSON.parse(startInvoiceCountDown);
              if (jsonObj && jsonObj.clientId === this.authService.getUserInfo().adminId && jsonObj.invoiceId === unpaidInvoice.id) {
                const countDownDiff = moment().diff(moment(jsonObj.startInvoiceCountDown, 'MM/DD/YYYY HH:mm:ss'), 'seconds', true);
                if (countDownDiff <= 0) {
                  this.companySettingService.getCompanySettingByCompanyId(this.authService.getUserInfo().companyId).subscribe(com => {
                    if (com.status === 'SUCCESS' && com.data && com.data.clientPaymentGracePeriod) {
                      this.restrictedAccess = true;
                      localStorage.setItem('_pastDueInvoice', 'yes');
                      // this.invoiceWarningMessage = 'Attention: Your account is currently restricted due to overdue Outstanding balance.';
                      this.invoiceWarningMessage = 'INVOICE_DUE_MESSAGE'
                    }
                  });
                } else {
                  this.invoiceWarningCountdown = 7200 - countDownDiff;
                }
              } else {
                this.invoiceWarningCountdown = 7200;
                const entity = {
                  clientId: this.authService.getUserInfo().adminId,
                  invoiceId: unpaidInvoice.id,
                  startInvoiceCountDown: moment().format('MM/DD/YYYY HH:mm:ss')
                }
                this.cookieService.set('_startInvoiceCountDown', JSON.stringify(entity));
              }
            } else {
              this.invoiceWarningCountdown = 7200;
              const entity = {
                clientId: this.authService.getUserInfo().adminId,
                invoiceId: unpaidInvoice.id,
                startInvoiceCountDown: moment().format('MM/DD/YYYY HH:mm:ss')
              }
              this.cookieService.set('_startInvoiceCountDown', JSON.stringify(entity));
            }
            this.checkDefaultPayment(this.unpaidInvoices);
          } else if (diff === 0) {
            this.invoiceWarningMessage = 'Your invoice is due for today ' + moment(dueDate).format('MM/DD/YYYY');
            this.checkDefaultPayment(this.unpaidInvoices);
          } else if (diff <= 5) {
            this.invoiceWarningMessage = 'Your invoice is Due in ' + diff + ' Day(s), Due date for that invoice is ' + moment(dueDate).format('MM/DD/YYYY');
            this.checkDefaultPayment(this.unpaidInvoices);
          } else if (defaultPaymentCheck) {
            this.invoiceWarningMessage = null;
            this.employeeService.invoiceWarningDialog = false;
            this.checkDefaultPayment(this.unpaidInvoices);
          }
        } else {
          this.invoiceWarningMessage = null;
          this.employeeService.invoiceWarningDialog = false;
        }

        if (this.invoiceWarningMessage) {
          // this.clientService.get(this.authService.getUserInfo().adminId).subscribe((clientRes: any) => {
          //   this.isLockedTrustPayment = clientRes.data.trustPaymentStatus === 'LOCKED';
          // }, () => this.isLockedTrustPayment = false);
          this.companySettingService.getCompanySettingByCompanyId(this.authService.getCurrentCompanyId()).subscribe(settingRes => {
            this.isLockedTrustPayment = settingRes?.data?.alreadyPaid === 'Active';
          }, () => this.isLockedTrustPayment = false);
        }
      }
    });
  }

  getUnpaidInvoices(invoices: any[]) {
    this.totalUnPaidAmount = 0

    if (!invoices || invoices.length <= 0) {
      return null;
    }
    invoices = invoices.sort((a, b) => a.id - b.id);
    // return invoices.filter(i => ((i.fee - (i.paidFee ? i.paidFee : 0) > 0) && ((i.fee - (i.paidFee ? i.paidFee : 0)).toFixed(2) !== '0.00')) && !(i.status === 'Pending' && i.pendingTransactionId));
    this.totalUnPaidAmount = invoices.filter(i => i.status === 'Unpaid' || i.status === 'Partial Paid').reduce((total, i) => total += ((i.totalFee || 0) - (i.paidFee || 0)), 0)
    return invoices.filter(i => i.status === 'Unpaid' || i.status === 'Partial Paid');
  }

  isCheckMenu4Role(url: string) {
    this.menuListAfter = [];
    this.menuList = [];
    this.dashBoardForRole = {};
    this.menuListBefore = [];
    this.menuListAfter.push('/');
    this.menuListAfter.push('/login');
    this.menuListAfter.push('/app/email');
    this.menuListAfter.push('/reports/dailypdf');
    this.menuListAfter.push('/app/note');
    this.menuListAfter.push('/reset-password');
    this.menuListAfter.push('/forgot-password');
    this.menuListAfter.push('/app/faq');
    this.getMenuByRole(this.authService.getLoggedInUserMenuList(), 'menuList');
    for (let i = 0; i < this.menuList.length; i++) {
      this.menuList[i] = this.menuList[i] && this.menuList[i].replace('///', '/').replace('//', '/');
    }
    this.menuListAfter.indexOf(this.location.path());
    this.menuListAfter.push(...this.menuList);
    this.menuListAfter.push(this.dashBoardForRole);
    if (this.menuListAfter.indexOf(url) < 0) {
      const partUrl = url.split('/');
      const concatUrl = partUrl[1] + '/' + partUrl[2];
      if (concatUrl === 'app/employee' || concatUrl === 'app/employee-clocking') {
        if (partUrl.length > 3 && (partUrl[3] === 'view'
          || partUrl[3] === 'edit'
          || partUrl[3] === 'list')) {
          this.isCheckURL = false;
        } else if (this.menuListAfter.indexOf(concatUrl + '/' + partUrl[3]) >= 0) {
          this.isCheckURL = true;
        } else {
          this.isCheckURL = this.checkMenuLogin(this.menuListAfter, concatUrl, url, 'menuList');
        }
      } else {
        this.isCheckURL = this.checkMenuLogin(this.menuListAfter, concatUrl, url, 'menuList');
      }
      if (url.indexOf('app/note') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/task-management') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/tm') >= 0) {
        this.isCheckURL = true;
      }
    } else {
      if (this.loggedInUserMenus.indexOf('21') > -1 && (url.indexOf('/app/employee/add') >= 0
        || url.indexOf('/app/employee/payment') >= 0)) {
        this.isCheckURL = false;
      } else {
        this.isCheckURL = true;
      }
    }
  }

  checkMenuLogin(menuList, concatUrl, url, type) {
    for (const menu of menuList) {
      if (menu && typeof menu === 'string') {
        const partMenu = menu.split('/');
        const concatMenu = partMenu[1] + '/' + partMenu[2];
        if (concatMenu.indexOf(concatUrl) >= 0) {
          if (!this.loggedInUserMenus.includes('113') && url.startsWith('/app/billings/dispatch/')) {
            return false;
          }
          return true;
        }
      }
    }
    if (this.checkMenIdExisted(url)) {
      return this.checkMenIdExisted(url);
    }
    return false;
  }

  checkMenuEditLogin(menuList, concatUrl, url, type) {
    for (const menu of menuList) {
      if (menu && menu !== '') {
        const partMenu = menu.split('/');
        const concatMenu = partMenu[1] + '/' + partMenu[2];
        if (concatMenu.indexOf(concatUrl) >= 0) {
          const concatURl = url.includes('edit') ? url.split('edit') : [];
          if (type !== 'menuList' && concatURl.length > 0) {
            return this.checkEditMenuList(concatURl, this.menuEditList, url);
          }
          const concatURLAdd = url.includes('add') ? url.split('add') : [];
          if (type !== 'menuList' && concatURLAdd.length > 0) {
            return this.checkEditMenuList(concatURLAdd, this.menuListAfter, url);
          }
          if (this.checkMenIdExisted(url)) {
            return this.checkMenIdExisted(url);
          }
          return false;
        }
      }
    }
    return false;
  }

  isCheckMenu4Admin(url: String) {
    console.log(url);
    this.menuListAfter = [];
    this.menuList = [];
    this.dashBoardForRole = {};
    this.menuListBefore = [];
    this.menuListAfter.push('/');
    this.menuListAfter.push('/login');
    this.menuListAfter.push('/app/email');
    this.menuListAfter.push('/app/note');
    this.menuListAfter.push('/reset-password');
    this.menuListAfter.push('/reports/dailypdf');
    this.menuListAfter.push('/forgot-password');
    this.menuListAfter.push('/app/faq');
    this.menuListAfter.push('/app/resume');
    this.menuListAfter.push('/app/stock/stock-used/edit');
    this.menuListAfter.push('/app/job-follow-up/list');
    this.menuListAfter.push('/app/share-holder/list');
    this.menuListAfter.push('/app/admin/company-admin/view');
    this.menuListAfter.push('/app/pwdmng/list');
    this.menuListAfter.push('/app/pwdmng/edit');
    const test = this.getMenuByRole(this.authService.getLoggedInUserMenuList(), 'menuList');
    for (let i = 0; i < this.menuList.length; i++) {
      if (!this.menuList[i] && this.menuList[i] !== null) {
        this.menuList[i] = this.menuList[i] && this.menuList[i].replace('///', '/').replace('//', '/');
      }
    }
    this.getMenuByRole(this.authService.getLoggedInUserEditList(), 'editList');
    this.menuListAfter.indexOf(this.location.path());
    this.menuListAfter.push(...this.menuList);
    this.menuListAfter.push(this.dashBoardForRole);
    console.log(url);
    console.log(this.menuListAfter.indexOf(url));
    if (this.menuListAfter.indexOf(url) < 0) {
      const partUrl = url.split('/');
      const concatUrl = partUrl[1] + '/' + partUrl[2];
      if (this.menuListAfter.indexOf(concatUrl + '/' + partUrl[3]) >= 0) {
        this.isCheckURL = true;
      } else {
        this.isCheckURL = this.checkMenuEditLogin(this.menuListAfter, concatUrl, url, 'editList');
      }
      if (url.indexOf('app/note') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/task-management') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/pwdmng/edit') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/tm') >= 0) {
        this.isCheckURL = true;
      }
      if (url.indexOf('app/employee') >= 0) {
        this.isCheckURL = true;
      }
    } else {
      this.isCheckURL = true;
    }
  }

  checkEditMenuList(concatURl, menuListCheck, url) {
    if (url.indexOf('/app/transaction/add') >= 0) {
      if (!this.authService.getLoggedInUserMenuList().includes('85')) {
        return false;
      } else {
        return true;
      }
    }
    if (url.indexOf('/app/employee/add') >= 0) {
      if (!this.authService.getLoggedInUserMenuList().includes('99')) {
        return false;
      } else {
        return true;
      }
    }
    if (url.startsWith('/app/transaction-type/edit')) {
      if (!this.authService.getLoggedInUserEditList().includes('82')) {
        return false;
      } else {
        return true;
      }
    }
    if (menuListCheck.indexOf(concatURl[0] + 'list') >= 0) {
      return true;
    }
    if (this.checkMenIdExisted(url)) {
      return this.checkMenIdExisted(url);
    }
    return false;
  }

  checkMenIdExisted(url) {
    let splitUrl = url.includes('list') ? url.split('list') :
      (url.includes('add') ? url.split('add') : (url.includes('edit') ? url.split('edit') : url));
    if (splitUrl !== url) {
      splitUrl = splitUrl[0] + 'list';
    }
    let findMenuId: any;
    findMenuId = (this.menuListData.menus||[]).find(menu => menu && menu.routerLink === splitUrl);
    if (splitUrl.includes('/app/conference') && !findMenuId) {
      findMenuId = (this.menuListData.menus||[]).find(menu => menu && menu.routerLink === '/app/conference');
    }
    if (!findMenuId || (findMenuId && this.authService.getLoggedInUserMenuList().includes(findMenuId.id + ''))) {
      return true;
    }
    return false;
  }

  bindRipple() {
    this.rippleInitListener = this.init.bind(this);
    document.addEventListener('DOMContentLoaded', this.rippleInitListener);
  }

  init() {
    this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
    document.addEventListener('mousedown', this.rippleMouseDownListener, false);
  }

  rippleMouseDown(e) {
    for (let target = e.target; target && target !== this; target = target['parentNode']) {
      if (!this.isVisible(target)) {
        continue;
      }

      // Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
      if (this.selectorMatches(target, '.ripplelink, .ui-button')) {
        const element = target;
        this.rippleEffect(element, e);
        break;
      }
    }
  }

  selectorMatches(el, selector) {
    const p = Element.prototype;
    const f = p['matches'] || p['webkitMatchesSelector'] || p['mozMatchesSelector'] || p['msMatchesSelector'] || function (s) {
      return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
    };
    return f.call(el, selector);
  }

  isVisible(el) {
    return !!(el.offsetWidth || el.offsetHeight);
  }

  rippleEffect(element, e) {
    if (element.querySelector('.ink') === null) {
      const inkEl = document.createElement('span');
      this.addClass(inkEl, 'ink');

      if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
        element.querySelector('span').insertAdjacentHTML('afterend', '<span class=\'ink\'></span>');
      } else {
        element.appendChild(inkEl);
      }
    }

    const ink = element.querySelector('.ink');
    this.removeClass(ink, 'ripple-animate');

    if (!ink.offsetHeight && !ink.offsetWidth) {
      const d = Math.max(element.offsetWidth, element.offsetHeight);
      ink.style.height = d + 'px';
      ink.style.width = d + 'px';
    }

    const x = e.pageX - this.getOffset(element).left - (ink.offsetWidth / 2);
    const y = e.pageY - this.getOffset(element).top - (ink.offsetHeight / 2);

    ink.style.top = y + 'px';
    ink.style.left = x + 'px';
    ink.style.pointerEvents = 'none';
    this.addClass(ink, 'ripple-animate');
  }

  hasClass(element, className) {
    if (element.classList) {
      return element.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
    }
  }

  addClass(element, className) {
    if (element.classList) {
      element.classList.add(className);
    } else {
      element.className += ' ' + className;
    }
  }

  removeClass(element, className) {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();

    return {
      top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
      left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0),
    };
  }

  unbindRipple() {
    if (this.rippleInitListener) {
      document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
    }
    if (this.rippleMouseDownListener) {
      document.removeEventListener('mousedown', this.rippleMouseDownListener);
    }
  }

  ngOnDestroy() {
    if (this.authObservable) {
      this.authObservable.unsubscribe();
    }
    if (this.paymentChargingObservable) {
      this.paymentChargingObservable.unsubscribe();
    }
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
    this.unbindRipple();
  }

  onWrapperClick() {
    if (!this.menuClick && !this.menuButtonClick) {
      this.mobileMenuActive = false;
    }

    if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
      this.topbarMenuActive = false;
      this.activeTopbarItem = null;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isOverlay()) {
        this.resetMenu = true;
      }

      this.menuHoverActive = false;
    }


    this.menuClick = false;
    this.menuButtonClick = false;
    this.topbarMenuClick = false;
    this.topbarMenuButtonClick = false;
  }

  onMenuButtonClick(event: Event) {
    this.menuButtonClick = true;

    if (this.isMobile()) {
      this.mobileMenuActive = !this.mobileMenuActive;
    }

    event.preventDefault();
  }

  onTopbarMobileMenuButtonClick(event: Event) {
    this.topbarMenuButtonClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    event.preventDefault();
  }

  onTopbarRootItemClick(event: Event, item: Element) {
    if (JSON.parse(localStorage.getItem('_disableAll')) === 1) {
      this.messageService.add({ severity: 'error', summary: 'Set Schedule', detail: 'Please set your schedule first.' });
    } else {
      if (this.activeTopbarItem === item) {
        this.activeTopbarItem = null;
      } else {
        this.activeTopbarItem = item;
      }
      //set freeze page when click Away from desk
      // if (item !== null && item.getElementsByClassName('topbar-icon') !== null && item.getElementsByClassName('topbar-icon')[0].innerHTML === "hourglass_full") {
      if (item !== null && item.getElementsByClassName('topbar-item-name')
        && item.getElementsByClassName('topbar-item-name')[0] 
        && item.getElementsByClassName('topbar-item-name')[0].innerHTML === "Away Message"
      ) {
        this.freezePageWhenAway = true;
      } else {
        this.freezePageWhenAway = false;
      }
      event.preventDefault();
    }
  }

  onTopbarMenuProfileClick(event: Event) {
    // this.router.navigate(['app/admin/platform-admin/view/1']);
    const userId = this.authService.getCurrentLoggedInId();
    const roleLevel = this.authService.getRoleLevel();
    switch (roleLevel) {
      case 1:
        this.router.navigate(['app/admin/platform-admin/view/' + userId]);
        break;
      case 3:
      case 5:
        this.router.navigate(['app/admin/agency-admin/edit/' + userId]);
        break;
      case 4:
      case 6:
        this.router.navigate(['app/admin/company-admin/view/' + userId]);
        break;
      case 7:
        this.router.navigate(['app/employee/view/' + userId]);
        break;
      case 9:
        this.router.navigate(['app/crm/client/edit/' + userId]);
        break;
      case 10:
        this.router.navigate(['app/guest/view/' + userId]);
        // this.router.navigate(['app/guest', 'view',  userId]);
        break;
      case 13:
        this.router.navigate(['app/crm/freelancer/edit/' + userId]);
        break;
      default:
        break;
    }
  }

  onResume(event: Event) {
    // this.router.navigate(['app/admin/platform-admin/view/1']);
    this.router.navigate(['app/resume']);
  }

  onTopbarMenuClick(event: Event) {
    this.topbarMenuClick = true;
  }

  onSidebarClick(event: Event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onToggleMenuClick(event: Event) {
    this.layoutMode = this.layoutMode !== 'static' ? 'static' : 'overlay';
    event.preventDefault();
  }

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isHorizontal() {
    return this.layoutMode === 'horizontal';
  }

  isOverlay() {
    return this.layoutMode === 'overlay';
  }

  onlineCheck() {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        resolve(true);
      };
      xhr.onerror = () => {
        reject(false);
      };
      xhr.open('GET', environment.server_backend, true);
      xhr.send();
    });
  }

  getMenuByRole(menuListData: any, type): MenuItem[] {
    let menuList = this.allMenus.getAllMenus();
    const getCookieJSON = localStorage.getItem('_globals');
    if (type === 'menuList') {
      this.loggedInUserMenus = menuListData;
    } else {
      this.loggedInUserMenusEdit = menuListData;
    }
    if (this.authService.isEmployeeRole()) {
      this.loggedInUserMenus.push('678');
      this.loggedInUserMenus.push('677');
      this.loggedInUserMenus.push('1101');
      this.loggedInUserMenus.push('500');
      this.loggedInUserMenus.push('5555');
      if (this.loggedInUserMenus.indexOf('23') > -1 || this.loggedInUserMenus.indexOf('24') > -1 ||
        this.loggedInUserMenus.indexOf('21') > 1 || this.loggedInUserMenus.indexOf('22') > -1) {
        this.menuList.push('/app/employee/protest-hour');
        this.menuList.push('/app/employee/payment');
      }
      this.loggedInUserMenus.push('1055');
    } if (this.authService.adminRole()) {
      if (this.loggedInUserMenus.includes('85')) {
        this.menuList.push('/app/transaction/add');
      }
      if (this.loggedInUserMenus.includes('99')) {
        this.menuList.push('/app/employee/add');
      }
      if (this.loggedInUserMenus.includes('23')) {
        this.menuList.push('/app/employee/clocking/punch/history');
      }
      if (this.loggedInUserMenus.includes('21')) {
        this.menuList.push('/app/employee/clocking/punch/ontime-late');
      }
      if (this.loggedInUserMenus.includes('5')) {
        this.menuList.push('/app/company/holiday');
      }
      if (this.loggedInUserMenus.includes('53')) {
        this.menuList.push('/app/stock/purchase-list');
      }
      if (this.loggedInUserMenusEdit.includes('41')) {
        this.menuEditList.push('/app/admin/groups/list');
      }
      if (this.loggedInUserMenusEdit.includes('4')) {
        this.menuEditList.push('/app/admin/agency-admin/list');
      }
      if (this.loggedInUserMenusEdit.includes('2')) {
        this.menuEditList.push('/app/admin/platform-admin/list');
      }
      this.loggedInUserMenus.push('1040');
    }

    // https://login.operrwork.com/#/app/tm/OA%3D%3D?t=1047-Employee-Group-new-Logic
    if (this.authService.isEmployeeRole()) {
      this.loggedInUserMenus = [];
      const group = this.authService.getLoggedInUserGroup();
      if (group && group.menuList && group.menuList.length > 0) {
        group.menuList.forEach(item => {
          if (!isNaN(item) && item !== 'null') {
            this.loggedInUserMenus.push(item);
          }
        });
      } else {
        return null;
      }
    }
    let getCookieObj: any;
    if (getCookieJSON) {
      getCookieObj = JSON.parse(getCookieJSON);
      if (getCookieObj.company && getCookieObj.company.serviceTypes && getCookieObj.company.serviceTypes.length) {
        let menuIds = [];
        if (!this.authService.isEmployeeRole()) {
          getCookieObj.company.serviceTypes.forEach(serviceType => {
            menuIds = menuIds.concat(ServiceType[serviceType]);
          });
        } else {
          this.dashBoardForRole = '/app/employee-dashboard';
          menuIds = menuIds.concat(ServiceType['Employee']);
        }
        if (this.authService.adminRole()) {
          this.dashBoardForRole = '/app/dashboard';
        }
        menuIds = type === 'menuList' ? menuIds.concat(getCookieObj.menuList) : menuIds.concat(getCookieObj.editList);
        menuIds = menuIds.map(Number);
        menuList.forEach(e => {
          if (e['items']) {
            e['items'] = e['items'].filter(item => menuIds.indexOf(item.menuId) > -1 || (item.uid && menuIds.indexOf(item.uid) > -1));
            e['items'].forEach(sub => {
              if (sub['items']) {
                sub['items'] = sub['items'].filter(item => menuIds.indexOf(item.menuId) > -1 || (item.uid && menuIds.indexOf(item.uid) > -1));
              }
            });
          }
        });
        menuList = menuList.filter(item => (item.uid && menuIds.indexOf(item.uid) > -1));
        const rtn = [];
        for (let i = 0; i < menuList.length; i++) {
          const menuGranted = this.isMenuAccessGranted(menuList[i], type);
          if (menuGranted) {
            rtn.push(menuGranted);
          }
        }
        return _.compact(rtn);
      } else {
        if (this.authService.isEmployeeRole()) {
          this.dashBoardForRole = '/app/employee-dashboard';
        } else if (this.authService.adminRole()) {
          this.dashBoardForRole = '/app/dashboard';
        }
        const rtn = [];
        for (let i = 0; i < menuList.length; i++) {
          const menuGranted = this.isMenuAccessGranted(menuList[i], type);
          if (menuGranted) {
            rtn.push(menuGranted);
          }
        }
        return _.compact(rtn);
      }
    } else {
      const rtn = [];
      for (let i = 0; i < menuList.length; i++) {
        const menuGranted = this.isMenuAccessGranted(menuList[i], type);
        if (menuGranted) {
          rtn.push(menuGranted);
        }
      }
      return _.compact(rtn);
    }
  }

  private isMenuAccessGranted(menuItem: any, type: any): MenuItem {
    let mni: any;
    if (menuItem.menuId && menuItem.routerLink) {
      let compareData = [];
      if (type === 'menuList') {
        compareData = this.loggedInUserMenus;
      } else {
        compareData = this.loggedInUserMenusEdit;
      }
      if (compareData.indexOf(menuItem.menuId.toString()) > -1) {
        mni = {};
        mni.label = menuItem.label;
        mni.routerLink = menuItem.routerLink;
        mni.icon = menuItem.icon;
        mni.command = menuItem.command;
        mni.id = menuItem.id;
        let linkURL = '';
        if (Array.isArray(menuItem.routerLink)) {
          for (let i = 0; i < menuItem.routerLink.length; i++) {
            linkURL += '/' + menuItem.routerLink[i];
          }
        } else {
          linkURL = menuItem.routerLink;
        }
        if (linkURL && linkURL !== null) {
          linkURL = linkURL.replace('///', '/').replace('//', '/');
        }
        if (type !== 'menuList') {
          if (this.menuEditList && this.menuEditList.length === 0) {
            this.menuEditList.push(linkURL);
          }
          if (this.menuEditList.indexOf(linkURL) <= 0) {
            this.menuEditList.push(linkURL);
          }
        } else {
          if (this.menuList && this.menuList.length === 0) {
            this.menuList.push(linkURL);
          }
          if (this.menuList.indexOf(linkURL) <= 0) {
            this.menuList.push(linkURL);
          }
        }
        this.menuListBefore.push({
          id: menuItem.menuId,
          routerLink: linkURL
        });

        if (menuItem.items && menuItem.items.length > 0) {
          mni.items = [];
          menuItem.items.forEach((it) => {
            const mnii = this.isMenuAccessGranted(it, type);
            if (mnii) {
              mni.items.push(mnii);
              let linkURLChild = '';
              if (Array.isArray(mni.routerLink)) {
                for (let i = 0; i < mni.routerLink.length; i++) {
                  linkURLChild += '/' + mni.routerLink[i];
                }
              } else {
                linkURLChild = mni.routerLink;
              }
              if (linkURLChild && linkURLChild != null) {
                linkURLChild = linkURLChild.replace('///', '/').replace('//', '/');
              }
              if (type !== 'menuList') {
                if (this.menuEditList && this.menuEditList.length === 0) {
                  this.menuList.push(linkURLChild);
                }
                if (this.menuEditList.indexOf(linkURLChild) <= 0) {
                  this.menuEditList.push(linkURLChild);
                }
              } else {
                if (this.menuList && this.menuList.length === 0) {
                  this.menuList.push(linkURLChild);
                }
                if (this.menuList.indexOf(linkURLChild) <= 0) {
                  this.menuList.push(linkURLChild);
                }
              }
              this.menuListBefore.push({
                id: mni.menuId,
                routerLink: linkURLChild
              });
            }

          });
        }

        return mni;
      } else {
        const menuList = this.menuListData.menus;
        menuList.forEach(menu => {
          if (compareData.indexOf(menu.id + '') > -1) {
            let linkURLChild = '';
            if (Array.isArray(menu.routerLink)) {
              for (let i = 0; i < menu.routerLink.length; i++) {
                linkURLChild += '/' + menu.routerLink[i];
              }
            } else {
              linkURLChild = menu.routerLink;
            }
            if (linkURLChild && linkURLChild != null) {
              linkURLChild = linkURLChild.replace('///', '/').replace('//', '/');
            }
            if (type !== 'menuList') {
              if (this.menuEditList && this.menuEditList.length === 0) {
                this.menuEditList.push(linkURLChild);
              }
              if (this.menuEditList.indexOf(menu.routerLink) <= 0) {
                this.menuEditList.push(linkURLChild);
              }
            } else {
              if (this.menuList && this.menuList.length === 0) {
                this.menuList.push(linkURLChild);
              }
              if (this.menuList.indexOf(menu.routerLink) < 0) {
                this.menuList.push(linkURLChild);
              }
            }
          }
        });
      }
    } else {
      mni = {};
      mni.label = menuItem.label;
      mni.id = menuItem.id;
      if (menuItem.command) {
        mni.command = menuItem.command;
      }
      let linkURLChild = '';
      if (Array.isArray(menuItem.routerLink)) {
        for (let i = 0; i < menuItem.routerLink.length; i++) {
          linkURLChild += '/' + menuItem.routerLink[i];
        }
      } else {
        linkURLChild = menuItem.routerLink;
      }
      if (linkURLChild && linkURLChild != null) {
        linkURLChild = linkURLChild.replace('///', '/').replace('//', '/');
      }
      if (menuItem.routerLink) {
        mni.routerLink = menuItem.routerLink;
      }
      if (this.menuList && this.menuList.length === 0) {
        this.menuList.push(linkURLChild);
      }
      if (this.menuList.indexOf(menuItem.routerLink) < 0) {
        this.menuList.push(linkURLChild);
      }

      mni.icon = menuItem.icon;
      if (menuItem.items && menuItem.items.length > 0) {
        mni.items = [];

        menuItem.items.forEach((it) => {
          const mnii = this.isMenuAccessGranted(it, type);
          if (mnii) {
            mni.items.push(mnii);
          }

        });
      }
      mni.items = _.compact(mni.items);
      if ((mni.items && mni.items.length > 0) || mni.routerLink) {
        return mni;
      }

    }
    return mni;
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log('Script Loaded');
      resolve("");
    });
  }

  getUserType() {
    return this.authService.adminRole() ? 'ADMIN' : this.authService.isEmployeeRole() ? 'EMPLOYEE' : this.authService.isGuestRole() ? 'GUEST' : 'FREELANCER';
  }

  acceptAutoPay() {
    if (this.defaultCard) {
      this.defaultCard.autoPay = true;
      this.paymentCardDefaultService.setDefaultCard(this.defaultCard).subscribe(() => {
        this.employeeService.showConfirmAutoPayReminderDialog = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auto pay active successfully' });
        this.enableAutoPayChange(true);
        this.checkDefaultPayment(this.unpaidInvoices, false);
      });
    }
  }

  rejectAutoPay() {
    this.defaultCard = null;
    this.employeeService.showConfirmAutoPayReminderDialog = false;
  }

  invoiceWarningClick() {
    this.employeeService.invoiceWarningDialog = false;
    if (this.restrictedAccess) {
      this.router.navigate(['/app/payments/detail']);
    }
    this.checkDefaultPayment(this.unpaidInvoices, true);
  }

  handleCountdownEvent(event: CountdownEvent) {
    if (CountdownStatus.done === event.status) {
      this.invoiceWarningCountdown = null;
      this.companySettingService.getCompanySettingByCompanyId(this.authService.getUserInfo().companyId).subscribe(com => {
        if (com.status === 'SUCCESS' && com.data && com.data.clientPaymentGracePeriod) {
          // this.invoiceWarningMessage = 'Attention: Your account is currently restricted due to overdue Outstanding balance.';
          this.invoiceWarningMessage = 'INVOICE_DUE_MESSAGE'
          this.restrictedAccess = true;
          localStorage.setItem('_pastDueInvoice', 'yes');
        }
      });
    }
  }

  accept() {
    if (this.showErr) {
      return this.messageService.add({
        severity: 'info', summary: 'Information',
        detail: 'Sorry, only letters (A-Z), numbers (0-9), and periods (.) are rquired for username'
      });
    }
    if (this.childClientForm.invalid) {
      return this.messageService.add({
        severity: 'error', summary: 'Invalid',
        detail: 'Please enter all required fields!'
      });
    }
    const data = {
      username: this.childClient.childClientUsername,
      password: this.childClient.childClientPassword,
      email: this.childClient.email,
      crmClientId: this.authService.getCurrentLoggedInId(),
      companyId: this.authService.getCurrentCompanyId(),
      createdByUsr: this.authService.getCurrentUsername(),
      status: 1,
    };
    this.clientService.saveChildCrmClient(data).subscribe(res => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Create Child Client Account successfully' });
        this.employeeService.showRegisterChildAccountDialog = false;
        // this.defaultEmail = res.data.email;
        // this.defaultChildUserName = res.data.username;
      }

      if (res.status === 'EXIST') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Username already exist' });
      }
    })
  }

  reject() {
    this.employeeService.showRegisterChildAccountDialog = false;
    this.outstandingBalancePayForm.reset();
    this.childClientForm.reset();
  }

  async acceptOutstandingBalancePay() {
    if (this.outstandingBalancePayProcessing) {
      return;
    }
    this.outstandingBalancePayProcessing = true;
    if (this.outstandingBalancePayForm.invalid || (this.outstandingBalancePay.paymentMethod === 'other' && (!this.outstandingBalancePay.note || this.outstandingBalancePay.note === ''))) {
      this.outstandingBalancePayProcessing = false;
      return this.messageService.add({
        severity: 'error', summary: 'Invalid',
        detail: 'Please enter all required fields!'
      });
    }

    if (this.outstandingBalancePay.amount <= 0) {
      this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Invalid amount number' });
      this.outstandingBalancePayProcessing = false;
      return;
    }

    let totalUnPaidAmount = this.outstandingBalancePay.amount;
    let paidAmount = 0;
    let paidInvoiceNumbers = '';
    let paidCount = 0;
    for (let invoice of this.outstandingBalancePay.invoices) {
      if (totalUnPaidAmount <= 0) {
        break;
      }
      const unpaidInvoiceAmount = invoice.totalFee - (invoice.paidFee ? invoice.paidFee : 0);
      const amount = unpaidInvoiceAmount > totalUnPaidAmount ? totalUnPaidAmount : unpaidInvoiceAmount;
      const data = {
        userId: this.authService.getCurrentLoggedInId(),
        userType: this.authService.getPaymentUserType(),
        companyId: this.authService.getCurrentCompanyId(),
        createdByUsr: this.authService.getCurrentUsername(),
        paymentMethod: this.outstandingBalancePay.paymentMethod,
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        amount: amount,
        balance: unpaidInvoiceAmount,
        paymentDate: this.outstandingBalancePay.paymentDate,
        note: this.outstandingBalancePay.note,
        documentUrl: this.outstandingBalancePay.documentUrl
      };
      try {
        const invoiceRes = await this.paymentInvoiceService.outstandingBalancePay(data).toPromise();
        if (invoiceRes) {
          totalUnPaidAmount = totalUnPaidAmount - amount;
          paidCount += 1;
          paidAmount += amount;
          if (paidInvoiceNumbers == '') {
            paidInvoiceNumbers += invoice.invoiceNumber;
          } else {
            paidInvoiceNumbers += ', ' + invoice.invoiceNumber;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    if (paidCount) {
      this.employeeService.showOutstandingBalancePayDialog = true;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Pay Outstanding Balance successfully' });
      const payload = {
        userId: this.authService.getCurrentLoggedInId(),
        userType: this.authService.getPaymentUserType(),
        companyId: this.authService.getCurrentCompanyId(),
        createdByUsr: this.authService.getCurrentUsername(),
        paymentMethod: this.outstandingBalancePay.paymentMethod,
        invoiceNumber: paidInvoiceNumbers,
        amount: '$' + paidAmount.toFixed(2),
        paymentDate: this.outstandingBalancePay.paymentDate,
        note: this.outstandingBalancePay.note,
        documentUrl: this.outstandingBalancePay.documentUrl,
        status: 'Pending',
        clientName: this.authService.getUserInfo().name
      };
      payload.paymentDate = moment().format('MM/DD/YYYY');
      this.paymentNotificationService.sendPendingTransactionEmail(payload, this.authService.getUserInfo().email);
      this.outstandingBalancePay = {};
      this.outstandingBalancePayForm.reset();
      this.employeeService.showOutstandingBalancePayDialog = false;
      this.sendPendingTransactionNotification(payload);
      this.checkInvoicePayment(false);
      this.outstandingBalancePayProcessing = false;
    } else {
      this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Pay Outstanding Balance failed' });
      this.outstandingBalancePayProcessing = false;
    }
  }

  rejectOutstandingBalancePay() {
    this.employeeService.showOutstandingBalancePayDialog = false;
    this.outstandingBalancePayForm.reset();
  }

  showPassword() {
    this.showPwd = !this.showPwd;
  }

  isCharacter(evt) {
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    if (format.test(this.childClient.childClientUsername)) this.showErr = true;
    else this.showErr = false;
  }

  isWhiteSpace(evt) {
    if (evt.which === 32) evt.preventDefault();
  }

  openDialogDelete() {
    this.showConfirmDialogImage = true;
  }

  acceptDelete() {
    this.outstandingBalancePay.documentUrl = '';
    this.showConfirmDialogImage = false;
  }
  closeDeleteDialog() {
    this.showConfirmDialogImage = false;
  }

  acceptPayUnPaidInvoice() {
    this.autoPayPending = true;
    const payload = {
      clientId: this.authService.getUserInfo().adminId,
      companyId: this.authService.getUserInfo().companyId,
      invoices: this.unpaidInvoices
    }
    this.paymentInvoiceService.autoPayInvoices(payload).subscribe((rp: any) => {
      this.employeeService.payUnPaidInvoiceDialog = false;
      this.autoPayPending = false;
      if (rp.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully' });
        this.sendNewPaymentTransactionNotification(payload);
        this.checkInvoicePayment();
      } else {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: rp.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'ERROR', detail: error.message })
      this.autoPayPending = false;
    });
  }

  openOutstandingBalancePayDialog() {
    this.maxPaymentDate = new Date();
    this.outstandingBalancePay.paymentDate = new Date();
    this.outstandingBalancePayForm.get('paymentDate').setValue(this.outstandingBalancePay.paymentDate);
    this.employeeService.showOutstandingBalancePayDialog = true;
  }

  outstandingBalanceInvoiceChange(event) {
    this.outstandingBalancePay.amount = 0;
    if (this.outstandingBalancePay.invoices && this.outstandingBalancePay.invoices.length > 0) {
      this.outstandingBalancePay.invoices.forEach((invoice) => {
        this.outstandingBalancePay.amount += invoice.totalFee - (invoice.paidFee ? invoice.paidFee : 0);
      });
      this.outstandingBalancePay.amount = this.outstandingBalancePay.amount.toFixed(2);
    }

    if (event.value.length === this.unpaidInvoices.length) {
      const x = document.getElementById('id_invoices_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = 'All';
    } else {
      const x = document.getElementById('id_invoices_to_selected_label').getElementsByClassName('ui-multiselect-label').item(0);
      x.textContent = `${event.value.length} items selected`;
    }
  }

  uploadDocumentImg(event, img, auto?) {
    if (event.files[0]) {
      this.uploadingImage = true;
      this.documentService.uploadFile(event.files[0], 'pendingTransactionDocument', this.authService.getUserInfo().adminId, 'PendingTransaction').subscribe(res => {
        this.uploadingImage = false;
        const resObj: any = res;
        if (resObj.status === 'SUCCESS') {
          this.outstandingBalancePay.documentUrl = resObj.data.fileUrl;
          img.clear();
          if (auto) {
            this.uploadProofOfPaymentDialog = false;
            this.validateOutstandingBalancePay();
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'ERROR', detail: res.message });
        }
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: error.message });
        this.uploadingImage = false;
      });
    }
  }

  calculateBalance() {
    const totalAmount = this.outstandingBalancePay.invoices.map(invoice => invoice.totalFee - (invoice.paidFee ? invoice.paidFee : 0)).reduce((o1, o2) => o1 + o2);
    if (this.outstandingBalancePay.amount > totalAmount) {
      this.outstandingBalancePay.amount = totalAmount;
      this.employeeService.balanceWaringDialog = true;
    } else {
      this.employeeService.balanceWaringDialog = false;
    }
  }

  sendPendingTransactionNotification(transaction) {
    this.firebaseNotificationService.saveFirebaseNotification(`/latest-pending-transaction/${this.authService.getCurrentCompanyId()}`, {
      ...transaction,
      updateTime: new Date().getTime()
    }).subscribe();
  }

  sendNewPaymentTransactionNotification(transaction) {
    this.firebaseNotificationService.saveFirebaseNotification(`/latest-transaction/${this.authService.getCurrentCompanyId()}`, {
      ...transaction,
      updateTime: new Date().getTime()
    }).subscribe();
  }

  formatCurrency(data) {
    let value;
    if (Number(data)) {
      value = Number(data).toFixed(2);
    } else {
      value = '0.00';
    }
    this.outstandingBalancePay.amount = value;
  }

  enableAutoPayChange(enable: boolean) {
    this.firebaseNotificationService.saveFirebaseNotification(`/auto-pay/${this.authService.getCurrentCompanyId()}/${this.authService.getUserInfo().adminId}`, {
      enable: enable,
      updateTime: new Date().getTime()
    }).subscribe();
  }

  hideAllDialog() {
    this.invoiceWarningDialog = false;
    this.balanceWaringDialog = false;
    this.showConfirmAutoPayReminderDialog = false;
    this.payUnPaidInvoiceDialog = false;
    this.showRegisterChildAccountDialog = false;
    this.showOutstandingBalancePayDialog = false;
    this.employeeService.invoiceWarningDialog = false;
    this.employeeService.balanceWaringDialog = false;
    this.employeeService.showConfirmAutoPayReminderDialog = false;
    this.employeeService.payUnPaidInvoiceDialog = false;
    this.employeeService.showRegisterChildAccountDialog = false;
    this.employeeService.showOutstandingBalancePayDialog = false;
  }

  validateOutstandingBalancePay() {
    if (!this.outstandingBalancePay.documentUrl) {
      this.uploadProofOfPaymentDialog = true;
      return;
    }
    this.showConfirmPaymentDialog = true;
  }

  acceptConfirmPayment() {
    this.closeConfirmPayment();
    this.acceptOutstandingBalancePay();
  }

  closeConfirmPayment() {
    this.showConfirmPaymentDialog = false;
  }

  onClickMyMessage() {
    this.router.navigate(['/app/phonesystem/my-message']);
  }
}
