<p-toast position="bottom-right">{{msgs}}</p-toast>
<h1 class="m-0 ml-2 my-4">{{'Time Corrections' | translate}}</h1>
<p-panel [toggleable]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-magnifying-glass text-primary mr-2"></i>{{'searchForRequests' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-6 ui-sm-12" *ngIf="isPlatformAdmin">
			<label>{{'companyLabel' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-building"></i>
					</button>
				</div>
				<p-dropdown [filter]="true" emptyFilterMessage="{{'No results found' | translate}}" [options]="allCompanyList" optionLabel="value" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="selectedCompany" (onChange)="loadBasedOnCompanyId()" [disabled]="isCompanyDisabled">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'reason' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-triangle-exclamation"></i>
					</button>
				</div>
				<p-dropdown [options]="reasonOptions" [showClear]="true" showClear="true" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="selectedReason">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'Date Range' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-calendar-week"></i>
					</button>
				</div>
				<mat-form-field >
					<mat-date-range-input [rangePicker]="picker">
							<input readonly matStartDate matInput placeholder="{{'StartDate' | translate}}" [(ngModel)]="startDate" name="startDate" (focus)="picker.open()">
							<input readonly matEndDate matInput placeholder="{{'EndDate' | translate}}" [(ngModel)]="endDate" name="endDate" (focus)="picker.open()">
					</mat-date-range-input>
					<mat-date-range-picker #picker></mat-date-range-picker>
				</mat-form-field>
			</div>
		</div>
		<div class="ui-g-6 ui-sm-12">
			<label>{{'form.status' | translate}}</label>
			<div class="input-group w-100">
				<div class="input-group-prepend p-0">
					<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
						<i class="fa-solid fa-info"></i>
					</button>
				</div>
				<p-dropdown [options]="statusList" placeholder="{{'placeholder.pleaseSelect' | translate}}" [(ngModel)]="selectedStatus">
					<ng-template pTemplate="item" let-item>
						<span class="text-truncate">
							<i *ngIf="item.label === 'Approved'" class="fa-solid fa-circle-check text-success mr-1"></i>
							<i *ngIf="item.label === 'Rejected'" class="fa-solid fa-circle-xmark text-danger mr-1"></i>
							<i *ngIf="item.label === 'Pending'" class="fa-solid fa-circle-info text-primary mr-1"></i>
							{{item.label | translate}}</span>
					</ng-template>
					<ng-template let-item pTemplate="selectedItem">
						<i *ngIf="item.label === 'Approved'" class="fa-solid fa-circle-check text-success mr-1"></i>
						<i *ngIf="item.label === 'Rejected'" class="fa-solid fa-circle-xmark text-danger mr-1"></i>
						<i *ngIf="item.label === 'Pending'" class="fa-solid fa-circle-info text-primary mr-1"></i>
						<span class="one-liner">{{item.label | translate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="ui-g-12 py-4 text-center">
			<button (click)="resetSearchForm()" class="btn btn-danger mx-1"><i class="fa-solid fa-xmark mr-2"></i>{{'button.reset' | translate}}</button>
			<button (click)="loadProtestHourList()" class="btn btn-primary mx-1"><i class="fa-solid fa-check mr-2"></i>{{'button.search' | translate}}</button>
		</div>
	</div>
</p-panel>
<br>
<p-panel id="table-ppanel" [toggleable]="true" [collapsed]="true">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-table text-primary mr-2"></i>{{'viewEmployeePunchHistory' | translate}} ({{totalRecords || 0 | number}})</span>
	</p-header>
	<p-table #table [value]="dataSourcePunchHistory" [responsive]="true" dataKey="id" [customSort]="true" [lazy]="true" styleClass="punch-table" (onLazyLoad)="loadPunchHistoryLazy($event)" [rows]="10"
		 [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" class="pioh-data" [(first)]="first">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumnsPunchHistory" [pSortableColumn]="col?.field">
					<span class="d-flex justify-content-between align-items-center w-100">
						<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
						<p-sortIcon *ngIf="col?.sortable" [field]="col?.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
					</span>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData>
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayColumnsPunchHistory">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'id'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'ID' | translate}}: </span>{{rowData.id}}
							</span>
						</span>
						<span *ngSwitchCase="'firstName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'First Name' | translate}}: </span>
								<span *ngIf="rowData.firstName" pTooltip="{{rowData.firstName}}">{{rowData.firstName}}</span>
								<span *ngIf="!rowData.firstName" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'lastName'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Last Name' | translate}}: </span>
								<span *ngIf="rowData.lastName" pTooltip="{{rowData.lastName}}">{{rowData.lastName}}</span>
								<span *ngIf="!rowData.lastName" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'type'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Type' | translate}}: </span>
								<span *ngIf="rowData.type" pTooltip="{{rowData.type | translate}}">{{rowData.type | translate}}</span>
								<span *ngIf="!rowData.type" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'date'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData?.date" pTooltip="{{rowData.date  | date: 'MM/dd/yyyy' }}">{{rowData.date  | date: 'MM/dd/yyyy' }}</span>
								<span *ngIf="!rowData?.date" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'punchIn'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Time In' | translate}}: </span>
								<span *ngIf="rowData.punchIn" pTooltip="{{rowData.punchIn}}">{{rowData.punchIn}}</span>
								<span *ngIf="!rowData.punchIn" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'punchOut'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Time Out' | translate}}: </span>
								<span *ngIf="rowData.punchOut" pTooltip="{{rowData.punchOut}}">{{rowData.punchOut}}</span>
								<span *ngIf="!rowData.punchOut" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'total'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span *ngIf="rowData.total" pTooltip="{{rowData.total}}">{{rowData.total}}</span>
								<span *ngIf="!rowData.total" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'overtime'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Overtime' | translate}}: </span>
								<span *ngIf="rowData.overtime" pTooltip="{{rowData.overtime}}">{{rowData.overtime}}</span>
								<span *ngIf="!rowData.overtime" class="text-muted">{{ 'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchDefault>{{ rowData[col.field] }}</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="displayColumnsPunchHistory?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="displayColumnsPunchHistory?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="footer" let-columns>
			<tr>
				<td align="right" colspan="4"><span class="mobile-table-label">{{'total' | translate}}:</span></td>
				<td>{{dataSourcePunchHistory.length}} {{'days' | translate}} </td>
				<td></td>
				<td></td>
				<td class="bold-text"><b>{{totalWorkingTime}} </b></td>
				<td class="text-danger">{{totalOvertime}}</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<div class="d-flex align-items-center justify-content-between w-100 py-4">
	<h1 class="m-0 ml-2">{{'Browse Corrections' | translate}}</h1>
	<button *ngIf="isEmployee" class="btn btn-primary" (click)="addTimeCorrection()" pTooltip="{{'Add' | translate}}"><i class="fa-solid fa-plus"></i></button>
</div>
<p-panel [toggleable]="true" id="table-ppanel">
	<p-header class="flex-grow-1">
		<span><i class="fa-solid fa-list text-primary mr-2"></i>{{'timeCorrectionList' | translate}} <span *ngIf="companySetting && companySetting.timeCorrectionPer"> [{{companySetting?.timeCorrectionPer | translate}}] </span> ({{totalRecordProtestdHour || 0 | number}})</span>
	</p-header>
	<p-table id="time-correction-requests" #dt (onFilter)="onFilter($event, dt)" [columns]="displayColumnsProtestedHour" selectionMode="single" dataKey="id" [value]="dataSourceProtestedHour" [rows]="pageSizeProtestedHour" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [totalRecords]="totalRecordProtestdHour" [responsive]="true">
		<ng-template pTemplate="caption">
			<div class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<button type="button" class="float-right btn btn-primary mx-1" (click)="exportPdf()" >
						<span class="text-nowrap"><i *ngIf="!exportingPDF" class="fa-solid fa-print mr-2"></i><i *ngIf="exportingPDF" class="fa-solid fa-spinner mr-2 loadingHourglass"></i> {{'Export PDF' | translate}}</span>
					</button>
				</div>
				<div class="input-group w-100 mr-2" style="max-width: 460px">
					<div class="input-group-prepend p-0">
						<button class="btn btn-white" id="button-addon2" type="button">
							<i class="fa-solid fa-filter"></i>
						</button>
					</div>
					<input type="text" pInputText [(ngModel)]="textSearch" placeholder="{{'Filter by Keyword' | translate}}" (input)="dt.filterGlobal($event.target.value, 'contains')">
				</div> 
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayColumnsProtestedHour" [attr.id]="col.field === 'note' ? 'note' : null" width="{{col?.width}}">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchDefault>
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">
										<i class="fa-solid fa-sort cursor-pointer text-muted ml-1 align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'date'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">
										<i class="fa-solid fa-sort cursor-pointer text-muted ml-1 align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'issueDatesText'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op3.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">
										<i class="fa-solid fa-sort cursor-pointer text-muted ml-1 align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'totalTimeCorrections'">
							<span class="d-flex justify-content-between align-items-center w-100">
								<span class="one-liner" pTooltip="{{companySetting?.timeCorrectionPer}} {{col.label | translate}}" tooltipPosition="left">{{col.label | translate}}</span>
								<span *ngIf="col.sort">
									<span (click)="col.sort == 'number'? op2.toggle($event) : op1.toggle($event); selectedField = col.field; selectedSortOption = col.sortOptions">
										<i class="fa-solid fa-sort cursor-pointer text-muted ml-1 align-bottom" aria-hidden="true"></i>
									</span>
								</span>
							</span>
						</span>
						<span *ngSwitchCase="'document'" class="text-center justify-content-center">
							<i class="fa-solid fa-upload text-muted m-auto" pTooltip="{{'Uploads' | translate}}"></i>
						</span>
						<span *ngSwitchCase="'note'" class="text-center justify-content-start">
							<span>{{'Notes' | translate}}</span>
						</span>
						<span *ngSwitchCase="'status'" class="text-center justify-content-center">
				
								<i class="fa-solid fa-info text-muted m-auto" pTooltip="{{'Status' | translate}}" tooltipPosition="left"></i>
								<span *ngIf="col.sort">
									<span (click)="op4.toggle($event); selectedField = col.field; setSortOption(col.field, col.sortOptions)">
										<i class="fa-solid fa-sort cursor-pointer align-bottom" aria-hidden="true"></i>
									</span>
								</span>
					
						</span>
						<span *ngSwitchCase="'actions'" class="text-center justify-content-center">
							<i class="fa-solid fa-bars text-muted m-auto" pTooltip="{{'Actions' | translate}}" tooltipPosition="left"></i>
						</span>
					</ng-container>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-rowIndex>
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayColumnsProtestedHour">
					<ng-container [ngSwitch]="col.field">
						<span (click)="openDetailDialog(rowData)" *ngSwitchCase="'name'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Employee' | translate}}: </span>
								<span *ngIf="rowData.name" pTooltip="{{ rowData.name }}">{{ rowData.name }}</span>
								<span *ngIf="!rowData.name" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'date'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Date' | translate}}: </span>
								<span *ngIf="rowData[col.field] && (rowData.issueDatesText.length > 0)" pTooltip="{{ rowData[col.field] | date : 'MM/d/yyyy'}}" (click)="openDetailDialog(rowData)">{{ rowData[col.field] | date : 'MM/d/yyyy'}}</span>
								<span *ngIf="rowData[col.field] && !(rowData.issueDatesText.length > 0)" pTooltip="{{ rowData[col.field] | date : 'MM/d/yyyy'}}">{{ rowData[col.field] | date : 'MM/d/yyyy'}}</span>
								<span *ngIf="!rowData[col.field]" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'reason'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Reason' | translate}}: </span>
								<span *ngIf="rowData.reason" pTooltip="{{ rowData.reason | translate }}">{{ rowData.reason | translate }}</span>
								<span *ngIf="!rowData.reason" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'issueDatesText'" class="w-100">
							<span class="d-flex w-100 align-items-center">
								<span class="mobile-table-label text-nowrap">{{'Problem Days' | translate}}: </span>
								<span *ngIf="rowData.issueDatesText.length === 1"  (click)="openDetailDialog(rowData)" class="w-100 d-flex text-primary" style="text-decoration: underline;">
									<span *ngFor="let issueDate of rowData.issueDatesText" class="one-liner" pTooltip="{{issueDate}}">{{issueDate}}</span>
								</span>
								<span *ngIf="rowData.issueDatesText.length >= 2"  (click)="openDetailDialog(rowData)" class="w-100 d-flex text-primary" style="text-decoration: underline;">
									<ng-container *ngFor="let issueDate of rowData.issueDatesText; let index = index;let first = isFirst">
										<span *ngIf="index+1===1" class="one-liner" pTooltip="{{issueDate}}">{{rowData.issueDatesText?.length || 0}} {{'Problem Days' | translate}}</span>
									</ng-container>
								</span>
								<i *ngIf="rowData.issueDatesText.length > 0" class="fa-solid fa-up-right-from-square text-primary ml-2 pull-right"></i>
								<span *ngIf="!(rowData.issueDatesText.length > 0)" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'note'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Note' | translate}}: </span>
								<span *ngIf="rowData.note" pTooltip="{{ rowData.note }}">{{ rowData.note }}</span>
								<span *ngIf="!rowData.note" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'totalTimeCorrections'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Corrections' | translate}}: </span>
								<span *ngIf="rowData.totalTimeCorrections" pTooltip="{{ rowData.totalTimeCorrections }}">{{ rowData.totalTimeCorrections }}</span>
								<span *ngIf="!rowData.totalTimeCorrections" class="text-muted">{{'no data' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'document'">
							<span class="d-flex align-items-center w-100">	
								<span class="mobile-table-label">{{'Attachment' | translate}}: </span>
								<!--<p-dropdown *ngIf="!rowData.documentId" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment" [options]="this.noFileOptions"-->
									<!--[style]="{'background-image' : 'none','font-size':'19px', 'border':'0','background': 'transparent', 'width': '20px', 'height': '20px', 'max-height':'20px'}" (onChange)="onChangeUploadOption($event, rowData)"-->
									<!--optionLabel="label" appendTo="body"  [autoWidth]="false" styleClass="custom-input"  pTooltip="{{'Attach File' | translate}}">-->
									<!--<ng-template pTemplate="item" let-country>-->
										<!--<span class="text-truncate">{{country.label | translate}}</span>-->
									<!--</ng-template>-->
									<!--<ng-template let-item pTemplate="selectedItem">-->
										<!--<span class="one-liner">{{item.label | translate}}</span>-->
									<!--</ng-template>-->
								<!--</p-dropdown>-->
								<!--<p-dropdown *ngIf="rowData.documentId" [(ngModel)]="rowData.uploadOption" [dropdownIcon]="this.attachment1" [options]="this.hasFileOptions"-->
									<!--[style]="{'font-size':'19px','background-image':'none', 'border':'0','background': 'transparent', 'width': '20px', 'height': '20px', 'max-height':'20px'}" optionLabel="label" (onChange)="onChangeUploadOption($event, rowData)" -->
									<!--[autoWidth]="false" appendTo="body" styleClass="custom-input" class="text-success" pTooltip="{{'Edit Attached' | translate}}">-->
									<!--<ng-template pTemplate="item" let-country>-->
										<!--<span class="text-truncate">{{country.label | translate}}</span>-->
									<!--</ng-template>-->
									<!--<ng-template let-item pTemplate="selectedItem">-->
										<!--<span class="one-liner">{{item.label | translate}}</span>-->
									<!--</ng-template>-->
								<!--</p-dropdown>-->
								<i *ngIf="!rowData?.documentId" class="fa-solid fa-paperclip text-muted" (click)="uploadFilePanel.toggle($event); selectedRecord = rowData;"></i>
								<i *ngIf="rowData?.documentId" class="fa-solid fa-paperclip text-success" (click)="uploadFilePanel.toggle($event); selectedRecord = rowData;"></i>
								<!--<input type="file" id="documentId" hidden (change)="onSelectDocument($event)">-->
							</span>
						</span>
						<span (click)="openDetailDialog(rowData)" *ngSwitchCase="'status'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Status' | translate}}: </span>
								<i *ngIf="rowData.status.toLocaleString() === 'Approved'" class="fa-solid fa-circle-check text-success" pTooltip="{{ rowData.status.toLocaleString() | translate}}"></i>
								<i *ngIf="rowData.status.toLocaleString() === 'Rejected'" class="fa-solid fa-circle-xmark text-danger" pTooltip="{{ rowData.rejectReason}}"></i>
								<i *ngIf="rowData.status.toLocaleString() === 'Pending'" class="fa-solid fa-circle-info text-primary" pTooltip="{{ rowData.status.toLocaleString() | translate}}"></i>
								<span class="mobile-table-label" style="font-weight: 400 !important"> {{ rowData.status.toLocaleString() | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'actions'" [hidden]="isEmployee">
							<span class="d-flex align-items-center">								
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i class="fa-solid fa-check mx-1" [ngClass]="isDisabledActions(rowData) ? 'text-muted' : 'text-success'" pTooltip="{{'approve' | translate}}" tooltipPosition="left" (click)="openConfirmModal(rowData, 'Approved')" [ngStyle]="isDisabledActions(rowData) ? {'pointer-events' : 'none'} : {}"></i>
								<i class="fa-solid fa-xmark mx-1" [ngClass]="isDisabledActions(rowData) ? 'text-muted' : 'text-danger'" pTooltip="{{'reject' | translate}}" tooltipPosition="left" (click)="openConfirmModal(rowData, 'Rejected')" [ngStyle]="isDisabledActions(rowData) ? {'pointer-events' : 'none'} : {}"></i>
								<i class="fa-solid fa-print text-primary mx-1" (click)="reportPrint(rowData)" pTooltip="{{'Print' | translate}}" tooltipPosition="left" style="cursor: pointer"></i>
							</span>
						</span>
						<span *ngSwitchCase="'actions'" [hidden]="!isEmployee || isApproved(rowData)">
							<span class="d-flex align-items-center">
								<span class="mobile-table-label">{{'Actions' | translate}}: </span>
								<i *ngIf="!isApproved(rowData)"  class="fa-solid fa-pen-to-square text-primary mx-1" pTooltip="{{'approve' | translate}}" tooltipPosition="left" (click)="onEdit(rowData)"></i>
								<i class="fa-solid fa-print text-primary mx-1" (click)="reportPrint(rowData)" pTooltip="{{'Print' | translate}}" tooltipPosition="left" style="cursor: pointer"></i>
								<i *ngIf="!isApproved(rowData)" class="fa-solid fa-xmark text-danger mx-1" pTooltip="{{'reject' | translate}}" tooltipPosition="left" (click)="onDelete(rowData)"></i>
							</span>
						</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="displayColumnsProtestedHour?.length" class="text-center py-5">
					<div class="text-muted my-5">{{'no data' | translate}}</div>
				</td>
			</tr>
			<tr *ngIf="loading">
				<td [attr.colspan]="displayColumnsProtestedHour?.length" class="text-center py-5">
					<div class="text-muted my-5"><i class="fa-solid fa-spinner mr-2 loadingHourglass"></i>{{'Loading...' | translate}}</div>
				</td>
			</tr>
		</ng-template>
	</p-table>
</p-panel>
<p-overlayPanel #op1 appendTo="body">
	<p-listbox [options]="textSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op2 appendTo="body">
	<p-listbox [options]="numberSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op3 appendTo="body">
	<p-listbox [options]="dateSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} text-primary mr-2"></i>
				<span>{{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>
<p-overlayPanel #op4 appendTo="body">
	<p-listbox [options]="statusSortOptions" [(ngModel)]="selectedSortOption" (onChange)="sortDataSelected(selectedSortOption)">
		<ng-template let-col pTemplate="item">
			<div class="d-flex align-items-center">
				<i class="fa-solid fa-{{col.img}} mr-2"></i>
				<span> {{col.name | translate}}</span>
			</div>
		</ng-template>
	</p-listbox>
</p-overlayPanel>


<p-dialog [closable]="true" [(visible)]="showHistoryInfoDialog" [modal]="true" [resizable]="false" [dismissableMask]="true" showEffect="fade" [style]="{'width':'90%', 'max-width':'500px', 'max-height':'85vh'}">
	<p-header>
		<span><i class="fa-solid fa-circle-exclamation mr-2"></i>{{'Time Correction' | translate}}: {{protestHourDetail.name}}</span>
	</p-header>
	<div class="ui-g px-3">
		<div class="ui-g-12 ui-sm-12">
			<div class="d-flex w-100 justify-content-between align-items-center pt-4">
				<h2 class="m-0 text-truncate">{{protestHourDetail?.reason | translate}}</h2>
				<div class="badge badge-primary" style="font-size: 16px" [ngClass]="{'badge-primary' : protestHourDetail?.status === 'Pending', 'badge-success' :  protestHourDetail?.status === 'Approved', 'badge-danger' :  protestHourDetail?.status === 'Rejected'}" >{{protestHourDetail?.status | translate}}</div>
			</div>
			<p *ngIf="protestHourDetail.note" class="mt-2 m-0" style="font-size: 16px">{{protestHourDetail?.note}}</p>
		</div>
	</div>
	<hr>
		<div class="ui-g p-3">
			<ng-container *ngFor="let col of displayColumns; let colIndex = index">
				<div *ngIf="(colIndex + 1 !== 1) && (workIn[colIndex].checkIn || protestHourDetail.workEnd[colIndex].checkOut || protestHourDetail.lunchOut[colIndex].isMultiLunch || protestHourDetail.lunchIn[colIndex].isMultiLunch || protestHourDetail.lunchOut[colIndex].lunchOut || protestHourDetail.lunchIn[colIndex].lunchIn)" class="ui-g-12 ui-sm-12">
					<div *ngIf="colIndex + 1 < 9" class="card add-shadow mb-0 p-0">
						<div class="card-header">
							<span class="one-liner" pTooltip="{{ col?.weekDay | translate}} {{ col?.dateStr | translate}}" tooltipPosition="left">
								<i class="fa-solid fa-calendar-day text-primary mr-2"></i><b>{{ col?.weekDay | translate}}</b> {{ col?.dateStr | translate}}
							</span>
						</div>
						<div class="content p-4">
							<div *ngIf="workIn[colIndex]">
								<div class="check-in d-flex">
									<span style="width: 90px;"><b>{{'Start Work' | translate}}: </b></span>
									<span *ngIf="!workIn[colIndex].checkIn" class="one-liner" [class.text-muted]="workIn[colIndex].checkInTime === 'OFF'">
										<span class="text-muted" id="noneValue">No changes</span>	
									</span>
									<span *ngIf="workIn[colIndex].checkIn" class="one-liner">
										<i class="fa-solid fa-circle-xmark text-danger mr-1"></i>
										<span *ngIf="workIn[colIndex].prevCheckInTime" class="text-danger">{{workIn[colIndex].prevCheckInTime}}</span>
										<span class="text-danger" *ngIf="!workIn[colIndex].prevCheckInTime">{{'NONE' | translate}}</span>
										<i class="fa-solid fa-right-long text-muted mx-1"></i> <i class="fa-solid fa-circle-check text-success mx-1"></i>
										<span class="text-success" pTooltip="{{protestHourDetail.lastModifiedBy ? ' by ' + protestHourDetail.lastModifiedBy : '' }}">{{ workIn[colIndex].checkInTime | translate }} </span>
									</span>
								</div>
							</div>
							<div *ngIf="protestHourDetail.lunchOut[colIndex]">
								<div class="lunch-out d-flex">
									<span style="width: 90px;"><b>{{'Start Break' | translate}}: </b></span>
									<span *ngIf="protestHourDetail.lunchOut[colIndex].isMultiLunch" class="one-liner">
										<span class="c-pointer" (click)="expandMultiLunch(protestHourDetail.lunchOut[colIndex].multiLunchIssueDate, protestHourDetail.issueDatesText)" style="cursor: pointer"><i class="fa-solid fa-circle-plus text-primary mr-1"></i>{{'Multiple Breaks' | translate}}<i class="fa-solid fa-up-right-from-square text-primary ml-1" style="font-size: 12px"></i></span>
									</span>
									<span *ngIf="!protestHourDetail.lunchOut[colIndex].isMultiLunch && !protestHourDetail.lunchOut[colIndex].lunchOut" class="one-liner" [class.text-muted]="protestHourDetail.lunchOut[colIndex].lunchOutTime === 'OFF'">
										<span class="text-muted" id="noneValue">No changes</span>
									</span>
									<span *ngIf="!protestHourDetail.lunchOut[colIndex].isMultiLunch && protestHourDetail.lunchOut[colIndex].lunchOut" class="one-liner" pTooltip="{{(protestHourDetail.lastModifiedBy ? ' by ' + protestHourDetail.lastModifiedBy : '') }}">
										<i class="fa-solid fa-circle-xmark text-danger mr-1"></i>
										<span *ngIf="protestHourDetail.lunchOut[colIndex].prevLunchOutTime" class="text-danger">{{protestHourDetail.lunchOut[colIndex].prevLunchOutTime}}</span>
										<span *ngIf="!protestHourDetail.lunchOut[colIndex].prevLunchOutTime" class="text-danger">{{'NONE' | translate}}</span>
										<i class="fa-solid fa-right-long text-muted mx-1"></i> <i class="fa-solid fa-circle-check text-success mx-1"></i>
										<span class="text-success">{{ protestHourDetail.lunchOut[colIndex].lunchOutTime | translate }} </span>
									</span>
								</div>
							</div>
							<div *ngIf="protestHourDetail.lunchIn[colIndex]">
								<div class="lunch-in d-flex">
									<span style="width: 90px;"><b>{{'End Break' | translate}}: </b></span>
									<span *ngIf="protestHourDetail.lunchIn[colIndex].isMultiLunch" class="one-liner">
										<span class="c-pointer" (click)="expandMultiLunch(protestHourDetail.lunchIn[colIndex].multiLunchIssueDate, protestHourDetail.issueDatesText)" style="cursor: pointer"><i class="fa-solid fa-circle-plus text-primary mr-1"></i>{{'Multiple Breaks' | translate}}<i class="fa-solid fa-up-right-from-square text-primary ml-1" style="font-size: 12px"></i></span>
									</span>
									<span *ngIf="!protestHourDetail.lunchIn[colIndex].isMultiLunch && !protestHourDetail.lunchIn[colIndex].lunchIn" class="one-liner" [class.text-muted]="protestHourDetail.lunchIn[colIndex].lunchInTime === 'OFF'">
										<span class="text-muted" id="noneValue">No changes</span>
									</span>
									<span *ngIf="!protestHourDetail.lunchIn[colIndex].isMultiLunch && protestHourDetail.lunchIn[colIndex].lunchIn" class="one-liner" pTooltip="{{(protestHourDetail.lastModifiedBy ? ' by ' + protestHourDetail.lastModifiedBy : '')}}">
										<i class="fa-solid fa-circle-xmark text-danger mr-1"></i>
										<span *ngIf="protestHourDetail.lunchIn[colIndex].lunchInTime" class="text-danger">{{protestHourDetail.lunchIn[colIndex].lunchInTime}}</span>
										<span *ngIf="!protestHourDetail.lunchIn[colIndex].lunchInTime" class="text-danger">{{'NONE' | translate}}</span>
										<i class="fa-solid fa-right-long text-muted mx-1"></i> <i class="fa-solid fa-circle-check text-success mx-1"></i>
										<span class="text-success">{{ protestHourDetail.lunchIn[colIndex].lunchInTime | translate }} </span>
									</span>
								</div>
							</div>
							<div *ngIf="protestHourDetail.workEnd[colIndex]">
								<div class="work-end d-flex">
									<span style="width: 90px;"><b>{{'End Work' | translate}}: </b></span>
									<span *ngIf="!protestHourDetail.workEnd[colIndex].checkOut" class="one-liner" [class.text-muted]="protestHourDetail.workEnd[colIndex].checkOutTime === 'OFF'">
										<span class="text-muted" id="noneValue">No changes</span>
									</span>
									<span *ngIf="protestHourDetail.workEnd[colIndex].checkOut" class="one-liner" pTooltip="{{(protestHourDetail.lastModifiedBy ? ' by ' + protestHourDetail.lastModifiedBy : '')}}">
										<i class="fa-solid fa-circle-xmark text-danger mr-1"></i>
										<span *ngIf="protestHourDetail.workEnd[colIndex].checkOutTime" class="text-danger">{{protestHourDetail.workEnd[colIndex].checkOutTime}}</span>
										<span *ngIf="!protestHourDetail.workEnd[colIndex].checkOutTime" class="text-danger">{{'NONE' | translate}}</span>
										<i class="fa-solid fa-right-long text-muted mx-1"></i> <i class="fa-solid fa-circle-check text-success mx-1"></i>
										<span class="text-success">{{ protestHourDetail.workEnd[colIndex].checkOutTime | translate }} </span> 
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	<p-footer class="d-flex justify-content-between">
		<span>
			<button *ngIf="!isEmployee && currentData && !(currentData.status === 'Approved' || currentData.status === 'Rejected') && isShowButton" (click)="closeDetailDialog()" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		</span>
		<span *ngIf="!isEmployee && currentData && !(currentData.status === 'Approved' || currentData.status === 'Rejected') && isShowButton">
			<button (click)="openConfirmModal(currentData, 'Rejected')" class="btn btn-danger mx-1 accept-button"><i class="fa-solid fa-thumbs-down mr-2"></i>{{'Reject' | translate}}</button>	
			<button (click)="openConfirmModal(currentData, 'Approved')" class="btn btn-success mx-1 accept-button"><i class="fa-solid fa-thumbs-up mr-2"></i>{{'Approve' | translate}}</button>
		</span>
		<button *ngIf="!(!isEmployee && currentData && !(currentData.status === 'Approved' || currentData.status === 'Rejected') && isShowButton)" (click)="closeDetailDialog()" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog header="" [(visible)]="showViewFileDialog" [responsive]="true" showEffect="fade" [style]="{width: '65vw'}" [closable]="false">
	<img [src]="fileUrl" *ngIf="fileUrl" class="imageShown" />
	<p-footer>
		<div class="ui-dialog-buttonpane ui-helper-clearfix">
			<button type="button" pButton (click)="closeViewFileDialog()" label="{{'close' | translate}}"></button>
		</div>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showConfirmDialog" class="modal_in" [modal]="true" [style]="{'width':'90%', 'max-width':'500px'}">
	<p-header>
		<span *ngIf="statusType === 'Approved'"><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Approve Item' | translate}}</span>
		<span *ngIf="statusType !== 'Approved'"><i class="fa-solid fa-triangle-exclamation mr-2"></i>{{'Reject Item' | translate}}</span>
	</p-header>
	<div class="ui-g">
		<div class="ui-g-12 p-4">
			<span *ngIf="statusType === 'Approved'" style="font-size: 16px">{{'Are you sure you want to approve this item?' | translate}}</span>
			<span *ngIf="statusType !== 'Approved'" style="font-size: 16px">{{'Are you sure you want to reject this item?' | translate}}</span>
		</div>
		<div class="ui-g-12 ui-sm-12" *ngIf="statusType !== 'Approved'">
			<label>{{'Reject Reason' | translate}}:<span class="text-danger">*</span></label>
			<textarea class="w-100 text-area" pInputTextarea [ngModelOptions]="{'standalone':true}" [rows]="4" maxlength="500" autoResize="true" [(ngModel)]="rejectReason"></textarea>
			<span class="pull-right" [ngClass]="rejectReason?.length < 1000 ? 'text-muted' : 'text-danger'" style="font-size: 12px">{{rejectReason?.length || 0}}/500</span>
		</div>
	</div>
	<p-footer class="d-flex justify-content-between">
		<button type="button" (click)="reject()" [disabled]="this.loading" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="setStatus()" [disabled]="this.loading" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>


<p-dialog header="{{'Confirmation' | translate}}" width="500px" [(visible)]="showDeleteConfirmDialog" class="modal_in"
		  [modal]='true'>
	<div class="ui-g confirm">
		Do you want to delete correction record?
	</div>
	<p-footer class="btn btn-">
		<button type="button" (click)="closeConfirmDeleteDialog()" [disabled]="this.loading" class="btn btn-danger"><i class="fa-solid fa-xmark mr-2"></i>{{'Cancel' | translate}}</button>
		<button type="button" (click)="deleteCorrectionRecord()" [disabled]="this.loading" class="btn btn-primary"><i class="fa-solid fa-check mr-2"></i>{{'Confirm' | translate}}</button>
	</p-footer>
</p-dialog>

<p-dialog [(visible)]="showDialogLunch" [modal]="true" [style]="{'width': '95%', 'max-width':'500px'}" [closable]="true" [id]="'multipleLunches'">
	<p-header>
		<span><i class="fa-solid fa-plus mr-2"></i>{{'Multiple Lunch Details' | translate}} ({{punchDate}})</span>
	</p-header>
	<p-table #table [value]="selectedRowData?.value" [rows]="(selectedRowData?.value)?.size" [paginator]="false" [lazy]="false" [responsive]="true">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayLunchColumns"  [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="loading && (i+1 === 1)">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'Loading...' | translate}}</span>
				</td>
			</tr>
			<!-- <tr *ngIf="i+1 === 2" [pSelectableRow]="rowData"> -->
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayLunchColumns;">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'id'">
							{{i + 1}}
						</span>
						<span *ngSwitchCase="'lunchStart'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Lunch #2 Start' | translate}}: </span>
								<span>{{rowData.lunchOutTime | date: 'h:mm a'}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'lunchEnd'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Lunch #2 End' | translate}}: </span>
								<span >{{rowData.lunchInTime != null?  (rowData.lunchInTime| date: 'h:mm a') : 'Missing' | translate}}</span>
							</span>
						</span>
						<span *ngSwitchCase="'lunchTot'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span>{{calculateTotalhhmmForSecondLunchNew(rowData)}}</span>
							</span>
						</span>
					</ng-container>
				</td>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<div class="p-3 w-100 text-center">
		<span style="font-size: 16px">
			<span style="font-weight: 500">{{'Combined Total' | translate}}: </span>{{calCulateTotalhhmm()}}
		</span>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showDialogLunch = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>


<p-dialog [(visible)]="showDialogMultiLunch" [modal]="true" [style]="{'width': '90%', 'max-width':'750px'}" [closable]="true" [id]="'multipleLunches'">
	<p-header>
		<span><i class="fa-solid fa-plus mr-2"></i>{{'Multiple Lunch Details' | translate}} ({{punchDateMultiLunch | date: 'MM/dd/yyyy'}})</span>
	</p-header>
	<p-table #tableMultiLunch [value]="selectedMultiLunchIssueDate" [paginator]="false" [lazy]="false" [responsive]="true">
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th *ngFor="let col of displayLunchColumns"  [ngClass]="col?.field==='action' ? 'tdwh-action' : ''" [style.width]="col.width">
					<span class="d-flex align-items-center justify-content-between">
						<span class="one-liner">{{col?.label | translate}}</span>
					</span>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-rowData let-i="rowIndex">
			<tr *ngIf="loading && (i+1 === 1)">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'Loading...' | translate}}</span>
				</td>
			</tr>
			<!-- <tr *ngIf="i+1 === 2" [pSelectableRow]="rowData"> -->
			<tr [pSelectableRow]="rowData">
				<td *ngFor="let col of displayLunchColumns;">
					<ng-container [ngSwitch]="col.field">
						<span *ngSwitchCase="'id'">
							{{i + 1}}
						</span>
						<span *ngSwitchCase="'lunchStart'" style="position: relative;">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Lunch #2 Start' | translate}}: </span>
								<span>{{rowData.lunchOutTimeDate | date: 'hh:mm a'}}</span>
								<span *ngIf="rowData.prevLunchOutTimeDate" style="color:red"> ({{rowData.prevLunchOutTimeDate ? (rowData.prevLunchOutTimeDate | date:'hh:mm a') : 'None' | translate}})</span>
							</span>
						</span>
						<span *ngSwitchCase="'lunchEnd'" style="position: relative;">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Lunch #2 End' | translate}}: </span>
								<span>{{rowData.lunchInTimeDate | date: 'hh:mm a'}}</span>
								<span *ngIf="rowData.prevLunchInTimeDate" style="color:red"> ({{rowData.prevLunchInTimeDate ? (rowData.prevLunchInTimeDate | date:'hh:mm a') : 'None' | translate}})</span>
							</span>
						</span>
						<span *ngSwitchCase="'lunchTot'">
							<span class="one-liner">
								<span class="mobile-table-label">{{'Total' | translate}}: </span>
								<span >{{rowData.lunchTimeStr}}</span>
							</span>
						</span>
					</ng-container>
				</td>
		</ng-template>
		<ng-template pTemplate="emptymessage" let-columns>
			<tr *ngIf="!loading">
				<td [attr.colspan]="5"  class="text-center py-5">
					<span class="text-muted my-5">{{'no data' | translate}}</span>
				</td>
			</tr>
		</ng-template>
	</p-table>
	<div class="p-3 w-100 text-center">
		<span style="font-size: 16px">
			<span style="font-weight: 500">{{'Combined Total' | translate}}: </span>{{combinedTotalStr}}
		</span>
	</div>
	<p-footer class="d-flex justify-content-end">
		<button class="btn btn-primary" (click)="showDialogMultiLunch = false">
			<span class="text-nowrap"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</span>
		</button>
	</p-footer>
</p-dialog>

<input type="file" id="documentId" hidden (change)="onSelectDocument($event)">
<p-overlayPanel #uploadFilePanel appendTo="body">
	<ng-template pTemplate>
		<ul class="m-0 px-1" style="list-style: none; min-width: 150px;">
			<li style="cursor: pointer;" class="px-2" *ngIf="!selectedRecord?.documentId">
				<span (click)="onChangeUploadOption(uploadFilePanel, 'Upload')" class="text-dark d-block my-2"><i class="fa-solid fa-upload text-primary mr-2"></i>{{'upload' | translate}}</span>
			</li>
			<li style="cursor: pointer;" class="px-2" *ngIf="selectedRecord?.documentId">
				<span (click)="onChangeUploadOption(uploadFilePanel, 'View')" class="text-dark d-block my-2"><i class="fa-solid fa-up-right-from-square text-primary mr-2"></i>{{'view' | translate}}</span>
			</li>
			<li style="cursor: pointer;" class="px-2"  *ngIf="selectedRecord?.documentId">
				<span (click)="onChangeUploadOption(uploadFilePanel, 'Change')" class="text-dark d-block my-2"><i class="fa-solid fa-recycle text-primary mr-2"></i>{{'change' | translate}}</span>
			</li>
			<!--<li style="cursor: pointer;" class="px-2">-->
				<!--<span (click)="viewHistoryUpload(uploadFilePanel)" class="text-dark d-block my-2"><i class="fa-solid fa-clock-rotate-left text-primary mr-2"></i> {{'history.title' | translate}}</span>-->
			<!--</li>-->
			<li style="cursor: pointer;" class="px-2" *ngIf="selectedRecord?.documentId">
				<span (click)="onChangeUploadOption(uploadFilePanel, 'Remove')" class="text-dark d-block my-2"><i class="fa-solid fa-xmark text-danger mr-2"></i>{{'remove' | translate}}</span>
			</li>
		</ul>
	</ng-template>
</p-overlayPanel>