import { AuthService } from 'app/shared/service/auth/auth.service';
import { EvvRegistered } from '../model/evv-registered';
import { AbstractService } from '../../shared/service/abstract.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../shared/model/constants';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Page } from '../../shared/model/page.model';
import { EvvSearch } from '../model/evv-search';
import { AgencyAdmin } from '../../admin/agency-admin/model/agency-admin.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EvvRegisterdService extends AbstractService<EvvRegistered> {

    private company_api_url = `${environment.server_backend}/${Constants.ROUTES.COMPANY}`;
    private agency_api_url = `${environment.server_backend}/${Constants.ROUTES.AGENCY}`;
    private admin_api_url = `${environment.server_backend}/${Constants.ROUTES.ADMIN}`;

    constructor(private http: HttpClient, private authService: AuthService) {
        super(http, Constants.ROUTES.CRM.EVV_REGISTERED, '');
    }

    saveEvvRegisterd(data: EvvRegistered): Observable<EvvRegistered> {
        return this._http.post<EvvRegistered>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}`, data).pipe(map(resp => resp));
    }

    search(options?: any): Observable<Page<EvvRegistered>> {
        const params = this.createParams(options);
        return this._http.get<Page<EvvRegistered>>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}/${Constants.ROUTES.CRM.SEARCH}`, { params: params }).pipe(map(resp => resp));
    }

    filter(options: any): Observable<Page<EvvRegistered>> {
        // return this._http.post<Page<EvvRegistered>>(`http://localhost:8010/api/v2/crm/evv-registered/search`, options).pipe(map(resp => resp));
        return this._http.post<Page<EvvRegistered>>(`${this.baseUrlV2}search`, options).pipe(map(resp => resp));
    }

    exportPdf(data): Observable<any> {
        return this._http.post(`${this.baseUrlV2}exportPdf`, data).pipe(map(resp => resp));
    }

    updateEvvRegisters(data: EvvRegistered): Observable<EvvRegistered> {
        return this._http.put<EvvRegistered>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}`, data).pipe(map(resp => resp));
    }

    getEvvRegisteredById(id: Number): Observable<EvvRegistered> {
        return this._http.get<EvvRegistered>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}/${id}`).pipe(map(resp => resp));
    }

    getALLEvvRegistered(): Observable<EvvRegistered> {
        return this._http.get<EvvRegistered>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}`).pipe(map(resp => resp));
    }

    getEvvRegisteredByPage(params: any): Observable<Page<EvvRegistered>> {
        return this._http.get<Page<EvvRegistered>>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}`, { params: params }).pipe(map(resp => resp));
    }

    deleteEvvRegisteredById(id: Number): Observable<EvvRegistered> {
        return this._http.delete<EvvRegistered>(`${environment.server_backend}/${Constants.ROUTES.CRM.EVV_REGISTERED}/${id}`).pipe(map(resp => resp));
    }

    getAllCompanyList(): Observable<any> {
        return this.http.get(`${this.company_api_url}/${Constants.URI.COMPANY.DROPDOWNLIST}`).pipe(map((resp) => resp));
    }

    getAllAdminList(): Observable<any> {
        return this.http.get(`${this.admin_api_url}/${Constants.URI.ADMIN.DROPDOWNLIST}`).pipe(map((resp) => resp));
    }

    getAllAgencyByCompanyId(companyId: any): Observable<any> {
        const sendParam: any = {};
        if (companyId) {
            sendParam.companyId = companyId;
        }
        return this.http.get(`${this.agency_api_url}/${Constants.URI.AGENCY.DROPDOWNLIST}`, { params: sendParam }).pipe(map((resp) => resp));
    }

    getEvvPhoneNumberDropdown(params: any): Observable<any> {
        return this._http.get<any>(`${this.baseUrlV2}dropdown`, { params: params }).pipe(map(resp => resp));
    }

    getAgencyAdminDropdown(params: any): Observable<any> {
        return this._http.get<any>(`${this.admin_api_url}/${Constants.URI.COMPANY.ADMINDROPDOWNLIST}`, { params: params }).pipe(map(resp => resp));
    }

    getAgencyAdmin(id: Number): Observable<AgencyAdmin> {
        return this._http.get<AgencyAdmin>(`${this.admin_api_url}/${id}`).pipe(map(resp => resp));
    }
    getEmployeeDuty(status, id, dutyType): Observable<any> {
        const request = {
            'status': status,
            'sortField': 'createdAt',
            'sortOrder': 'DESC',
            companyId: this.authService.getCurrentCompanyId()
        };
        console.log(dutyType);
        let requestId = {};
        if (id) {
            if (dutyType === 'admin') {
                requestId = { adminId: id };
            } else {
                requestId = { employeeId: id };
            }
        }
        return this.http.post(`${this.baseUrlV2}employee_on_duty`, { ...request, ...id ? requestId : {} });
    }
    getLocationByAddress(address) {
        return this.http.get(`${environment.server_backend}/crm-service/api/v2/crm/events/geolocation?address=${address}`).toPromise();
    }
    getAdminInfo(ids: any[]) {
        const url = `${environment.server_backend}/company/api/v2/admin/find-by-ids?ids=${ids.toString()}`;
        return this.http.get(url);
    }
    getEmployeeInfo(ids: any[]) {
        const req = {
            page: 0,
            size: ids.length || 1,
            ids,
        };
        const url = `${environment.server_backend}/employee/api/v2/employee/search`;
        // const url = `http://localhost:8004/api/v2/employee/search`;
        return this.http.post(url, req);
    }

    getEmployeeInfoV2(ids: any[]) {
        const req = {
            page: 0,
            size: ids.length || 1,
            ids,
            isGetAvatarUrl: true
        };
        const url = `${environment.server_backend}/employee/api/v2/employee/search`;
        // const url = `http://localhost:8004/api/v2/employee/search`;
        return this.http.post(url, req);
    }

    getWalkerQuestionnaire(visitId: number) {
        const params: any = {};
        params.visitId = visitId;
        return this._http.get<any>(`${this.baseUrlV2}walker-questionnaire`, { params: params }).pipe(map(resp => resp));
    }
}
