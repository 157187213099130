import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService, OverlayPanel} from 'primeng';
import {Message} from 'aws-sdk/clients/cloudwatch';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {CompanyService} from '../../../company/service/company.service';
import {DropDownsService} from '../../../shared/service/drop-downs/drop-downs.service';
import {PaymentInvoiceService} from '../../service/payment-invoice.service';
import {NavigationEnd, Router} from '@angular/router';
import {ExportMonitorService} from '../../../admin/process-monitor/service/export-monitor.service';
import {MonitoringDetailsService} from '../../../admin/process-monitor/service/monitoring-details.service';
import moment from 'moment';
import * as _ from 'lodash';
import {PaymentCardDefaultService} from '../../service/payment-card-default.service';
import {FileUtility} from '../../../shared/utility/file.utility';
import {Table} from 'primeng/table';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {__assign} from 'tslib';

@Component({
  selector: 'app-invoice-summary',
  templateUrl: './invoice-summary.component.html',
  styleUrls: ['./invoice-summary.component.scss']
})
export class InvoiceSummaryComponent implements OnInit {

  @ViewChild('op3', { static: true }) op3: OverlayPanel;
  @ViewChild('dt', { static: true }) table: Table;
  @ViewChild('dt1', { static: true }) detailsTable: Table;

  isPlatformAdmin = false;
  isClientRole = false;
  allCompanyList: any[] = [];
  selectedCompany: any;
  clientList: any[] = [];
  clientWithAutoPayList: any[] = [];
  fromDate: Date;
  toDate: Date;
  loading = false;
  sortField: string;
  sortOrder = 0;
  totalRecords = 0;
  allClientList: any = [];
  selectedClient;
  sortF;
  filteredValues = [];

  cols = [
    { field: 'invoiceDate', label: 'Date', sortOptions: '', sort: 'text' },
    { field: 'totalInvoices', label: 'Total Invoices', sortOptions: '', sort: 'number' },
    { field: 'invoiceType', label: 'Invoice Type', sortOptions: '', sort: 'text' },
    { field: 'totalClient', label: 'Total Client', sortOptions: '', sort: 'number' },
    { field: 'clientWithAutoPay', label: 'Client with Auto Pay', sortOptions: '', sort: 'number' },
    { field: 'totalServiceFee', label: 'Total Service Fee', sortOptions: '', sort: 'number' },
    { field: 'invoicePaid', label: 'Invoice Paid', sortOptions: '', sort: 'number' },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
  ];

  detailCols = [
    { field: 'stt', label: '#', sortOptions: '', sort: 'text' },
    { field: 'invoiceNumber', label: 'Invoice Number', sortOptions: '', sort: 'number' },
    { field: 'clientName', label: 'Client', sortOptions: '', sort: 'text' },
    { field: 'serviceFee', label: 'Service Fee', sortOptions: '', sort: 'number' },
    { field: 'balance', label: 'Balance', sortOptions: '', sort: 'number' },
  ];

  timespent = new Date();
  msgs: Message[] = [];
  companyInfo;

  textSortOptions = [
    { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
    { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
  ];

  numberSortOptions = [
    { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
    { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
  ];

  invoiceTypes = [
    { label: 'Billing', value: 'Billing' },
    { label: 'Dispatch', value: 'Dispatch' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Dispatching', value: 'Dispatching' },
    { label: 'OperrTel', value: 'OperrTel' },
  ];

  exportOptions = [
    { name: 'View Details', value: 'view' },
    { name: 'Download Invoice', value: 'download' },
  ];

  selectedSortOption = '';
  selectedField = '';
  invoiceSummaries: any = [];
  invoiceSummariesTmp: any = [];
  invoiceType: any;
  invoiceSummaryDetails: any = [];
  invoiceSummaryDetailsTmp: any = [];
  showDetails = false;
  exportAs: any;
  invoiceRef: any = {};
  filterInvoiceDetails = '';
  employees: any[];
  employeeUsername: any;
  filterInvoice: any;
  totalServiceFees = 0;
  totalInvoicePaid = 0;
  totalBalance = 0;

  summaryDetails: any = {
    totalServiceFee: 0,
    invoicePaid: 0,
    balance: 0,
  }
  routerEventsSubscribe;

  constructor(private authService: AuthService,
              private companyService: CompanyService,
              private dropDownsService: DropDownsService,
              private paymentInvoiceService: PaymentInvoiceService,
              private router: Router,
              private exportMonitorService: ExportMonitorService,
              private messageService: MessageService,
              private monitoringDetailsService: MonitoringDetailsService,
              private paymentCardDefaultService: PaymentCardDefaultService) {

    this.routerEventsSubscribe = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('app/payments/invoice-summary')) {
          this.monitoringDetailsService.monitorAction(
              `Navigated to Invoice Summary`,
              this.timespent,
              {
                navigated_to_accounts_aging_summary_by: this.authService.getCurrentLoggedInName()
              },
              'complete',
              `Navigated to Invoice Summary`,
              0
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.isClientRole = this.authService.isClientRole();
    this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();

    this.loadAllClientWithAutoPay();
    this.loadAllClient();
    this.getUserList();
    if (this.isPlatformAdmin) {
      this.loadAllCompanies();
    } else {
      this.selectedCompany = this.authService.getCurrentCompanyId();
      this.companyService.getCompanyByCompanyId(this.selectedCompany).subscribe(res => {
        this.companyInfo = res.data;
      });
    }
    const currentDate = new Date();
    this.toDate = currentDate;
    this.fromDate = this.firstDayPreviousMonth(currentDate);
    this.search();
  }
  ngOnDestroy() {
    if (this.routerEventsSubscribe) {
      this.routerEventsSubscribe.unsubscribe();
    }
  }

  firstDayPreviousMonth(originalDate) {
    const d = new Date(originalDate);
    d.setDate(0); // set to last day of previous month
    d.setDate(1); // set to the first day of that month
    return d;
  }

  loadAllCompanies() {
    this.dropDownsService.getAllCompanyList().subscribe((res) => {
      const resObj: any = res;
      this.allCompanyList = [];
      if (resObj.status === 'SUCCESS') {
        this.allCompanyList = _.sortBy(resObj.data, 'value');
      }
    });
  }

  changeCompany() {
    if (this.selectedCompany) {
      this.loadAllClientWithAutoPay();
      this.loadAllClient();
    }
  }

  loadAllClientWithAutoPay() {
    this.paymentCardDefaultService.search({ companyId: this.isPlatformAdmin ? this.selectedCompany : this.authService.getCurrentCompanyId() }).subscribe(res => {
      const resObj: any = res;
      this.clientWithAutoPayList = resObj?.data?.content || [];
    });
  }

  loadAllClient() {
    let companyId;
    if (this.isPlatformAdmin) {
      companyId = this.selectedCompany;
    } else {
      companyId = this.authService.getCurrentCompanyId();
    }
    const options: any = {
      size: 99999,
      page: 0,
      moduleName: 'billing',
      companyId: companyId,
    };
    if (this.isClientRole) {
      options.userId = this.authService.getCurrentLoggedInId();
      this.selectedClient = options.userId;
    }

    this.dropDownsService.getAllClientList(options).subscribe((res) => {
      this.clientList = [];
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data.sort((a, b) => a.value.localeCompare(b.value));
        this.clientList = data.map(c => ({ value: c.key, label: c.value, email: c.email }));
        this.allClientList = this.clientList;
      }
    });
  }

  search(event?: any) {
    // const options = this.buildOptions(event, event ? null : 0, event ? null : (this.table?.rows || 10));
    const options = this.buildOptions(event, 0, 9999);
    this.loadPage(options).then(() => {
      this.monitoringDetailsService.monitorAction(
          `Searched Invoice Summary`,
          this.timespent,
          {
            search_to_accounts_aging_summary_by: this.authService.getCurrentLoggedInName()
          },
          'complete',
          `Searched Invoice Summary`,
          0
      );
    });
  }

  buildOptions(event?: any, first?, rows?) {
    const options = <any>{
      page: first ? first : ((event && event.first >= 0 && event.rows > 0) ? (event.first / event.rows) : 0),
      size: rows ? rows : (event ? event.rows : 10)
    };

    this.sortField = this.sortField ? this.sortField : event && event.sortField ? event.sortField : 'createdAt';
    this.sortOrder = this.sortOrder ? this.sortOrder : event && event.sortOrder ? event.sortOrder : 0;

    if (this.sortField === 'createdAt') {
      this.sortOrder = 0;
    }

    options.sortField = this.sortField;
    options.sortOrder = this.sortOrder === 1 ? 'ASC' : 'DESC';

    if (this.isPlatformAdmin) {
      options.companyId = this.selectedCompany;
    } else {
      options.companyId = this.authService.getCurrentCompany();
    }

    if (this.fromDate) {
      options.fromDate = moment(this.fromDate).utc(true).startOf('day').toDate();
      options.startDate = options.fromDate;
    }

    if (this.toDate) {
      options.toDate = moment(this.toDate).utc(true).endOf('day').toDate();
      options.endDate = options.toDate;
    }

    if (this.invoiceType) {
      options.invoiceType = this.invoiceType;
    }

    options.oldInvoiceOnly = false;
    options.ignoreInvoiceDetails = true;
    if (this.selectedClient) {
      options.clientId = this.selectedClient;
    }

    options.employeeUserName = this.employeeUsername;
    return options;
  }

  setSortOption(field, selectedSortOption?) {
    this.cols.forEach((item) => {
      if (field === item.field) {
        item.sort = selectedSortOption;
        this.selectedSortOption = selectedSortOption;
      }
    });
  }

  sortingClick(selectedSortOption) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.filteredValues
    };

    // this.setSortOption(this.selectedField, selectedSortOption);
    this.customSort(options);
  }

  customSort(event) {
    if (this.sortF.length > 0) {
      if (event.data) {
        if (event.field === 'amount' || event.field === 'fee' ) {
          event.data.sort((row1, row2) => {
            let val1 = row1[event.field];
            let val2 = row2[event.field];
            if (val1?.toLowerCase) {
              val1 = val1.toLowerCase();
            }
            if (val2?.toLowerCase) {
              val2 = val2.toLowerCase();
            }
            if (val1 === val2) {
              return 0;
            }
            let result = -1;

            if (Number(val1) > Number(val2)) {
              result = 1;
            }
            if (event.order < 0) {
              result = -result;
            }
            return result;
          });
        } else if (event.field === 'invoiceDate') {
          event.data.sort((row1, row2) => {
            let val1 = row1['date'];
            let val2 = row2['date'];

            if (val1 === val2) {
              return 0;
            }
            let result = -1;

            if (moment(val1, 'DD/MM/YYYY') > moment(val2, 'DD/MM/YYYY')) {
              result = 1;
            }
            if (event.order < 0) {
              result = -result;
            }
            return result;
          });
        } else {
          event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
              result = -1;
            } else if (value1 != null && value2 == null) {
              result = 1;
            } else if (value1 == null && value2 == null) {
              result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
            } else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
          });
        }
      }
      this.sortF = '';
    }
  }

  async loadPage(options) {
    this.loading = true;
    await this.paymentInvoiceService.invoiceSummarySearch(options).subscribe(res => {
      this.loading = false;
      this.invoiceSummaries = [];
      this.totalRecords = 0;
      const resObj: any = res;

      if (resObj.status === 'SUCCESS') {
        const data = _.orderBy(resObj?.data?.content || [], ['invoiceDate'], ['desc']);
        // this.totalRecords = resObj.data.totalElements;

        data.forEach(item => {
          const invoiceDate = this.formatDate(item.invoiceDate);

          const pInvoice = this.invoiceSummaries.find(i => i.date === invoiceDate && i.invoiceType === item.invoiceType);
          if (pInvoice) {
            pInvoice.totalInvoices += 1;
            pInvoice.totalServiceFee = (pInvoice.totalServiceFee || 0) + (item.totalFee || 0);

            if (item.status !== 'Paid' && item.status !== 'Partial Paid') {
              pInvoice.balance = (pInvoice.balance || 0) + (item.balance || 0);
              // pInvoice.invoicePaid = 0;
            } else {
              pInvoice.balance = (pInvoice.balance || 0) + ((item.totalFee || 0) - (item.paidFee || 0));
            }
            pInvoice.invoicePaid = (pInvoice.invoicePaid || 0) + (item.paidFee || 0);

            const client = this.clientWithAutoPayList.find(c => c.userId === item.clientId);
            if (client && client.autoPay) {
              pInvoice.clientWithAutoPay = (pInvoice.clientWithAutoPay || 0) + 1;
            }

            const detail = {
              stt: (pInvoice.details?.length + 1) || 1,
              invoiceNumber: item.invoiceNumber,
              clientId: item.clientId,
              clientName: '',
              serviceFee: item.totalFee || 0,
              balance: (item.totalFee || 0) - (item.paidFee || 0),
              id: item.id,
              pdfLink: item.pdfLink,
            };

            pInvoice.details.push(detail);
            pInvoice.totalClient = _(pInvoice.details).groupBy('clientId').map((g, n) => ({n})).value().length;
          } else {
            const inv: any = {};
            inv.date = invoiceDate;
            inv.totalInvoices = 1;
            inv.invoiceType = item.invoiceType;
            inv.totalServiceFee = item.totalFee || 0;
            inv.totalClient = 1;

            const client = this.clientWithAutoPayList.find(c => c.userId === item.clientId);
            if (client && client.autoPay) {
              inv.clientWithAutoPay = 1;
            }

            if (item.status !== 'Paid' && item.status !== 'Partial Paid') {
              inv.balance = item.balance || 0;
              inv.invoicePaid = 0;
            } else {
              inv.invoicePaid = item.paidFee || 0;
              inv.balance = (item.totalFee || 0) - inv.invoicePaid;
            }

            inv.details = [
              {
                stt: 1,
                invoiceNumber: item.invoiceNumber,
                clientId: item.clientId,
                clientName: '',
                serviceFee: item.totalFee || 0,
                balance: item.balance || 0,
                id: item.id,
                pdfLink: item.pdfLink,
              }
            ];
            this.invoiceSummaries.push(inv);
          }
        });
        __assign(this.invoiceSummariesTmp, this.invoiceSummaries);
        this.totalRecords = this.invoiceSummariesTmp.length;
        this.totalServiceFees = this.invoiceSummaries.reduce((total, i) => total += i.totalServiceFee, 0);
        this.totalInvoicePaid = this.invoiceSummaries.reduce((total, i) => total += i.invoicePaid, 0);
        this.totalBalance = this.invoiceSummaries.reduce((total, i) => total += i.balance, 0);
      }
    }, () => {
      this.loading = false;
      this.invoiceSummaries = [];
      this.totalRecords = 0;
    });
  }

  sortDataSelected(selectedSortOption) {
    this.sortF = this.selectedField;
    const options = {
      field: this.selectedField,
      order: selectedSortOption === 'ASC' ? 1 : -1,
      mode: 'single',
      data: this.invoiceSummaries
    };
    this.customSort(options);
  }

  getCurrentDateString() {
    return moment().format('LL');
  }

  reset() {
    if (this.isPlatformAdmin) {
      this.selectedCompany = null;
    }
    this.selectedClient = null;
    this.fromDate = null;
    this.toDate = null;
    this.sortField = null;
    this.sortOrder = null;
    this.invoiceType = null;
    this.filterInvoice = null;
    this.table?.reset();
    this.search();
  }

  formatDate(date: any) {
    if (date) {
      return moment.utc(moment(date).format('MM/DD/YYYY')).local().format('MM/DD/YYYY');
    }
    return null;
  }

  openDetails(rowData) {
    this.showDetails = true;
    this.filterInvoiceDetails = '';
    this.detailsTable?.reset();
    this.invoiceSummaryDetails = (rowData.details || []).map(v => {
      v.clientName = this.clientList.find(c => c.value === v.clientId)?.label
      return v;
    });
    __assign(this.invoiceSummaryDetailsTmp, this.invoiceSummaryDetails);

    this.summaryDetails.totalServiceFee = rowData.totalServiceFee
    this.summaryDetails.invoicePaid = rowData.invoicePaid
    this.summaryDetails.balance = rowData.balance

    this.filteredValues = this.invoiceSummaryDetails;
  }

  clickInvoiceRef(event: any, rowData) {
    this.op3.toggle(event);
    this.invoiceRef = rowData;
  }

  invoiceRefAction(type: string) {
    switch (type) {
      case 'view': this.exportPdf(this.invoiceRef);
        break;
      case 'download': this.downloadInvoiceRefPdf();
        break;
    }
  }

  exportPdf(invoice) {
    this.op3.hide();
    if (invoice.pdfLink) {
      window.open(invoice.pdfLink, '_blank');
    } else {
      this.paymentInvoiceService.exportPdf(invoice.id).subscribe(res => {
        if (res.data) {
          const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          const data = <any>{
            featureName: 'Aging Summary',
            fileName: blobUrl,
            fileSize: blob.size,
            action: 'Export',
            createdByUsr: this.authService.getCurrentUsername(),
            companyId: this.authService.getCurrentCompanyId()
          };
          this.exportMonitorService.create(data).subscribe(() => {
          });
        }
      });
    }
  }

  downloadInvoiceRefPdf() {
    this.op3.hide();
    this.paymentInvoiceService.exportPdfWithName(this.invoiceRef.id).subscribe(res => {
      if (res.data) {
        const blob = FileUtility.b64toBlob(res.data.blob.body, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.target = '_blank';
        let fileName = 'Dummy.pdf';
        if (res.data.fileName && res.data.fileName.length > 0) {
          fileName = res.data.fileName;
        }
        a.download = fileName;
        a.click();
        const args = {
          ticket_invoice_download_by: this.authService.getCurrentLoggedInName(),
          Ticket_id: this.invoiceRef.invoiceId,
        };
      }
    });
  }

  getUserList(){
    this.employees = [];
    this.dropDownsService.getAllAdminNameListByCompany({companyId: this.authService.getCurrentCompanyId()}).subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((dt) => {
          if(!dt.name.includes('punchinout')) {
            const fullName = dt.name.slice(0, dt.name.indexOf('(') - 1);
            this.employees.push({id: dt.id, label: fullName, admin: 1, value: dt.userName});
          }
        });
      }
    });
  }

  exportSummaryDetail() {
    const doc = this.buildPdf();
    let output = doc.output('bloburl');
    window.open(output.toString(), '_blank');
  }

  buildPdf(): jsPDF {
    const pdfBody: any = this.getPdfBody();
    const doc = new jsPDF();
    autoTable(doc, {
      body: [
        [
          {
            content: "Invoice Summary Details",
            styles: {
              halign: 'center',
              fontSize: 20,
              fontStyle: 'bold'
            }
          }
        ],
        [
          {
            content: "Name: " + this.authService.getCurrentUsername(),
            styles: {
              halign: 'left',
              fontSize: 11
            }
          }
        ],
        [
          {
            content: "Date: " + moment().format('MM/DD/YYYY hh:mm a'),
            styles: {
              halign: 'left',
              fontSize: 11
            }
          }
        ]
      ],
      theme: 'plain'
    });

    autoTable(doc, {
      margin: 0,
      head: [
        [
          {
            content: '#',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 15
            }
          },
          {
            content: 'Invoice Number',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 40
            }
          },
          {
            content: 'Client',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 50
            }
          },
          {
            content: 'Service Fee',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 35
            }
          },
          {
            content: 'Balance',
            styles: {
              halign: 'left',
              fontSize: 11,
              fontStyle: 'bold',
              cellWidth: 35
            }
          }
        ],
      ],
      theme: 'striped',
      columnStyles: {
        0: {cellWidth: 15},
        1: {cellWidth: 40},
        2: {cellWidth: 50},
        3: {cellWidth: 35},
        4: {cellWidth: 35},
      },
      styles: {
        lineWidth: 0.5,
      },
      bodyStyles: {
        fillColor: 255
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245]
      },
      body: pdfBody,
    });
    return doc;
  }

  getPdfBody() {
    console.log('getPdfBody invoiceSummaryDetails: ', this.invoiceSummaryDetails)
    return this.invoiceSummaryDetails.map((row, i) => {
      return [
        {
          content: String(i + 1),
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.invoiceNumber,
          styles: {
            halign: 'left'
          }
        },
        {
          content: row.clientName ?  row.clientName : '',
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.serviceFee || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        },
        {
          content: '$' + (row.balance || 0).toFixed(2),
          styles: {
            halign: 'left'
          }
        }
      ]
    });
  }

  filterInvoiceTable(value) {
    if (value) {
      this.invoiceSummaries = this.invoiceSummariesTmp.filter(t => {
        const totalServiceFee = this.formatMoney(t.totalServiceFee);
        const invoicePaid = this.formatMoney(t.invoicePaid);
        const balance = this.formatMoney(t.balance);
        return ((t.date && t.date.toLowerCase().includes(value.toLowerCase())) || (t.totalInvoices && String(t.totalInvoices).toLowerCase().search(value.toLowerCase()) > -1)
            || (t.invoiceType && t.invoiceType.toLowerCase().search(value.toLowerCase()) > -1) || (t.totalClient && String(t.totalClient).toLowerCase().search(value.toLowerCase()) > -1)
            || (t.clientWithAutoPay && String(t.clientWithAutoPay).toLowerCase().search(value.toLowerCase()) > -1) || (totalServiceFee && totalServiceFee.toLowerCase().search(value.toLowerCase()) > -1)
            || (invoicePaid && invoicePaid.toLowerCase().search(value.toLowerCase()) > -1) || (balance && balance.toLowerCase().search(value.toLowerCase()) > -1));
      });
    } else {
      this.invoiceSummaries = this.invoiceSummariesTmp;
    }

    this.totalServiceFees = this.invoiceSummaries.reduce((total, i) => total += i.totalServiceFee, 0);
    this.totalInvoicePaid = this.invoiceSummaries.reduce((total, i) => total += i.invoicePaid, 0);
    this.totalBalance = this.invoiceSummaries.reduce((total, i) => total += i.balance, 0);
  }

  filterDetailsTable(value) {
    if (value) {
      this.invoiceSummaryDetails = this.invoiceSummaryDetailsTmp.filter(t => {
        const serviceFee = this.formatMoney(t.serviceFee);
        const balance = this.formatMoney(t.balance);
        return ((t.clientName && t.clientName.toLowerCase().search(value.toLowerCase()) > -1) || (t.invoiceNumber && t.invoiceNumber.toLowerCase().search(value.toLowerCase()) > -1)
            || (serviceFee && serviceFee.toLowerCase().search(value.toLowerCase()) > -1) || (balance && balance.toLowerCase().search(value.toLowerCase()) > -1));
      });
    } else {
      this.invoiceSummaryDetails = this.invoiceSummaryDetailsTmp;
    }
  }

  formatMoney(amount) {
    return amount ? amount.toFixed(2) : '';
  }
}
