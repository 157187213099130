import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../../../shared/model/page.model';
import { ProcessMonitorLoginActivity } from '../model/process-monitor-login-activity.model';
import { AbstractService } from '../../../shared/service/abstract.service';
import { Constants } from '../../../shared/model/constants';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PrcoessMonitorLoginActivityService extends AbstractService<ProcessMonitorLoginActivity> {

    constructor(
        private http: HttpClient) {
        super(http, Constants.ROUTES.OPERATOR, '');
    }

    getUserDetails(selectedAdmin: any, options?: any): Observable<Page<ProcessMonitorLoginActivity>> {
        const params = this.createParams(options);
        return this.http.get<Page<ProcessMonitorLoginActivity>>(this.baseUrl + 'auth/users/' + selectedAdmin, { params: params }).pipe(map(resp => resp));
    }

    findUserDetails(options?: any): Observable<Page<ProcessMonitorLoginActivity>> {
        return this._http.post<Page<ProcessMonitorLoginActivity>>(this.baseUrlV2 + 'user-activity/search', options).pipe(map(resp => resp));
    }

    exportPdf(options?: any): Observable<any> {
        return this._http.post<any>(this.baseUrlV2 + 'user-activity/pdf', options).pipe(map(resp => resp));
    }

    getUserDeskList(options?: any): Observable<Page<any>> {
        const params = this.createParams(options);
        return this.http.get<Page<any>>(this.baseUrlV2 + 'users-away/users-away', { params: params }).pipe(map(resp => resp));
    }

    getUserDeskDetail(id: number, trackDate: any): Observable<Page<any>> {
        return this.http.get<Page<any>>(this.baseUrlV2 + `users-away/users-away/${id}/${trackDate}`);
    }

    exportData(data, startDate, endDate, companyName, reportDate): Observable<any> {
        return this.http.post(this.baseUrlV2 + `users-away/users-away/export?startDate=${startDate}&endDate=${endDate}&companyName=${companyName}&reportDate=${reportDate}`, data);
    }
}
