
<p-dialog header=""
          [(visible)]="display"
          [modal]="true"
          [responsive]="true"
          [style]="{
            width: '700px',
            minWidth: '400px'
          }"
          (onHide)="onClickClose()"
          [minY]="70"
          [dismissableMask]="true"
          id="paymentConfirmationDialog"
          >
          <p-header>
            <span><i class="fa-solid fa-receipt mr-2"></i>Payment Confirmation</span>
          </p-header>
    <div id="payment-print" #paymentPrint>
        <div class="ui-g">
            <div class="text-center ui-g-12">
                =============RECEIPT CONFIRMATION=============
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
            <div class="ui-g-3">
               <b>Invoice:</b>
            </div>
            <div class="ui-g-9">
                {{data?.invoice}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport === 'TOP_UP' || functionImport === 'PLAN'">
            <div class="ui-g-3">
                <b>Plan:</b>
            </div>
            <div class="ui-g-9">
                {{data?.planTypeName}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport === 'TOP_UP'">
            <div class="ui-g-3">
                <b>SMS Available:</b>
            </div>
            <div class="ui-g-9">
                {{data?.totalSmsAvailable}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                <b>Description:</b>
            </div>
            <div class="ui-g-9" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
                OPERRWORK Invoices Payment
            </div>
            <div class="ui-g-9" *ngIf="functionImport === 'TOP_UP' || functionImport === 'PLAN'">
                {{data?.description}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                <b>Amount:</b>
            </div>
            <div class="ui-g-9">
                ${{data?.amount | number:'1.2-2'}} USD
            </div>
        </div>

        <div class="ui-g">
            <div class="ui-g-3">
                <b>Fee:</b>
            </div>
            <div class="ui-g-9">
                ${{data?.fee | number:'1.2-2'}} USD
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                <b>Payment Method:</b>
            </div>
            <div class="ui-g-9">
                {{data?.paymentCardType}}
            </div>
        </div>
        <div class="ui-g" *ngIf="functionImport !== 'TOP_UP' && functionImport !== 'PLAN'">
            <div class="ui-g-3">
                <b>Transaction Type:</b>
            </div>
            <div class="ui-g-9">
                Authorization and Capture
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-3">
                <b>Transaction ID:</b>
            </div>
            <div class="ui-g-9">
                {{data?.transactionPaymentId}}
            </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-12 text-center">
                ===============LINE ITEMS===============
            </div>
        </div>
    </div>
    <p-footer *ngIf="functionImport !== 'PRINT'">
        <button class="btn btn-primary" (click)="onSubmit()"><i class="fa-solid fa-check mr-2"></i>{{'Done' | translate}}</button>
    </p-footer>
    <p-footer *ngIf="functionImport === 'PRINT'" class="d-flex justify-content-between">
        <button type="button" class="btn btn-danger" (click)="onSubmit()"><i class="fa-solid fa-xmark mr-2"></i>{{'Close' | translate}}</button>
        <span>
            <button type="button" class="btn btn-purple mx-1" [useExistingCss]="true" printSectionId="payment-print" ngxPrint><i class="fa-solid fa-print mr-2"></i>{{'print' | translate}}</button>
            <button type="button" class="btn btn-info mx-1" (click)="sendEmail(paymentPrint)"><i class="fa-solid fa-paper-plane mr-2"></i>{{'Email' | translate}}</button>
        </span>
    </p-footer>
</p-dialog>
