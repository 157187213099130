import { Component, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, Message, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DocumentsService } from '../../../shared/service/documents-upload/documents.service';
import { AuthService } from '../../../shared/service/auth/auth.service';
import { CompanyService } from '../../service/company.service';
import { Company } from '../../model/company.model';
import { ContactInfoMappingService } from '../../../employee/service/contact-info-mapping.service';
import { CompanySetting } from '../../model/company-setting.model';
import { PayrollSetting } from '../../../payroll-setting/model/payroll-setting.model';
import { Agency } from '../../../agency/model';
import { AgencyService } from '../../../agency/service';
import { CMD_ACTION, AGENCY_PAGE } from '../../../agency/agency.constant';
import * as moment from 'moment';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { PlatformAdminService } from 'app/admin/platform-admin/service/platform-admin.service';
import { FileUtility } from 'app/shared/utility/file.utility';
import { ExportMonitorService } from 'app/admin/process-monitor/service/export-monitor.service';
import * as _ from 'lodash';
import {UtilsService} from "../../../shared/service/utils.service";
import {MonitoringDetailsService} from "../../../admin/process-monitor/service/monitoring-details.service";

@Component({
    selector: 'app-company-list',
    templateUrl: './company-list.component.html',
    styleUrls: ['./company-list.component.css'],
    providers: [CompanyService, DocumentsService, AuthService, ContactInfoMappingService]
})
export class CompanyListComponent implements OnInit {
    companies: Company[];
    selectedCompany: Company = new Company();
    totalRecords: Number = 0;
    items: MenuItem[];
    msgs: Message[] = [];
    loading = true;
    agency: Agency = new Agency();
    @ViewChild('dt', { static: true }) table: Table;
    userPermission: Boolean;
    rowData: any;
    cols: any[] = [];
    allCreatedByList: any = [];
    selectedSortOption = '';
    selectedField = '';
    textSearch;
    startDate;
    endDate;
    isDisableExport = false;

    textSortOptions = [
        { name: 'Sort A To Z', value: 'ASC', img: 'arrow-down-a-z' },
        { name: 'Sort Z To A', value: 'DESC', img: 'arrow-up-z-a' }
      ];
      numberSortOptions = [
        { name: 'Sort 1 To 9', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 9 To 1', value: 'DESC', img: 'arrow-up-9-1' }
      ];
      dateSortOptions = [
        { name: 'Sort 1 To 12', value: 'ASC', img: 'arrow-down-1-9' },
        { name: 'Sort 12 To 1', value: 'DESC', img: 'arrow-up-9-1' }
      ];
    filter: any = {
        fromDate: null,
        toDate: null,
        createdBy: '',
        operationalPolicy: null,
        status: '',
        page: 0,
        size: 25
    };
    ranges: any = {
        today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        Last7Days: [moment().subtract(6, 'days'), moment()],
        Last30Days: [moment().subtract(29, 'days'), moment()],
        ThisMonth: [moment().startOf('month'), moment().endOf('month')],
        LastMonth: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
    statusList = [];
    selectedStatus;
    isSettingActive: Boolean = false;
    isContractActive: Boolean = false;
    isCompanyAdmin: Boolean = false;
    isPlatformAdmin: boolean = false;
    role: number;
    exportingPDF = false;

    displayedFields: string[] = ['name'];
    planList: any;
    groups: any[];
    isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }
    operationalPolicies = [
        { label: 'Office', value: 'Office' },
        { label: 'Restaurant/Bar', value: 'Restaurant/Bar' },
    ];
    constructor(
        private platformAdminService: PlatformAdminService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private router: Router,
        private agencyService: AgencyService,
        private documentService: DocumentsService,
        private contactInfoMappingService: ContactInfoMappingService,
        private authService: AuthService,
        private translate: TranslateService,
        private exportMonitorService: ExportMonitorService,
        private translatePipe: TranslatePipe,
        private cookieService: CookieService,
        private messageService: MessageService,
        private utilsService: UtilsService,
        private monitoringDetailsService: MonitoringDetailsService,
        private datePipe: DatePipe) {
        let currentLang = 'en';
        if (this.cookieService.get('_currentLang')) {
            currentLang = this.cookieService.get('_currentLang');
        }
        this.translate.setDefaultLang(currentLang);
        this.translate.use(currentLang);
    }

    ngOnInit() {
        localStorage.removeItem('_companySetting');
        this.cols = [
            { label: 'cols.id', field: 'id', sortOptions: '', sort: 'number' },
            { label: 'cols.name', field: 'name', sortOptions: '', sort: 'text' },
            { label: 'cols.email', field: 'email', sortOptions: '', sort: 'text' },
            { label: 'cols.phone', field: 'phone', sortOptions: '', sort: 'number' },
            { label: 'OperrTel #', field: 'operrTel', sortOptions: '', sort: 'number' },
            { label: 'cols.address', field: 'addressOne', sortOptions: '', sort: 'text' },
        // { label: 'Operation Days of Week', field: 'daysWork', sortOptions: '', sort: 'text' },
            { label: 'cols.type', field: 'type', sortOptions: '', sort: 'text' },
            { label: 'Active Plan', field: 'planName', sortOptions: '', sort: 'text' },
            { field: 'createdAt', label: 'Date', sortOptions: '', sort: 'number' },
            { label: 'cols.status', field: 'status', sortOptions: '', sort: 'text' },
            { label: 'cols.action', field: 'actions' }
        ];
        // if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()){
        //     this.router.navigate(['app/company', 'view', this.authService.getCurrentCompany().id]);
        // } else {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.items = [
            { label: 'View', icon: 'fa-solid fa-magnifying-glass', command: (event) => this.viewCompany(this.selectedCompany) },
            { label: 'View', icon: 'fa-solid fa-xmark text-danger', command: (event) => this.deleteCompany(this.selectedCompany) }
        ];
        this.statusList = [
            { label: 'All', value: '' },
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' }
        ];
        this.companies = [];
        this.userPermission = this.authService.adminRole();
        let menuList = this.authService.getLoggedInUserMenuList();
        this.isSettingActive = (menuList.indexOf('78') >= 0) ? true : false;
        this.isContractActive = (menuList.indexOf('79') >= 0) ? true : false;
        // this.getAllCompanies();
        this.getPlanList();
        this.getCreatedByList();

        this.role = this.authService.getRoleLevel();
        // }

    }
    getCreatedByList() {
        let params: any = {};
        if (!this.isPlatformAdmin) {
            params["companyId"] = (this.authService.getCurrentCompany()) ? this.authService.getCurrentCompany() : null;
            // params["agencyId"] = (this.authService.getCurrentAgency()) ? this.authService.getCurrentAgency().id : null;
        }
        this.platformAdminService.getDropdown(params).subscribe((e: any) => {
            if (e.status === "SUCCESS") {
                e.data.forEach((item) => {
                    if (item.name.length > 0) {
                        this.allCreatedByList.push({ label: item.name, value: item.name });
                    }
                });
            }
        });
    }
    isSuperAdmin() {
        return this.role === 1 || this.role === 2;
    }
    translateRange() {
        const object: any = {};
        object[this.translatePipe.transform('today')] = [moment(), moment()];
        object[this.translatePipe.transform('Yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        object[this.translatePipe.transform('Last7Days')] = [moment().subtract(6, 'days'), moment()];
        object[this.translatePipe.transform('Last30Days')] = [moment().subtract(29, 'days'), moment()];
        object[this.translatePipe.transform('ThisMonth')] = [moment().startOf('month'), moment().endOf('month')];
        object[this.translatePipe.transform('LastMonth')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        return object;
    }

    searchBySelectedDate(date) {
        if (date.startDate && date.endDate) {
            this.filter.size = this.table.rows;
            this.filter.fromDate = this.datePipe.transform(date.startDate, 'yyyy-MM-dd');
            this.filter.toDate = this.datePipe.transform(date.endDate, 'yyyy-MM-dd');
        }
    }

    deleteCompany(d: Company) {
        this.companyService.delete(d.id).subscribe((_response) => {
            // this.getAllCompanies();
            this.loadCompaniesLazy();
        }, (error) => {
            console.log(error);
        });
    }

    editCompany(d: Company) {
        this.router.navigate(['app/company', 'edit', d.id]);
    }

    viewCompany(d: Company) {
        localStorage.setItem('_companyDetail', JSON.stringify(d));
        this.router.navigate(['app/company', 'view', d.id]);
    }

    companySetting(d: Company) {

        this.router.navigate(['app/company', 'setting', 'view', d.id]);
    }

    viewOtherSetting(d: Company) {
        const actionTime = this.utilsService.visitedPageData.pageActionTime;
        this.monitoringDetailsService.monitorNavigationProcess('Navigated to Other Settings',
            { 'navigated_to_other_settings': this.authService.getCurrentLoggedInName() },
            'Completed', 'Navigated to Other Settings', actionTime, false, false, true);
        this.router.navigate(['app/company', 'other', 'view', d.id]);
    }

    viewPayrollSetting(rowData: Company) {
        localStorage.removeItem('collapsedList');
        this.getAgency(rowData.id);
    }

    setPayroll(rowData: any) {
        let payrollSetting = new PayrollSetting();
        localStorage.setItem('_payrollSetting', JSON.stringify(rowData));
    }

    loadCompaniesLazy() {
        this.loading = true;
        this.loadPage({ page: 0, size: 9999 });
    }

    search(event) {
        this.table.first = 0;
        this.loading = true;
        //if (event.target.value) {
            this.loadPage({ page: 0, size: 9999, key: event.target.value });
        //} else {
        //    this.resetEmployeeSearchForm();
        //}
    }

    loadPage(options) {
        this.companies = [];
        this.loading = true;
        let searchOptions = {} as any;
        searchOptions.page = options.page;
        searchOptions.size = options.size;
        if (!this.isPlatformAdmin) {
            searchOptions.companyId = this.authService.getCurrentCompanyId();
        }

        if (options.searchKey && options.searchKey.length > 0) {
            searchOptions.key = options.searchKey;
        }

        if (options.sortField) {
            searchOptions.sortField = options.sortField;
            searchOptions.sortOrder = options.sortOrder;
        }

        if (this.filter.status !== null && this.filter.status !== undefined) {
            searchOptions.status = this.filter.status;
        }

        if (this.filter.fromDate && this.filter.toDate) {
            searchOptions.fromDate = this.datePipe.transform(this.filter.fromDate, 'yyyy-MM-dd');
            searchOptions.toDate = this.datePipe.transform(this.filter.toDate, 'yyyy-MM-dd');
            //searchOptions.fromDate = this.filter.fromDate;
           // searchOptions.toDate = this.filter.toDate;
        }

        if (this.filter.createdBy) {
            searchOptions.createdByUsr = this.filter.createdBy;
        }
        if (this.filter.operationalPolicy) {
            searchOptions.operationalPolicy = this.filter.operationalPolicy;
        }
        if(options.key) searchOptions.key = options.key;
        searchOptions.sortOrder = 'DESC';
        searchOptions.sortField = 'id';
        this.companyService.getCompaniesListByPage(searchOptions).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                setTimeout(() => {
                    this.loading = false;
                    if (resObj.data.content && (this.authService.isCompanyAd() || this.authService.isSubCompanyAd())) {
                        this.isCompanyAdmin = true;
                        this.companies = resObj.data.content.filter((ele) => ele.id == this.authService.getCurrentCompany());
                        this.totalRecords = this.companies.length;
                    } else {
                        this.companies = resObj.data.content;
                        this.totalRecords = resObj.data.totalElements;
                    }
                    this.filteredValues = this.companies;
                    console.log('this.companies', this.companies);
                    this.companies.forEach(item => {
                        item.type = this.getType(item.type);
                        item.planName = this.getPlanName(item.planId);
                    });
                }, 1000);

            }
        });
    }

    private getAllCompanies() {
        this.companyService.getAll().subscribe((result: any) => {
            this.companies = result.data;
        }, (_error) => {
            console.log(_error);
        });
    }

    companyContract(d: Company) {
        this.router.navigate(['app/company', 'contract', d.id]);
    }
    async filterByCompanyAndAgency(event?: any) {
        this.table.first = 0;
        this.filter.page = 0;
        this.filter.size = 9999;//this.table.rows;
        this.loadPage(this.filter);
    }

    resetEmployeeSearchForm() {
        this.filter = {
            fromDate: null,
            toDate: null,
            createdBy: '',
            status: '',
            operationalPolicy: null,
            page: 0,
            size: 99999
        };
        this.textSearch = null;
        this.table.reset();
        this.loadPage(this.filter);
    }
    sortingClick(selectedSortOption) {
        const sortForStatus = selectedSortOption;
        if (this.selectedField === 'status') {
            selectedSortOption = (selectedSortOption === 'ASC') ? 'DESC' : (selectedSortOption === 'DESC' ? 'ASC' : selectedSortOption);
        }
        selectedSortOption = selectedSortOption === 'ASC' ? 1 : -1;

        if (this.selectedField === 'operrTel') {
            this.filteredValues.sort((abs1, abs2) => {
                if (this.selectedSortOption === 'ASC') {
                    return this.sortAlphabet(
                        abs1.operrTel,
                        abs2.operrTel
                    );
                } else {
                    return this.sortAlphabet(
                        abs2.operrTel,
                        abs1.operrTel
                    );
                }
            });
            this.setSortOption(this.selectedField, selectedSortOption);
        } else {
            this.sort(this.selectedField, selectedSortOption);
        }
        
    }
    sort(fieldName: string, order: number) {

        this.filteredValues.sort((row1, row2) => {
            let val1 = row1[fieldName];
            let val2 = row2[fieldName];
            if(val1) {
                val1 = val1.toLowerCase();
            } 
            if(val2) {
                val2 = val2.toLowerCase();
            } 
            if (val1 === val2) {
                return 0;
            }
            let result = -1;
            if (fieldName === 'type' || fieldName === 'addressOne') {
                val1 = (val1 && isNaN(val1) && val1.trim() !== "") ? val1.toLowerCase() : val1;
                val2 = (val2 && isNaN(val2) && val2.trim() !== "") ? val2.toLowerCase() : val2;
                val1 = (val1 === null) ? "" : val1;
                val2 = (val2 === null) ? "" : val2;
            }
            if (val1 > val2) {
                result = 1;
            }
            if (order < 0) {
                result = -result;
            }
            return result;
        });
    }

    setSortOption(field, selectedSortOption?) {
        this.cols.forEach((item) => {
            if (field === item.field) {
                item.sortOptions = selectedSortOption;
                this.selectedSortOption = selectedSortOption;
            }
        });
    }

    showAlert() {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: `You don't have permissions!` });
    }

    getAgency(companyId) {
        this.agencyService.getPayrollSetting(companyId).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                if (resObj.data) {
                this.agency = resObj.data;
                this.setPayroll(this.agency);
                this.router.navigate(['app/company', 'payroll', 'view', companyId]);

                } else {
                    localStorage.removeItem('_payrollSetting');
                    this.router.navigate(['app/company', 'payroll', 'view', companyId]);
                }

            }
        });
    }

    getType(type: string) {
        switch (type) {
            case '1':
              return 'Sole proprietorship';
            case '2':
                return 'Partnership';
            case '3':
                return 'Corporation';
            case '4':
                return 'Limited Liability Company (LLC)';
            case '5':
                return 'Churches/ministries';
            default:
                return '';
        }
    }
  
    exportPdf() {
       this.exportingPDF = true;
        if (this.filteredValues && this.filteredValues.length > 0) {
            this.messageService.add({ severity: 'info', summary: 'Exporting File', detail: 'The exporting process has begun, please wait. This may take over a minute to complete.' });
            const data: any = {
                contentList: this.filteredValues.map(value => {
                    return {
                        id: value.id,
                        name: value.name || '',
                        email: value.email || '',
                        phone: value.phone || '',
                        operrTel: value.operrTel || '',
                        addressOne: value.addressOne || '',
                        type: value.type || '',
                        status: (value.status == 'ACTIVE' || value.status == 1) ? 'ACTIVE' : 'INACTIVE',
                        planName: value.planId ? this.getPlanName(value.planId) : '',
                        createdAt: this.datePipe.transform(value.createdAt, 'MM/dd/yyyy hh:mm:ss a')
                    };
                })
            };
            if (!this.isPlatformAdmin) {
                data.companyId = this.authService.getCurrentCompany();
            }
            data.username = this.authService.getCurrentUsername();
            data.reportDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy EEE hh:mm a');
            this.isDisableExport = true;
            this.companyService.exportPdf(data).subscribe(res => {
                if (res.data) {
                    const blob = FileUtility.b64toBlob(res.data.body, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);
                    window.open(blobUrl, '_blank');
                    this.isDisableExport = false;
                    this.exportingPDF = false;
                    this.messageService.add({ severity: 'success', summary: 'Export Complete', detail: 'The PDF file has been successfully created.' });
                    let data = <any>{
                        featureName: 'Company List',
                        fileName: blobUrl,
                        fileSize: blob.size,
                        action: 'Export',
                        createdByUsr: this.authService.getCurrentUsername(),
                        companyId: this.authService.getCurrentCompanyId()
                    };
                    this.exportMonitorService.create(data).subscribe(()=>{
                    });

                } else {
                    this.isDisableExport = false;
                    this.exportingPDF = false;
                    this.messageService.add({ severity: 'error', summary: 'Export Error', detail: 'Error exporting PDF file, try again. If this problem persists, please contact support.' });
                }
            });
        } else {
            this.exportingPDF = false;
            this.messageService.add({ severity: 'error', summary: 'Export Error', detail: 'Data not found.' });
        }
    }

    gotoBill(d) {
        this.router.navigate(['app/company', 'company-payment']);
    }

    sortAlphabet(alpha1: string, alpha2: string) {
        const alpha1ToUpperCase = alpha1 ? alpha1.toUpperCase() : '';
        const alpha2ToUpperCase = alpha2 ? alpha2.toUpperCase() : '';
        if (alpha1ToUpperCase > alpha2ToUpperCase) {
            return 1;
        } else {
            if (alpha1ToUpperCase === alpha2ToUpperCase) {
                return 0;
            }
            return -1;
        }
    }

    getPlanList() {
        this.planList = [];
        const adminType = ['PLAN'];
        this.companyService.getAdminType(adminType).subscribe(res => {
            const resObj: any = res;
            if (resObj.status === 'SUCCESS') {
                this.groups = resObj.data;
                if (this.groups.length > 0 ) {
                    this.groups.forEach(group => {
                        const groupName = group.name.split(/\s+/).map(e => _.capitalize(e)).join('');
                        this.planList.push({value: group.id, label : groupName });
                    });
                }
            }
            this.loadCompaniesLazy();
        }, err => {});
    }

    getPlanName(id: any) {
        const data = this.planList.filter((val) => {
            return val.value === id;
        });

        if (data && data[0]) {
            const data0: any =  data[0];
            return data0.label ? data0.label : '';
        } else {
            return '';
        }
    }

    filteredValues = [];
    onFilter(event, dt) {
        this.filteredValues = event.filteredValue;
        this.totalRecords = this.filteredValues.length;
    }

    ableToAccessSettings() {
      return this.authService.getLoggedInUserEditList().includes('1687');
    }

    ableToAccessPayrollSettings() {
      return this.authService.getLoggedInUserEditList().includes('1688');
    }

    ableToAccessOtherSettings() {
      return this.authService.getLoggedInUserEditList().includes('1689');
    }
}
