export const environmentLocal = {
  api: 'https://voip1.operrtel.com/api/addExtensionPjsipWeb.php',
  dndApi: 'https://voip1.operrtel.com/api/dndWeb.php',
  callForwardApi: 'https://voip1.operrtel.com/api/setCallForwardWeb.php',
  phoneSettingsApi: 'https://voip1.operrtel.com/api/extensionStatusWeb.php',
  callLog: 'https://voip1.operrtel.com/api/callLogWeb.php',
  listAvailableDids: 'https://voip1.operrtel.com/api/listAvailableDidsWeb.php'
};

const server_ip = 'https://qa2-api.operrwork.com';
const v2_server_ip = 'https://qa2-api.operrwork.com';
const operrtell_ip = 'https://prodapi.operrtel.com';

export const environment = {
  production: false,
  server_backend: server_ip, // pls change this field when change the server ip without '/api'
  server_ip: `${server_ip}/api`,
  v2_server_backend: `${v2_server_ip}`,
  v2_server_ip: `${v2_server_ip}`,
  operrtell_server_ip: `${operrtell_ip}`,
  ip_checking_site: 'https://api.ipify.org/?format=json',
  // server_backend: 'https://qa-backend-api.operrwork.com',
  // v2_server_backend: 'http://192.168.130.170:8080',
  // v2_server_ip: 'http://192.168.130.170:8080',
  // server_backend: 'http://192.168.120.187:8080',
  // server_ip: 'https://qa-backend-api.operrwork.com/api/v2',
  // server_ip: 'http://192.168.130.170:8080/api',
  // server_ip: 'https://qa-backend-api.operrwork.com/api',
  time_to_display_clock: 15,
  sip: {
    server: 'voip1.operrtel.com',
    websocketProxyUrl: 'wss://ws.operrtel.com:8089/ws',
    iceServers: [{url: 'stun:stun.l.google.com:19302'}]
  },
  chat_url: 'https://chatbox-dev.operr.com/#/init?',
  chat_api_key: '697ce7aa546b5dd022fb7e09ef4cb5fb7a2cf2b8',
  mail_url: 'https://mail.operr.com/',
  chat_api_url: 'https://chatbox-dev-api.operr.com/api/v1.0',
  conference_api_url: 'https://qa2-api.operrwork.com/meeting/api/v2/meeting',
  v2_api_url: "https://qa2-api.operrwork.com/company/api/v2",
  // v2_api_url: "http://localhost:8003/api/v2"
  payment_api_url: 'https://payment-gateway.operr.com/api/v1',
  payment_app_key: 'eaf8ee35d9f9c7335604437104afff4c',
  map_google_key: 'AIzaSyAAdRuq-hCMdR5W1591xLuHJUled9jlL_w',
  firebase: {
    apiKey: "AIzaSyAXKguIQG_sJArtDrm-4qFMecZPrJN5RmI",
    authDomain: "operrwork-qa.firebaseapp.com",
    databaseURL: "https://operrwork-qa.firebaseio.com",
    projectId: "operrwork-qa",
    storageBucket: "operrwork-qa.appspot.com",
    messagingSenderId: "6576601425",
    appId: "1:6576601425:web:8c4125e5e56c8744c77b70",
    measurementId: "G-8BB55RQJG4"
  },
  fustiontable: {
    apiUrl: 'https://www.googleapis.com/fusiontables/v2/query',
    apiKey: 'AIzaSyAAdRuq-hCMdR5W1591xLuHJUled9jlL_w',
    tableId: '1rMogw_5raP2ggDiK0S8k2-bDhQzf3yrmu4ObwcA_',
  },
  face_recognition_url: 'https://face-api.operrwork.com',
  face_recognition_system: 'Operrwork_QA',
  dispatch: {
    url: 'https://qa3-api.operr.com'
  },
  fe_url: 'https://qa2.operrwork.com/#/app',
  operrtel_socket: 'https://devsocket.operrtel.com'
};
