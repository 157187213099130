import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import { BreadcrumbService } from '../../../layout/service/breadcrumb.service';
import {AuthService} from '../../../shared/service/auth/auth.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {TranslateCustomerService} from '../../../shared/service/translate-custome.service';
import { MonitoringDetailsService } from 'app/admin/process-monitor/service/monitoring-details.service';
import { UtilsService } from 'app/shared/service/utils.service';
@Component({
    selector: 'app-company-layout',
    templateUrl: './company-layout.component.html',
    styleUrls: ['./company-layout.component.css']
})
export class CompanyLayoutComponent implements OnInit {

    basicMenutItems: MenuItem[] = [];

    defaultBreadCrumbs:MenuItem[] = []
    companyTabMenuItems: MenuItem[] = [];

    activeItem: MenuItem;
    isPlatformAdmin: Boolean = false;

    ngOnInit() {
        // this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
    }
    initTab() {
        this.companyTabMenuItems = [];
        if (this.authService.isSuper() || this.authService.isSubSuper() || this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) {
            this.companyTabMenuItems.push(...this.basicMenutItems);
        }
    }

    initBreadCrumbs()
    {
        this.defaultBreadCrumbs = [];
        this.defaultBreadCrumbs.push({ label: 'Operation' });
        this.defaultBreadCrumbs.push({ label: this.isPlatformAdmin ? 'Companies' : 'company.profile' });
    }

    routerEventsSubscribe;

    constructor(
        private router: Router,
        private breadcrumbService: BreadcrumbService,
        private authService: AuthService,
        private translate: TranslateService,
        private cookieService: CookieService,
        private monitoringDetailsService: MonitoringDetailsService,
        private utilsService: UtilsService,
    ) {
        this.isPlatformAdmin = this.authService.isSuper() || this.authService.isSubSuper();
        this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.cookieService.set('_currentLang', event.lang);
            this.translate.setDefaultLang(event.lang);
            this.translate.use(event.lang);
        });
        this.addMenuItems();
        this.routerEventsSubscribe = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if(event.url.indexOf('/app/company/list') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                } else if (event.url.indexOf('/app/company/view') >= 0) {
                    this.initTab();
                    this.companyTabMenuItems.push({ label: 'company.companyView', routerLink: ['view'] });
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'company.companyView', routerLink: ['view']});
                }  else if (event.url.indexOf('/app/company/edit') >= 0) {
                    this.companyTabMenuItems.push({ label: 'company.editCompany', routerLink: ['edit'] });
                } else if (event.url.indexOf('/app/company/setting/view') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.companyTabMenuItems.push({ label: 'company.viewCompanySetting',
                        id : 'view_company',
                        routerLink: ['view'] });
                    this.defaultBreadCrumbs.push({label: 'company.viewCompanySetting', routerLink: ['view']});
                    this.activeItem = this.companyTabMenuItems[this.companyTabMenuItems.length-1];
                }  else if (event.url.indexOf('/app/company/setting/edit') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'company.editCompanySetting', routerLink: ['edit']});
                    this.companyTabMenuItems.push({label: 'company.editCompanySetting', id : 'edit_company', routerLink: ['edit']});
                    this.activeItem = this.companyTabMenuItems[this.companyTabMenuItems.length-1];
                } else if (event.url.indexOf('/app/company/contract') >= 0) {
                    this.defaultBreadCrumbs = [];
                    this.defaultBreadCrumbs.push({ label: 'contractLabel', routerLink: ['contract'] });
                    this.companyTabMenuItems.push({ label: 'contractLabel',
                        id : 'company_contract',
                        routerLink: ['contract'] });
                } else if(event.url.indexOf('/app/company/add') >= 0) {
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({ label: 'company.addCompany' });
                } else if (event.url.indexOf('/app/company/payroll/view') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.companyTabMenuItems.push({label: 'company.viewPayrollSetting' ,  id : 'view_payroll_setting'});
                    this.defaultBreadCrumbs.push({label: 'company.viewPayrollSetting'});
                    this.activeItem = this.companyTabMenuItems[this.companyTabMenuItems.length-1];
                } else if (event.url.indexOf('/app/company/payroll/') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.companyTabMenuItems.push({label: 'company.payrollSetting', id : 'payroll_setting'});
                    this.defaultBreadCrumbs.push({label: 'company.payrollSetting'});
                    this.activeItem = this.companyTabMenuItems[this.companyTabMenuItems.length-1];
                } else if (event.url.indexOf('/app/company/other/') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.companyTabMenuItems.push({label: 'company.otherSetting', id : 'other_setting'});
                    this.defaultBreadCrumbs.push({label: 'company.otherSetting'});
                    this.activeItem = this.companyTabMenuItems[this.companyTabMenuItems.length-1];
                    
                    const actionTime = this.utilsService.visitedPageData.pageActionTime;
                    this.monitoringDetailsService.monitorNavigationProcess('Navigated to Other Settings',
                        {'navigated_to_other_settings': this.authService.getCurrentLoggedInName()},
                        'Completed', 'Navigated to Other Settings', actionTime, false, false, true);

                } else if (event.url.indexOf('/app/company/holiday') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'company.holidayManage'});
                } else if (event.url.indexOf('/app/company/document-settings') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'company.documentSettings'});
                } else if (event.url.indexOf('/app/company/company-payment') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Bill & Payments'});
                } else if (event.url.indexOf('/app/company/company-payment-method') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Payment Method'});
                } else if (event.url.indexOf('/app/company/operational-policy') >= 0) {
                    this.initTab();
                    this.initBreadCrumbs();
                    this.defaultBreadCrumbs.push({label: 'Operational Policy'});
                } else {
                    this.initTab();
                    this.initBreadCrumbs();
                }
                this.breadcrumbService.setItems(this.defaultBreadCrumbs);
            }
        });

    }

    ngOnDestroy() {
        if (this.routerEventsSubscribe) {
          this.routerEventsSubscribe.unsubscribe();
        }
    }

    addMenuItems() {
        console.log(this.authService.isCompanyAd());
        if (this.authService.isCompanyAd() || this.authService.isSubCompanyAd()) {
            this.basicMenutItems = [
                {label: this.isPlatformAdmin ? 'Companies' : 'company.profile', id : 'all_companies' , routerLink: ['list'],
                    visible: this.authService.getLoggedInUserMenuList().includes('5')},
                {label: 'company.holidayManage', id : 'holiday_manage', routerLink: ['holiday']},
                {label: 'company.documentSettings', id : 'menu_document_settings' , routerLink: ['document-settings/list'],
                    visible: this.isPlatformAdmin && this.authService.getLoggedInUserMenuList().includes('1176')},
                {label: 'Bill & Payments', id : 'company_payment', routerLink: ['company-payment']},
                {label: 'Add Payment Method', id : 'company_payment_method', routerLink: ['company-payment-method']},
                {label: 'Operational Policy', id : 'operation_policy', routerLink: ['operational-policy'],
                    visible: this.authService.isSubSuper() || this.authService.isSuper()},
            ];

        } else {
            this.basicMenutItems = [
                {label: this.isPlatformAdmin ? 'Companies' : 'company.profile', id : 'all_companies', routerLink: ['list'],
                    visible: this.authService.isEmployeeRole() ? true :
                      this.authService.getLoggedInUserMenuList().includes('5')
                },
                {label: 'company.addANewCompany', id : 'add_company', routerLink: ['add']},
                {label: 'company.holidayManage', id : 'holiday_manage', routerLink: ['holiday']},
                {label: 'company.documentSettings', id : 'menu_document_settings' , routerLink: ['document-settings/list'],
                    visible: this.isPlatformAdmin && this.authService.getLoggedInUserMenuList().includes('1176')},
                {label: 'Bill & Payments', id : 'company_payment', routerLink: ['company-payment']},
                {label: 'Add Payment Method', id : 'company_payment_method', routerLink: ['company-payment-method']},
                {label: 'Operational Policy', id : 'operation_policy', routerLink: ['operational-policy'],
                    visible: this.authService.isSubSuper() || this.authService.isSuper()},
            ];
        }

    }

}
